import React, {Component} from 'react'
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Paper from "@material-ui/core/es/Paper/Paper";
import Table from "@material-ui/core/es/Table/Table";
import TableHead from "@material-ui/core/es/TableHead/TableHead";
import TableCell from "@material-ui/core/es/TableCell/TableCell";
import TableBody from "@material-ui/core/es/TableBody/TableBody";
import TableRow from "@material-ui/core/es/TableRow/TableRow";
import {ReportConstant as ReportConstants} from "../ReportConstant";
import Constants from "../../Constants";
import PaginationComponent from "../../common/components/common/PaginationComponent";
import {reportCalls} from "../service/ReportService";
import {connect} from 'react-redux';

const styles = theme => ({
  paper: {
    margin: theme.spacing.unit * 1
  },
  pagination: {
    textAlign: "center"
  },
  cellSmall: {
    width: "100px"
  },
  tableCell: {
    padding: "4px"
  },

});

class CallReportTableComponent extends Component {

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
    };
    this.onPageChange = this.onPageChange.bind(this);
    this.refreshCalls = this.refreshCalls.bind(this);
  }

  /**
   * On page change
   *
   * @param page
   */
  onPageChange(page) {
    this.setState({
      ...this.state,
      page: page
    }, this.refreshCalls);
  }

  /**
   * refresh invoices
   */
  refreshCalls() {
    const {dispatch} = this.props;
    const {page} = this.state;
    dispatch(reportCalls(page));
  }

  render() {
    const {calls, classes} = this.props;
    const {page} = this.state;
    return (
      <Paper>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell + " " + classes.cellSmall}>#</TableCell>
              <TableCell align="right" className={classes.tableCell + " " + classes.cellSmall}>From Mobile</TableCell>
              <TableCell align="right" className={classes.tableCell + " " + classes.cellSmall}>To Mobile</TableCell>
              <TableCell align="right" className={classes.tableCell + " " + classes.cellSmall}>Date of call</TableCell>
              <TableCell align="right" className={classes.tableCell + " " + classes.cellSmall}>Month/Year</TableCell>
              <TableCell align="right" className={classes.tableCell + " " + classes.cellSmall}>Time of call</TableCell>
              <TableCell align="right" className={classes.tableCell + " " + classes.cellSmall}>Call Type</TableCell>
              <TableCell align="right" className={classes.tableCell + " " + classes.cellSmall}>Status</TableCell>
              <TableCell align="right" className={classes.tableCell + " " + classes.cellSmall}>Time of call
                end</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {calls.calls.map((row, key) => (
              <TableRow key={key}>
                <TableCell component="th" scope="row">
                  {key + 1}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {ReportConstants.maskNumber(row.from_mobile)}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {ReportConstants.maskNumber(row.mobile)}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {ReportConstants.formatDate(row.call_date)}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {ReportConstants.getMonthYear(row.call_date)}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>{row.time_of_call_start}</TableCell>
                <TableCell align="left" className={classes.tableCell}>{row.type}</TableCell>
                <TableCell align="left" className={classes.tableCell}>{row.status}</TableCell>
                <TableCell align="left" className={classes.tableCell}>{row.time_of_call_end}</TableCell>
              </TableRow>
            ))}
            {
              calls.calls.length === 0 &&
              <TableRow>
                <TableCell component="th" scope="row" colspan={7}>
                  NO RECORDS FOUND
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
        <div className={classes.pagination}>
          <PaginationComponent pagesPerSection={5} page={page}
                               totalPages={Constants.calculatePages(calls.totalRecords)}
                               onPageChange={this.onPageChange}/>
        </div>
      </Paper>);

  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
CallReportTableComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  calls: PropTypes.object.isRequired
};

/**
 * Map state to properties
 *
 * @param state
 * @returns {{auth: *}}
 */
const mapStateToProps = state => {
  const {} = state;

  return {};
};

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps)(CallReportTableComponent));