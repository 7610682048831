import React from 'react';
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import Button from "@material-ui/core/es/Button/Button";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import green from "@material-ui/core/es/colors/green";
import withStyles from "@material-ui/core/es/styles/withStyles";
import PatientProgramDocumentVerifyForm from "./forms/PatientProgramDocumentVerifyForm";
import restCallController from "../../../common/hoc/forms/restCallController";
import {acceptPatientProgramDocument, rejectPatientProgramDocument} from "../../service/PatientProgramDocumentService";
import Grid from "@material-ui/core/es/Grid/Grid";

const styles = theme => ({
  media: {
    height: 140,
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialogContent: {
    minWidth: 400
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  image: {
    width: "100%",
  }
});

class PatientProgramDocumentVerifyFormComponent extends React.Component {

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      document: {
        accept_comments: '',
        reject_reason: ''
      }
    };
    this.onModelChange = this.onModelChange.bind(this);
    this.reject = this.reject.bind(this);
    this.accept = this.accept.bind(this);
  }

  /**
   * On model field change
   *
   * @param model
   * @return {Function}
   */
  onModelChange = (model) => (field, value) => {
    this.setState({
      ...this.state,
      [model]: {
        ...this.state[model],
        [field]: value
      }
    });
  };

  /**
   * Remove patient program document
   */
  reject = () => {
    let {handlePromise, document} = this.props;
    handlePromise(rejectPatientProgramDocument(document.id, this.state.document));
  };

  /**
   * Accept patient program document
   */
  accept = () => {
    let {handlePromise, document} = this.props;
    handlePromise(acceptPatientProgramDocument(document.id, this.state.document));
  };

  // render view
  render() {
    const {classes, open, isFetching, handleClose, errors, document} = this.props;
    return (
      <Dialog open={open} onClose={handleClose} maxWidth="md"
              aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Document verification</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={0}>

            {/* Document image */}
            <Grid item md={6} sm={6}>
              <div className={classes.root}>
                {
                  document.document_id &&
                  (document.file_type === 'image/png' || document.file_type === 'image/jpeg') &&
                  <img src={document.document_path} className={classes.image}/>
                }
                {
                  document.document_id && document.file_type === 'application/pdf' &&
                  <img className={classes.image}
                       src="https://banner2.kisspng.com/20180426/zkw/kisspng-computer-icons-pdf-5ae16a1a6187a1.6588193515247222023995.jpg"/>
                }
              </div>
            </Grid>
            {/* Document image */}

            {/* Patient program document verification form */}
            <Grid item md={6} sm={6}>
              <PatientProgramDocumentVerifyForm model={this.state.document}
                                                onModelChange={this.onModelChange("document")}
                                                errors={errors}
                                                document={document}/>
            </Grid>
            {/* ./Patient program document verification form */}

          </Grid>
        </DialogContent>

        {/* Actions */}
        <DialogActions className={classes.wrapper}>
          {
            !(document.status === 'accepted') &&
            <Button onClick={this.accept} color="primary" disabled={isFetching}>
              Accept
            </Button>
          }
          {
            !(document.status === 'rejected') &&
            <Button onClick={this.reject} color="primary" autoFocus disabled={isFetching}>
              Reject
            </Button>
          }
          <Button onClick={handleClose} variant="outlined" color="secondary" autoFocus disabled={isFetching}>
            Close
          </Button>
          {isFetching && <CircularProgress size={24} className={classes.buttonProgress}/>}
        </DialogActions>
        {/* ./Actions */}

      </Dialog>
    );
  }
}

PatientProgramDocumentVerifyFormComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  document: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  successCallback: PropTypes.func.isRequired,
  failureCallback: PropTypes.func.isRequired
};

export default restCallController(withStyles(styles)(PatientProgramDocumentVerifyFormComponent));