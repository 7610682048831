import React, { Component } from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/es/Card/Card";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import withStyles from "@material-ui/core/es/styles/withStyles";
import { withSnackbar } from "notistack";
import Grid from "@material-ui/core/es/Grid/Grid";
import TableBody from "@material-ui/core/es/TableBody/TableBody";
import TableHead from "@material-ui/core/es/TableHead/TableHead";
import Table from "@material-ui/core/es/Table/Table";
import TableRow from "@material-ui/core/es/TableRow/TableRow";
import TableCell from "@material-ui/core/es/TableCell/TableCell";
import Paper from "@material-ui/core/es/Paper/Paper";
import Typography from "@material-ui/core/es/Typography/Typography";
import OrderStatus from "./OrderStatus";
import { OrderConstants } from "../OrderConstants";
import Constants from "../../Constants";

const styles = (theme) => ({
  card: {
    borderRadius: 0,
    height: "100%",
  },
  root: {
    width: "100%",
    overflowX: "auto",
    borderRadius: 0,
  },
  content: {
    padding: 0,
  },
  header: {
    marginBottom: 0,
    padding: 5,
  },
  table: {
    minWidth: 500,
    backgroundColor: "#eee",
  },
  tableHeader: {
    backgroundColor: "#eee",
  },
});

/**
 * Order list
 *
 * @return {*}
 * @constructor
 */
class OrderListComponent extends Component {
  state = {};

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.onOrderSelect = this.onOrderSelect.bind(this);
  }

  /**
   * On order select
   * @param order
   */
  onOrderSelect(order) {
    this.props.selectOrder(order);
  }

  //render content
  render() {
    const { success, data } = this.props.orders;
    const { classes, onSearch, selected_order } = this.props;

    return (
      <Card className={classes.card} elevation={24}>
        <CardContent className={classes.content}>
          <Grid container spacing={0}>
            <Grid item md={12} sm={12}>
              <Typography
                gutterBottom
                align="center"
                variant="h6"
                component="h5"
                className={classes.header}
              >
                ORDERS
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Paper className={classes.root} elevation={0}>
          {success && (
            <Table className={classes.table} margin="dense">
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell align="center">Order date</TableCell>
                  <TableCell align="center">Order for</TableCell>
                  <TableCell align="center">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.orders.map((o) => (
                  <TableRow
                    key={o.id}
                    onClick={() => {
                      this.onOrderSelect(o);
                    }}
                    className={o.id === selected_order.id ? "active" : ""}
                  >
                    <TableCell component="th" scope="row">
                      {o.id}
                    </TableCell>
                    <TableCell align="center">
                      {OrderConstants.convertToDate(o.order_date)}
                    </TableCell>
                    <TableCell align="center">{o.order_for}</TableCell>
                    <TableCell align="left">
                      <OrderStatus status={o.status} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Paper>
      </Card>
    );
  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
OrderListComponent.propTypes = {
  orders: PropTypes.object.isRequired,
  selected_order: PropTypes.object.isRequired,
  selectOrder: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withSnackbar(
  withStyles(styles, { withTheme: true })(OrderListComponent)
);
