import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Paper from "@material-ui/core/es/Paper/Paper";
import Table from "@material-ui/core/es/Table/Table";
import TableHead from "@material-ui/core/es/TableHead/TableHead";
import TableCell from "@material-ui/core/es/TableCell/TableCell";
import TableBody from "@material-ui/core/es/TableBody/TableBody";
import TableRow from "@material-ui/core/es/TableRow/TableRow";
import Grid from "@material-ui/core/es/Grid/Grid";
import { ReportConstant } from "../ReportConstant";

const styles = (theme) => ({
  paper: {
    overflowY: "scroll",
    maxHeight: "60vh",
  },
  tableCell: {
    padding: "4px",
  },
  cellSmall: {
    width: "100px",
  },
  verySmall: {
    width: "50px",
  },
});

class OrdersTableComponent extends Component {
  render() {
    const { orders, classes } = this.props;
    return (
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>Coupon id</TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    CFA
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    SKU + DOSE
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    QOS
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    Enrolment Date
                  </TableCell>
                  {/*<TableCell align="center" className={classes.tableCell}>FGO Initiated date</TableCell>*/}
                  <TableCell align="center" className={classes.tableCell}>
                    Infusion due date
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableCell + " " + classes.cellSmall}
                  >
                    FGO raised and sent to CFA
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableCell + " " + classes.verySmall}
                  >
                    TAT (FGO raised and sent – Infusion due date)
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    Date of Dispatch
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableCell + " " + classes.verySmall}
                  >
                    TAT Dispatched date- FGO raised and sent
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    Date of Delivery
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableCell + " " + classes.verySmall}
                  >
                    TAT ( Delivery- Dispatched date)
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableCell + " " + classes.verySmall}
                  >
                    Over All TAT Delivery – Infusion due date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.tableCell}
                    >
                      {row.fgo_code}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {row.cfa}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {row.brand_dose}
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      {/* {row.quantity} */}
                      {row.qos}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {ReportConstant.formatDate(row.enrolment_date)}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {ReportConstant.formatDate(row.infusion_date)}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {ReportConstant.formatDate(row.order_date)}
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      {ReportConstant.differenceInDates(
                        row.infusion_date,
                        row.order_date
                      )}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {ReportConstant.formatDate(row.dispatched_date)}
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      {ReportConstant.differenceInDates(
                        row.dispatched_date,
                        row.order_date
                      )}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {ReportConstant.formatDate(row.delivered_date)}
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      {ReportConstant.differenceInDates(
                        row.delivered_date,
                        row.dispatched_date
                      )}
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      {this.renderOverAllTat(
                        row.delivered_date,
                        row.infusion_date
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                {orders.length === 0 && (
                  <TableRow>
                    <TableCell component="th" scope="row" colspan={10}>
                      NO RECORDS FOUND
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    );
  }

  renderOverAllTat(delivered_date, infusion_date) {
    let difference = ReportConstant.differenceInDates(
      delivered_date,
      infusion_date
    );
    if (difference < 0) {
      return "Delivered " + Math.abs(difference) + " days before";
    } else if (difference > 0) {
      return "Delivered " + Math.abs(difference) + " days after";
    } else if (difference === 0) return "Delivered on Infusion date";
    return difference;
  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
OrdersTableComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  orders: PropTypes.array.isRequired,
};
export default withStyles(styles, { withTheme: true })(OrdersTableComponent);
