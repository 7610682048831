import Constants from "../Constants";
import moment from "moment";

export class OrderConstants {

  // order constants
  static ORDERS_URL = Constants.ORDER_SERVICE_API_ENDPOINT + 'orders';
  static ORDER_USERS_URL = Constants.ORDER_SERVICE_API_ENDPOINT + 'users';
  static ORDER_VENDORS_URL = Constants.ORDER_SERVICE_API_ENDPOINT + 'vendors';

  /**
   * Convert to date
   *
   * @param date
   * @return {string}
   */
  static convertToDate(date) {
    return moment(date).format("Do MMM, YY");
  }

  /**
   * Get modal style
   */
  static getModalStyle() {
    const top = 10;
    const left = 20;

    return {
      top: `${top}%`,
      left: `${left}%`
    };
  }

  /**
   * Get modal style
   */
  static getTopModalStyle() {
    const top = 0;
    const left = 20;

    return {
      top: `${top}%`,
      left: `${left}%`
    };
  }

  /**
   * Address to string
   *
   * @return {*}
   */
  static addressToString(address) {
    return (address.line + ", " + address.landmark + ", " + address.district + ", "
      + address.state + ", " + address.country + ", " + address.zip_code).replace(/(,\s)+/, ", ");
  };
}