import React, {Component} from 'react'
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Typography from "@material-ui/core/es/Typography/Typography";
import Card from "@material-ui/core/es/Card/Card";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import CardActions from "@material-ui/core/es/CardActions/CardActions";
import Button from "@material-ui/core/es/Button/Button";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import green from "@material-ui/core/es/colors/green";
import moment from "moment";
import {createFollowUp, updateFollowUp} from "../../service/FollowUpService";
import FollowUpForm from "./forms/FollowUpForm";
import {PatientProgramConstant} from "../../PatientProgramConstant";
import restCallController from "../../../common/hoc/forms/restCallController";
import * as lodash from "lodash";


const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 19,
  },
  card: {
    borderRadius: 0
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});


/**
 * Update patient program form
 *
 * @param patient
 * @return {*}
 * @constructor
 */
class FollowUpFormComponent extends Component {

  /**
   * Patient form data
   */
  state = {
    follow_up: {
      patient_program_id: '', date: '', time: '', comment: '', selected_date: new Date(),
      status: 'pending'
    }
  };

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      follow_up: {
        ...this.state.follow_up,
        patient_program_id: props.patient_program.id
      }
    };

    // if follow up already exists
    if (props.follow_up) {
      this.state = {
        ...this.state,
        follow_up: {
          ...this.state.follow_up,
          ...this.props.follow_up
        }
      };
    }
  }

  /**
   * On value change
   *
   * @param name
   */
  onFieldChange = name => event => {
    this.setState({
      ...this.state,
      follow_up: {
        ...this.state.follow_up,
        [name]: event.target.value,
      }
    });
  };

  /**
   * Update patient
   */
  save = () => {
    let {handlePromise} = this.props;
    let follow_up = lodash.clone(this.state.follow_up);
    let m = moment(follow_up.selected_date);
    follow_up.date = m.format("YYYY-MM-DD");
    follow_up.time = m.format("HH:mm:ss");
    if (!follow_up.id)
      handlePromise(createFollowUp(follow_up));
    else
      handlePromise(updateFollowUp(follow_up.id, follow_up));
  };

  /**
   * On model field change
   *
   * @param model
   * @return {Function}
   */
  onModelChange = (model) => (field, value) => {
    this.setState({
      ...this.state,
      [model]: {
        ...this.state[model],
        [field]: value
      },
    });
  };

  /**
   * Render view
   * @return {*}
   */
  render() {
    const {classes, isFetching, errors} = this.props;
    const {follow_up} = this.state;
    return (
      <Card className={classes.card}>
        <AppBar position="static">
          <Toolbar variant="dense">
            <Typography variant="h6" color="inherit">
              Follow up form
            </Typography>
          </Toolbar>
        </AppBar>
        <CardContent>
          <form className={classes.container} noValidate autoComplete="off">
            <FollowUpForm model={follow_up} onModelChange={this.onModelChange('follow_up')}
                          errors={errors}
                          data={{statuses: PatientProgramConstant.FOLLOW_UP_STATUSES}}
            />
          </form>
        </CardContent>
        <CardActions dir="rtl">
          <Button variant="outlined" color="secondary" onClick={this.props.close} disabled={isFetching}>
            Close
          </Button>
          <div className={classes.wrapper}>
            <Button variant="contained" color="primary" onClick={this.save} disabled={isFetching}>
              {isFetching ? "Saving" : "Save"}
            </Button>
            {isFetching && <CircularProgress size={24} className={classes.buttonProgress}/>}
          </div>
        </CardActions>
      </Card>
    );
  }
}

FollowUpFormComponent.propTypes = {
  patient_program: PropTypes.object.isRequired,
  close: PropTypes.func,
  follow_up: PropTypes.object,
  classes: PropTypes.object.isRequired,
  successCallback: PropTypes.func.isRequired,
  failureCallback: PropTypes.func.isRequired
};

export default restCallController((withStyles(styles)(FollowUpFormComponent)));
