import React from 'react'
import PropTypes from "prop-types";
import Grid from "@material-ui/core/es/Grid/Grid";
import withStyles from "@material-ui/core/es/styles/withStyles";
import formController from "../../../../common/hoc/forms/formController";
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";
import OutlinedInput from "@material-ui/core/es/OutlinedInput/OutlinedInput";
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import InputLabel from "@material-ui/core/es/InputLabel/InputLabel";
import Select from "@material-ui/core/es/Select/Select";
import TextField from "@material-ui/core/es/TextField/TextField";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  picker: {
    marginLeft: 8,
    marginRight: 8
  }
});

class InvoiceItemForm extends React.Component {

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
  }

  /**
   * Render view
   */
  render() {
    const {classes, onFieldChange, model, data: {products}, errors} = this.props;
    return (<Grid container spacing={8}>
      <Grid item xs={4}>
        <FormControl variant="outlined" className={classes.picker} fullWidth={true} margin="dense">
          <InputLabel ref={ref => {
            this.InputLabelRef = ref;
          }} htmlFor="status">Product</InputLabel>
          <Select value={model.product_id} onChange={onFieldChange}
                  input={
                    <OutlinedInput labelWidth={100} name="product_id" id="product-id"/>
                  }>
            {
              products.map((d, i) => {
                return <MenuItem value={d.id} key={i}>{d.name}</MenuItem>
              })
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <TextField id="quantity" label="Qty" className={classes.textField} name="quantity"
                   defaultValue={model.quantity} onChange={onFieldChange}
                   margin="dense" fullWidth={true} variant="outlined"/>
      </Grid>
      <Grid item xs={4}>
        <TextField id="uom" label="Uom" className={classes.textField} name="uom"
                   defaultValue={model.uom} onChange={onFieldChange}
                   margin="dense" fullWidth={true} variant="outlined"/>
      </Grid>
    </Grid>);
  }
}

InvoiceItemForm.propTypes = {
  data: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired
};

export default formController(withStyles(styles)(InvoiceItemForm));