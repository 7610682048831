import React from "react";
import PropTypes from "prop-types";
import PatientPersonalDetailTableComponent from "./PatientPersonalDetailTableComponent";
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import Tab from "@material-ui/core/es/Tab/Tab";
import Tabs from "@material-ui/core/es/Tabs/Tabs";

import withStyles from "@material-ui/core/es/styles/withStyles";
import Card from "@material-ui/core/es/Card/Card";
import CardActions from "@material-ui/core/es/CardActions/CardActions";
import Button from "@material-ui/core/es/Button/Button";
import Modal from "@material-ui/core/es/Modal/Modal";
import PatientProgramTableComponent from "./patient_program/PatientProgramTableComponent";
import PatientProgramDocumentsComponent from "./patient_program_document/PatientProgramDocumentsComponent";
import FulfillmentFormComponent from "./fulfillment/FulfillmentFormComponent";
import { withSnackbar } from "notistack";
import PatientFormComponent from "./PatientFormComponent";
import PatientProgramFormComponent from "./patient_program/PatientProgramFormComponent";
import { PatientProgramConstant } from "../PatientProgramConstant";
import SendLinkToPatientComponent from "./patient_program/SendLinkToPatientComponent";
import EmptySpace from "../../common/components/common/EmptySpace";
import InvoiceCardList from "./invoice/lists/InvoiceCardList";
import Paper from "@material-ui/core/es/Paper/Paper";
import OrderCardList from "./order/lists/OrderCardList";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 120,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: "none",
  },
  card: {
    borderRadius: 0,
  },
  invoicesCards: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 1,
    paddingBottom: theme.spacing.unit * 1,
  },
});

// get modal style
function getModalStyle() {
  const top = 5;
  const left = 10;

  return {
    top: `${top}%`,
    left: `${left}%`,
  };
}

class PatientProgramDetailComponent extends React.Component {
  /**
   * State of component
   * @type {{value: number}}
   */
  state = {
    arrow: false,
    arrowRef: null,
    value: 0,
    update_patient: false,
    update_patient_program: false,
    send_to_cfa: false,
    send_link_to_patient: false,
  };

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);

    // func linkage
    this.toggleUpdatePatientFlag = this.toggleUpdatePatientFlag.bind(this);
    this.toggleSendLinkToPatient = this.toggleSendLinkToPatient.bind(this);
    this.toggleSendToCfaFlag = this.toggleSendToCfaFlag.bind(this);
    this.toggleUpdatePatientProgramFlag =
      this.toggleUpdatePatientProgramFlag.bind(this);
  }

  /**
   * on tab value change
   *
   * @param event
   * @param value
   */
  handleChange = (event, value) => {
    this.setState({ value: value });
  };

  /**
   * Patient toggle
   */
  toggleUpdatePatientFlag = () => {
    this.setState({
      ...this.state,
      update_patient: !this.state.update_patient,
    });
  };

  /**
   * Cfa toggle
   */
  toggleSendToCfaFlag = () => {
    this.setState({ ...this.state, send_to_cfa: !this.state.send_to_cfa });
  };

  /**
   * Patient program toggle
   */
  toggleUpdatePatientProgramFlag = () => {
    this.setState({
      ...this.state,
      update_patient_program: !this.state.update_patient_program,
    });
  };

  /**
   * send link
   */
  toggleSendLinkToPatient = () => {
    this.setState({
      ...this.state,
      send_link_to_patient: !this.state.send_link_to_patient,
    });
  };

  /**
   * Send link
   */
  sendLinkSuccessCallback = () => {
    let { enqueueSnackbar } = this.props;
    enqueueSnackbar("Link sent to patient successfully!");
    this.toggleSendLinkToPatient();
  };

  /**
   * Send link
   */
  orderEmailSentSuccessCallback = () => {
    let { enqueueSnackbar } = this.props;
    enqueueSnackbar("Email sent successfully!");
  };

  /**
   * Update patient success callback
   */
  updatePatientSuccessCallback = () => {
    let { enqueueSnackbar, refreshPatient } = this.props;
    refreshPatient();
    this.toggleUpdatePatientFlag();
    enqueueSnackbar("Patient updated successfully!");
  };

  /**
   * Send to cfa success callback
   */
  sendToFulfillmentSuccessCallback = () => {
    let { enqueueSnackbar, refreshPatientProgram } = this.props;
    this.toggleSendToCfaFlag();
    refreshPatientProgram();
    enqueueSnackbar("Send to CFA for fulfillment successfully!");
  };

  /**
   * Update patient program success callback
   */
  updatePatientProgramSuccessCallback = () => {
    let { enqueueSnackbar, refreshPatientProgram } = this.props;
    this.toggleUpdatePatientProgramFlag();
    refreshPatientProgram();
    enqueueSnackbar("Program details updated successfully!");
  };

  /**
   * Render ui
   */
  render() {
    const {
      value,
      update_patient_program,
      send_to_cfa,
      send_link_to_patient,
      update_patient,
    } = this.state;
    const {
      classes,
      patient_program,
      refreshPatientProgram,
      document_groups,
      invoicesForPatientProgram,
      ordersForPatient,
      sendOrderEmail,
    } = this.props;
    const { isFetching, success } = this.props.patient;

    // return if not data found
    if (isFetching || !success || !this.props.patient.data.patient) return null;

    // get patient details
    const {
      data: { patient },
    } = this.props.patient;
    let {
      cfa_list: { data, success: cfaListSuccess },
    } = this.props;
    return (
      <div className={classes.root}>
        {/* tabs */}
        <AppBar position="static">
          <Tabs value={value} onChange={this.handleChange}>
            <Tab label="Personal" value={0} />
            {/*<Tab label={"Documents (" + (patient.documents ? patient.documents.length : 0) + ")"}/>*/}
            <Tab label="Program" value={2} />
            <Tab label="Program documents" value={3} />
            <Tab label="Invoices" value={4} />
          </Tabs>
        </AppBar>
        {/* ./tabs */}

        {/* Patient details table */}
        {value === 0 && (
          <Card elevation={0} className={classes.card}>
            <PatientPersonalDetailTableComponent patient={patient} />
            <CardActions dir="rtl">
              <Button
                size="small"
                color="primary"
                variant="outlined"
                onClick={this.toggleUpdatePatientFlag}
              >
                Edit
              </Button>
            </CardActions>
          </Card>
        )}
        {/* ./Patient details table */}

        {/* Patient program form */}
        {value === 2 && update_patient_program && (
          <PatientProgramFormComponent
            data={{
              treatment_cycles: PatientProgramConstant.TREATMENT_CYCLES,
              products: patient_program.program.products,
            }}
            patient_program={patient_program}
            successCallback={this.updatePatientProgramSuccessCallback}
            failureCallback={() => {}}
            close={this.toggleUpdatePatientProgramFlag}
          />
        )}
        {/* ./Patient program form */}

        {/* Patient program details table */}
        {value === 2 && !update_patient_program && !send_to_cfa && (
          <Card elevation={0}>
            <PatientProgramTableComponent patient_program={patient_program} />
            <CardActions dir="rtl">
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={this.toggleUpdatePatientProgramFlag}
              >
                Edit
              </Button>
              <Button
                size="small"
                color="secondary"
                variant="outlined"
                onClick={this.toggleSendToCfaFlag}
              >
                Send to CFA
              </Button>
              <Button
                className={classes.button}
                variant="outlined"
                color="primary"
                onClick={this.toggleSendLinkToPatient}
                size="small"
              >
                Send link to patient
              </Button>
              <SendLinkToPatientComponent
                successCallback={this.sendLinkSuccessCallback}
                failedCallback={() => {}}
                data={{ patient_program: patient_program, patient: patient }}
                open={send_link_to_patient}
                close={this.toggleSendLinkToPatient}
              />
            </CardActions>
          </Card>
        )}
        {/* ./Patient program details table */}

        {/* Fulfillment form */}
        {value === 2 && send_to_cfa && cfaListSuccess && (
          <FulfillmentFormComponent
            data={{
              patient_program: patient_program,
              patient: patient,
              cfa_list: data.users,
            }}
            successCallback={this.sendToFulfillmentSuccessCallback}
            failureCallback={() => {}}
            close={this.toggleSendToCfaFlag}
          />
        )}

        {/* Patient program documents */}
        {value === 3 && (
          <PatientProgramDocumentsComponent
            documents={patient_program.documents}
            products={this.props.products}
            document_groups={document_groups}
            refreshPatientProgram={refreshPatientProgram}
            patient_program={patient_program}
          />
        )}
        {/* ./Patient program documents */}

        {value === 4 &&
          invoicesForPatientProgram.success &&
          ordersForPatient.success && (
            <Paper elevation={0} className={classes.invoicesCards}>
              <InvoiceCardList
                invoices={invoicesForPatientProgram.data.invoices}
              />
              <OrderCardList
                orders={ordersForPatient.data.orders}
                successCallback={this.orderEmailSentSuccessCallback}
              />
            </Paper>
          )}

        {/* ./Fulfillment form */}

        {/* Patient form */}
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={update_patient}
          onClose={this.toggleUpdatePatientFlag}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <PatientFormComponent
              patient={patient}
              successCallback={this.updatePatientSuccessCallback}
              failureCallback={() => {}}
              close={this.toggleUpdatePatientFlag}
            />
          </div>
        </Modal>
        {/* ./Patient form */}
        <EmptySpace />
      </div>
    );
  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
PatientProgramDetailComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  patient: PropTypes.object.isRequired,
  products: PropTypes.object.isRequired,
  invoicesForPatientProgram: PropTypes.object.isRequired,
  ordersForPatient: PropTypes.object.isRequired,
  document_groups: PropTypes.array.isRequired,
  patient_program: PropTypes.object.isRequired,
  cfa_list: PropTypes.object.isRequired,
  refreshPatientProgram: PropTypes.func.isRequired,
  refreshPatient: PropTypes.func.isRequired,
};

export default withSnackbar(withStyles(styles)(PatientProgramDetailComponent));
