import {get, postForm} from "../../common/services/BaseService";
import {failedPatient, requestPatient, successPatient} from "../reducers/actions/patient-actions";
import {PatientConstants} from "../PatientConstants";
import {
  failedPatientProgramDocumentsForPatientProgram,
  failedPatientPrograms,
  requestPatientProgramDocumentsForPatientProgram,
  requestPatientPrograms,
  successPatientProgramDocumentsForPatientProgram,
  successPatientPrograms
} from "../reducers/actions/patient-program-actions";


/**
 * Fetch patient
 *
 * @returns {function(*): Promise<any>}
 * @param id
 */
export const getPatient = (id) => dispatch => {
  dispatch(requestPatient());
  return get(PatientConstants.PATIENTS_URL + '/' + id).then(json => {
    dispatch(successPatient(json));
    return json;
  }).catch(error => {
    dispatch(failedPatient(error));
    throw(error);
  });
};

/**
 * Find patient programs
 *
 * @returns {function(*): Promise<any>}
 */
export const findPatientPrograms = () => dispatch => {
  dispatch(requestPatientPrograms());
  return get(`${PatientConstants.PATIENT_PROGRAMS_URL}`).then(json => {
    dispatch(successPatientPrograms(json));
    return json;
  }).catch(error => {
    dispatch(failedPatientPrograms(error));
    throw(error);
  });
};

/**
 * Find patient program documents for patient program
 *
 * @returns {function(*): Promise<any>}
 */
export const findPatientProgramDocumentsForPatientProgram = (patient_program_id) => dispatch => {
  dispatch(requestPatientProgramDocumentsForPatientProgram());
  return get(`${PatientConstants.PATIENT_PROGRAMS_URL}/${patient_program_id}/documents`).then(json => {
    dispatch(successPatientProgramDocumentsForPatientProgram(json));
    return json;
  }).catch(error => {
    dispatch(failedPatientProgramDocumentsForPatientProgram(error));
    throw(error);
  });
};

/**
 * Upload patient document
 *
 * @param patient_id
 * @param file
 * @return {Promise<Response>}
 */
export const uploadPatientDocument = (patient_id, file) => {
  return postForm(`${PatientConstants.PATIENTS_URL}/${patient_id}/documents`, file).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};
