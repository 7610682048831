import React from 'react'
import PropTypes from "prop-types";
import Grid from "@material-ui/core/es/Grid/Grid";
import withStyles from "@material-ui/core/es/styles/withStyles";
import formController from "../../../../common/hoc/forms/formController";
import OutlinedInput from "@material-ui/core/es/OutlinedInput/OutlinedInput";
import Select from "@material-ui/core/es/Select/Select";
import InputLabel from "@material-ui/core/es/InputLabel/InputLabel";
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";
import FormLabel from "@material-ui/core/es/FormLabel/FormLabel";
import RadioGroup from "@material-ui/core/es/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/es/FormControlLabel/FormControlLabel";
import {PatientProgramConstant} from "../../../PatientProgramConstant";
import Radio from "@material-ui/core/es/Radio/Radio";
import DateFnsUtils from "@date-io/date-fns/build/index";
import {DatePicker, MuiPickersUtilsProvider} from "material-ui-pickers";
import FormHelperText from "@material-ui/core/es/FormHelperText/FormHelperText";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  }
});

class FulfillmentForm extends React.Component {

  /**
   * On expected delivery date
   *
   * @param date
   */
  onExpectedDeliveryDateChange = (date) => {
    let {onFieldChange} = this.props;
    onFieldChange({target: {name: 'expected_delivery_date_picker', value: date}});
  };

  /**
   * On patient cycle date
   *
   * @param date
   */
  onPatientCycleDateChange = (date) => {
    let {onFieldChange} = this.props;
    onFieldChange({target: {name: 'patient_cycle_date_picker', value: date}});
  };

  render() {
    // TODO:: convert CFA selection to auto-complete
    const {classes, onFieldChange, model, data: {users}, patient, errors} = this.props;
    console.log(errors);
    return (<Grid container spacing={8}>
      <Grid item xs={12}>
        <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
          <InputLabel ref={ref => {
            this.InputLabelRef = ref;
          }} htmlFor="document-group">Select CFA</InputLabel>
          <Select value={model.user_id} onChange={onFieldChange}
                  input={
                    <OutlinedInput labelWidth={100} name="user_id" id="cfa"/>
                  }>
            {
              users.map((d, i) => {
                return <MenuItem value={d.id} key={i}>{d.full_name} ({d.email})</MenuItem>
              })
            }
          </Select>
          {
            errors && errors.user_id &&
            <FormHelperText error={true}>{errors.user_id}</FormHelperText>
          }
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker margin="dense" variant="outlined" fullWidth={true} label="Expected delivery date"
                      value={model.expected_delivery_date_picker} format={"dd MMM` yyyy"}
                      onChange={this.onExpectedDeliveryDateChange} className={classes.picker}
                      mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker margin="dense" variant="outlined" fullWidth={true} label="Patient cycle date"
                      value={model.patient_cycle_date_picker} format={"dd MMM` yyyy"}
                      onChange={this.onPatientCycleDateChange} className={classes.picker}
                      mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={1}>
      </Grid>
      {
        patient.primaryAddress &&
        <Grid item xs={4}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">SELECT ADDRESS</FormLabel>
            <RadioGroup aria-label="Address" name="address_str" className={classes.group}
                        value={model.address_str}
                        onChange={onFieldChange}>
              <FormControlLabel value="primary" control={<Radio/>}
                                label={`Primary`}/>
            </RadioGroup>
            {PatientProgramConstant.addressToString(patient.primaryAddress)}
          </FormControl>
        </Grid>
      }
      <Grid item xs={2}>
      </Grid>
      {
        patient.secondaryAddress &&
        <Grid item xs={4}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">&nbsp;</FormLabel>
            <RadioGroup aria-label="Address" name="address_str" className={classes.group}
                        value={model.address_str}
                        onChange={onFieldChange}>
              {
                <FormControlLabel value="secondary" control={<Radio/>}
                                  label={`Secondary`}/>
              }
            </RadioGroup>
            {PatientProgramConstant.addressToString(patient.secondaryAddress)}
          </FormControl>
        </Grid>
      }
    </Grid>);
  }
}

FulfillmentForm.propTypes = {
  patient: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired
};

export default formController(withStyles(styles)(FulfillmentForm));