import moment from "moment/moment";
import * as lodash from "lodash";

export default class Constants {
  // default page length
  static PAGINATION_LENGTH = 50;

  // api endpoints
  // static AUTH_API_ENDPOINT = 'http://localhost:8081/auth/api/v1/';
  // static PATIENT_PROGRAM_API_ENDPOINT = 'http://localhost:8000/programs/api/v1/';
  // static PATIENT_SERVICE_API_ENDPOINT = 'http://localhost:8082/patients/api/v1/';
  // static ORDER_SERVICE_API_ENDPOINT = 'http://localhost:8001/orders/api/v1/';

  // production
  static AUTH_API_ENDPOINT =
    "https://biocon-user-service-api.connectod.com/auth/api/v1/";
  static PATIENT_PROGRAM_API_ENDPOINT =
    "https://biocon-program-service-api.connectod.com/programs/api/v1/";
  static PATIENT_SERVICE_API_ENDPOINT =
    "https://biocon-user-service-api.connectod.com/patients/api/v1/";
  static ORDER_SERVICE_API_ENDPOINT =
    "https://biocon-program-service-api.connectod.com/orders/api/v1/";
  static POSTAL_SERVICE_API_ENDPOINT = "https://api.postalpincode.in/pincode/";

  // staging
  // static AUTH_API_ENDPOINT = "http://34.100.151.161/auth/api/v1/";
  // static PATIENT_PROGRAM_API_ENDPOINT =
  //   "http://34.100.151.161/programs/api/v1/";
  // static PATIENT_SERVICE_API_ENDPOINT = "http://34.100.151.161/patients/api/v1/";
  // static ORDER_SERVICE_API_ENDPOINT = "http://34.100.151.161/orders/api/v1/";
  // static POSTAL_SERVICE_API_ENDPOINT = "https://api.postalpincode.in/pincode/";

  // image
  static PDF_IMAGE =
    "https://banner2.kisspng.com/20180426/zkw/kisspng-computer-icons-pdf-5ae16a1a6187a1.6588193515247222023995.jpg";

  /**
   * Get range of number
   *
   * @param start
   * @param end
   * @return {Array}
   */
  static getRange(start, end) {
    let range = [];
    for (let i = start; i <= end; i++) range.push(i);
    return range;
  }

  /**
   * Calculate pages from total records
   *
   * @param totalRecords
   * @return {number}
   */
  static calculatePages(totalRecords) {
    return Math.ceil(totalRecords / Constants.PAGINATION_LENGTH);
  }

  /**
   * Format select options
   *
   * @param values
   * @return {*}
   */
  static formatForSelect(values) {
    return values.map((v) => {
      return { value: v.id, label: v.name };
    });
  }

  /**
   * Get today date
   *
   * @return {string}
   */
  static getTodayDate() {
    let m = moment();
    return m.format("YYYY-MM-DD");
  }

  /**
   * Get today date
   *
   * @return {string}
   */
  static formatToDisplayDate(date) {
    let m = moment(date);
    return m.format("DD MMM`YY");
  }

  /**
   * Format date to server date format
   *
   * @param date
   * @return {string}
   */
  static formatToServerDate(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  /**
   * Get value or empty string
   * @param data
   * @param field
   * @param value
   * @return {*}
   */
  static getValueOrDefault(data, field, value = "") {
    if (data.hasOwnProperty(field) && data[field] != null) return data[field];
    return value;
  }

  /**
   * Remove empty fields
   * @param dict
   */
  static removeEmptyFields(dict) {
    return lodash.pickBy(dict, lodash.identity);
  }

  /**
   * Mask name by keeping first 1 and last 2 charaters and mask rest.
   * and if length of name is less than 3 then mask all characters except the first one.
   *
   * @param {*} name
   * @returns
   */
  static maskName(name) {
    if (name.length < 3) {
      return name[0] + "*".repeat(name.length - 1);
    }
    return name[0] + "*".repeat(name.length - 2) + name.slice(-2);
  }
}
