import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import './App.css';
import indexRoutes from "./routes/index.js";
import {SnackbarProvider} from 'notistack';

class App extends Component {
  render() {
    return (
      <SnackbarProvider maxSnack={3}>
        <div className="App">
          <Switch>
            {
              indexRoutes.map((prop, key) => {
                return <Route path={prop.path} component={prop.component} exact={prop.exact} key={key}/>;
              })
            }
          </Switch>
        </div>
      </SnackbarProvider>
    );
  }
}

export default App;
