import DemoRequestPage from "../containers/DemoRequestPage";

let indexRoutes = [
  {
    path: "/",
    component: DemoRequestPage,
    exact: false,
  },
];

export default indexRoutes;
