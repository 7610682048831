import React from 'react';
import withStyles from "@material-ui/core/es/styles/withStyles";
import PropTypes from 'prop-types';
import Paper from "@material-ui/core/es/Paper/Paper";

const styles = theme => ({
  root: {
    padding: 100,
    background: "transparent"
  },
});

function EmptySpace(props) {
  const {classes} = props;
  return (
    <Paper className={classes.root} elevation={0}>
    </Paper>
  );
}

EmptySpace.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EmptySpace);