import React from 'react';
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/es/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import Button from "@material-ui/core/es/Button/Button";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import green from "@material-ui/core/es/colors/green";
import withStyles from "@material-ui/core/es/styles/withStyles";

const styles = theme => ({
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

function AlertBox(props) {
  const {classes, open, handleClose, handleSuccess, title, description, isFetching} = props;
  return (
    <Dialog open={open} onClose={handleClose}
            aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title + "?"}</DialogTitle>
      <DialogContent>
        {
          description &&
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        }
      </DialogContent>
      <DialogActions className={classes.wrapper}>
        <Button onClick={handleClose} color="primary" disabled={isFetching}>
          Disagree
        </Button>
        <Button onClick={handleSuccess} color="primary" autoFocus disabled={isFetching}>
          Agree
        </Button>
        {isFetching && <CircularProgress size={24} className={classes.buttonProgress}/>}
      </DialogActions>
    </Dialog>
  );
}

AlertBox.propTypes = {
  open: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default withStyles(styles)(AlertBox);