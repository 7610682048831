import {get, post, put} from "../../common/services/BaseService";
import {PatientProgramConstant} from "../PatientProgramConstant";
import {
  failedAllPatientPrograms,
  failedFetchPatientProgramMonthlyCount,
  failedPatientProgram,
  failedPatientProgramForDueDate,
  requestAllPatientPrograms,
  requestFetchPatientProgramMonthlyCount,
  requestPatientProgram,
  requestPatientProgramForDueDate,
  successAllPatientPrograms,
  successFetchPatientProgramMonthlyCount,
  successPatientProgram,
  successPatientProgramForDueDate
} from "../reducers/actions/patient-program-actions";

/**
 * Fetch patient programs
 *
 * @returns {function(*): Promise<any>}
 * @param q
 * @param page
 * @param length
 */
export const fetchPatientPrograms = (q = '', page = 0, length = 100) => dispatch => {
  dispatch(requestAllPatientPrograms());
  return get(PatientProgramConstant.PATIENT_PROGRAMS_URL, {
    q: q,
    page: page,
    length: length
  }).then(json => {
    dispatch(successAllPatientPrograms(json));
    return json;
  }).catch(error => {
    dispatch(failedAllPatientPrograms(error));
    throw(error);
  });
};

/**
 * Fetch patient program
 *
 * @returns {function(*): Promise<any>}
 * @param id
 */
export const getPatientProgram = (id) => dispatch => {
  dispatch(requestPatientProgram());
  return get(`${PatientProgramConstant.PATIENT_PROGRAMS_URL}/${id}`).then(json => {
    dispatch(successPatientProgram(json));
    return json;
  }).catch(error => {
    dispatch(failedPatientProgram(error));
    throw(error);
  });
};

/**
 * Get patient program for mobile
 *
 * @returns {function(*): Promise<any>}
 * @param mobile
 */
export const searchPatientProgramByMobile = (mobile) => {
  return get(`${PatientProgramConstant.PATIENT_PROGRAMS_URL}/search_for_mobile/${mobile}`).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};

/**
 * Update patient program
 *
 * @param id
 * @param patient_program
 * @return {Promise<Response>}
 */
export const updatePatientProgram = (id, patient_program) => {
  return put(`${PatientProgramConstant.PATIENT_PROGRAMS_URL}/${id}`, patient_program).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};

/**
 * Update patient program
 *
 * @param id
 * @return {Promise<Response>}
 */
export const sendForApproval = (id) => {
  return put(`${PatientProgramConstant.PATIENT_PROGRAMS_URL}/${id}/sendForApproval`).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};

/**
 * Update patient program status
 *
 * @param id
 * @param patient_program
 * @return {Promise<Response>}
 */
export const updatePatientProgramStatus = (id, patient_program) => {
  return put(`${PatientProgramConstant.PATIENT_PROGRAMS_URL}/${id}/status`, patient_program).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};

/**
 * Create patient program
 *
 * @param patientProgram
 * @return {Promise<Response>}
 */
export const createPatientProgram = (patientProgram) => {
  return post(`${PatientProgramConstant.PATIENT_PROGRAMS_URL}`, patientProgram).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};

/**
 * Add/Update patient program document
 *
 * @param id
 * @param document
 * @return {Promise<Response>}
 */
export const updatePatientProgramDocument = (id, document) => {
  return put(`${PatientProgramConstant.PATIENT_PROGRAMS_URL}/${id}/documents`, document).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};


/**
 * Call patient
 *
 * @param id
 * @return {Promise<Response>}
 */
export const callPatient = (id) => {
  return get(`${PatientProgramConstant.PATIENT_PROGRAMS_URL}/${id}/callPatient`).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};


/**
 * Fetch patient program due monthly counts
 *
 * @returns {function(*): Promise<any>}
 * @param month
 * @param year
 */
export const fetchPatientProgramDueMonthlyCounts = (month, year) => dispatch => {
  dispatch(requestFetchPatientProgramMonthlyCount());
  return get(`${PatientProgramConstant.PATIENT_PROGRAMS_URL}/monthly_count/${month}/${year}`,).then(json => {
    dispatch(successFetchPatientProgramMonthlyCount(json));
    return json;
  }).catch(error => {
    dispatch(failedFetchPatientProgramMonthlyCount(error));
    throw(error);
  });
};


/**
 * Fetch follow ups for date
 *
 * @returns {function(*): Promise<any>}
 * @param date
 * @param month
 * @param year
 */
export const fetchPatientProgramForDueDate = (date, month, year) => dispatch => {
  dispatch(requestPatientProgramForDueDate());
  return get(`${PatientProgramConstant.PATIENT_PROGRAMS_URL}/for_date/${date}/${month}/${year}`,).then(json => {
    dispatch(successPatientProgramForDueDate(json));
    return json;
  }).catch(error => {
    dispatch(failedPatientProgramForDueDate(error));
    throw(error);
  });
};