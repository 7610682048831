import React from 'react'
import PropTypes from "prop-types";
import Grid from "@material-ui/core/es/Grid/Grid";
import withStyles from "@material-ui/core/es/styles/withStyles";
import TextField from "@material-ui/core/es/TextField/TextField";
import formController from "../../../../common/hoc/forms/formController";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';


const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  }
});

class AddressForm extends React.Component {

  /**
   * Separate google address and field details
   *
   * @param address
   */
  separateGoogleAddress = (address) => {
    let {onBulkModelChange} = this.props;
    let terms = address.terms;
    let types = address.types;
    let data = {district: "", state: "", country: ""};

    if (types.indexOf("geocode") >= 0)
      data.country = terms[types.indexOf("geocode")].value;
    if (types.indexOf("political") >= 0)
      data.state = terms[types.indexOf("political")].value;
    if (types.indexOf("locality") >= 0)
      data.district = terms[types.indexOf("locality")].value;
    onBulkModelChange(data);
  };

  /**
   * Render view
   *
   * @return {*}
   */
  render() {
    const {classes, model, onFieldChange} = this.props;
    return (<Grid container spacing={8}>
      <Grid item xs={12}>
        <GooglePlacesAutocomplete
          placeholder="Google address search"
          onSelect={this.separateGoogleAddress}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField id="address-line" label="Address line" name="line" className={classes.textField}
                   value={model.line} onChange={onFieldChange}
                   margin="dense" fullWidth={true} variant="outlined"/>
      </Grid>
      <Grid item xs={4}>
        <TextField id="landmark" label="Landmark" name="landmark" className={classes.textField}
                   value={model.landmark} onChange={onFieldChange}
                   margin="dense" fullWidth={true} variant="outlined"/>
      </Grid>
      <Grid item xs={4}>
      </Grid>
      <Grid item xs={3}>
        <TextField id="pin_code" label="Pincode" name="pincode" className={classes.textField}
                   value={model.pincode} onChange={onFieldChange}
                   type="number" margin="dense" fullWidth={true} variant="outlined"/>
      </Grid>
      <Grid item xs={3}>
        <TextField id="district" label="District" name="district" className={classes.textField}
                   value={model.district} onChange={onFieldChange}
                   margin="dense" fullWidth={true} variant="outlined"/>
      </Grid>
      <Grid item xs={3}>
        <TextField id="state" label="State" name="state" className={classes.textField}
                   value={model.state} onChange={onFieldChange}
                   margin="dense" fullWidth={true} variant="outlined"/>
      </Grid>
      <Grid item xs={3}>
        <TextField id="country" label="Country" name="country" className={classes.textField}
                   value={model.country} onChange={onFieldChange}
                   margin="dense" fullWidth={true} variant="outlined"/>
      </Grid>
    </Grid>);
  }
}

AddressForm.propTypes = {
  model: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired
};

export default formController(withStyles(styles)(AddressForm));