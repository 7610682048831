import React, {Component} from 'react'
import withStyles from "@material-ui/core/es/styles/withStyles";
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/es/Typography/Typography";
import Grid from "@material-ui/core/es/Grid/Grid";
import Button from "@material-ui/core/es/Button/Button";
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import green from "@material-ui/core/es/colors/green";
import CardActions from "@material-ui/core/es/CardActions/CardActions";
import Card from "@material-ui/core/es/Card/Card";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import moment from "moment/moment";
import {DatePicker, MuiPickersUtilsProvider} from 'material-ui-pickers';
import DateFnsUtils from "@date-io/date-fns/build/index";
import {withSnackbar} from 'notistack';
import TextField from "@material-ui/core/es/TextField/TextField";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  card: {
    borderRadius: 0
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  picker: {
    marginLeft: 8,
    marginRight: 8
  }
});


/**
 * Transfer order form
 *
 * @return {*}
 * @constructor
 */
class MarkDeliveredForm extends Component {

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    let m = moment();
    let date = m.format("YYYY-MM-DD");
    this.state = {
      saving: false,
      received_by: '',
      delivery_date: date,
      picker_date: new Date()
    }
  }

  /**
   * Handle date change
   * @param date
   */
  handleDateChange = date => {
    let m = moment(date);
    let only_date = m.format("YYYY-MM-DD");
    this.setState({
      ...this.state,
      delivery_date: only_date,
      picker_date: date
    });
  };

  /**
   * Toggle saving
   */
  toggleSaving = () => {
    this.setState({
      ...this.state,
      saving: !this.state.saving
    });
  };

  /**
   * On value change
   *
   * @param name
   */
  onFieldChange = name => event => {
    this.setState({
      ...this.state,
      [name]: event.target.value
    });
  };

  /**
   * Update document
   */
  markDelivered = () => {
    this.toggleSaving();
    let {done, close, handlers, order} = this.props;
    handlers.deliveredOrder(order.id, {
      delivery_date: this.state.delivery_date,
      received_by: this.state.received_by,
      received_by_mobile: this.state.received_by_mobile
    }).then(json => {
      this.setState({
        ...this.state,
        saving: !this.state.saving,
      });
      this.props.enqueueSnackbar('Order marked delivered successfully!');
      done();
      close();
    });
  };

  /**
   * Render html
   * @return {*}
   */
  render() {
    const {classes, close} = this.props;
    const {saving, picker_date, received_by, received_by_mobile} = this.state;

    return <Card className={classes.card}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Typography variant="h6" color="inherit">
            Delivered form
          </Typography>
        </Toolbar>
      </AppBar>
      <CardContent>
        <form noValidate autoComplete="off">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={8}>
              <Grid item xs={12}>
                <DatePicker
                  margin="dense"
                  label="Delivery date"
                  value={picker_date}
                  variant="outlined"
                  fullWidth={true}
                  format={"dd MMM` yyyy"}
                  className={classes.picker}
                  onChange={this.handleDateChange}/>
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <TextField id="received_by"
                           label="Receiver's Name"
                           className={classes.textField}
                           value={received_by}
                           onChange={this.onFieldChange('received_by')}
                           margin="dense" fullWidth={true}
                           variant="outlined"/>
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <TextField id="received_by_mobile"
                           label="Receiver's Mobile"
                           className={classes.textField}
                           value={received_by_mobile}
                           onChange={this.onFieldChange('received_by_mobile')}
                           margin="dense" fullWidth={true}
                           variant="outlined"/>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </form>
      </CardContent>
      <CardActions dir="rtl">
        <Button variant="outlined" color="secondary" onClick={close} disabled={saving}>
          Close
        </Button>
        <div className={classes.wrapper}>
          <Button variant="contained" color="primary" disabled={saving} onClick={this.markDelivered}>
            {saving ? "Marking delivered" : "Mark delivered"}
          </Button>
          {saving && <CircularProgress size={24} className={classes.buttonProgress}/>}
        </div>
      </CardActions>
    </Card>;
  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
MarkDeliveredForm.propTypes = {
  order: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  handlers: PropTypes.object.isRequired,
  done: PropTypes.func.isRequired
};

export default withSnackbar(withStyles(styles)(MarkDeliveredForm));