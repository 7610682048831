import React from 'react'
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/es/ListItem/ListItem";
import ListItemText from "@material-ui/core/es/ListItemText/ListItemText";
import Typography from "@material-ui/core/es/Typography/Typography";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Icon from "@material-ui/core/es/Icon/Icon";
import ListItemIcon from "@material-ui/core/es/ListItemIcon/ListItemIcon";
import Button from "@material-ui/core/es/Button/Button";
import {withSnackbar} from 'notistack';
import FollowUpFormComponent from "./FollowUpFormComponent";
import ListItemSecondaryAction from "@material-ui/core/es/ListItemSecondaryAction/ListItemSecondaryAction";

const styles = theme => ({
  list: {
    selected: {
      border: "1px solid #000",
      backgroundColor: '#000'
    }
  },
  icon: {
    margin: theme.spacing.unit * 0.5,
    fontSize: "25px"
  },
  item_icon: {
    marginRight: 0
  }
});

/**
 * Patient personal detail
 *
 * @param patient
 * @return {*}
 * @constructor
 */
class FollowUpListItemComponent extends React.Component {

  state = {
    editing: false
  };

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.toggleEditing = this.toggleEditing.bind(this);
    this.selectFollowUp = this.selectFollowUp.bind(this);
    this.followUpUpdatedCallback = this.followUpUpdatedCallback.bind(this);
  }

  /**
   * Toggle editing
   */
  toggleEditing = () => {
    this.setState({
      ...this.state,
      editing: !this.state.editing
    });
  };

  /**
   * Select patient program
   */
  selectFollowUp = () => {
    this.props.onSelect(this.props.follow_up);
  };

  /**
   * Follow up created callback
   */
  followUpUpdatedCallback() {
    const {enqueueSnackbar, refresh} = this.props;
    this.setState({...this.state, editing: !this.state.editing});
    enqueueSnackbar('Follow up Updated successfully!');
    refresh();
  }

  /**
   * Render view
   *
   * @return {*}
   */
  render() {
    let {editing} = this.state;
    let {follow_up, selected_follow_up_id, classes} = this.props;

    // if editing show form
    if (editing)
      return <FollowUpFormComponent close={this.toggleEditing}
                                    follow_up={follow_up}
                                    patient_program={follow_up.patient_program}
                                    successCallback={this.followUpUpdatedCallback}
                                    failureCallback={() => {
                                    }}/>;

    // render view
    return (
      <ListItem selected={selected_follow_up_id === follow_up.id}
                alignItems="flex-start"
                button={true} divider={true}>
        <ListItemIcon className={classes.item_icon}>
          <Icon className={classes.icon} color="primary" fontSize="large">
            calendar_today
          </Icon>
        </ListItemIcon>
        <ListItemText onClick={this.selectFollowUp}
                      primary={
                        <React.Fragment>
                          <Typography variant="body2" gutterBottom>
                            {
                              follow_up.patient_program.patient_full_name != null ?
                                follow_up.patient_program.patient_full_name : follow_up.patient_program.mobile
                            }
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography variant="caption">
                            {follow_up.patient_program.program.name} ({follow_up.patient_program.product.name})
                          </Typography>
                          <Typography variant="caption">
                            {follow_up.date}
                          </Typography>
                          {
                            selected_follow_up_id === follow_up.id &&
                            <div>
                              <Typography variant="caption">
                                {follow_up.comment}
                              </Typography>
                            </div>
                          }
                        </React.Fragment>
                      }>
        </ListItemText>
        <ListItemSecondaryAction>
          <Button color="primary" className={classes.button} onClick={this.toggleEditing}>
            EDIT
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }
}

/**
 * Properties which can be passed to component
 *
 * @type {{patientProgram: *, selectedPatientProgramId: shim, onSelect: *}}
 */
FollowUpListItemComponent.propTypes = {
  follow_up: PropTypes.object.isRequired,
  selected_follow_up_id: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  refresh: PropTypes.func.isRequired
};

export default withStyles(styles)(withSnackbar(FollowUpListItemComponent));

