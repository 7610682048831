import {post} from "../../common/services/BaseService";
import {PatientProgramConstant} from "../PatientProgramConstant";


/**
 * Generate temp token link for user
 *
 * @param data
 * @return {Promise<Response>}
 */
export const generateToken = (data) => {
  return post(`${PatientProgramConstant.TEMP_TOKEN_URL}/generateTempToken`, data).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};