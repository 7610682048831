import React from 'react'
import PropTypes from "prop-types";
import Grid from "@material-ui/core/es/Grid/Grid";
import withStyles from "@material-ui/core/es/styles/withStyles";
import formController from "../../../../common/hoc/forms/formController";
import TextField from "@material-ui/core/es/TextField/TextField";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  picker: {
    marginLeft: 8,
    marginRight: 8
  }
});

class PatientProgramDocumentVerifyForm extends React.Component {
  render() {
    const {classes, onFieldChange, model, errors, document} = this.props;
    return (<Grid container spacing={8}>
      {
        !(document.status === 'accepted') &&
        <Grid item xs={12}>
          <TextField id="fields" label="Accept comments" name="accept_comments" className={classes.textField}
                     multiline={true} defaultValue={model.accept_comments} rows={2} onChange={onFieldChange}
                     placeholder="Enter comma separated image content of image"
                     margin="dense" fullWidth={true} variant="outlined"
                     error={!!errors.accept_comments} helperText={errors.accept_comments}/>
        </Grid>
      }
      {
        !(document.status === 'rejected') &&
        <Grid item xs={12}>
          <TextField id="reject-reason" label="Reject reason" name="reject_reason" className={classes.textField}
                     multiline={true} defaultValue={model.reject_reason} rows={2} onChange={onFieldChange}
                     placeholder="Enter reason for rejection"
                     margin="dense" fullWidth={true} variant="outlined"
                     error={!!errors.reject_reason} helperText={errors.reject_reason}/>
        </Grid>
      }
    </Grid>);
  }
}

PatientProgramDocumentVerifyForm.propTypes = {
  document: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired
};

export default formController(withStyles(styles)(PatientProgramDocumentVerifyForm));