import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import theme from "../../app_theme";
import AddIcon from "@material-ui/icons/Add";
import PatientEnrollmentForm from "./PatientEnrollmentForm";
import EnrolledCard from "../components/EnrolledCard";

const useStyles = makeStyles({
  mainHeading: {
    fontSize: "20px",
    fontWeight: 500,
    fontFamily: "Roboto",
    margin: 25,
  },
  button: {
    borderRadius: "50px",
    padding: "12px",
    position: "fixed",
    bottom: 20,
    right: 20,
    left: 180,
  },
});

const EnrolledPatients = () => {
  const [success, setSuccess] = useState(false);

  const classes = useStyles();

  const handleEnroll = () => {
    setSuccess(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <>
        {!success && (
          <>
            <Typography className={classes.mainHeading}>
              Enrolled Patients
            </Typography>
            <EnrolledCard />
            <EnrolledCard />
            <EnrolledCard />
            <EnrolledCard />
            <Button
              variant="contained"
              color="primary"
              onClick={handleEnroll}
              className={classes.button}
            >
              <AddIcon /> ENROLL PATIENT
            </Button>
          </>
        )}
        {success && <PatientEnrollmentForm />}
      </>
    </ThemeProvider>
  );
};

export default EnrolledPatients;
