import {get} from "../../common/services/BaseService";
import {PatientProgramConstant} from "../PatientProgramConstant";
import {failedPrograms, requestPrograms, successPrograms} from "../reducers/actions/program-actions";

/**
 * Fetch programs
 *
 * @returns {function(*): Promise<any>}
 * @param q
 * @param page
 * @param length
 */
export const fetchPrograms = (q = "", page = 0, length = 50) => dispatch => {
  dispatch(requestPrograms());
  return get(PatientProgramConstant.PROGRAMS_URL, {
    q: q,
    page: page,
    length: length
  }).then(json => {
    dispatch(successPrograms(json));
    return json;
  }).catch(error => {
    dispatch(failedPrograms(error));
    throw(error);
  });
};