import React, {Component} from 'react';
import {Form} from 'formik';
import Grid from "@material-ui/core/es/Grid/Grid";
import Card from "@material-ui/core/es/Card/Card";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import Typography from "@material-ui/core/es/Typography/Typography";
import LockOpen from '@material-ui/icons/LockOpen';
import withStyles from "@material-ui/core/es/styles/withStyles";
import {Redirect, withRouter} from "react-router-dom";

const styles = theme => ({
  card: {
    margin: 10
  },
  cardHeader: {
    textAlign: "center",
  },
  cardContent: {
    margin: "10px 30px"
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  textField: {
    fontSize: 12,
    fontWeight: 400,
    marginRight: 20
  },
  InputProps: {
    position: "static"
  },
  cardActions: {
    textAlign: "right"
  }
});

class SplashApp extends Component {

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      role: ''
    }
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    let auth = localStorage.getItem("auth");
    if (auth == null) {
      this.props.history.push('/auth');
    } else {
      auth = JSON.parse(auth);
      this.setState({...this.state, role: auth.role.name});
    }
  }

  // view
  render() {
    const {classes} = this.props;
    const url = this.props.match.url;
    return (
      <Grid container spacing={24}>
        <Grid item md={4} sm={3} xs={12}>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Form>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h6" color="inherit" className={classes.cardHeader}>
                  <LockOpen/>
                </Typography>
                <Typography variant="h6" color="inherit" className={classes.cardHeader}>
                  Welcome to ConnectOd
                </Typography>
                <Typography variant="h6" color="inherit" className={classes.cardHeader}>
                  Please wait for some time
                </Typography>
              </CardContent>
            </Card>
          </Form>
          {
            this.state.role === "ADMIN"
            && <Redirect to='/patient_programs'/>
          }
          {
            this.state.role === "COMPANY_ADMIN"
            && <Redirect to='/reports/dashboard'/>
          }
          {
            this.state.role === "CFA"
            && <Redirect to='/orders'/>
          }
          {
            this.state.role === "PATIENT"
            && <Redirect to='/patients/patient_programs'/>
          }
        </Grid>
      </Grid>
    )
  };
}

export default withRouter(withStyles(styles)(SplashApp));
