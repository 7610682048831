import { PatientEnrollmentConstant } from "../PatientEnrollmentConstants";

export const getPostalDataBasedOnPincode = async (pincode) => {
  let postalData = await fetch(
    `${PatientEnrollmentConstant.POSTAL_API_URL}${pincode}`
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    );
  return postalData;
};
