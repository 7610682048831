import React from 'react'
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/es/styles/withStyles";
import TableRow from "@material-ui/core/es/TableRow/TableRow";
import TableCell from "@material-ui/core/es/TableCell/TableCell";

const styles = theme => ({
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  chip: {
    margin: theme.spacing.unit,
  },
});

/**
 * Patient personal detail
 *
 * @param patient
 * @return {*}
 * @constructor
 */
function InvoiceCardItem({invoice, invoiceItem, classes}) {
  return (
    <TableRow key={invoiceItem.id}>
      <TableCell align="right">{invoiceItem.product.name}</TableCell>
      <TableCell align="right">{invoiceItem.quantity}</TableCell>
    </TableRow>
  );
}

/**
 * Properties which can be passed to component
 *
 * @type {{}}
 */
InvoiceCardItem.propTypes = {
  invoice: PropTypes.object.isRequired,
  invoiceItem: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(InvoiceCardItem);

