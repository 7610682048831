import React, {Component} from 'react';
import {post} from 'axios';
import Constants from "../../Constants";
import Card from "@material-ui/core/es/Card/Card";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

export default class FileUploadPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      image: ''
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.fileUpload = this.fileUpload.bind(this)
  }

  onFormSubmit(e) {
    e.preventDefault();
    this.fileUpload(this.state.image);
  }

  onChange(e) {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length)
      return;
    this.createImage(files[0]);
  }

  createImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        image: e.target.result
      })
    };
    reader.readAsDataURL(file);
  }

  fileUpload(image) {
    const url = `${Constants.PATIENT_PROGRAM_API_ENDPOINT}patient_programs/imageFileUpload`;
    const formData = {file: this.state.image};
    return post(url, formData)
      .then(response => {
        alert("Image uploaded successfully.");
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  render() {
    return (
      <Card>
        <CardContent>
          <h1>Upload your document</h1>
          <TextField type="file" name="fileimg" onChange={this.onChange}/>
          <br/>
          <br/>
          <br/>
          <Button variant="contained" color="primary" component="span" onClick={this.onFormSubmit}>
            Upload
          </Button>
        </CardContent>
      </Card>
    );
  }
}
