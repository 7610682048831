import Constants from "../../Constants";


export default class Address {
  static get() {
    return {
      line: '',
      landmark: '',
      pincode: '',
      district: '',
      state: '',
      country: ''
    }
  }

  /**
   * Format address
   *
   * @param address
   */
  static format(address) {
    console.log(address);
    return {
      line: Constants.getValueOrDefault(address, 'line', ''),
      landmark: Constants.getValueOrDefault(address, 'landmark', ''),
      pincode: Constants.getValueOrDefault(address, 'pincode', ''),
      district: Constants.getValueOrDefault(address, 'district', ''),
      state: Constants.getValueOrDefault(address, 'state', ''),
      country: Constants.getValueOrDefault(address, 'country', '')
    }
  }
}