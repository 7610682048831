import React from 'react';
import withStyles from "@material-ui/core/es/styles/withStyles";
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/es/Typography/Typography";
import Paper from "@material-ui/core/es/Paper/Paper";
import Icon from "@material-ui/core/es/Icon/Icon";

const styles = theme => ({
  root: {
    padding: 10
  },
});

function NoContent(props) {
  const {classes} = props;
  return (
    <Paper className={classes.root} elevation={0}>
      <Typography variant="body2" component="div" align="center">
        <Icon className={classes.icon} color="disabled" fontSize="default">
          warning
        </Icon>
      </Typography>
      <Typography variant="body1" component="div" align="center">
        {props.label}
      </Typography>
    </Paper>
  );
}

NoContent.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired
};

export default withStyles(styles)(NoContent);