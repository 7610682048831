import React, { Component } from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/es/Card/Card";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import withStyles from "@material-ui/core/es/styles/withStyles";
import { withSnackbar } from "notistack";
import Grid from "@material-ui/core/es/Grid/Grid";
import TableBody from "@material-ui/core/es/TableBody/TableBody";
import TableHead from "@material-ui/core/es/TableHead/TableHead";
import Table from "@material-ui/core/es/Table/Table";
import TableRow from "@material-ui/core/es/TableRow/TableRow";
import TableCell from "@material-ui/core/es/TableCell/TableCell";
import Paper from "@material-ui/core/es/Paper/Paper";
import Typography from "@material-ui/core/es/Typography/Typography";
import OrderStatus from "../../order/components/OrderStatus";

const styles = (theme) => ({
  card: {
    borderRadius: 0,
    height: "100%",
  },
  root: {
    width: "100%",
    overflowX: "auto",
    borderRadius: 0,
  },
  content: {
    padding: 0,
  },
  header: {
    marginBottom: 0,
    padding: 5,
  },
  table: {
    minWidth: 500,
    backgroundColor: "#eee",
  },
  tableHeader: {
    backgroundColor: "#eee",
  },
});

/**
 * Demo request list
 *
 * @return {*}
 * @constructor
 */
class DemoRequestListComponent extends Component {
  state = {};

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.onDemoRequestSelect = this.onDemoRequestSelect.bind(this);
  }

  /**
   * On order select
   * @param order
   */
  onDemoRequestSelect(order) {
    this.props.selectRequest(order);
  }

  //render content
  render() {
    const { classes } = this.props;

    return (
      <Card className={classes.card} elevation={24}>
        <CardContent className={classes.content}>
          <Grid container spacing={0}>
            <Grid item md={12} sm={12}>
              <Typography
                gutterBottom
                align="center"
                variant="h6"
                component="h5"
                className={classes.header}
              >
                DEMO REQUEST LIST
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Paper className={classes.root} elevation={0}>
          <Table className={classes.table} margin="dense">
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell align="center">Patient Name</TableCell>
                <TableCell align="center">Patient Mobile No</TableCell>
                <TableCell align="center">Demo Person Name</TableCell>
                <TableCell align="center">Demo Person Mobile No</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow onClick={() => {}}>
                <TableCell component="th" scope="row">
                  1
                </TableCell>
                <TableCell align="center">Ramesh</TableCell>
                <TableCell align="center">7893399087</TableCell>
                <TableCell align="center">GoApptiv</TableCell>
                <TableCell align="center">7893399087</TableCell>
                <TableCell align="left">
                  <OrderStatus status="pending" />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Card>
    );
  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
// DemoRequestListComponent.propTypes = {
//   classes: PropTypes.object.isRequired,
//   theme: PropTypes.object.isRequired
// };

export default withSnackbar(
  withStyles(styles, { withTheme: true })(DemoRequestListComponent)
);
