import {get} from "../../common/services/BaseService";
import {
  failedFetchReportCalls,
  failedFetchReportCounts,
  failedFetchReportDeferredPatientPrograms,
  failedFetchReportDroppedPatientPrograms,
  failedFetchReportMonthlyCounts,
  failedFetchReportOrders,
  failedFetchReportPatientPrograms,
  requestFetchReportCalls,
  requestFetchReportCounts,
  requestFetchReportDeferredPatientPrograms,
  requestFetchReportDroppedPatientPrograms,
  requestFetchReportMonthlyCounts,
  requestFetchReportOrders,
  requestFetchReportPatientPrograms,
  successFetchReportCalls,
  successFetchReportCounts,
  successFetchReportDeferredPatientPrograms,
  successFetchReportDroppedPatientPrograms,
  successFetchReportMonthlyCounts,
  successFetchReportOrders,
  successFetchReportPatientPrograms
} from "../reducers/actions/report-actions";
import {ReportConstant} from "../ReportConstant"
import Constants from "../../Constants";

/**
 * Find users
 *
 * @returns {function(*): Promise<any>}
 */
export const reportCounts = () => dispatch => {
  dispatch(requestFetchReportCounts());
  return get(`${ReportConstant.REPORT_URL}/counts`).then(json => {
    dispatch(successFetchReportCounts(json));
    return json;
  }).catch(error => {
    dispatch(failedFetchReportCounts(error));
    throw(error);
  });
};

/**
 * Find monthly counts
 *
 * @returns {function(*): Promise<any>}
 */
export const reportMonthlyCounts = () => dispatch => {
  dispatch(requestFetchReportMonthlyCounts());
  return get(`${ReportConstant.REPORT_URL}/counts/monthly`).then(json => {
    dispatch(successFetchReportMonthlyCounts(json));
    return json;
  }).catch(error => {
    dispatch(failedFetchReportMonthlyCounts(error));
    throw(error);
  });
};

/**
 * Find deferred list
 *
 * @returns {function(*): Promise<any>}
 */
export const reportDeferredList = () => dispatch => {
  dispatch(requestFetchReportDeferredPatientPrograms());
  return get(`${ReportConstant.REPORT_URL}/patientPrograms/deferred`).then(json => {
    dispatch(successFetchReportDeferredPatientPrograms(json));
    return json;
  }).catch(error => {
    dispatch(failedFetchReportDeferredPatientPrograms(error));
    throw(error);
  });
};

/**
 * Find dropped list
 *
 * @returns {function(*): Promise<any>}
 */
export const reportDroppedList = () => dispatch => {
  dispatch(requestFetchReportDroppedPatientPrograms());
  return get(`${ReportConstant.REPORT_URL}/patientPrograms/dropped`).then(json => {
    dispatch(successFetchReportDroppedPatientPrograms(json));
    return json;
  }).catch(error => {
    dispatch(failedFetchReportDroppedPatientPrograms(error));
    throw(error);
  });
};

/**
 * Find dropped list
 *
 * @returns {function(*): Promise<any>}
 */
export const reportOrders = () => dispatch => {
  dispatch(requestFetchReportOrders());
  return get(`${ReportConstant.REPORT_URL}/orders`).then(json => {
    dispatch(successFetchReportOrders(json));
    return json;
  }).catch(error => {
    dispatch(failedFetchReportOrders(error));
    throw(error);
  });
};

/**
 * Find patient programs
 *
 * @returns {function(*): Promise<any>}
 */
export const reportPatientPrograms = () => dispatch => {
  dispatch(requestFetchReportPatientPrograms());
  return get(`${ReportConstant.REPORT_URL}/patientPrograms`).then(json => {
    dispatch(successFetchReportPatientPrograms(json));
    return json;
  }).catch(error => {
    dispatch(failedFetchReportPatientPrograms(error));
    throw(error);
  });
};

/**
 * Fetch calls
 *
 * @returns {function(*): Promise<any>}
 */
export const reportCalls = (page = 1, length = Constants.PAGINATION_LENGTH) => dispatch => {
  dispatch(requestFetchReportCalls());
  return get(`${ReportConstant.REPORT_URL}/calls`, {
    page: page,
    length: length
  }).then(json => {
    dispatch(successFetchReportCalls(json));
    return json;
  }).catch(error => {
    dispatch(failedFetchReportCalls(error));
    throw(error);
  });
};