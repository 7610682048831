import React from 'react'
import PropTypes from "prop-types";
import Typography from "@material-ui/core/es/Typography/Typography";
import CardActionArea from "@material-ui/core/es/CardActionArea/CardActionArea";
import Card from "@material-ui/core/es/Card/Card";
import CardMedia from "@material-ui/core/es/CardMedia/CardMedia";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import withStyles from "@material-ui/core/es/styles/withStyles";

const styles = theme => ({
  card: {
    margin: "10px"
  },
  media: {
    height: 140,
  }
});

function PatientProgramDocumentListItem({document, classes}) {
  return (
    <Card className={classes.card}>
      <CardActionArea>
        {
          (document.file_type === 'image/png' || document.file_type === 'image/jpeg') &&
          <CardMedia
            className={classes.media}
            image={document.document_path}
            title="Document"/>
        }
        {
          document.type === 'application/pdf' &&
          <CardMedia
            className={classes.media}
            image="https://banner2.kisspng.com/20180426/zkw/kisspng-computer-icons-pdf-5ae16a1a6187a1.6588193515247222023995.jpg"
            title="Document"/>
        }
        <CardContent>
          <Typography gutterBottom variant="body2" component="h6">
            {document.document_type ? document.document_type : "NA"}
          </Typography>
          <Typography component="p" variant="caption">
            {document.document_group ? document.document_group : "NA"}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

PatientProgramDocumentListItem.propTypes = {
  document: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PatientProgramDocumentListItem);

