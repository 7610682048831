import React from 'react';
import PropTypes from 'prop-types';
import {fade} from "@material-ui/core/styles/colorManipulator";
import {withStyles} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from "@material-ui/core/es/InputBase/InputBase";
import debounce from 'lodash/debounce';

const styles = theme => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 5,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 7,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 140,
      '&:focus': {
        width: 200,
      },
    },
  }
});
/**
 * Check box row
 *
 * @param patient
 * @return {*}
 * @constructor
 */
const SearchBox = ({classes, placeholder, onChange}) => {

  /**
   * On change of check box value
   * @param event
   */
  const searchChanged = debounce((value) => {
    onChange(value);
  }, 1000, false);

  /**
   * Render content
   */
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon/>
      </div>
      <InputBase
        placeholder={placeholder}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        onChange={e => {searchChanged(e.target.value)}}
      />
    </div>
  )
};

/**
 * Properties types
 *
 * @type {{fetch: *}}
 */
SearchBox.propTypes = {
  classes: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default withStyles(styles)(SearchBox);