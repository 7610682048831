import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/es/Grid/Grid";
import withStyles from "@material-ui/core/es/styles/withStyles";
import formController from "../../../../common/hoc/forms/formController";
import OutlinedInput from "@material-ui/core/es/OutlinedInput/OutlinedInput";
import Select from "@material-ui/core/es/Select/Select";
import InputLabel from "@material-ui/core/es/InputLabel/InputLabel";
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";
import TextField from "@material-ui/core/es/TextField/TextField";
import FormHelperText from "@material-ui/core/es/FormHelperText/FormHelperText";
import FormControlLabel from "@material-ui/core/es/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/es/Checkbox/Checkbox";

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  picker: {
    marginLeft: 8,
    marginRight: 8,
  },
});

class PatientProgramEnrolForm extends React.Component {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
  }

  /**
   * Render view
   */
  render() {
    const {
      classes,
      onFieldChange,
      model,
      data: { programs, products },
      errors,
    } = this.props;
    console.log(errors.pen_code);
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl
            variant="outlined"
            fullWidth={true}
            error={!!errors.program_id}
          >
            <InputLabel
              ref={(ref) => {
                this.InputLabelRef = ref;
              }}
              htmlFor="program"
            >
              Select Program
            </InputLabel>
            <Select
              value={model.program_id}
              onChange={onFieldChange}
              input={
                <OutlinedInput
                  labelWidth={100}
                  name="program_id"
                  id="program"
                />
              }
            >
              {programs.map((d, i) => {
                return (
                  <MenuItem value={d.id} key={i}>
                    {d.name}
                  </MenuItem>
                );
              })}
            </Select>
            {errors.program_id && (
              <FormHelperText>{errors.program_id}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          {model.program_id && (
            <FormControl
              variant="outlined"
              fullWidth={true}
              error={!!errors.program_id}
            >
              <InputLabel
                ref={(ref) => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="product"
              >
                Product
              </InputLabel>
              <Select
                value={model.product_id}
                onChange={onFieldChange}
                input={
                  <OutlinedInput
                    labelWidth={100}
                    name="product_id"
                    id="product"
                  />
                }
              >
                {products.map((d, i) => {
                  return (
                    <MenuItem value={d.id} key={i}>
                      {d.name}
                    </MenuItem>
                  );
                })}
              </Select>
              {errors.product_id && (
                <FormHelperText>{errors.product_id}</FormHelperText>
              )}
            </FormControl>
          )}
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="mobile"
            label="Mobile"
            name="mobile"
            className={classes.textField}
            defaultValue={model.mobile}
            onChange={onFieldChange}
            margin="normal"
            fullWidth={true}
            variant="outlined"
            error={!!errors.mobile}
            helperText={errors.mobile}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="patient_full_name"
            label="Patient full name"
            name="patient_full_name"
            className={classes.textField}
            defaultValue={model.patient_full_name}
            onChange={onFieldChange}
            margin="normal"
            fullWidth={true}
            variant="outlined"
            error={!!errors.patient_full_name}
            helperText={errors.patient_full_name}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="pen-code"
            label="Coupon code"
            name="pen_code"
            disabled={!!errors.pen_code && errors.pen_code.includes("invalid")}
            className={classes.textField}
            defaultValue={model.pen_code}
            onChange={onFieldChange}
            margin="normal"
            fullWidth={true}
            variant="outlined"
            error={!!errors.pen_code}
            helperText={errors.pen_code}
          />
          {!!errors.pen_code && errors.pen_code.includes("invalid") && (
            <FormControlLabel
              className={classes.textField}
              name="generate_new_code"
              control={
                <Checkbox
                  name="generate_new_code"
                  checked={model.generate_new_code}
                  onChange={onFieldChange}
                  value="1"
                />
              }
              label="Generate new Code"
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

PatientProgramEnrolForm.propTypes = {
  data: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default formController(withStyles(styles)(PatientProgramEnrolForm));
