import React, {Component} from 'react'
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Typography from "@material-ui/core/es/Typography/Typography";
import Card from "@material-ui/core/es/Card/Card";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import CardActions from "@material-ui/core/es/CardActions/CardActions";
import Button from "@material-ui/core/es/Button/Button";
import * as lodash from "lodash";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import green from "@material-ui/core/es/colors/green";
import {createPatientProgram, searchPatientProgramByMobile} from "../service/PatientProgramService";
import PatientProgramEnrolForm from "./patient_program/forms/PatientProgramEnrolForm";
import restCallController from "../../common/hoc/forms/restCallController";
import debounce from "lodash/debounce";

const styles = theme => ({
  card: {
    borderRadius: 0
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  warning: {
    margin: 10
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});


/**
 * Update patient program form
 *
 * @param patient
 * @return {*}
 * @constructor
 */
class PatientEnrolComponent extends Component {

  /**
   * Patient form data
   */
  state = {
    products: [],
    warning: "",
    patient_program: {program_id: '', product_id: ''}
  };

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.create = this.create.bind(this);
    this.onModelChange = this.onModelChange.bind(this);
    this.setProducts = this.setProducts.bind(this);
  }

  /**
   * Check mobile
   *
   * @param mobile
   */
  checkMobile = debounce((mobile) => {
    let self = this;
    searchPatientProgramByMobile(mobile).then(function (json) {
      let warning = '';
      if (json.patient_program)
        warning = "Warning: Mobile number was previously used for patient: " + json.patient_program.patient_full_name;

      // set warning
      self.setState({
        ...self.state,
        warning: warning
      });
    })
  }, 1000, false);

  /**
   * On model field change
   *
   * @param model
   * @return {Function}
   */
  onModelChange = (model) => (field, value) => {
    console.log(field + value);
    let self = this;
    this.setState({
      ...this.state,
      [model]: {
        ...this.state[model],
        [field]: value
      },
    }, () => {
      if (field === 'program_id')
        self.setProducts(value);
      else if (field === 'mobile')
        this.checkMobile(value);
    })
  };

  /**
   * Set products
   *
   * @param program_id
   */
  setProducts = program_id => {
    let {programs} = this.props;
    let program = programs.find(p => {
      return p.id === program_id;
    });

    // get products
    let products = [];
    if (program)
      products = program.products;

    // set products
    this.setState({
      ...this.state,
      products: products
    })
  };

  /**
   * Update patient
   */
  create = () => {
    let {handlePromise} = this.props;
    let patient_program = lodash.clone(this.state.patient_program);
    handlePromise(createPatientProgram(patient_program));
  };

  /**
   * Render view
   * @return {*}
   */
  render() {
    const {patient_program, warning, products} = this.state;
    const {classes, programs, close, isFetching, errors} = this.props;
    return (
      <Card className={classes.card}>
        <AppBar position="static">
          <Toolbar variant="dense">
            <Typography variant="h6" color="inherit">
              Enrolment form
            </Typography>
          </Toolbar>
        </AppBar>
        {
          warning && warning.length !== 0 &&
          <CardContent>
            <Typography color="secondary">
              {warning}
            </Typography>
          </CardContent>
        }
        <CardContent>
          <form className={classes.container} noValidate autoComplete="off">
            <PatientProgramEnrolForm model={patient_program} onModelChange={this.onModelChange("patient_program")}
                                     data={{programs: programs, products: products}} errors={errors}/>
          </form>
        </CardContent>
        <CardActions dir="rtl" className={classes.wrapper}>
          <Button variant="outlined" color="secondary" onClick={close} disabled={isFetching}>
            Close
          </Button>
          <Button variant="contained" color="primary" onClick={this.create} disabled={isFetching}>
            {isFetching ? "Enrolling" : "Enrol"}
          </Button>
          {isFetching && <CircularProgress size={24} className={classes.buttonProgress}/>}
        </CardActions>
      </Card>
    );
  }
}

PatientEnrolComponent.propTypes = {
  programs: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  close: PropTypes.func,
  successCallback: PropTypes.func.isRequired,
  failureCallback: PropTypes.func.isRequired
};

export default restCallController(withStyles(styles)(PatientEnrolComponent));