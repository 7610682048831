import React, {Component} from 'react'
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Button from "@material-ui/core/es/Button/Button";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import green from "@material-ui/core/es/colors/green";
import {sendForApproval} from "../../service/PatientProgramService";
import restCallController from "../../../common/hoc/forms/restCallController";
import {PatientProgramConstant} from "../../PatientProgramConstant";
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";

const styles = theme => ({
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});


/**
 * Update patient program form
 *
 * @param patient
 * @return {*}
 * @constructor
 */
class PatientProgramSendForApprovalComponent extends Component {

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      send_for_approval: false
    };
    this.sendForApproval = this.sendForApproval.bind(this);
    this.toggleSendForApprovalFlag = this.toggleSendForApprovalFlag.bind(this);
  }

  /**
   * Update patient
   */
  sendForApproval = () => {
    let {handlePromise, patient_program} = this.props;
    let self = this;
    handlePromise((sendForApproval(patient_program.id)).then(json => {
      self.toggleSendForApprovalFlag();
      return json;
    }));
  };

  /**
   * Patient program toggle
   */
  toggleSendForApprovalFlag = () => {
    this.setState({...this.state, send_for_approval: !this.state.send_for_approval});
  };

  /**
   * Render view
   * @return {*}
   */
  render() {
    const {classes, patient_program: {status}, isFetching} = this.props;
    const {send_for_approval} = this.state;

    if (PatientProgramConstant.PATIENT_PROGRAM_CAN_APPROVE_STATUSES.indexOf(status) < 0)
      return "";

    return (
      <span>
        <Button size="small" color="primary" variant="text" onClick={this.toggleSendForApprovalFlag}>
          Send for approval
        </Button>
        <Dialog open={send_for_approval} onClose={this.toggleSendForApprovalFlag} maxWidth="md"
                aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Are you sure you want to send it for approval?</DialogTitle>
          <DialogActions className={classes.wrapper}>
            <Button onClick={this.sendForApproval} color="primary" disabled={isFetching}>
              Accept
            </Button>
            <Button onClick={this.toggleSendForApprovalFlag} variant="outlined" color="secondary" autoFocus
                    disabled={isFetching}>
              Close
            </Button>
            {isFetching && <CircularProgress size={24} className={classes.buttonProgress}/>}
          </DialogActions>
        </Dialog>
      </span>
    );
  }
}

PatientProgramSendForApprovalComponent.propTypes = {
  patient_program: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  successCallback: PropTypes.func.isRequired,
  failureCallback: PropTypes.func.isRequired,
};

export default restCallController(withStyles(styles)(PatientProgramSendForApprovalComponent));