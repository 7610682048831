import React, { Component } from "react";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Typography from "@material-ui/core/es/Typography/Typography";
import Button from "@material-ui/core/es/Button/Button";
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import CardActions from "@material-ui/core/es/CardActions/CardActions";
import Card from "@material-ui/core/es/Card/Card";

const styles = (theme) => ({
  card: {
    borderRadius: 0,
  },
});

/**
 * Accept Request
 *
 * @return {*}
 * @constructor
 */
class AcceptRequest extends Component {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Render html
   * @return {*}
   */
  render() {
    const { classes, close } = this.props;

    return (
      <Card className={classes.card}>
        <AppBar position="static">
          <Toolbar variant="dense">
            <Typography variant="h6" color="inherit">
              Accept Demo Request
            </Typography>
          </Toolbar>
        </AppBar>
        <CardContent>
          <Typography>Are you sure you want to accept this request</Typography>
        </CardContent>
        <CardActions dir="rtl">
          <Button variant="text" color="primary" onClick={() => {}}>
            Agree
          </Button>
          <div style={{ marginRight: 5 }}>
            <Button variant="text" color="primary" onClick={close}>
              Disagree
            </Button>
          </div>
        </CardActions>
      </Card>
    );
  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
// AcceptRequest.propTypes = {
//     close: PropTypes.func.isRequired,
//     classes: PropTypes.object.isRequired,
// };

export default withStyles(styles)(AcceptRequest);
