import React, {Component} from 'react';
import {Provider} from 'react-redux';
import {applyMiddleware, createStore} from 'redux';
import Footer from "../common/components/base/Footer";
import reducer from './reducers/auth'
import {Route, Switch} from "react-router-dom";
import thunk from 'redux-thunk'
import {createLogger} from 'redux-logger'
import withStyles from "@material-ui/core/es/styles/withStyles";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {NotificationContainer} from "react-notifications";
import indexRoutes from './routes/index.js'

const middleware = [thunk];
if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger());
}

const drawerWidth = 0;
const styles = theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginTop: 65
  },
  content: {
    flexGrow: 1,
    padding: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
});

/**
 * Auth Store
 *
 * @type {Store<any, Action> & {dispatch: any}}
 */
const store = createStore(
  reducer,
  applyMiddleware(...middleware)
);

/**
 * Auth App
 */
class AuthApp extends Component {
  render() {
    let {classes, theme} = this.props;
    return (
      <Provider store={store}>
        <div className={classes.root}>
          <NotificationContainer/>
          <div className={classNames(classes.content, {})}>
            <div className={classes.paper}>
              <Switch>
                  {
                      indexRoutes.map((prop,key)=>{
                          return <Route path={prop.path} component={prop.component} exact={prop.exact} key={key}/>;
                      })
                  }
              </Switch>
            </div>
            <Footer/>
          </div>
        </div>
      </Provider>
    );
  }
}

AuthApp.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};
export default withStyles(styles, {withTheme: true})(AuthApp);
