import React, { Component } from "react";
import withStyles from "@material-ui/core/es/styles/withStyles";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/es/Typography/Typography";
import Paper from "@material-ui/core/es/Paper/Paper";
import Grid from "@material-ui/core/es/Grid/Grid";
import Button from "@material-ui/core/es/Button/Button";
import Modal from "@material-ui/core/es/Modal/Modal";
import { withSnackbar } from "notistack";
import OrderStatus from "../../order/components/OrderStatus";
import CancelRequest from "./CancelRequest";
import AcceptRequest from "./AcceptRequest";
import RescheduleRequest from "./RescheduleRequest";
import DemoPersonScreen from "./DemoPersonScreen";

const styles = (theme) => ({
  actions: {
    padding: "10px 50px",
    background: "#fff",
  },

  addressSection: {
    background: "transparent",
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: "relative",
  },
  invoiceHeaderDetails: {
    backgroundColor: "#eee",
    padding: 20,
    borderRadius: 0,
    margin: 10,
  },
  invoiceHeader: {
    margin: 10,
    padding: 20,
    borderRadius: 0,
  },
  button: {
    margin: theme.spacing.unit,
  },
});

/**
 * Demo Reques tDetails component
 *
 * @return {*}
 * @constructor
 */
class DemoRequestDetailsComponent extends Component {
  /**
   * State of component
   * @type {{value: number}}
   */
  state = {
    cancel_request: false,
    accept_request: false,
    reschedule_request: false,
  };

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {};
  }

  modalMarkCancelRequest = () => {
    this.setState({
      ...this.state,
      cancel_request: !this.state.cancel_request,
    });
  };

  modalMarkRescheduleRequest = () => {
    this.setState({
      ...this.state,
      reschedule_request: !this.state.reschedule_request,
    });
  };

  modalMarkAcceptRequest = () => {
    this.setState({
      ...this.state,
      accept_request: !this.state.accept_request,
    });
  };

  /**
   * Render html
   * @return {*}
   */
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Paper className={classes.addressSection} elevation={0}>
          <Paper elevation={0} className={classes.invoiceHeader}>
            <Typography
              color="primary"
              align="left"
              variant="h4"
              component="h4"
            >
              REQUEST # 1234567
            </Typography>

            <Typography align="left" variant="caption" component="span">
              DEMO PERSON NAME:
            </Typography>
            <Typography align="left" variant="subtitle1" component="span">
              GoApptiv
            </Typography>
            <Typography direction="rtl" align="left">
              <OrderStatus status={"pending"} />
            </Typography>
          </Paper>
          <Paper className={classes.invoiceHeaderDetails} elevation={0}>
            <Typography align="left" variant="subtitle2">
              PATIENT REQUEST DETAILS:
            </Typography>
            <Typography align="left" variant="subtitle2">
              NAME
            </Typography>
            <Typography gutterBottom align="left" variant="caption">
              Ramesh
            </Typography>
            <Typography align="left" variant="subtitle2">
              PATIENT ADDRESS
            </Typography>
            <Typography gutterBottom align="left" variant="caption">
              Hanuman Temple, Road Number 1, Banjara Hills, Hyderabad - 500034,
              Telangana, India
            </Typography>

            <Typography align="left" variant="subtitle2">
              REQUEST NO.
            </Typography>
            <Typography gutterBottom align="left" variant="caption">
              # 1234567
            </Typography>

            <Typography align="left" variant="subtitle2">
              REQUEST DATE
            </Typography>
            <Typography gutterBottom align="left" variant="caption" disabled>
              11/10/2022
            </Typography>
          </Paper>
        </Paper>
        <Paper elevation={0} className={classes.actions}>
          <Button
            className={classes.button}
            buttonRef={(node) => {
              this.anchorEl = node;
            }}
            variant="contained"
            color="primary"
            onClick={this.modalMarkAcceptRequest}
            size="small"
          >
            Accept
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="secondary"
            onClick={this.modalMarkCancelRequest}
          >
            Reject
          </Button>

          {/* <div className={classes.wrapper}>
              <Button
                className={classes.button}
                buttonRef={(node) => {
                  this.anchorEl = node;
                }}
                variant="contained"
                color="primary"
                onClick={()=>{}}
                aria-describedby={packing_id}
                size="small"
              >
                Completed
              </Button>
              {saving && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
         */}

          {/* <div className={classes.wrapper}>
                <div>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={this.modalMarkRescheduleRequest}
                  >
                    Reschedule
                  </Button>
                </div>
              {saving && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div> */}
        </Paper>

        <Modal
          aria-labelledby="address-label-order-title"
          aria-describedby="address-label-order-description"
          open={this.state.cancel_request}
          onClose={() => {}}
        >
          <div
            style={{ width: "600px", margin: 200 }}
            className={classes.modalCss}
          >
            <CancelRequest close={this.modalMarkCancelRequest} />
          </div>
        </Modal>
        <Modal
          aria-labelledby="address-label-order-title"
          aria-describedby="address-label-order-description"
          open={this.state.reschedule_request}
          onClose={() => {}}
        >
          <div
            style={{ width: "600px", margin: 200 }}
            className={classes.modalCss}
          >
            <RescheduleRequest close={this.modalMarkRescheduleRequest} />
          </div>
        </Modal>

        <Modal
          aria-labelledby="address-label-order-title"
          aria-describedby="address-label-order-description"
          open={this.state.accept_request}
          onClose={() => {}}
        >
          <div
            style={{ width: "600px", margin: 200 }}
            className={classes.modalCss}
          >
            <AcceptRequest close={this.modalMarkAcceptRequest} />
            {/* <DemoPersonScreen close={this.modalMarkAcceptRequest}/> */}
          </div>
        </Modal>
      </div>
    );
  }
}

export default withSnackbar(withStyles(styles)(DemoRequestDetailsComponent));
