import React, {Component} from 'react'
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Paper from "@material-ui/core/es/Paper/Paper";
import Table from "@material-ui/core/es/Table/Table";
import TableHead from "@material-ui/core/es/TableHead/TableHead";
import TableCell from "@material-ui/core/es/TableCell/TableCell";
import TableBody from "@material-ui/core/es/TableBody/TableBody";
import TableRow from "@material-ui/core/es/TableRow/TableRow";
import Grid from "@material-ui/core/es/Grid/Grid";
import {ReportConstant} from "../ReportConstant";

const styles = theme => ({
  paper: {
    overflowY: "scroll",
    maxHeight: "60vh"
  },
  tableCell: {
    padding: "4px"
  },
  cellSmall: {
    width: "100px"
  },
  veryCellSmall: {
    width: "50px"
  }
});

class PatientProgramTableComponent extends Component {
  render() {
    const {patientPrograms, classes} = this.props;
    return (
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>#</TableCell>
                  <TableCell className={classes.tableCell}>Coupon Id</TableCell>
                  <TableCell align="center" className={classes.tableCell}>Date of Enrolment</TableCell>
                  <TableCell align="center" className={classes.tableCell}>Month of Enrolment</TableCell>
                  <TableCell align="center" className={classes.tableCell + " " + classes.cellSmall}>
                    Date of 1st therapy infusion
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell + " " + classes.cellSmall}>Status</TableCell>
                  <TableCell align="center" className={classes.tableCell}>Doctor</TableCell>
                  <TableCell align="center" className={classes.tableCell}>Hospital</TableCell>
                  <TableCell align="center" className={classes.tableCell}>Product</TableCell>
                  <TableCell align="center" className={classes.tableCell}>Dosage</TableCell>
                  <TableCell align="center" className={classes.tableCell + " " + classes.cellSmall}>
                    No of cycles completed
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>Treatment Cycle</TableCell>
                  <TableCell align="center" className={classes.tableCell}>QOS</TableCell>
                  {/*<TableCell align="center" className={classes.tableCell}>Total Purchased</TableCell>*/}
                  <TableCell align="center" className={classes.tableCell + " " + classes.veryCellSmall}>
                    Total Free sample
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell + " " + classes.cellSmall}>
                    Drop date
                  </TableCell>

                  <TableCell align="center" className={classes.tableCell + " " + classes.veryCellSmall}>
                    Total InBound Calls
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell + " " + classes.veryCellSmall}>
                    Total OutBound Calls
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell + " " + classes.veryCellSmall}>
                    Total month on therapy
                  </TableCell>
                  {/*<TableCell align="center" className={classes.tableCell}>*/}
                  {/*Total Number of follow-up call for the patients (Outgoing & Incoming)*/}
                  {/*</TableCell>*/}
                </TableRow>
              </TableHead>
              <TableBody>
                {patientPrograms.map((row, key) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row" className={classes.tableCell}>
                      {key + 1}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>{row.coupon_code}</TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {ReportConstant.formatDate(row.enrolment_date)}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {ReportConstant.getMonth(row.enrolment_date)}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {ReportConstant.formatDate(row.treatment_start_date)}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {row.current_status}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {row.doctor_full_name}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {row.doctor_hospital}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {row.brand}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {row.dosage + row.uom}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {this.renderPatientProgramStatus(row)}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>{row.treatment_cycle}</TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {row.qos}
                    </TableCell>
                    {/*<TableCell align="right" className={classes.tableCell}>{}</TableCell>*/}
                    <TableCell align="right" className={classes.tableCell}>
                      {row.free_samples_processed}
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>{row.drop_out_date}</TableCell>
                    <TableCell align="right" className={classes.tableCell}>{row.no_of_incoming_calls}</TableCell>
                    <TableCell align="right" className={classes.tableCell}>{row.no_of_outgoing_calls}</TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      {ReportConstant.differenceFromCurrentMonth(row.treatment_start_date)}
                    </TableCell>
                    {/*<TableCell align="right" className={classes.tableCell}>{row.no_of_phone_calls}</TableCell>*/}
                  </TableRow>
                ))}
                {
                  patientPrograms.length === 0 &&
                  <TableRow>
                    <TableCell component="th" scope="row" colspan={10}>
                      NO RECORDS FOUND
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>);

  }

  /**
   * Render patient program status
   *
   * @param patientProgram
   * @return {*}
   */
  renderPatientProgramStatus(patientProgram) {
    if (patientProgram.current_status === 'active')
      return patientProgram.total_orders + " Cycles";
    return patientProgram.current_status;

  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
PatientProgramTableComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  patientPrograms: PropTypes.array.isRequired
};
export default withStyles(styles, {withTheme: true})(PatientProgramTableComponent);
