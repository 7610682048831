import React from 'react'
import PropTypes from "prop-types";
import Grid from "@material-ui/core/es/Grid/Grid";
import withStyles from "@material-ui/core/es/styles/withStyles";
import formController from "../../../../common/hoc/forms/formController";
import OutlinedInput from "@material-ui/core/es/OutlinedInput/OutlinedInput";
import Select from "@material-ui/core/es/Select/Select";
import InputLabel from "@material-ui/core/es/InputLabel/InputLabel";
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";
import TextField from "@material-ui/core/es/TextField/TextField";

const styles = theme => ({
  formControl: {
    margin: 0
  },
  textField: {
    margin: 0,
  },
  picker: {
    marginLeft: 8,
    marginRight: 8
  }
});

class PatientProgramStatusUpdateForm extends React.Component {
  render() {
    const {classes, onFieldChange, model, data: {statuses}} = this.props;
    return (<Grid container spacing={8}>
      <Grid item xs={12}>
        <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
          <InputLabel ref={ref => {
            this.InputLabelRef = ref;
          }} htmlFor="document-group">Select status</InputLabel>
          <Select value={model.status} onChange={onFieldChange}
                  input={
                    <OutlinedInput labelWidth={100} name="status" id="patient-program-status"/>
                  }>
            {
              statuses.map((d, i) => {
                return <MenuItem value={d} key={i}>{d}</MenuItem>
              })
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField id="fields" label="Comments" name="closer_comments" className={classes.textField}
                   multiline={true} defaultValue={model.closer_comments} rows={2} onChange={onFieldChange}
                   placeholder="Enter comments if any"
                   margin="dense" fullWidth={true} variant="outlined"/>
      </Grid>
    </Grid>);
  }
}

PatientProgramStatusUpdateForm.propTypes = {
  data: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired
};

export default formController(withStyles(styles)(PatientProgramStatusUpdateForm));