import React from 'react'
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/es/ListItem/ListItem";
import ListItemText from "@material-ui/core/es/ListItemText/ListItemText";
import Typography from "@material-ui/core/es/Typography/Typography";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Icon from "@material-ui/core/es/Icon/Icon";
import ListItemIcon from "@material-ui/core/es/ListItemIcon/ListItemIcon";

const styles = theme => ({
  list: {
    selected: {
      border: "1px solid #000",
      backgroundColor: '#000'
    }
  },
  icon: {
    margin: theme.spacing.unit * 0.5,
  },
  item_icon: {
    marginRight: 0
  }
});

/**
 * Patient personal detail
 *
 * @param patient
 * @return {*}
 * @constructor
 */
function PatientProgramItem({patientProgram, selectedPatientProgramId, onSelect, classes}) {

  /**
   * Select patient program
   */
  let selectPatientProgram = () => {
    onSelect(patientProgram);
  };

  const noOfDocumentsPendingUpload = patientProgram.documents.filter(document => {
    return !document.document_id;
  }).length;

  // render
  return (
    <ListItem selected={selectedPatientProgramId === patientProgram.id}
              alignItems="flex-start" onClick={selectPatientProgram} button={true} divider={true}>
      <ListItemIcon className={classes.item_icon}>
        <Icon className={classes.icon} color="primary" fontSize="large">
          account_circle
        </Icon>
      </ListItemIcon>
      <ListItemText
        primary={
          <React.Fragment>
            <Typography variant="body2" gutterBottom>
              {patientProgram.patient_full_name != null ? patientProgram.patient_full_name : patientProgram.mobile}
            </Typography>
          </React.Fragment>
        }
        secondary={
          <React.Fragment>
            <Typography variant="caption">
              {patientProgram.program.name} ({patientProgram.product != null && patientProgram.product.name})
            </Typography>
            {
              noOfDocumentsPendingUpload > 0 &&
              <Typography variant="caption" color="secondary">
                {`Documents pending : ${noOfDocumentsPendingUpload}`}
              </Typography>
            }
          </React.Fragment>
        }
      />
    </ListItem>
  )
}

/**
 * Properties which can be passed to component
 *
 * @type {{patientProgram: *, selectedPatientProgramId: shim, onSelect: *}}
 */
PatientProgramItem.propTypes = {
  patientProgram: PropTypes.object.isRequired,
  selectedPatientProgramId: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PatientProgramItem);

