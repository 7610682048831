import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Paper from "@material-ui/core/es/Paper/Paper";
import Table from "@material-ui/core/es/Table/Table";
import TableHead from "@material-ui/core/es/TableHead/TableHead";
import TableCell from "@material-ui/core/es/TableCell/TableCell";
import TableBody from "@material-ui/core/es/TableBody/TableBody";
import TableRow from "@material-ui/core/es/TableRow/TableRow";
import { ReportConstant as ReportConstants } from "../ReportConstant";

const styles = (theme) => ({
  paper: {
    margin: theme.spacing.unit * 1,
  },
  cellSmall: {
    width: "100px",
  },
  tableCell: {
    padding: "4px",
  },
});

class MonthlyCountTableComponent extends Component {
  render() {
    const { monthlyCounts, classes } = this.props;
    return (
      <Paper>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                className={classes.tableCell + " " + classes.cellSmall}
              >
                Month
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableCell + " " + classes.cellSmall}
              >
                Total enrolments
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableCell + " " + classes.cellSmall}
              >
                No. of active enrolments
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableCell + " " + classes.cellSmall}
              >
                No. of deferred active enrolments
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableCell + " " + classes.cellSmall}
              >
                No. of QOS Dropped{" "}
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableCell + " " + classes.cellSmall}
              >
                No. of QOS Closed{" "}
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableCell + " " + classes.cellSmall}
              >
                No. of QOS released
              </TableCell>
              {/* <TableCell
                align="center"
                className={classes.tableCell + " " + classes.cellSmall}
              >
                Total calls (Outgoing)
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableCell + " " + classes.cellSmall}
              >
                Total calls (Incoming)
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableCell + " " + classes.cellSmall}
              >
                Avg no. of calls for deferred
              </TableCell> */}
              <TableCell
                align="center"
                className={classes.tableCell + " " + classes.cellSmall}
              >
                Avg time on therapy for dropped
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {monthlyCounts.map((row) => (
              <TableRow key={row.month}>
                <TableCell component="th" scope="row">
                  {ReportConstants.convertToMonthYearString(
                    row.month,
                    row.year
                  )}
                </TableCell>
                <TableCell align="right">{row.total_enrolments}</TableCell>
                <TableCell align="right">{row.active}</TableCell>
                <TableCell align="right">{row.deferred}</TableCell>
                <TableCell align="right">{row.dropped}</TableCell>
                <TableCell align="right">{row.closed}</TableCell>
                <TableCell align="right">{row.released}</TableCell>
                {/* <TableCell align="right">{row.total_no_of_outgoing_calls}</TableCell> */}
                {/* <TableCell align="right">
                  {row.total_no_of_incoming_calls}
                </TableCell> */}
                {/* <TableCell align="right">
                  {ReportConstants.roundToOneDecimal(row.avg_no_of_phone_calls)}
                </TableCell> */}
                <TableCell align="right">{row.time_on_therapy}</TableCell>
              </TableRow>
            ))}
            {monthlyCounts.length === 0 && (
              <TableRow>
                <TableCell component="th" scope="row" colspan={7}>
                  NO RECORDS FOUND
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
MonthlyCountTableComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  monthlyCounts: PropTypes.array.isRequired,
};
export default withStyles(styles, { withTheme: true })(
  MonthlyCountTableComponent
);
