import { get, post } from "../../common/services/BaseService";
import { PatientProgramConstant } from "../PatientProgramConstant";
import {
  failedFindCfaList,
  failedFindOrderListForPatient,
  requestFindCfaList,
  requestFindOrderListForPatient,
  successFindCfaList,
  successFindOrderListForPatient,
} from "../reducers/actions/order-actions";

/**
 * Create order
 *
 * @param order
 * @return {Promise<Response>}
 */
export const createOrder = (order) => {
  return post(`${PatientProgramConstant.ORDERS_URL}`, order)
    .then((json) => {
      return json;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Send Email
 *
 * @param id
 * @return {Promise<Response>}
 */
export const sendEmail = (id) => {
  return get(`${PatientProgramConstant.SEND_ORDER_EMAIL_URL}/${id}`)
    .then((json) => {
      return json;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Find users
 *
 * @returns {function(*): Promise<any>}
 */
export const findUsers = () => (dispatch) => {
  dispatch(requestFindCfaList());
  return get(`${PatientProgramConstant.ORDER_USERS_URL}`)
    .then((json) => {
      dispatch(successFindCfaList(json));
      return json;
    })
    .catch((error) => {
      dispatch(failedFindCfaList(error));
      throw error;
    });
};

/**
 * Find users
 *
 * @returns {function(*): Promise<any>}
 */
export const fetchOrdersForPatient = (patientId) => (dispatch) => {
  dispatch(requestFindOrderListForPatient());
  return get(`${PatientProgramConstant.ORDERS_URL}/forPatient/${patientId}`)
    .then((json) => {
      dispatch(successFindOrderListForPatient(json));
      return json;
    })
    .catch((error) => {
      dispatch(failedFindOrderListForPatient(error));
      throw error;
    });
};
