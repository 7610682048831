export default class RestError {

  /**
   * Error object
   *
   * @type {{}}
   */
  errors = {};

  constructor(errors) {
    this.errors = errors;
    this.name = 'RestError';
  }
}