import React, {Component} from 'react';
import {connect} from 'react-redux';
import {verifyTempToken} from "../../service/LoginService";
import {Redirect} from "react-router-dom";
import Grid from "@material-ui/core/es/Grid/Grid";
import Card from "@material-ui/core/es/Card/Card";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import Typography from "@material-ui/core/es/Typography/Typography";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import withStyles from "@material-ui/core/es/styles/withStyles";
import {withSnackbar} from 'notistack';

const styles = theme => ({
  card: {
    margin: 10
  },
  cardHeader: {
    textAlign: "center",
  },
  cardContent: {
    margin: "10px 30px"
  }
});

class TokenVerificationPage extends Component {

  state = {role: ''};

  /**
   * Component did mount
   */
  componentDidMount() {
    this.onSubmit();
  };

  /**
   * On submit of login form
   */
  onSubmit() {
    let self = this;
    let {dispatch, match} = this.props;
    verifyTempToken(match.params.token + ".abc").then(function (json) {
      localStorage.setItem("auth", JSON.stringify(json.auth));
      self.setState({...self.state, role: json.auth.role.name});
      return json;
    })
  };

  // view
  render() {
    const {classes} = this.props;
    return (
      <Grid container spacing={24}>
        <Grid item md={3} sm={2} xs={12}>
        </Grid>
        <Grid item md={6} sm={8} xs={12}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" color="inherit" className={classes.cardHeader}>
                WELCOME TO
              </Typography>
              <Typography variant="h4" color="primary" className={classes.cardHeader}>
                CONNECT ON DEMAND APP
              </Typography>
              <Typography variant="h4" color="inherit" className={classes.cardHeader}>
                <CircularProgress size={30} className={classes.buttonProgress}/>
              </Typography>
              <Typography variant="h5" color="inherit" className={classes.cardHeader}>
                VERIFYING USER
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {
          this.state.role === "ADMIN"
          && <Redirect to='/patient_programs'/>
        }
        {
          this.state.role === "CFA"
          && <Redirect to='/orders'/>
        }
        {
          this.state.role === "PATIENT"
          && <Redirect to='/patients/patient_programs'/>
        }
      </Grid>
    )
  };
}

/**
 * Map state to properties
 *
 * @param state
 * @returns {{auth: *}}
 */
const mapStateToProps = state => {
  const {authData} = state;

  return {...authData};
};

export default connect(mapStateToProps)(withSnackbar(withStyles(styles)(TokenVerificationPage)));
