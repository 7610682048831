import {get, post, put} from "../../common/services/BaseService";
import {PatientProgramConstant} from "../PatientProgramConstant";
import {
  failedFetchFollowUpMonthlyCount,
  failedFetchFollowUps,
  failedFetchFollowUpsForDate,
  failedFetchFollowUpsForPatientProgram,
  requestFetchFollowUpForDate,
  requestFetchFollowUpMonthlyCount,
  requestFetchFollowUps,
  requestFetchFollowUpsForDate,
  requestFetchFollowUpsForPatientProgram,
  successFetchFollowUpMonthlyCount,
  successFetchFollowUps,
  successFetchFollowUpsForDate,
  successFetchFollowUpsForPatientProgram
} from "../reducers/actions/follow-up-actions";

/**
 * Fetch follow ups
 *
 * @returns {function(*): Promise<any>}
 * @param q
 * @param page
 * @param length
 */
export const fetchFollowUps = (q = "", page = 0, length = 50) => dispatch => {
  dispatch(requestFetchFollowUps());
  return get(PatientProgramConstant.FOLLOW_UPS_URL, {
    q: q,
    page: page,
    length: length
  }).then(json => {
    dispatch(successFetchFollowUps(json));
    return json;
  }).catch(error => {
    dispatch(failedFetchFollowUps(error));
    throw(error);
  });
};

/**
 * Fetch follow ups for patient program
 *
 * @returns {function(*): Promise<any>}
 * @param patient_program_id
 */
export const fetchFollowUpsForPatientProgram = (patient_program_id) => dispatch => {
  dispatch(requestFetchFollowUpsForPatientProgram());
  return get(`${PatientProgramConstant.FOLLOW_UPS_URL}/for_patient_program/${patient_program_id}`,).then(json => {
    dispatch(successFetchFollowUpsForPatientProgram(json));
    return json;
  }).catch(error => {
    dispatch(failedFetchFollowUpsForPatientProgram(error));
    throw(error);
  });
};


/**
 * Fetch follow ups monthly counts
 *
 * @returns {function(*): Promise<any>}
 * @param month
 * @param year
 */
export const fetchFollowUpsMonthlyCounts = (month, year) => dispatch => {
  dispatch(requestFetchFollowUpMonthlyCount());
  return get(`${PatientProgramConstant.FOLLOW_UPS_URL}/monthly_count/${month}/${year}`,).then(json => {
    dispatch(successFetchFollowUpMonthlyCount(json));
    return json;
  }).catch(error => {
    dispatch(failedFetchFollowUpMonthlyCount(error));
    throw(error);
  });
};


/**
 * Fetch follow ups for date
 *
 * @returns {function(*): Promise<any>}
 * @param date
 * @param month
 * @param year
 */
export const fetchFollowUpsForMonth = (date, month, year) => dispatch => {
  dispatch(requestFetchFollowUpsForDate());
  return get(`${PatientProgramConstant.FOLLOW_UPS_URL}/for_date/${date}/${month}/${year}`,).then(json => {
    dispatch(successFetchFollowUpsForDate(json));
    return json;
  }).catch(error => {
    dispatch(failedFetchFollowUpsForDate(error));
    throw(error);
  });
};

/**
 * Create follow up
 *
 * @param follow_up
 * @return {Promise<Response>}
 */
export const createFollowUp = (follow_up) => {
  return post(`${PatientProgramConstant.FOLLOW_UPS_URL}`, follow_up).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};


/**
 * Create follow up
 *
 * @param id
 * @param follow_up
 * @return {Promise<Response>}
 */
export const updateFollowUp = (id, follow_up) => {
  return put(`${PatientProgramConstant.FOLLOW_UPS_URL}/${id}`, follow_up).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};