import AuthApp from '../auth/AuthApp';
import PatientApp from "../patient/PatientApp";
import PatientProgramApp from "../patient_program/PatientProgramApp";
import OrderApp from "../order/OrderApp";
import SplashApp from "../SplashApp";
import ReportApp from "../reports/ReportApp";
import PatientEnrollmentApp from '../patient_enrollment_program/PatientEnrollmentApp';
import DemoRequestApp from '../demo_request/DemoRequestApp';


let indexRoutes = [{
  path: "/", name: "Splash page", component: SplashApp, exact: true
  //path: "/", component: PatientEnrollmentApp, exact: true
  // path: "/", component: DemoRequestApp, exact: true
}, {
  path: "/auth", name: "Auth app", component: AuthApp, exact: false
}, {
  path: "/patient_programs", component: PatientProgramApp, exact: false
}, {
  path: "/orders", component: OrderApp, exact: false
}, {
  path: "/patients", component: PatientApp, exact: false
}, {
  path: "/reports", component: ReportApp, exact: false
}];

export default indexRoutes;