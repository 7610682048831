/**
 * Initial rest state
 *
 * @type {{isFetching: boolean, failed: boolean, success: boolean, errors: {}}}
 */

export const initialRestState = {
  isFetching: false,
  failed: false,
  success: false,
  errors: {},
  data: {}
};

/**
 * Rest Request statuses
 * @type {string}
 */
export const NEW_REST_REQUEST = 'NEW';
export const SUCCESS_REST_REQUEST = 'SUCCESS';
export const FAILED_REST_REQUEST = 'FAILED';

/**
 * handle Rest Requests
 *
 * @param state
 * @param action
 * @return {*}
 */
export const handleRestRequest = (state, action) => {
  switch (action.status) {
    case FAILED_REST_REQUEST:
      return {
        ...state,
        isFetching: false,
        failed: true,
        success: false,
        errors: action.errors
      };
    case NEW_REST_REQUEST:
      return {
        ...state,
        isFetching: true,
        failed: false,
        errors: {}
      };
    case SUCCESS_REST_REQUEST:
      return {
        ...state,
        isFetching: false,
        failed: false,
        errors: {},
        success: true,
        data: action.data
      };
    default:
      return state
  }
};