import {get, put} from "../../common/services/BaseService";
import {OrderConstants} from "../OrderConstants";
import {
  failedFindCfaList,
  failedFindOrders,
  failedFindVendorList,
  requestFindCfaList,
  requestFindOrders,
  requestFindVendorList,
  successFindCfaList,
  successFindOrders,
  successFindVendorList
} from "../reducers/actions/order-actions";

/**
 * Find orders
 *
 * @returns {function(*): Promise<any>}
 * @param q
 * @param page
 * @param length
 */
export const findOrders = (q = "", page = 0, length = 50) => dispatch => {
  dispatch(requestFindOrders());
  return get(OrderConstants.ORDERS_URL, {
    q: q,
    page: page,
    length: length
  }).then(json => {
    dispatch(successFindOrders(json));
    return json;
  }).catch(error => {
    dispatch(failedFindOrders(error));
    throw(error);
  });
};

/**
 * Find users
 *
 * @returns {function(*): Promise<any>}
 */
export const findUsers = () => dispatch => {
  dispatch(requestFindCfaList());
  return get(`${OrderConstants.ORDER_USERS_URL}`).then(json => {
    dispatch(successFindCfaList(json));
    return json;
  }).catch(error => {
    dispatch(failedFindCfaList(error));
    throw(error);
  });
};

/**
 * Find vendors
 *
 * @returns {function(*): Promise<any>}
 */
export const findVendors = () => dispatch => {
  dispatch(requestFindVendorList());
  return get(`${OrderConstants.ORDER_VENDORS_URL}`).then(json => {
    dispatch(successFindVendorList(json));
    return json;
  }).catch(error => {
    dispatch(failedFindVendorList(error));
    throw(error);
  });
};

/**
 * Accept order
 *
 * @param id
 * @returns {function(*): Promise<any>}
 */
export const acceptOrder = (id) => dispatch => {
  return put(`${OrderConstants.ORDERS_URL}/${id}/accept`).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};

/**
 * Transfer order
 *
 * @param id
 * @param order
 * @returns {function(*): Promise<any>}
 */
export const transferOrder = (id, order) => dispatch => {
  return put(`${OrderConstants.ORDERS_URL}/${id}/transfer`, order).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};

/**
 * Packing order
 *
 * @param id
 * @returns {function(*): Promise<any>}
 */
export const packOrder = (id) => dispatch => {
  return put(`${OrderConstants.ORDERS_URL}/${id}/packing`).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};

/**
 * Dispatch order
 *
 * @param id
 * @param order
 * @returns {function(*): Promise<any>}
 */
export const dispatchOrder = (id, order) => dispatch => {
  return put(`${OrderConstants.ORDERS_URL}/${id}/dispatch`, order).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};

/**
 * Delivered order
 *
 * @param id
 * @param order
 * @returns {function(*): Promise<any>}
 */
export const deliveredOrder = (id, order) => dispatch => {
  return put(`${OrderConstants.ORDERS_URL}/${id}/delivered`, order).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};

/**
 * Save order
 *
 * @param id
 * @param order
 * @returns {function(*): Promise<any>}
 */
export const saveOrder = (id, order) => dispatch => {
  return put(`${OrderConstants.ORDERS_URL}/${id}`, order).then(json => {
    return json
  }).catch(error => {
    throw(error);
  });
};