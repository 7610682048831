import React, {Component} from 'react';
import {connect} from 'react-redux';
import Grid from "@material-ui/core/es/Grid/Grid";
import withStyles from "@material-ui/core/es/styles/withStyles";
import green from "@material-ui/core/es/colors/green";
import {findOrders, findUsers, findVendors} from "../services/OrderService";
import OrderListComponent from "../components/OrderListComponent";
import OrderDetailsComponent from "../components/OrderDetailsComponent";
import orderHandler from "./handlers/orderHandler";
import NoContent from "../../common/components/common/NoContent";

const styles = theme => ({
  icon: {
    margin: theme.spacing.unit * 0.5,
  },
  button: {
    borderRadius: 0,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  tabContainer: {
    flexGrow: 1,
  },
  orderDetail: {
    backgroundColor: "#fff",
    overflowY: "scroll"
  },
  order: {
    backgroundColor: "#eee"
  },
  fullHeight: {
    minHeight: "100%",
    backgroundColor: "#fff"
  },
  leftPanel: {
    minHeight: "100%",
    backgroundColor: "#eee",
    zIndex: '1'
  }
});


class OrderPage extends Component {

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.selectOrder = this.selectOrder.bind(this);
    this.state = {
      value: 0,
      selected_order: {id: null}
    };
    this.refreshOrderList = this.refreshOrderList.bind(this);
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    const {dispatch} = this.props;

    // request for all patient programs and programs
    dispatch(findOrders()); // TODO:: make it paginated
    dispatch(findUsers());
    dispatch(findVendors());
  };

  /**
   * Refresh order list
   */
  refreshOrderList() {
    const {dispatch} = this.props;
    let self = this;
    dispatch(findOrders()).then(function (json) {
      let order = json.orders.find(o => o.id === self.state.selected_order.id);
      self.setState({...self.state, selected_order: order});
    });
  }

  /**
   * Select order
   *
   * @param order
   */
  selectOrder(order) {
    this.setState({
      ...this.state,
      selected_order: order
    })
  }

  /**
   * Prepare view
   *
   * @return {*}
   */
  render() {
    const {selected_order} = this.state;
    const {classes, cfa_list, vendors, orders, dispatch} = this.props;
    return (
      <Grid container spacing={0} className={classes.fullHeight} direction={'row-reverse'}>
        <Grid item md={7} sm={7} className={classes.orderDetail + " " + classes.fullHeight}>
          {
            !selected_order.id &&
            <NoContent label="Please select order to view details"/>
          }
          {
            selected_order.id &&
            <OrderDetailsComponent order={selected_order} cfa_list={cfa_list}
                                   vendors={vendors}
                                   handlers={orderHandler(dispatch)} done={this.refreshOrderList}/>
          }
        </Grid>
        <Grid item md={5} sm={5} className={classes.leftPanel}>
          <OrderListComponent orders={orders} selectOrder={this.selectOrder}
                              selected_order={selected_order}/>
        </Grid>
      </Grid>
    )
  };
}

/**
 * Map state to properties
 *
 * @param state
 * @returns {{auth: *}}
 */
const mapStateToProps = state => {
  const {orders, cfaList, vendors} = state;

  return {orders: orders, cfa_list: cfaList, vendors: vendors};
};
export default connect(mapStateToProps)(withStyles(styles)(OrderPage));
