import React, { Component } from "react";
import withStyles from "@material-ui/core/es/styles/withStyles";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/es/Typography/Typography";
import Button from "@material-ui/core/es/Button/Button";
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import green from "@material-ui/core/es/colors/green";
import CardActions from "@material-ui/core/es/CardActions/CardActions";
import Card from "@material-ui/core/es/Card/Card";
import moment from "moment/moment";
import Table from "@material-ui/core/es/Table/Table";
import TableHead from "@material-ui/core/es/TableHead/TableHead";
import TableRow from "@material-ui/core/es/TableRow/TableRow";
import TableCell from "@material-ui/core/es/TableCell/TableCell";
import { OrderConstants } from "../OrderConstants";
import TableBody from "@material-ui/core/es/TableBody/TableBody";
import Constants from "../../Constants";

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  card: {
    borderRadius: 0,
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

/**
 * Address label
 *
 * @return {*}
 * @constructor
 */
class AddressLabel extends Component {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      delivery_date: "",
      picker_date: new Date(),
    };
  }

  /**
   * Handle date change
   * @param date
   */
  handleDateChange = (date) => {
    let m = moment(date);
    let only_date = m.format("YYYY-MM-DD");
    this.setState({
      ...this.state,
      delivery_date: only_date,
      picker_date: date,
    });
  };

  /**
   * Toggle saving
   */
  toggleSaving = () => {
    this.setState({
      ...this.state,
      saving: !this.state.saving,
    });
  };

  /**
   * Render html
   * @return {*}
   */
  render() {
    const { classes, close, order } = this.props;

    return (
      <Card className={classes.card}>
        <AppBar position="static">
          <Toolbar variant="dense">
            <Typography variant="h6" color="inherit">
              Address label
            </Typography>
          </Toolbar>
        </AppBar>
        <CardContent>
          <Table className={classes.table}>
            <TableBody>
              <TableRow>
                <TableCell component="th">Order for</TableCell>
                <TableCell>{order.order_for}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">Contact Number</TableCell>
                <TableCell>{order.contact_no}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">Address</TableCell>
                <TableCell>
                  {OrderConstants.addressToString(order.address)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
        <CardActions dir="rtl">
          <Button variant="outlined" color="secondary" onClick={close}>
            Close
          </Button>
          <div className={classes.wrapper}>
            <Button variant="contained" color="primary">
              Print
            </Button>
          </div>
        </CardActions>
      </Card>
    );
  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
AddressLabel.propTypes = {
  order: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddressLabel);
