import React, {Component} from 'react'
import withStyles from "@material-ui/core/es/styles/withStyles";
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/es/Typography/Typography";
import Grid from "@material-ui/core/es/Grid/Grid";
import Button from "@material-ui/core/es/Button/Button";
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import green from "@material-ui/core/es/colors/green";
import CardActions from "@material-ui/core/es/CardActions/CardActions";
import Card from "@material-ui/core/es/Card/Card";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import InputLabel from "@material-ui/core/es/InputLabel/InputLabel";
import Select from "@material-ui/core/es/Select/Select";
import OutlinedInput from "@material-ui/core/es/OutlinedInput/OutlinedInput";
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";
import {withSnackbar} from 'notistack';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  card: {
    borderRadius: 0
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});


/**
 * Transfer order form
 *
 * @return {*}
 * @constructor
 */
class TransferOrderForm extends Component {

  /**
   * Patient form data
   */
  state = {
    saving: false,
    user_id: ''
  };

  /**
   * On value change
   *
   * @param name
   */
  onFieldChange = name => event => {
    this.setState({
      ...this.state,
      [name]: event.target.value
    });
  };

  /**
   * Toggle saving
   */
  toggleSaving = () => {
    this.setState({
      ...this.state,
      saving: !this.state.saving
    });
  };

  /**
   * Update document
   */
  transfer = () => {
    this.toggleSaving();
    let {close, done, handlers, order} = this.props;
    handlers.transferOrder(order.id, {user_id: this.state.user_id}).then(json => {
      this.setState({
        ...this.state,
        saving: !this.state.saving,
      });
      this.props.enqueueSnackbar('Order transferred successfully!');
      done();
      close();
    });
  };

  /**
   * Render html
   * @return {*}
   */
  render() {
    const {classes, close, cfa_list: {success, data}} = this.props;
    const {saving, order} = this.state;

    return <Card className={classes.card}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Typography variant="h6" color="inherit">
            Transfer order
          </Typography>
        </Toolbar>
      </AppBar>
      <CardContent>
        <form noValidate autoComplete="off">
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                {
                  success &&
                  <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
                    <InputLabel ref={ref => {
                      this.InputLabelRef = ref;
                    }} htmlFor="cfa">CFA</InputLabel>
                    <Select value={this.state.user_id} onChange={this.onFieldChange("user_id")}
                            input={
                              <OutlinedInput labelWidth={0} name="CFA"
                                             id="cfa"/>
                            }>
                      {
                        data.users.map((d, i) => {
                          return <MenuItem value={d.id} key={i}>{d.full_name}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>
                }

              </Grid>
            </Grid>
          </Grid>
        </form>
      </CardContent>
      <CardActions dir="rtl">
        <Button variant="outlined" color="secondary" onClick={close} disabled={saving}>
          Close
        </Button>
        <div className={classes.wrapper}>
          <Button variant="contained" color="primary" disabled={saving} onClick={this.transfer.bind(this)}>
            {saving ? "Transferring" : "Transfer"}
          </Button>
          {saving && <CircularProgress size={24} className={classes.buttonProgress}/>}
        </div>
      </CardActions>
    </Card>;
  }
}


/**
 * Properties
 *
 * @type {{fetch: *}}
 */
TransferOrderForm.propTypes = {
  order: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  cfa_list: PropTypes.object.isRequired,
  handlers: PropTypes.object.isRequired,
  done: PropTypes.func.isRequired
};

export default withSnackbar(withStyles(styles)(TransferOrderForm));