import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import '../../scss/login.css'
import VerificationCodeForm from "../../components/forgot_password/VerificationCodeForm";
import PasswordResetForm from "../../components/forgot_password/PasswordResetForm";

/**
 * Forgot Password Page
 */
class ForgotPasswordPage extends Component {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    failed: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    verificationCodeSent: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired
  };

  render() {
    const {isFetching, dispatch, verificationCodeSent} = this.props;
    return (
      <div className="row">
        <div className="col-md-4 offset-md-4 login-form">
          <h3>FORGOT PASSWORD FORM</h3>
          {/* Send verification code section */}
          {!verificationCodeSent && <VerificationCodeForm isFetching={isFetching} dispatch={dispatch}/>}
          {/* ./Send verification code section */}

          {/* Password reset section */}
          {verificationCodeSent && <PasswordResetForm isFetching={isFetching} dispatch={dispatch}/>}
          {/* ./Password reset section */}
        </div>
      </div>
    )
  };
}

/**
 * Map state to properties
 *
 * @param state
 * @returns {{}}
 */
const mapStateToProps = state => {
  const {forgotPasswordData} = state;
  return forgotPasswordData;
};

export default connect(mapStateToProps)(ForgotPasswordPage)
