import React, { Component } from "react";
import PropTypes from "prop-types";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import Typography from "@material-ui/core/es/Typography/Typography";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Card from "@material-ui/core/es/Card/Card";
import Grid from "@material-ui/core/es/Grid/Grid";

const styles = (theme) => ({
  card: {
    margin: theme.spacing.unit * 0.5,
  },
  cardPrimary: {
    backgroundColor: "#3f51b5",
  },
  cardDanger: {
    backgroundColor: "#f44336",
  },
  cardDark: {
    backgroundColor: "#757575",
  },
  cardGreen: {
    backgroundColor: "#009688",
  },
  cardYellow: {
    backgroundColor: "#fbc02d",
  },
  whiteText: {
    color: "#ffffff",
  },
  textTitle: {
    textAlign: "center",
  },
  box: {
    paddingBottom: "5px !important",
    paddingTop: "5px !important",
  },
  subTitle: {
    fontSize: "12px",
  },
});

class CountsComponent extends Component {
  render() {
    const { counts, classes } = this.props;
    return (
      <Grid container spacing={0}>
        <Grid item xs={2}>
          <Card className={classes.card + " " + classes.cardPrimary}>
            <div className={classes.details}>
              <CardContent className={classes.box}>
                <Typography
                  variant="subtitle1"
                  className={classes.whiteText + " " + classes.textTitle}
                >
                  Enrolments
                </Typography>
              </CardContent>
              <CardContent className={classes.box}>
                <Grid container spacing={0}>
                  <Grid item xs={4}>
                    <Typography
                      component="h5"
                      variant="h5"
                      color="textPrimary"
                      className={classes.whiteText}
                    >
                      {counts.total_enrolments}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      variant="subtitle1"
                      className={classes.whiteText + " " + classes.subTitle}
                      dir="rtl"
                    >
                      Canmab: {counts.total_canmab_enrolments}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={classes.whiteText + " " + classes.subTitle}
                      dir="rtl"
                    >
                      Biomab: {counts.total_biomab_enrolments}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={classes.whiteText + " " + classes.subTitle}
                      dir="rtl"
                    >
                      Krabeva: {counts.total_krabeva_enrolments}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </div>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Card className={classes.card + " " + classes.cardDanger}>
            <div className={classes.details}>
              <CardContent className={classes.box}>
                <Typography
                  variant="subtitle1"
                  className={classes.whiteText + " " + classes.textTitle}
                >
                  Active
                </Typography>
              </CardContent>
              <CardContent className={classes.box}>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <Typography
                      component="h5"
                      variant="h5"
                      className={classes.whiteText}
                    >
                      {counts.existing}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle1"
                      className={classes.whiteText + " " + classes.subTitle}
                      dir="rtl"
                    >
                      Canmab: {counts.canmab_existing}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={classes.whiteText + " " + classes.subTitle}
                      dir="rtl"
                    >
                      Biomab: {counts.biomab_existing}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={classes.whiteText + " " + classes.subTitle}
                      dir="rtl"
                    >
                      Krabeva: {counts.krabeva_existing}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </div>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Card className={classes.card + " " + classes.cardDark}>
            <div className={classes.details}>
              <CardContent className={classes.box}>
                <Typography
                  variant="subtitle1"
                  className={classes.whiteText + " " + classes.textTitle}
                >
                  Released
                </Typography>
              </CardContent>
              <CardContent className={classes.box}>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <Typography
                      component="h5"
                      variant="h5"
                      className={classes.whiteText}
                    >
                      {counts.active}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle1"
                      className={classes.whiteText + " " + classes.subTitle}
                      dir="rtl"
                    >
                      Canmab: {counts.canmab_active}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={classes.whiteText + " " + classes.subTitle}
                      dir="rtl"
                    >
                      Biomab: {counts.biomab_active}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={classes.whiteText + " " + classes.subTitle}
                      dir="rtl"
                    >
                      Krabeva: {counts.krabeva_active}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </div>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Card className={classes.card + " " + classes.cardGreen}>
            <div className={classes.details}>
              <CardContent className={classes.box}>
                <Typography
                  variant="subtitle1"
                  className={classes.whiteText + " " + classes.textTitle}
                >
                  Deferred Active
                </Typography>
              </CardContent>
              <CardContent className={classes.box}>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <Typography
                      component="h5"
                      variant="h5"
                      className={classes.whiteText}
                    >
                      {counts.deferred}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle1"
                      className={classes.whiteText + " " + classes.subTitle}
                      dir="rtl"
                    >
                      Canmab: {counts.canmab_deferred}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={classes.whiteText + " " + classes.subTitle}
                      dir="rtl"
                    >
                      Biomab: {counts.biomab_deferred}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={classes.whiteText + " " + classes.subTitle}
                      dir="rtl"
                    >
                      Krabeva: {counts.krabeva_deferred}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </div>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Card className={classes.card + " " + classes.cardYellow}>
            <div className={classes.details}>
              <CardContent className={classes.box}>
                <Typography
                  variant="subtitle1"
                  className={classes.whiteText + " " + classes.textTitle}
                >
                  Dropped
                </Typography>
              </CardContent>
              <CardContent className={classes.box}>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <Typography
                      component="h5"
                      variant="h5"
                      className={classes.whiteText}
                    >
                      {counts.dropped}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle1"
                      className={classes.whiteText + " " + classes.subTitle}
                      dir="rtl"
                    >
                      Canmab: {counts.canmab_dropped}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={classes.whiteText + " " + classes.subTitle}
                      dir="rtl"
                    >
                      Biomab: {counts.biomab_dropped}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={classes.whiteText + " " + classes.subTitle}
                      dir="rtl"
                    >
                      Krabeva: {counts.krabeva_dropped}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </div>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Card className={classes.card + " " + classes.cardYellow}>
            <div className={classes.details}>
              <CardContent className={classes.box}>
                <Typography
                  variant="subtitle1"
                  className={classes.whiteText + " " + classes.textTitle}
                >
                  Therapy Completed
                </Typography>
              </CardContent>
              <CardContent className={classes.box}>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <Typography
                      component="h5"
                      variant="h5"
                      className={classes.whiteText}
                    >
                      {counts.closed}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle1"
                      className={classes.whiteText + " " + classes.subTitle}
                      dir="rtl"
                    >
                      Canmab: {counts.canmab_closed}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={classes.whiteText + " " + classes.subTitle}
                      dir="rtl"
                    >
                      Biomab: {counts.biomab_closed}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={classes.whiteText + " " + classes.subTitle}
                      dir="rtl"
                    >
                      Krabeva: {counts.krabeva_closed}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </div>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
CountsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  counts: PropTypes.object.isRequired,
};
export default withStyles(styles, { withTheme: true })(CountsComponent);
