import PatientProgramsPage from "../containers/PatientProgramsPage";
import PatientProgramDocumentPage from "../containers/PatientProgramDocumentPage";
import RatingPage from "../containers/RatingPage";
import FileUploadPage from "../containers/FileUploadPage";


let indexRoutes = [{
  path: "/patients/patient_programs", component: PatientProgramsPage, exact: true
}, {
  path: "/patients/patient_programs/file_upload", component: FileUploadPage, exact: true
}, {
  path: "/patients/patient_programs/:patient_program_id/documents", component: PatientProgramDocumentPage, exact: true
}, {
  path: "/patients/rating/:patient_program_id", component: RatingPage, exact: true
}];

export default indexRoutes;

