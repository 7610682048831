import React from 'react'
import '../scss/patient_personal_detail.css';
import Table from "@material-ui/core/es/Table/Table";
import TableBody from "@material-ui/core/es/TableBody/TableBody";
import TableRow from "@material-ui/core/es/TableRow/TableRow";
import TableCell from "@material-ui/core/es/TableCell/TableCell";
import withStyles from "@material-ui/core/es/styles/withStyles";
import PropTypes from 'prop-types';
import Paper from "@material-ui/core/es/Paper/Paper";
import {PatientProgramConstant} from "../PatientProgramConstant";

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: 0,
    overflowX: 'auto',
  },
  table: {
    minWidth: 500,
  },
  tableCell: {
    color: "#a7a7 a7",
    fontWeight: 300,
  },
});


/**
 * Headquarter Table row
 *
 * @param patient
 * @param classes
 * @return {*}
 * @constructor
 */
function PatientPersonalDetailTableComponent({patient, classes}) {
  /**
   * Render html
   * @return {*}
   */
  return (
    <Paper className={classes.root} elevation={0}>
      <Table className={classes.table}>
        <TableBody>
          <TableRow>
            <TableCell className={classes.tableCell}>Full Name : </TableCell>
            <TableCell>{patient.fullName}</TableCell>
            <TableCell className={classes.tableCell}>Age :</TableCell>
            <TableCell>{patient.age}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>Email : </TableCell>
            <TableCell>{patient.email}</TableCell>
            <TableCell className={classes.tableCell}>Mobile :</TableCell>
            <TableCell>{patient.mobile}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>Alt. Mobile : </TableCell>
            <TableCell>{patient.altMobile}</TableCell>
            <TableCell className={classes.tableCell}>Total Number of People Within Household
              :</TableCell>
            <TableCell>{patient.noOfFamilyMembers}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>Total Number of Earning People Within Household
              : </TableCell>
            <TableCell>{patient.noOfEarningFamilyMembers}</TableCell>
            <TableCell className={classes.tableCell}>
              Total Annual Income for Entire Household :
            </TableCell>
            <TableCell>{patient.annualIncomeOfFamily}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>Primary Address :</TableCell>
            <TableCell>{patient.primaryAddress && (PatientProgramConstant.addressToString(patient.primaryAddress))}</TableCell>
            <TableCell className={classes.tableCell}>Secondary Address : </TableCell>
            <TableCell>{patient.secondaryAddress && (PatientProgramConstant.addressToString(patient.secondaryAddress))}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>Comments</TableCell>
            <TableCell>{patient.comments}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>);
}


/**
 * Properties
 *
 * @type {{fetch: *}}
 */
PatientPersonalDetailTableComponent.propTypes = {
  patient: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PatientPersonalDetailTableComponent);