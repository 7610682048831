import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#19BE9E",
    },
  },
});
export default theme;
