import React from 'react'
import PropTypes from 'prop-types'
import {requestPasswordReset} from "../../reducers/actions/login";
import {ErrorMessage, Field, Form, Formik} from 'formik';

/**
 * Send verification code form
 *
 * @param isFetching
 * @param dispatch
 * @return {*}
 * @constructor
 */
const PasswordRestForm = ({isFetching, dispatch}) => (
  <Formik initialValues={{verificationCode: '', newPassword: '', confirmNewPassword: ''}}
          onSubmit={(values) => {
            dispatch(requestPasswordReset(values.verificationCode, values.newPassword, values.confirmNewPassword));
          }}>
    <Form>
      <div className="form-group">
        <Field className="form-control" placeholder="Verification Code" type="text"
               name="verificationCode"/>
        <ErrorMessage name="verificationCode" component="div"/>
      </div>
      <div className="form-group">
        <Field className="form-control" placeholder="New Password" type="password"
               name="newPassword"/>
        <ErrorMessage name="newPassword" component="div"/>
      </div>
      <div className="form-group">
        <Field className="form-control" placeholder="Confirm New Password" type="password"
               name="confirmNewPassword"/>
        <ErrorMessage name="confirmNewPassword" component="div"/>
      </div>
      <div className="form-group text-center">
        <button className="btnSubmit" type="submit" disabled={isFetching}>
          {isFetching && "Submitting"} Submit
        </button>
      </div>
    </Form>
  </Formik>
);

PasswordRestForm.propTypes = {
  isFetching: PropTypes.bool.isRequired,
}

export default PasswordRestForm
