import React, {Component} from 'react';
import {connect} from 'react-redux';
import logo from '../../logo.svg';
import Grid from "@material-ui/core/es/Grid/Grid";
import withStyles from "@material-ui/core/es/styles/withStyles";
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import Typography from "@material-ui/core/es/Typography/Typography";
import {getRating} from "../services/PatientProgramService";
import RatingFormComponent from "../components/RatingFormComponent";

const styles = theme => ({});


class RatingPage extends Component {

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      rating: {}
    }
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    this.refreshComponent();
  };

  /**
   * Refresh component
   */
  refreshComponent() {
    const {dispatch, match} = this.props;
    dispatch(getRating(match.params.patient_program_id)).then(json => {
      this.setState({
        ...this.state,
        rating: json.rating
      })
    });
  }

  /**
   * Prepare view
   *
   * @return {*}
   */
  render() {
    const {rating} = this.state;
    return (
      <div>
        <AppBar position="fixed" color="primary">
          <Toolbar>
            <IconButton color="inherit" aria-label="Menu">
              <img src={logo} className="App-logo" alt="logo"/>
            </IconButton>
            <Typography variant="h6" color="inherit">
              Oncology App
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <RatingFormComponent patient_program={rating}/>
          </Grid>
        </Grid>
      </div>
    )
  };
}

/**
 * Map state to properties
 *
 * @param state
 * @returns {{auth: *}}
 */
const mapStateToProps = state => {
  const {patientPrograms} = state;

  return {patient_programs: patientPrograms};
};
export default connect(mapStateToProps)(withStyles(styles)(RatingPage));
