
import LoginPage from "../containers/login/LoginPage";
import ChangePasswordPage from "../containers/login/ChangePasswordPage";
import ForgotPasswordPage from "../containers/login/ForgotPasswordPage";
import LoginSuccessPage from "../containers/login/LoginSuccessPage";
import TokenVerificationPage from "../containers/login/TokenVerificationPage";


let indexRoutes = [{
    path: "/auth", name: "Auth app", component: LoginPage, exact: false
},{
    path: "/auth/success", component: LoginSuccessPage, exact: false
}, {
    path: "/auth/change_password", component: ChangePasswordPage, exact: false
},{
    path: "/auth/forgot_password", component:ForgotPasswordPage, exact: false
},{
    path: "/auth/:token", component:TokenVerificationPage, exact: false
}];

export default indexRoutes;