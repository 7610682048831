import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/es/Grid/Grid";
import withStyles from "@material-ui/core/es/styles/withStyles";
import formController from "../../../../common/hoc/forms/formController";
import OutlinedInput from "@material-ui/core/es/OutlinedInput/OutlinedInput";
import Select from "@material-ui/core/es/Select/Select";
import InputLabel from "@material-ui/core/es/InputLabel/InputLabel";
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";
import DateFnsUtils from "@date-io/date-fns/build/index";
import TextField from "@material-ui/core/es/TextField/TextField";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import Checkbox from "@material-ui/core/es/Checkbox/Checkbox";
import FormControlLabel from "@material-ui/core/es/FormControlLabel/FormControlLabel";
import FormHelperText from "@material-ui/core/es/FormHelperText/FormHelperText";

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  picker: {
    marginLeft: 8,
    marginRight: 8,
  },
});

class PatientProgramForm extends React.Component {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.onPrescriptionDateChange = this.onPrescriptionDateChange.bind(this);
    this.onTreatmentStartDateChange =
      this.onTreatmentStartDateChange.bind(this);
  }

  /**
   * On prescription date change
   *
   * @param date
   */
  onPrescriptionDateChange = (date) => {
    let { onFieldChange } = this.props;
    onFieldChange({
      target: { name: "prescription_date_picker", value: date },
    });
  };

  /**
   * On treatment start date change
   *
   * @param date
   */
  onTreatmentStartDateChange = (date) => {
    let { onFieldChange } = this.props;
    onFieldChange({
      target: { name: "treatment_start_date_picker", value: date },
    });
  };

  /**
   * On treatment start date change
   *
   * @param date
   */
  onNextFreeCycleDateChange = (date) => {
    let { onFieldChange } = this.props;
    onFieldChange({
      target: { name: "next_free_cycle_date_picker", value: date },
    });
  };

  /**
   * On last infusion date change
   *
   * @param date
   */
  onLastInfusionDateChange = (date) => {
    let { onFieldChange } = this.props;
    onFieldChange({ target: { name: "last_infusion_date", value: date } });
  };

  /**
   * On next infusion date change
   *
   * @param date
   */
  onNextInfusionDateChange = (date) => {
    let { onFieldChange } = this.props;
    onFieldChange({ target: { name: "next_infusion_date", value: date } });
  };

  /**
   * Render view
   */
  render() {
    const {
      classes,
      onFieldChange,
      model,
      data: { treatment_cycles, products },
      errors,
    } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl
            variant="outlined"
            fullWidth={true}
            error={!!errors.program_id}
            margin="dense"
          >
            <InputLabel
              ref={(ref) => {
                this.InputLabelRef = ref;
              }}
              htmlFor="product"
            >
              Product
            </InputLabel>
            <Select
              value={model.product_id}
              onChange={onFieldChange}
              input={
                <OutlinedInput
                  labelWidth={100}
                  name="product_id"
                  id="product"
                />
              }
            >
              {products.map((d, i) => {
                return (
                  <MenuItem value={d.id} key={i}>
                    {d.name}
                  </MenuItem>
                );
              })}
            </Select>
            {errors.product_id && (
              <FormHelperText>{errors.product_id}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="product-dosage"
            label="Dosage"
            name="dosage"
            className={classes.textField}
            defaultValue={model.dosage}
            onChange={onFieldChange}
            type="number"
            margin="dense"
            fullWidth={true}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="product-uom"
            label="Unit"
            name="uom"
            className={classes.textField}
            defaultValue={model.uom}
            onChange={onFieldChange}
            margin="dense"
            fullWidth={true}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="primary-mobile"
            label="Primary contact"
            name="mobile"
            className={classes.textField}
            defaultValue={model.mobile}
            onChange={onFieldChange}
            margin="dense"
            fullWidth={true}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="secondary-mobile"
            label="Secondary contact"
            name="alt_mobile"
            className={classes.textField}
            defaultValue={model.alt_mobile}
            onChange={onFieldChange}
            margin="dense"
            fullWidth={true}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="doctor-full-name"
            label="Doctor full name"
            name="doctor_full_name"
            className={classes.textField}
            defaultValue={model.doctor_full_name}
            onChange={onFieldChange}
            margin="dense"
            fullWidth={true}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="doctor-hospital"
            label="Hospital/Clinic"
            name="doctor_hospital"
            className={classes.textField}
            defaultValue={model.doctor_hospital}
            onChange={onFieldChange}
            margin="dense"
            fullWidth={true}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="doctor-city"
            label="City"
            name="doctor_city"
            className={classes.textField}
            defaultValue={model.doctor_city}
            onChange={onFieldChange}
            margin="dense"
            fullWidth={true}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="full-name"
            label="Diagnosis"
            name="treatment"
            className={classes.textField}
            defaultValue={model.treatment}
            onChange={onFieldChange}
            margin="dense"
            fullWidth={true}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl
            variant="outlined"
            className={classes.picker}
            fullWidth={true}
            margin="dense"
          >
            <InputLabel
              ref={(ref) => {
                this.InputLabelRef = ref;
              }}
              htmlFor="document-type"
            >
              Treatment cycle
            </InputLabel>
            <Select
              value={model.treatment_cycle}
              onChange={onFieldChange}
              input={
                <OutlinedInput
                  labelWidth={100}
                  name="treatment_cycle"
                  id="treatment-cycle"
                />
              }
            >
              {treatment_cycles.map((d, i) => {
                return (
                  <MenuItem value={d} key={i}>
                    {d}
                  </MenuItem>
                );
              })}
            </Select>
            {errors.treatment_cycle && (
              <FormHelperText>{errors.treatment_cycle}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="full-name"
            label="No. of therapy cycles"
            name="no_of_therapy_months"
            className={classes.textField}
            type="number"
            defaultValue={model.no_of_therapy_months}
            onChange={onFieldChange}
            margin="dense"
            fullWidth={true}
            variant="outlined"
            error={!!errors.no_of_therapy_months}
            helperText={errors.no_of_therapy_months}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="full-name"
            label="No. of free cycles"
            name="no_of_free_months"
            className={classes.textField}
            type="number"
            defaultValue={model.no_of_free_months}
            onChange={onFieldChange}
            margin="dense"
            fullWidth={true}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              margin="dense"
              variant="outlined"
              fullWidth={true}
              label="Prescription date"
              value={model.prescription_date_picker}
              format={"dd MMM` yyyy"}
              onChange={this.onPrescriptionDateChange}
              className={classes.picker}
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              margin="dense"
              variant="outlined"
              fullWidth={true}
              label="Treatment start date"
              value={model.treatment_start_date_picker}
              format={"dd MMM` yyyy"}
              onChange={this.onTreatmentStartDateChange}
              className={classes.picker}
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            />
          </MuiPickersUtilsProvider>
          {errors.treatment_start_date_picker && (
            <FormHelperText>
              {errors.treatment_start_date_picker}
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              margin="dense"
              variant="outlined"
              fullWidth={true}
              label="Next Free Cycle date"
              value={model.next_free_cycle_date_picker}
              format={"dd MMM` yyyy"}
              onChange={this.onNextFreeCycleDateChange}
              className={classes.picker}
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              margin="dense"
              variant="outlined"
              fullWidth={true}
              label="Last Infusion date"
              value={model.last_infusion_date}
              format={"dd MMM` yyyy"}
              onChange={this.onLastInfusionDateChange}
              className={classes.picker}
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              margin="dense"
              variant="outlined"
              fullWidth={true}
              label="Next Infusion date"
              value={model.next_infusion_date}
              format={"dd MMM` yyyy"}
              onChange={this.onNextInfusionDateChange}
              className={classes.picker}
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="full-name"
            label="No. of cycles Completed"
            name="total_cycles_completed"
            className={classes.textField}
            type="number"
            defaultValue={model.total_cycles_completed}
            onChange={onFieldChange}
            margin="dense"
            fullWidth={true}
            variant="outlined"
            error={!!errors.total_cycles_completed}
            helperText={errors.total_cycles_completed}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="full-name"
            label="No. of cycles Remaining"
            name="total_cycles_remaining"
            className={classes.textField}
            type="number"
            defaultValue={model.total_cycles_remaining}
            onChange={onFieldChange}
            margin="dense"
            fullWidth={true}
            variant="outlined"
            error={!!errors.total_cycles_remaining}
            helperText={errors.total_cycles_remaining}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="comments"
            label="Comments"
            name="comments"
            className={classes.textField}
            multiline={true}
            defaultValue={model.comments}
            rows={2}
            onChange={onFieldChange}
            margin="dense"
            fullWidth={true}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            className={classes.textField}
            name="create_follow_ups"
            control={
              <Checkbox
                name="create_follow_ups"
                checked={model.create_follow_ups}
                onChange={onFieldChange}
                value="1"
              />
            }
            label="Create follow ups"
          />
        </Grid>
      </Grid>
    );
  }
}

PatientProgramForm.propTypes = {
  data: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
};

export default formController(withStyles(styles)(PatientProgramForm));
