import {get, post, postForm} from "../../common/services/BaseService";
import {PatientConstants} from "../PatientConstants";
import {
  failedPatientProgram,
  requestPatientProgram,
  successPatientProgram
} from "../reducers/actions/patient-program-actions";


/**
 * Fetch patient
 *
 * @returns {function(*): Promise<any>}
 * @param id
 */
export const getPatientProgram = (id) => dispatch => {
  dispatch(requestPatientProgram());
  return get(PatientConstants.PATIENT_PROGRAMS_URL + '/' + id).then(json => {
    dispatch(successPatientProgram(json));
    return json;
  }).catch(error => {
    dispatch(failedPatientProgram(error));
    throw(error);
  });
};


/**
 * Fetch patient
 *
 * @returns {function(*): Promise<any>}
 * @param id
 */
export const getRating = (id) => {
  return get(PatientConstants.PATIENT_PROGRAMS_URL + '/' + id + '/rating').then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};


/**
 * Save rating
 *
 * @returns {Promise<Response>}
 * @param id
 * @param rating
 */
export const saveRating = (id, rating) => {
  return post(PatientConstants.PATIENT_PROGRAMS_URL + '/' + id + '/rating', rating).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};


/**
 * Upload patient document
 *
 * @param patient_id
 * @param file
 * @return {Promise<Response>}
 */
export const uploadFile = (file) => {
  return postForm(`${PatientConstants.PATIENT_PROGRAMS_URL}/imageFileUpload`, file).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};
