import {FAILED_REST_REQUEST, NEW_REST_REQUEST, SUCCESS_REST_REQUEST} from "../../../common/actions/actions";

export const REPORT_COUNTS = 'REPORT_COUNTS';
export const REPORT_MONTHLY_COUNTS = 'REPORT_MONTHLY_COUNTS';
export const REPORT_DEFERRED_PATIENT_PROGRAMS = 'REPORT_DEFERRED_PATIENT_PROGRAMS';
export const REPORT_DROPPED_PATIENT_PROGRAMS = 'REPORT_DROPPED_PATIENT_PROGRAMS';
export const REPORT_ORDERS = 'REPORT_ORDERS';
export const REPORT_PATIENT_PROGRAMS = 'REPORT_PATIENT_PROGRAMS';
export const REPORT_CALLS = 'REPORT_CALLS';


export const requestFetchReportCounts = () => ({
  type: REPORT_COUNTS,
  status: NEW_REST_REQUEST,
});

export const successFetchReportCounts = (json) => ({
  type: REPORT_COUNTS,
  status: SUCCESS_REST_REQUEST,
  data: json
});

export const failedFetchReportCounts = (errors) => ({
  type: REPORT_COUNTS,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});

export const requestFetchReportMonthlyCounts = () => ({
  type: REPORT_MONTHLY_COUNTS,
  status: NEW_REST_REQUEST,
});

export const successFetchReportMonthlyCounts = (json) => ({
  type: REPORT_MONTHLY_COUNTS,
  status: SUCCESS_REST_REQUEST,
  data: json
});

export const failedFetchReportMonthlyCounts = (errors) => ({
  type: REPORT_MONTHLY_COUNTS,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});

export const requestFetchReportDeferredPatientPrograms = () => ({
  type: REPORT_DEFERRED_PATIENT_PROGRAMS,
  status: NEW_REST_REQUEST,
});

export const successFetchReportDeferredPatientPrograms = (json) => ({
  type: REPORT_DEFERRED_PATIENT_PROGRAMS,
  status: SUCCESS_REST_REQUEST,
  data: json
});

export const failedFetchReportDeferredPatientPrograms = (errors) => ({
  type: REPORT_DEFERRED_PATIENT_PROGRAMS,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});

export const requestFetchReportDroppedPatientPrograms = () => ({
  type: REPORT_DROPPED_PATIENT_PROGRAMS,
  status: NEW_REST_REQUEST,
});

export const successFetchReportDroppedPatientPrograms = (json) => ({
  type: REPORT_DROPPED_PATIENT_PROGRAMS,
  status: SUCCESS_REST_REQUEST,
  data: json
});

export const failedFetchReportDroppedPatientPrograms = (errors) => ({
  type: REPORT_DROPPED_PATIENT_PROGRAMS,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});

export const requestFetchReportOrders = () => ({
  type: REPORT_ORDERS,
  status: NEW_REST_REQUEST,
});

export const successFetchReportOrders = (json) => ({
  type: REPORT_ORDERS,
  status: SUCCESS_REST_REQUEST,
  data: json
});

export const failedFetchReportOrders = (errors) => ({
  type: REPORT_ORDERS,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});


export const requestFetchReportPatientPrograms = () => ({
  type: REPORT_PATIENT_PROGRAMS,
  status: NEW_REST_REQUEST,
});

export const successFetchReportPatientPrograms = (json) => ({
  type: REPORT_PATIENT_PROGRAMS,
  status: SUCCESS_REST_REQUEST,
  data: json
});

export const failedFetchReportPatientPrograms = (errors) => ({
  type: REPORT_PATIENT_PROGRAMS,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});

export const requestFetchReportCalls = () => ({
  type: REPORT_CALLS,
  status: NEW_REST_REQUEST,
});

export const successFetchReportCalls = (json) => ({
  type: REPORT_CALLS,
  status: SUCCESS_REST_REQUEST,
  data: {calls: json.calls, totalRecords: json.total}
});

export const failedFetchReportCalls = (errors) => ({
  type: REPORT_CALLS,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});
