import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Card from "@material-ui/core/es/Card/Card";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import CardActions from "@material-ui/core/es/CardActions/CardActions";
import Button from "@material-ui/core/es/Button/Button";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import green from "@material-ui/core/es/colors/green";
import FulfillmentForm from "./forms/FulfillmentForm";
import restCallController from "../../../common/hoc/forms/restCallController";
import Order from "../../models/Order";
import FullfillmentItemForm from "./forms/FullfillmentItemForm";
import Typography from "@material-ui/core/es/Typography/Typography";
import Icon from "@material-ui/core/es/Icon/Icon";
import { createOrder } from "../../service/OrderService";
import Grid from "@material-ui/core/es/Grid/Grid";
import Constants from "../../../Constants";

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 19,
  },
  card: {
    borderRadius: 0,
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

/**
 * Update patient program form
 *
 * @param patient
 * @return {*}
 * @constructor
 */
class FulfillmentFormComponent extends Component {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);

    let caretaker = "",
      caretaker_name = "",
      caretaker_alt = "";
    let { patient, patient_program } = props.data;
    if (patient.caretakers && patient.caretakers.length > 0) {
      caretaker = patient.caretakers[0].mobile;
      caretaker_name = patient.caretakers[0].fullName;
      caretaker_alt = patient.caretakers[0].altMobile;
    }
    this.state = {
      order: new Order({
        patient_id: patient.id,
        contact_no: caretaker && caretaker != "" ? caretaker : patient.mobile,
        order_no: patient_program.fgo_code,
        order_for: patient.fullName,
        order_for_id: patient.id,
        order_from: "Biocon",
        caretaker: caretaker,
        caretaker_alt: caretaker_alt,
        patient_cycle_date: patient_program.next_free_cycle_date,
        user_id: "",
        items: [
          {
            item: this.getProductFromId(patient_program.product_id).name,
            product_id: patient_program.product_id,
            quantity: 1,
          },
        ],
        address_str: "primary",
      }),
    };
    this.addMoreOrderItem = this.addMoreOrderItem.bind(this);
    this.onModelChange = this.onModelChange.bind(this);
    this.create = this.create.bind(this);
    this.onItemFieldChange = this.onItemFieldChange.bind(this);
  }

  /**
   * On model field change
   *
   * @param model
   * @return {Function}
   */
  onModelChange = (model) => (field, value) => {
    this.setState({
      ...this.state,
      [model]: {
        ...this.state[model],
        [field]: value,
      },
    });
  };

  /**
   * Update patient
   */
  create = () => {
    let {
      handlePromise,
      data: { patient },
    } = this.props;
    let order = this.state.order;
    order.address =
      order.address_str === "primary"
        ? patient.primaryAddress
        : patient.secondaryAddress;
    if (order.expected_delivery_date_picker)
      order["expected_delivery_date"] = Constants.formatToServerDate(
        order.expected_delivery_date_picker
      );
    if (order.patient_cycle_date_picker)
      order["patient_cycle_date"] = Constants.formatToServerDate(
        order.patient_cycle_date_picker
      );
    handlePromise(createOrder(order));
  };

  /**
   * Add more order items
   */
  addMoreOrderItem() {
    const { order } = this.state;
    this.setState({
      ...this.state,
      order: {
        ...order,
        items: [...order.items, { product_id: 0, quantity: 0 }],
      },
    });
  }

  /**
   * On item field change
   *
   * @param index
   * @return {Function}
   */
  onItemFieldChange = (index) => (event) => {
    let { items, ...order } = this.state.order;
    items[index] = { ...items[index], [event.target.name]: event.target.value };
    if (event.target.name === "product_id")
      items[index] = {
        ...items[index],
        item: this.getProductFromId(event.target.value).name,
      };
    this.setState({
      ...this.state,
      order: {
        ...order,
        items: items,
      },
    });
  };

  /**
   * Get product from id
   */
  getProductFromId(product_id) {
    return this.props.data.patient_program.program.products.find((p) => {
      return p.id === product_id;
    });
  }

  /**
   * Render view
   *
   * @return {*}
   */
  render() {
    const {
      classes,
      data: { cfa_list, patient, patient_program },
      isFetching,
      close,
      errors,
    } = this.props;
    const { saving, order } = this.state;
    return (
      <Card className={classes.card} elevation={0}>
        <CardContent>
          <form className={classes.container} noValidate autoComplete="off">
            <FulfillmentForm
              model={order}
              onModelChange={this.onModelChange("order")}
              data={{ users: cfa_list }}
              patient={patient}
              errors={errors}
            />
          </form>
        </CardContent>
        <CardContent>
          <Typography
            component="h6"
            variant="subtitle1"
            color="textSecondary"
            className={classes.subHeading}
          >
            ORDER ITEMS
            <Button
              variant="text"
              onClick={this.addMoreOrderItem}
              size="small"
              className={classes.addMore}
            >
              <Icon color="primary" fontSize="small">
                add
              </Icon>{" "}
              ADD MORE
            </Button>
          </Typography>
          <Grid container spacing={8}>
            <Grid item xs={6} color="textSecondary">
              <Typography color="textSecondary" align="center">
                PRODUCT
              </Typography>
            </Grid>
            <Grid item xs={6} color="textSecondary">
              <Typography color="textSecondary" align="center">
                QTY
              </Typography>
            </Grid>
          </Grid>
          {order.items.map((item, key) => {
            return (
              <FullfillmentItemForm
                model={item}
                key={key}
                products={patient_program.program.products}
                onItemFieldChange={this.onItemFieldChange(key)}
              />
            );
          })}
        </CardContent>
        <CardActions dir="rtl" className={classes.wrapper}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={close}
            disabled={isFetching}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.create}
            disabled={isFetching}
          >
            {saving ? "Sending to CFA" : "Send to CFA"}
          </Button>
          {isFetching && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </CardActions>
      </Card>
    );
  }
}

FulfillmentFormComponent.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  successCallback: PropTypes.func.isRequired,
  failureCallback: PropTypes.func.isRequired,
};

export default restCallController(withStyles(styles)(FulfillmentFormComponent));
