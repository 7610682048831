import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import '../../scss/login.css';
import {requestVerificationCode} from "../../reducers/actions/login";

class ChangePasswordPage extends Component {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    failed: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
  };

  render() {
    const {isFetching, dispatch} = this.props;
    return (
      <div className="row">
        <div className="col-md-4 offset-md-4 login-form">
          <h3>CHANGE PASSWORD FORM</h3>
          <Formik
            initialValues={{new_password: '', confirm_new_password: ''}}
            onSubmit={(values) => {
              dispatch(requestVerificationCode(values.new_password, values.confirm_new_password));
            }}>
            {({isSubmitting}) => (
              <Form>
                <div className="form-group">
                  <Field className="form-control" placeholder="New Password" type="password" name="new_password"/>
                  <ErrorMessage name="new_password" component="div"/>
                </div>

                <div className="form-group">
                  <Field className="form-control" placeholder="Confirm New Password" type="password"
                         name="confirm_new_password"/>
                  <ErrorMessage name="confirm_new_password" component="div"/>
                </div>
                <div className="form-group text-center">
                  <button className="btnSubmit" type="login" disabled={isFetching}>
                    {isFetching && "Submitting..."} Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    )
  };
}

/**
 * Map state to properties
 *
 * @param state
 * @returns {{auth: *}}
 */
const mapStateToProps = state => {
  const {authData} = state;
  return authData;
};

export default connect(mapStateToProps)(ChangePasswordPage)
