import React from 'react'
import '../../scss/patient_personal_detail.css';
import Table from "@material-ui/core/es/Table/Table";
import TableBody from "@material-ui/core/es/TableBody/TableBody";
import TableRow from "@material-ui/core/es/TableRow/TableRow";
import TableCell from "@material-ui/core/es/TableCell/TableCell";
import withStyles from "@material-ui/core/es/styles/withStyles";
import PropTypes from 'prop-types';
import Paper from "@material-ui/core/es/Paper/Paper";
import Constants from "../../../Constants";

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: 0,
    overflowX: 'auto',
  },
  table: {
    minWidth: 500,
  },
  tableCell: {
    color: "#a7a7a7",
    fontWeight: 300,
  },
});

function PatientProgramTableComponent({patient_program, classes}) {
  return (
    <Paper className={classes.root} elevation={0}>
      <Table className={classes.table}>
        <TableBody>
          <TableRow>
            <TableCell className={classes.tableCell}>Program & Product : </TableCell>
            <TableCell>
              {patient_program.program.name} &
              {patient_program.product != null && patient_program.product.name}
              {patient_program.dosage + patient_program.uom}
            </TableCell>
            <TableCell className={classes.tableCell}>Coupon code :</TableCell>
            <TableCell>{patient_program.pen_code}/{patient_program.fgo_code}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>Primary contact : </TableCell>
            <TableCell>{patient_program.mobile}</TableCell>
            <TableCell className={classes.tableCell}>Secondary contact :</TableCell>
            <TableCell>{patient_program.alt_mobile}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>Doctor (City): </TableCell>
            <TableCell>{patient_program.doctor_full_name} {!!patient_program.city && `(${patient_program.city})`}</TableCell>
            <TableCell className={classes.tableCell}>Hospital/Clinic :</TableCell>
            <TableCell>{patient_program.doctor_hospital}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>Diagnosis : </TableCell>
            <TableCell>{patient_program.treatment}</TableCell>
            <TableCell className={classes.tableCell}>Treatment Cycle</TableCell>
            <TableCell>{patient_program.treatment_cycle}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>No. of therapy cycles : </TableCell>
            <TableCell align="right">{patient_program.no_of_therapy_months}</TableCell>
            <TableCell className={classes.tableCell}>No. of free cycles :</TableCell>
            <TableCell>{patient_program.no_of_free_months}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>Prescription date</TableCell>
            <TableCell>
              {
                  Constants.formatToDisplayDate(patient_program.prescription_date)}</TableCell>
            <TableCell className={classes.tableCell}>Treatment date</TableCell>
            <TableCell>
              {
                patient_program.treatment_start_date &&
                Constants.formatToDisplayDate(patient_program.treatment_start_date)
              }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>Next free cycle date</TableCell>
            <TableCell>
              {
                patient_program.next_free_cycle_date &&
                Constants.formatToDisplayDate(patient_program.next_free_cycle_date)
              }
            </TableCell>
            <TableCell className={classes.tableCell}>Comments</TableCell>
            <TableCell>{patient_program.comments}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>Last infusion date</TableCell>
            <TableCell>
              {
                patient_program.last_infusion_date &&
                Constants.formatToDisplayDate(patient_program.last_infusion_date)
              }
            </TableCell>
            <TableCell className={classes.tableCell}>Next infusion date</TableCell>
            <TableCell>
              {
                patient_program.next_infusion_date &&
                Constants.formatToDisplayDate(patient_program.next_infusion_date)
              }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>Total completed cycles : </TableCell>
            <TableCell align="right">{patient_program.total_cycles_completed}</TableCell>
            <TableCell className={classes.tableCell}>Total remaining cycles :</TableCell>
            <TableCell>{patient_program.total_cycles_remaining}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>);
}


/**
 * Properties
 *
 * @type {{fetch: *}}
 */
PatientProgramTableComponent.propTypes = {
  patient_program: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PatientProgramTableComponent);
