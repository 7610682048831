import {get} from "../../common/services/BaseService";
import {PatientProgramConstant} from "../PatientProgramConstant";
import {failedProducts, requestProducts, successProducts} from "../reducers/actions/product-actions";

/**
 * Fetch products
 *
 * @returns {function(*): Promise<any>}
 * @param q
 * @param page
 * @param length
 */
export const fetchProducts = (q = "", page = 0, length = 50) => dispatch => {
  dispatch(requestProducts());
  return get(PatientProgramConstant.PRODUCTS_URL, {
    q: q,
    page: page,
    length: length
  }).then(json => {
    dispatch(successProducts(json));
    return json;
  }).catch(error => {
    dispatch(failedProducts(error));
    throw(error);
  });
};