import {FAILED_REST_REQUEST, NEW_REST_REQUEST, SUCCESS_REST_REQUEST} from "../../../common/actions/actions";

export const FIND_All_PATIENT_PROGRAM = 'FIND_All_PATIENT_PROGRAM';
export const GET_PATIENT_PROGRAM = 'GET_PATIENT_PROGRAM';
export const UPDATE_PATIENT_PROGRAM = 'UPDATE_PATIENT_PROGRAM';
export const CREATE_PATIENT_PROGRAM = 'CREATE_PATIENT_PROGRAM';
export const UPDATE_PATIENT_PROGRAM_DOCUMENT = 'UPDATE_PATIENT_PROGRAM_DOCUMENT';
export const FETCH_PATIENT_PROGRAM_DUE_MONTHLY_COUNT = 'FETCH_PATIENT_PROGRAM_DUE_MONTHLY_COUNT';
export const FETCH_PATIENT_PROGRAM_FOR_DUE_DATE = 'FETCH_PATIENT_PROGRAM_FOR_DUE_DATE';


// request all patient programs
export const requestAllPatientPrograms = () => ({
  type: FIND_All_PATIENT_PROGRAM,
  status: NEW_REST_REQUEST,
});

// success patient programs request
export const successAllPatientPrograms = (json) => ({
  type: FIND_All_PATIENT_PROGRAM,
  status: SUCCESS_REST_REQUEST,
  data: json
});

// failed patient programs request
export const failedAllPatientPrograms = (errors) => ({
  type: FIND_All_PATIENT_PROGRAM,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});

// request patient program
export const requestPatientProgram = () => ({
  type: GET_PATIENT_PROGRAM,
  status: NEW_REST_REQUEST,
});

// success patient program request
export const successPatientProgram = (json) => ({
  type: GET_PATIENT_PROGRAM,
  status: SUCCESS_REST_REQUEST,
  data: json
});

// failed patient program request
export const failedPatientProgram = (errors) => ({
  type: GET_PATIENT_PROGRAM,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});

// request create patient program
export const requestCreatePatientProgram = () => ({
  type: CREATE_PATIENT_PROGRAM,
  status: NEW_REST_REQUEST,
});

// success create patient program request
export const successCreatePatientProgram = (json) => ({
  type: CREATE_PATIENT_PROGRAM,
  status: SUCCESS_REST_REQUEST,
  data: json
});

// failed create patient program request
export const failedCreatePatientProgram = (errors) => ({
  type: CREATE_PATIENT_PROGRAM,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});

// request update patient program
export const requestUpdatePatientProgram = () => ({
  type: UPDATE_PATIENT_PROGRAM,
  status: NEW_REST_REQUEST,
});

// success update patient program request
export const successUpdatePatientProgram = (json) => ({
  type: UPDATE_PATIENT_PROGRAM,
  status: SUCCESS_REST_REQUEST,
  data: json
});

// failed update patient program request
export const failedUpdatePatientProgram = (errors) => ({
  type: UPDATE_PATIENT_PROGRAM,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});

// request update patient program document
export const requestUpdatePatientProgramDocument = () => ({
  type: UPDATE_PATIENT_PROGRAM_DOCUMENT,
  status: NEW_REST_REQUEST,
});

// success update patient program document request
export const successUpdatePatientProgramDocument = (json) => ({
  type: UPDATE_PATIENT_PROGRAM_DOCUMENT,
  status: SUCCESS_REST_REQUEST,
  data: json
});

// failed update patient program document request
export const failedUpdatePatientProgramDocument = (errors) => ({
  type: UPDATE_PATIENT_PROGRAM_DOCUMENT,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});

export const requestFetchPatientProgramMonthlyCount = () => ({
  type: FETCH_PATIENT_PROGRAM_DUE_MONTHLY_COUNT,
  status: NEW_REST_REQUEST,
});

export const successFetchPatientProgramMonthlyCount = (json) => ({
  type: FETCH_PATIENT_PROGRAM_DUE_MONTHLY_COUNT,
  status: SUCCESS_REST_REQUEST,
  data: json
});

export const failedFetchPatientProgramMonthlyCount = (errors) => ({
  type: FETCH_PATIENT_PROGRAM_DUE_MONTHLY_COUNT,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});

export const requestPatientProgramForDueDate = () => ({
  type: FETCH_PATIENT_PROGRAM_FOR_DUE_DATE,
  status: NEW_REST_REQUEST,
});

export const successPatientProgramForDueDate = (json) => ({
  type: FETCH_PATIENT_PROGRAM_FOR_DUE_DATE,
  status: SUCCESS_REST_REQUEST,
  data: json
});

export const failedPatientProgramForDueDate = (errors) => ({
  type: FETCH_PATIENT_PROGRAM_FOR_DUE_DATE,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});