class PatientProgramAppConstants {

  // get modal style
  static getModalStyle() {
    const top = 10;
    const left = 20;

    return {
      top: `${top}%`,
      left: `${left}%`
    };
  }
}

export default PatientProgramAppConstants;