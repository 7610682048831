import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/es/styles/withStyles";
import { withSnackbar } from "notistack";
import CountsComponent from "../components/CountsComponent";
import {
  reportCalls,
  reportCounts,
  reportDeferredList,
  reportDroppedList,
  reportMonthlyCounts,
  reportOrders,
  reportPatientPrograms,
} from "../service/ReportService";
import MonthlyCountTableComponent from "../components/MonthlyCountTableComponent";
import DeferredPatientProgramTableComponent from "../components/DeferredPatientProgramTableComponent";
import Tabs from "@material-ui/core/es/Tabs/Tabs";
import Tab from "@material-ui/core/es/Tab/Tab";
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import DropedPatientProgramTableComponent from "../components/DropedPatientProgramTableComponent";
import OrdersTableComponent from "../components/OrdersTableComponent";
import Paper from "@material-ui/core/es/Paper/Paper";
import PatientProgramTableComponent from "../components/PatientProgramTableComponent";
import Button from "@material-ui/core/es/Button/Button";
import DocumentStatusReportTableComponent from "../components/DocumentStatusReportTableComponent";
import CallReportTableComponent from "../components/CallReportTableComponent";
import EmptyComponent from "../../common/components/common/EmptyComponent";

const styles = (theme) => ({
  paper: {
    margin: theme.spacing.unit * 1,
    flexGrow: 1,
  },
  dashboardButton: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
  },
  tabStyle: {
    fontSize: "12px",
    width: 80,
    minWidth: "140px",
  },
});

class DashboardPage extends Component {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      value: 5,
    };
    this.toggleValue = this.toggleValue.bind(this);
  }

  toggleValue = (event, value) => {
    this.setState({
      ...this.state,
      value: value,
    });
  };

  /**
   * Component did mount
   */
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(reportCounts());
    dispatch(reportMonthlyCounts());
    dispatch(reportDeferredList());
    dispatch(reportDroppedList());
    dispatch(reportOrders());
    dispatch(reportPatientPrograms());
    dispatch(reportCalls());
  }

  /**
   * Prepare view
   *
   * @return {*}
   */
  render() {
    let { value } = this.state;
    let {
      reportCounts: { data, success },
      classes,
    } = this.props;
    if (!success) return "";
    return (
      <div>
        <CountsComponent counts={data.counts} />
        <Paper className={classes.paper}>
          <AppBar position="static" color={"default"}>
            <Tabs value={value} onChange={this.toggleValue}>
              <Tab value={5} label="Dashboard" className={classes.tabStyle} />
              <Tab
                value={0}
                label="Monthly Report"
                className={classes.tabStyle}
              />
              {/* <Tab
                value={4}
                label="Retention Report"
                className={classes.tabStyle}
              /> */}
              <Tab value={3} label="TAT report" className={classes.tabStyle} />
              <Tab
                value={6}
                label="Documentation Status Report"
                className={classes.tabStyle}
              />
              <Tab
                value={1}
                label="Deferred Enrolment Report"
                className={classes.tabStyle}
              />
              <Tab
                value={2}
                label="Enrolment Drop out report"
                className={classes.tabStyle}
              />
              {/*<Tab value={7} label="Calls Report" className={classes.tabStyle}/>*/}
            </Tabs>
          </AppBar>
          {value === 0 && <div>{this.renderMonthlyTable()}</div>}
          {value === 1 && <div>{this.renderDeferredPatientProgramList()}</div>}
          {value === 2 && <div>{this.renderDroppedPatientProgramList()}</div>}
          {value === 3 && <div>{this.renderTatList()}</div>}
          {/* {value === 4 && <div>{this.renderPatientProgramList()}</div>} */}
          {value === 5 && (
            <iframe
              width="100%"
              src="https://lookerstudio.google.com/embed/reporting/1f706f80-6e03-4d7c-90c0-b850e4b20323/page/Ckzq"
              frameBorder="0"
              style={{ border: "none", height: "70vh" }}
              allowFullScreen
            ></iframe>
          )}
          {/* {value === 5 && (
            <Paper className={classes.dashboardButton}>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  window.open(
                    "https://datastudio.google.com/embed/reporting/1_2vlcwyr1jy50ALmyved4DkLX_wC6jpI/page/Ckzq",
                    "_blank"
                  )
                }
                className={classes.button}
              >
                Click to go to Dashboard
              </Button>
            </Paper>
          )} */}
          {value === 6 && <div>{this.renderDocumentStatusReportList()}</div>}
          {value === 7 && <div>{this.renderCallList()}</div>}
          <EmptyComponent />
        </Paper>
      </div>
    );
  }

  /**
   * Render monthly count table
   * @return {*}
   */
  renderMonthlyTable() {
    let { data, success } = this.props.reportMonthlyCounts;
    if (!success) return "";
    return <MonthlyCountTableComponent monthlyCounts={data.counts} />;
  }

  /**
   * Render deferred patient program list
   *
   * @return {*}
   */
  renderDeferredPatientProgramList() {
    let { data, success } = this.props.reportDeferredPatientPrograms;
    if (!success) return "";
    return (
      <DeferredPatientProgramTableComponent
        patientPrograms={data.patient_programs}
      />
    );
  }

  /**
   * Render deferred patient program list
   *
   * @return {*}
   */
  renderDroppedPatientProgramList() {
    let { data, success } = this.props.reportDroppedPatientPrograms;
    if (!success) return "";
    return (
      <DropedPatientProgramTableComponent
        patientPrograms={data.patient_programs}
      />
    );
  }

  /**
   * Render tat report
   *
   * @return {*}
   */
  renderTatList() {
    let { data, success } = this.props.reportReportOrders;
    if (!success) return "";
    return <OrdersTableComponent orders={data.orders} />;
  }

  /**
   * Render patient programs
   *
   * @return {*}
   */
  renderPatientProgramList() {
    let { data, success } = this.props.reportReportPatientPrograms;
    if (!success) return "";
    return (
      <PatientProgramTableComponent patientPrograms={data.patient_programs} />
    );
  }

  /**
   * Render patient programs
   *
   * @return {*}
   */
  renderDocumentStatusReportList() {
    let { data, success } = this.props.reportReportPatientPrograms;
    if (!success) return "";
    return (
      <DocumentStatusReportTableComponent
        patientPrograms={data.patient_programs}
      />
    );
  }

  /**
   * Render Calls
   *
   * @return {*}
   */
  renderCallList() {
    let { data, success } = this.props.reportCalls;
    if (!success) return "";
    return <CallReportTableComponent calls={data} />;
  }
}

/**
 * Map state to properties
 *
 * @param state
 * @returns {{auth: *}}
 */
const mapStateToProps = (state) => {
  const {
    reportCounts,
    reportMonthlyCounts,
    reportDeferredPatientPrograms,
    reportDroppedPatientPrograms,
    reportReportOrders,
    reportReportPatientPrograms,
    reportCalls,
  } = state;

  return {
    reportCounts: reportCounts,
    reportMonthlyCounts: reportMonthlyCounts,
    reportDeferredPatientPrograms: reportDeferredPatientPrograms,
    reportDroppedPatientPrograms: reportDroppedPatientPrograms,
    reportReportOrders: reportReportOrders,
    reportReportPatientPrograms: reportReportPatientPrograms,
    reportCalls: reportCalls,
  };
};
export default connect(mapStateToProps)(
  withStyles(styles)(withSnackbar(DashboardPage))
);
