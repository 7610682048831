import {FAILED_REST_REQUEST, NEW_REST_REQUEST, SUCCESS_REST_REQUEST} from "../../../common/actions/actions";

export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';


// request update document
export const requestUpdateDocument = () => ({
  type: UPDATE_DOCUMENT,
  status: NEW_REST_REQUEST
});

// receive update document
export const successUpdateDocument = (json) => ({
  type: UPDATE_DOCUMENT,
  status: SUCCESS_REST_REQUEST,
  data: json
});

// failed update document request
export const failedUpdateDocument = (errors) => ({
  type: UPDATE_DOCUMENT,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});
