import {AuthConstant} from "../AuthConstant";
import {
  failedLogin,
  failedPasswordReset,
  failedVerificationCode,
  requestLogin,
  requestPasswordReset,
  requestVerificationCode,
  successLogin,
  successPasswordReset,
  successVerificationCode
} from "../reducers/actions/login";
import {post} from "../../common/services/BaseService";


/**
 * Urls
 *
 * @type {string}
 */
const LOGIN_URL = AuthConstant.AUTH_URL;
const VERIFY_TEMP_TOKEN_URL = AuthConstant.AUTH_URL + 'loginForToken';
const SEND_VERIFICATION_CODE_URL = AuthConstant.AUTH_URL + 'send_verification_code';
const RESET_PASSWORD_URL = AuthConstant.AUTH_URL + 'reset_verification_code';
const CHANGE_PASSWORD_URL = AuthConstant.AUTH_URL + 'change_verification_code';

/**
 * Login request to server
 *
 * @param username
 * @param password
 * @returns {function(*): Promise<any>}
 */
export const fetchLogin = (username, password) => dispatch => {
  dispatch(requestLogin(username, password));
  return post(LOGIN_URL, {
    username: username,
    password: password
  }).then(json => {
    dispatch(successLogin(json));
    return json;
  }).catch(error => {
    dispatch(failedLogin(error));
    throw(error);
  });
};

/**
 * Verify temp token
 *
 * @param token
 * @return {Promise<Response>}
 */
export const verifyTempToken = (token) => {
  return post(`${VERIFY_TEMP_TOKEN_URL}/${token}`).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};

/**
 * Send verification code
 *
 * @param username
 * @return {function(*): Promise<Response>}
 */
export const sendVerificationCode = (username) => dispatch => {
  dispatch(requestVerificationCode(username));
  return post(SEND_VERIFICATION_CODE_URL, {
    method: 'POST',
    body: JSON.stringify({username: username})
  }).then(response => dispatch(successVerificationCode(response)))
    .catch(error => {
      dispatch(failedVerificationCode(error));
    });
};

/**
 * Reset password
 *
 * @param verificationCode
 * @param newPassword
 * @param confirmNewPassword
 * @return {function(*): Promise<Response>}
 */
export const resetPassword = (verificationCode, newPassword, confirmNewPassword) => dispatch => {
  dispatch(requestPasswordReset());
  return post(RESET_PASSWORD_URL, {
    verificationCode: verificationCode,
    newPassword: newPassword,
    confirmNewPassword: confirmNewPassword
  }).then(response => {
    dispatch(successPasswordReset(response))
  }).catch(error => {
    dispatch(failedPasswordReset(error));
  });
};

/**
 * Change password
 *
 * @param newPassword
 * @param confirmNewPassword
 * @return {function(*): Promise<Response>}
 */
export const changePassword = (newPassword, confirmNewPassword) => dispatch => {
  dispatch(requestPasswordReset());
  return post(CHANGE_PASSWORD_URL, {
    newPassword: newPassword,
    confirmNewPassword: confirmNewPassword
  }).then(response => {
    dispatch(successPasswordReset(response))
  }).catch(error => {
    dispatch(failedPasswordReset(error));
  });
};