import React, {Component} from 'react'
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/es/styles/withStyles";
import {withSnackbar} from 'notistack';
import PatientProgramDocumentListItem from "./PatientProgramDocumentListItem";
import PatientProgramDocumentUpload from "../PatientProgramDocumentUpload";
import Grid from "@material-ui/core/es/Grid/Grid";
import EmptySpace from "../../../common/components/common/EmptySpace";

const styles = theme => ({});

class PatientProgramDocumentList extends Component {

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.imageUploadSuccessCallback = this.imageUploadSuccessCallback.bind(this);
  }

  /**
   * Image upload success callback
   */
  imageUploadSuccessCallback = () => {
    let {enqueueSnackbar, refresh} = this.props;
    enqueueSnackbar('Image uploaded successfully!');
    refresh();
  };

  render() {
    const {patient_program} = this.props;

    // prepare documents
    let documents = patient_program.documents.map((p, key) => {
      if (p.document_id)
        return <Grid item md={3} sm={6} xs={12} key={key}>
          <PatientProgramDocumentListItem document={p}/>
        </Grid>;
      else
        return <Grid item md={3} sm={6} xs={12} key={key}>
          <PatientProgramDocumentUpload document={p}
                                        successCallback={this.imageUploadSuccessCallback}
                                        failureCallback={() => {
                                        }}
                                        patient_id={patient_program.patient_id}/>
        </Grid>;
    });

    return (
      <Grid container spacing={0}>
        {
          documents
        }
        <Grid item md={3} sm={6} xs={12}>
          <EmptySpace/>
        </Grid>
      </Grid>
    );
  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
PatientProgramDocumentList.propTypes = {
  patient_program: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  refresh: PropTypes.func.isRequired
};

export default withSnackbar(withStyles(styles, {withTheme: true})(PatientProgramDocumentList));
