import React from "react";


class EmptyComponent extends React.Component {
  render() {
    return (<div style={{"height": "200px"}} />);
  }
}

EmptyComponent.propTypes = {};

export default EmptyComponent;