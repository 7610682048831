import React, { Component } from "react";
import withStyles from "@material-ui/core/es/styles/withStyles";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/es/Typography/Typography";
import Button from "@material-ui/core/es/Button/Button";
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import CardActions from "@material-ui/core/es/CardActions/CardActions";
import Card from "@material-ui/core/es/Card/Card";
import moment from "moment/moment";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns/build/index";

const styles = (theme) => ({
  card: {
    borderRadius: 0,
  },
});

/**
 * Cancel Request
 *
 * @return {*}
 * @constructor
 */
class CancelRequest extends Component {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      reschedule_check: false,
      reschedule_date: null,
    };
  }

  /**
   * Handle date change
   * @param date
   */
  handleDateChange = (date) => {
    let m = moment(date);
    let only_date = m.format("YYYY-MM-DD");
    this.setState({
      ...this.state,
      reschedule_date: only_date,
    });
  };

  handleReschedule = () => {
    this.setState({
      ...this.state,
      reschedule_check: !this.state.reschedule_check,
    });
  };

  /**
   * Render html
   * @return {*}
   */
  render() {
    const { classes, close } = this.props;

    return (
      <Card className={classes.card}>
        <AppBar position="static">
          <Toolbar variant="dense">
            <Typography variant="h6" color="inherit">
              Cancel Demo Request
            </Typography>
          </Toolbar>
        </AppBar>
        <CardContent>
          <Grid item xs={12} md={6} sm={6}>
            <FormControl variant="outlined" fullWidth={true} margin="dense">
              <InputLabel
                ref={(ref) => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="vendor"
              >
                Select Reason
              </InputLabel>
              <Select
                value={""}
                onChange={() => {}}
                input={
                  <OutlinedInput
                    labelWidth={0}
                    name="Select Reason"
                    id="vendor"
                  />
                }
              ></Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.reschedule_check}
                  onChange={this.handleReschedule}
                  name="checkedA"
                />
              }
              label="Want to reschedule"
            />
          </Grid>

          {this.state.reschedule_check && (
            <Grid item xs={12} md={6} sm={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  margin="dense"
                  label="Reschedule date"
                  value={this.state.reschedule_date}
                  variant="outlined"
                  format={"dd/MM/yyyy"}
                  className={classes.picker}
                  onChange={this.handleDateChange}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          )}
        </CardContent>
        <CardActions dir="rtl">
          <Button variant="contained" color="primary" onClick={() => {}}>
            Submit
          </Button>
          <div style={{ marginRight: 5 }}>
            <Button variant="contained" color="primary" onClick={close}>
              Close
            </Button>
          </div>
        </CardActions>
      </Card>
    );
  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
// CancelRequest.propTypes = {
//   close: PropTypes.func.isRequired,
//   classes: PropTypes.object.isRequired,
// };

export default withStyles(styles)(CancelRequest);
