import {put, remove} from "../../common/services/BaseService";
import {PatientProgramConstant} from "../PatientProgramConstant";

/**
 * Remove patient program document
 *
 * @param id
 * @return {Promise<Response>}
 */
export const removePatientProgramDocument = (id) => {
  return remove(`${PatientProgramConstant.PATIENT_PROGRAM_DOCUMENTS_URL}/${id}/`).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};

/**
 * Reject patient program document
 *
 * @param id
 * @param data
 * @return {Promise<Response>}
 */
export const rejectPatientProgramDocument = (id, data) => {
  return put(`${PatientProgramConstant.PATIENT_PROGRAM_DOCUMENTS_URL}/${id}/reject`, data).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};

/**
 * Accept patient program document
 *
 * @param id
 * @param data
 * @return {Promise<Response>}
 */
export const acceptPatientProgramDocument = (id, data) => {
  return put(`${PatientProgramConstant.PATIENT_PROGRAM_DOCUMENTS_URL}/${id}/accept`, data).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};

/**
 * Update patient program document
 *
 * @param document_id
 * @param document
 * @return {Promise<Response>}
 */
export const updatePatientProgramDocument = (document_id, document) => {
  return put(`${PatientProgramConstant.PATIENT_PROGRAM_DOCUMENTS_URL}/${document_id}`, document).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};