import React from 'react';
import ReactDOM from 'react-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-notifications/lib/notifications.css';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";

import registerServiceWorker from './registerServiceWorker';

ReactDOM.render((
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  ), document.getElementById('root')
);
registerServiceWorker();
