import React, {Component} from 'react';
import {connect} from 'react-redux';
import logo from '../../logo.svg';
import Grid from "@material-ui/core/es/Grid/Grid";
import withStyles from "@material-ui/core/es/styles/withStyles";
import PatientProgramList from "../components/patient_program/list/PatientProgramList";
import {findPatientPrograms} from "../services/PatientService";
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import Typography from "@material-ui/core/es/Typography/Typography";
import NoContent from "../../common/components/common/NoContent";

const styles = theme => ({});


class PatientProgramsPage extends Component {

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(findPatientPrograms());
  };

  /**
   * Prepare view
   *
   * @return {*}
   */
  render() {
    const {patient_programs: {success, data}} = this.props;
    return (
      <div>
        <AppBar position="fixed" color="primary">
          <Toolbar>
            <IconButton color="inherit" aria-label="Menu">
              <img src={logo} className="App-logo" alt="logo"/>
            </IconButton>
            <Typography variant="h6" color="inherit">
              Oncology App
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {
              success &&
              <PatientProgramList patient_programs={data.patient_programs}/>
            }
            {
              !success &&
              <NoContent label="No programs found"/>
            }
          </Grid>
        </Grid>
      </div>
    )
  };
}

/**
 * Map state to properties
 *
 * @param state
 * @returns {{auth: *}}
 */
const mapStateToProps = state => {
  const {patientPrograms} = state;

  return {patient_programs: patientPrograms};
};
export default connect(mapStateToProps)(withStyles(styles)(PatientProgramsPage));
