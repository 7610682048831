import RestError from "../exceptions/RestError";

/**
 * Prepare request headers
 *
 * @return {Headers}
 */
const prepareHeaders = () => {
  const headers = new Headers();
  let auth = localStorage.getItem("auth");
  auth = JSON.parse(auth);

  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("GA_Authorization", auth ? auth.authToken : "");
  headers.append("GA-Authorization", auth ? auth.authToken : "");
  return headers;
};

/**
 * Response statuses
 */
export const RESPONSE_STATUS = {
  SUCCESS: 200,
  BAD_REQUEST: 400,
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
};

/**
 * Format success response
 *
 * @param response
 * @return {*}
 */
const formatResponse = (response) => {
  return response.json().then((data) => ({
    data: data,
    status: response.status,
  }));
};

/**
 * Handle Rest Response
 *
 * @param response
 * @return {*}
 */
const handleSuccess = (response) => {
  if (response.status === RESPONSE_STATUS.BAD_REQUEST) {
    throw new RestError(response.data);
  } else {
    return response.data;
  }
};

/**
 * Handle rest errors
 *
 * @param response
 */
const handleErrors = (response) => {
  if (
    response.status === RESPONSE_STATUS.SUCCESS ||
    response.status === RESPONSE_STATUS.BAD_REQUEST
  )
    return response;
  if (response.status === RESPONSE_STATUS.SERVER_ERROR) {
    throw new RestError({ message: "Server Not Responding." });
  } else {
    throw new RestError({ message: "Please check your network connection" });
  }
};

/**
 * Get call
 */
export const get = (url, params = {}) => {
  // prepare rest url
  const rest_url = new URL(url);

  // add query params
  if (params) rest_url.search = new URLSearchParams(params);

  // request
  return fetch(rest_url, {
    headers: prepareHeaders(),
  })
    .then(handleErrors)
    .then(formatResponse)
    .then(handleSuccess)
    .catch((error) => {
      throw error;
    });
};

/**
 * Post request
 *
 * @param url
 * @param data
 * @return {Promise<Response>}
 */
export const post = (url, data) => {
  return fetch(url, {
    headers: prepareHeaders(),
    method: "POST",
    body: JSON.stringify(data),
  })
    .then(handleErrors)
    .then(formatResponse)
    .then(handleSuccess)
    .catch((error) => {
      throw error;
    });
};

/**
 * Put request
 *
 * @param url
 * @param data
 * @return {Promise<Response>}
 */
export const put = (url, data) => {
  return fetch(url, {
    headers: prepareHeaders(),
    method: "PUT",
    body: JSON.stringify(data),
  })
    .then(handleErrors)
    .then(formatResponse)
    .then(handleSuccess)
    .catch((error) => {
      throw error;
    });
};

/**
 * Delete request
 *
 * @param url
 * @return {Promise<Response>}
 */
export const remove = (url) => {
  return fetch(url, {
    headers: prepareHeaders(),
    method: "DELETE",
  })
    .then(handleErrors)
    .then(formatResponse)
    .then(handleSuccess)
    .catch((error) => {
      throw error;
    });
};

/**
 * Post request
 *
 * @param url
 * @param data
 * @return {Promise<Response>}
 */
export const postForm = (url, data) => {
  const headers = new Headers();
  let auth = localStorage.getItem("auth");
  auth = JSON.parse(auth);
  headers.append("Accept", "application/json");
  headers.append("GA_Authorization", auth ? auth.authToken : "");
  headers.append("GA-Authorization", auth ? auth.authToken : "");

  return fetch(url, {
    headers: headers,
    method: "POST",
    body: data,
  })
    .then(handleErrors)
    .then(formatResponse)
    .then(handleSuccess)
    .catch((error) => {
      throw error;
    });
};
