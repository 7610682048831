import React, {Component} from 'react'
import PropTypes from "prop-types";
import Card from "@material-ui/core/es/Card/Card";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import green from "@material-ui/core/es/colors/green";
import withStyles from "@material-ui/core/es/styles/withStyles";
import CardActions from "@material-ui/core/es/CardActions/CardActions";
import Button from "@material-ui/core/es/Button/Button";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import restCallController from "../../../common/hoc/forms/restCallController";
import InvoiceForm from "./forms/InvoiceForm";
import {createInvoice, updateInvoice} from "../../service/InvoiceService";
import Constants from "../../../Constants";

const styles = theme => ({
  card: {
    borderRadius: 0
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
});

/**
 * Add Patient program form
 *
 * @param patient
 * @return {*}
 * @constructor
 */
class InvoiceFormComponent extends Component {

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);

    let invoice = {items: [{product_id: ''}]};
    if (props.patient_program_document && props.patient_program_document.invoice)
      invoice = props.patient_program_document.invoice;

    console.log(props.patient_program_document);

    invoice.patient_program_document_id = props.patient_program_document.id;
    this.state = {
      invoice: invoice
    };

    this.onModelChange = this.onModelChange.bind(this);
    this.onInvoiceItemChange = this.onInvoiceItemChange.bind(this);
    this.addNewInvoiceItem = this.addNewInvoiceItem.bind(this);
  }

  /**
   * On model field change
   *
   * @param model
   * @return {Function}
   */
  onModelChange = (model) => (field, value) => {
    this.setState({
      ...this.state,
      [model]: {
        ...this.state[model],
        [field]: value
      }
    });
  };

  /**
   * On model field change
   *
   * @return {Function}
   * @param index
   */
  onInvoiceItemChange = (index) => (field, value) => {
    let {items, ...invoice} = this.state.invoice;
    items[index] = {...items[index], [field]: value};
    this.setState({
      ...this.state,
      invoice: {
        ...invoice,
        items: items
      }
    });
  };

  /**
   * Update invoice
   */
  update = () => {
    let {handlePromise, patient_program_document} = this.props;
    let {invoice} = this.state;
    if (invoice.selected_invoice_date)
      invoice.invoice_date = Constants.formatToServerDate(invoice.selected_invoice_date);
    if (!patient_program_document.invoice)
      handlePromise(createInvoice(this.state.invoice));
    else
      handlePromise(updateInvoice(patient_program_document.invoice.id, this.state.invoice));
  };

  /**
   * Add new invoice item
   */
  addNewInvoiceItem = () => {
    const invoiceItems = [...this.state.invoice.items, {product_id: ''}];
    this.setState({
      ...this.state,
      invoice: {
        ...this.state.invoice,
        items: invoiceItems
      }
    });
  };

  render() {
    let {classes, close, isFetching, data, errors} = this.props;
    let {invoice} = this.state;
    return (
      <Card className={classes.card} elevation={0}>
        <CardContent>
          <form className={classes.container} noValidate autoComplete="off">
            <InvoiceForm model={invoice} onModelChange={this.onModelChange("invoice")}
                         onInvoiceItemChange={this.onInvoiceItemChange}
                         addNewInvoiceItem={this.addNewInvoiceItem}
                         data={data} errors={errors}/>
          </form>
        </CardContent>
        <CardActions dir="rtl">
          <Button variant="outlined" color="secondary" onClick={close} disabled={isFetching}>
            Close
          </Button>
          <div className={classes.wrapper}>
            <Button variant="contained" color="primary" onClick={this.update} disabled={isFetching}>
              {isFetching ? "Updating" : "Update"}
            </Button>
            {isFetching && <CircularProgress size={24} className={classes.buttonProgress}/>}
          </div>
        </CardActions>
      </Card>
    );
  }
}

InvoiceFormComponent.propTypes = {
  data: PropTypes.object.isRequired,
  patient_program_document: PropTypes.object.isRequired,
  close: PropTypes.func,
  classes: PropTypes.object.isRequired,
  successCallback: PropTypes.func.isRequired,
  failureCallback: PropTypes.func.isRequired
};

export default restCallController(withStyles(styles)(InvoiceFormComponent));