import React from "react";
import PropTypes from "prop-types";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import Typography from "@material-ui/core/es/Typography/Typography";
import CardMedia from "@material-ui/core/es/CardMedia/CardMedia";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Card from "@material-ui/core/es/Card/Card";
import CardActionArea from "@material-ui/core/es/CardActionArea/CardActionArea";
import CardActions from "@material-ui/core/es/CardActions/CardActions";
import Button from "@material-ui/core/es/Button/Button";
import upload from "../../../images/upload.png";
import restCallController from "../../../common/hoc/forms/restCallController";
import green from "@material-ui/core/es/colors/green";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import PatientProgramDocumentVerifyFormComponent from "./PatientProgramDocumentVerifyFormComponent";
import Constants from "../../../Constants";
import DocumentInvoiceComponent from "./DocumentInvoiceComponent";

const styles = (theme) => ({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

/**
 * Document component
 *
 * @param patient
 * @return {*}
 * @constructor
 */
class PatientProgramDocumentComponent extends React.Component {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      update: false,
      document_details_form: false,
    };
    this.onPatientProgramDocumentUpdateSuccessCallback =
      this.onPatientProgramDocumentUpdateSuccessCallback.bind(this);
    this.toggleUpdate = this.toggleUpdate.bind(this);
  }

  /**
   * Toggle update
   */
  toggleUpdate = () => {
    this.setState({
      update: !this.state.update,
    });
  };

  /**
   * Toggle update
   */
  toggleDocumentDetailsForm = () => {
    this.setState({
      document_details_form: !this.state.document_details_form,
    });
  };

  /**
   * Patient program document update success callback
   */
  onPatientProgramDocumentUpdateSuccessCallback = () => {
    let { refreshPatientProgram, enqueueSnackbar } = this.props;
    refreshPatientProgram();
    this.toggleUpdate();
    enqueueSnackbar("Document updated successfully!");
  };

  /**
   * Document invoice update success callback
   */
  onDocumentInvoiceUpdateSuccessCallback = () => {
    let { refreshPatientProgram, enqueueSnackbar } = this.props;
    refreshPatientProgram();
    this.toggleDocumentDetailsForm();
    enqueueSnackbar("Invoice details updated successfully!");
  };

  //render content
  render() {
    const { classes, document, isFetching, refreshPatientProgram } = this.props;
    return (
      <div>
        <Card className={classes.card}>
          <CardActionArea>
            {!document.document_id && (
              <CardMedia
                className={classes.media}
                image={upload}
                title="Document"
              />
            )}
            {document.document_id &&
              (document.file_type === "image/png" ||
                document.file_type === "image/jpeg") && (
                <CardMedia
                  className={classes.media}
                  image={document.document_path}
                  title="Document"
                />
              )}
            {document.document_id &&
              document.file_type === "application/pdf" && (
                <CardMedia
                  className={classes.media}
                  image={Constants.PDF_IMAGE}
                  onClick={() => {
                    console.log(document);
                    window.open(document.document_path, "_blank");
                  }}
                  title="Document"
                />
              )}
            <CardContent>
              <Typography gutterBottom variant="body2" component="h6">
                {document.document_type ? document.document_type : "NA"} (
                {document.status})
              </Typography>
              <Typography component="p" variant="caption">
                {document.document_group ? document.document_group : "NA"}
              </Typography>
              <Typography component="p" variant="caption">
                {document.document_id &&
                  `Uploaded on: ${Constants.formatToDisplayDate(
                    document.upload_date
                  )}`}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions className={classes.wrapper}>
            {!!document.document_id && (
              <Button
                size="small"
                color="primary"
                onClick={this.toggleUpdate}
                disabled={isFetching}
              >
                Update
              </Button>
            )}
            {!!document.document_id &&
              document.document_group === "Invoices" && (
                <Button
                  size="small"
                  color="primary"
                  onClick={this.toggleDocumentDetailsForm}
                  disabled={isFetching}
                >
                  Update details
                </Button>
              )}
            {isFetching && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </CardActions>
        </Card>
        <PatientProgramDocumentVerifyFormComponent
          open={this.state.update}
          document={document}
          handleClose={this.toggleUpdate}
          successCallback={this.onPatientProgramDocumentUpdateSuccessCallback}
          failureCallback={() => {}}
        />
        <DocumentInvoiceComponent
          open={this.state.document_details_form}
          document={document}
          data={{ products: this.props.productList }}
          handleClose={this.toggleDocumentDetailsForm}
          successCallback={this.onDocumentInvoiceUpdateSuccessCallback}
          failureCallback={() => {}}
        />
      </div>
    );
  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
PatientProgramDocumentComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  productList: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
  refreshPatientProgram: PropTypes.func.isRequired,
  document: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  successCallback: PropTypes.func.isRequired,
  failureCallback: PropTypes.func.isRequired,
};
export default restCallController(
  withStyles(styles, { withTheme: true })(PatientProgramDocumentComponent)
);
