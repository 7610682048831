import React, {Component} from 'react'
import PropTypes from "prop-types";
import PatientProgramItem from "./PatientProgramListItem";
import Card from "@material-ui/core/es/Card/Card";
import List from "@material-ui/core/es/List/List";
import withStyles from "@material-ui/core/es/styles/withStyles";
import {withRouter} from "react-router-dom";

const styles = theme => ({
  card: {
    borderRadius: 0
  },
  list: {
    maxHeight: "83vh",
    paddingTop: 0,
    paddingBottom: 0,
    overflowY: "scroll"
  }
});

class PatientProgramList extends Component {

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      selected_patient_program: {}
    };
    this.onPatientProgramSelect = this.onPatientProgramSelect.bind(this);
  }

  /**
   * On select of patient program
   *
   * @param patient_program
   */
  onPatientProgramSelect(patient_program) {
    this.setState({
      ...this.state,
      selected_patient_program: patient_program
    });
    this.props.history.push(`/patients/patient_programs/${patient_program.id}/documents`)
  }

  //render content
  render() {
    const {patient_programs} = this.props;
    const {classes} = this.props;

    // prepare patent programs
    let items = patient_programs.map((p, key) => {
      return <PatientProgramItem patient_program={p} key={key} onSelect={this.onPatientProgramSelect}/>;
    });

    return (<Card className={classes.card}>
      <List className={classes.list}>
        {items}
      </List>
    </Card>);
  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
PatientProgramList.propTypes = {
  patient_programs: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withRouter(PatientProgramList));
