import React from 'react'
import PropTypes from "prop-types";
import Grid from "@material-ui/core/es/Grid/Grid";
import withStyles from "@material-ui/core/es/styles/withStyles";
import formController from "../../../../common/hoc/forms/formController";
import DateFnsUtils from "@date-io/date-fns/build/index";
import TextField from "@material-ui/core/es/TextField/TextField";
import {DatePicker, MuiPickersUtilsProvider} from "material-ui-pickers";
import InvoiceItemForm from "./InvoiceItemForm";
import Typography from "@material-ui/core/es/Typography/Typography";
import Button from "@material-ui/core/es/Button/Button";
import Icon from "@material-ui/core/es/Icon/Icon";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  picker: {
    marginLeft: 8,
    marginRight: 8
  },
  addMore: {
    marginLeft: 10
  },
});

class InvoiceForm extends React.Component {

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.onInvoiceDateChange = this.onInvoiceDateChange.bind(this);
  }

  /**
   * On treatment start date change
   *
   * @param date
   */
  onInvoiceDateChange = (date) => {
    let {onFieldChange} = this.props;
    onFieldChange({target: {name: 'selected_invoice_date', value: date}});
  };

  /**
   * Render view
   */
  render() {
    const {classes, onFieldChange, model, data, errors, onInvoiceItemChange, addNewInvoiceItem} = this.props;
    return (<div>
        <div>
          <Typography component="h6" variant="subtitle1" color="textSecondary" className={classes.subHeading}>
            INVOICE DETAILS
          </Typography>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker margin="dense" variant="outlined" fullWidth={true} label="Invoice Date"
                            value={model.selected_invoice_date} onChange={this.onInvoiceDateChange}
                            format={"dd MMM` yyyy"} className={classes.picker}
                            mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}/>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
              <TextField id="invoice_no" label="Invoice No." className={classes.textField} name="invoice_no"
                         defaultValue={model.invoice_no} onChange={onFieldChange}
                         margin="dense" fullWidth={true} variant="outlined"/>
            </Grid>
            <Grid item xs={6}>
              <TextField id="supplier_gst" label="Supplier Gst" className={classes.textField} name="supplier_gst"
                         defaultValue={model.supplier_gst} onChange={onFieldChange}
                         margin="dense" fullWidth={true} variant="outlined"/>
            </Grid>
          </Grid>
        </div>
        <Typography component="h6" variant="subtitle1" color="textSecondary" className={classes.subHeading}>
          INVOICE ITEMS
          <Button variant="outlined" onClick={addNewInvoiceItem} size="small" className={classes.addMore}>
            <Icon color="primary" fontSize="small">
              add
            </Icon> ADD MORE
          </Button>
        </Typography>
        <div>
          {
            model.items.map((invoice_item, key) => {
              return <InvoiceItemForm model={invoice_item} onModelChange={onInvoiceItemChange(key)} data={data}
                                      key={key}/>
            })
          }
        </div>
      </div>
    );
  }
}

InvoiceForm.propTypes = {
  data: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired
};

export default formController(withStyles(styles)(InvoiceForm));