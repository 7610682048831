import React, {Component} from 'react'
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Grid from "@material-ui/core/es/Grid/Grid";
import InvoiceCardItem from "./items/InvoiceCardItem";
import Constants from "../../../../Constants";
import Card from "@material-ui/core/es/Card/Card";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import Typography from "@material-ui/core/es/Typography/Typography";
import Table from "@material-ui/core/es/Table/Table";
import TableHead from "@material-ui/core/es/TableHead/TableHead";
import TableRow from "@material-ui/core/es/TableRow/TableRow";
import TableCell from "@material-ui/core/es/TableCell/TableCell";
import TableBody from "@material-ui/core/es/TableBody/TableBody";

const styles = theme => ({});

/**
 * Patient documents
 *
 * @param patient
 * @return {*}
 * @constructor
 */
class InvoiceCardList extends Component {

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.renderInvoices = this.renderInvoices.bind(this);
    this.renderInvoiceItems = this.renderInvoiceItems.bind(this);
  }

  render() {
    const {invoices} = this.props;
    return this.renderInvoices(invoices);
  }

  /**
   * Render invoices
   *
   * @param invoices
   * @return {*}
   */
  renderInvoices(invoices) {
    const {classes} = this.props;
    return invoices.map(invoice => <Card className={classes.card}>
      <CardContent>
        <Typography variant="body2" component="p">
          INVOICE: {invoice.id} ({invoice.invoice_no})
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Date: {Constants.formatToDisplayDate(invoice.invoice_date)}
        </Typography>
      </CardContent>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell align="right">Product</TableCell>
            <TableCell align="right">Quantity</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.renderInvoiceItems(invoice)}
        </TableBody>
      </Table>
    </Card>)
  }

  /**
   * Render invoice items
   *
   * @param invoice
   * @return {*}
   */
  renderInvoiceItems(invoice) {
    return invoice.items.map((item, key) => <InvoiceCardItem invoice={invoice} invoiceItem={item}/>)
  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
InvoiceCardList.propTypes = {
  invoices: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(InvoiceCardList);
