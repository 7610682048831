import {
  FETCH_PATIENT_PROGRAM_DUE_MONTHLY_COUNT,
  FETCH_PATIENT_PROGRAM_FOR_DUE_DATE,
  FIND_All_PATIENT_PROGRAM,
  GET_PATIENT_PROGRAM,
  UPDATE_PATIENT_PROGRAM,
  UPDATE_PATIENT_PROGRAM_DOCUMENT,
} from "./actions/patient-program-actions";
import {
  handleRestRequest,
  initialRestState,
} from "../../common/actions/actions";
import { combineReducers } from "redux";
import {
  FETCH_PATIENT_MASTERS,
  GET_PATIENT,
  UPDATE_PATIENT,
} from "./actions/patient-actions";
import { UPDATE_DOCUMENT } from "./actions/document-actions";
import { FIND_All_PROGRAM } from "./actions/program-actions";
import {
  CREATE_FOLLOW_UP,
  FETCH_FOLLOW_UP_MONTHLY_COUNTS,
  FETCH_FOLLOW_UPS,
  FETCH_FOLLOW_UPS_FOR_DATE,
  FETCH_FOLLOW_UPS_FOR_PATIENT_PROGRAM,
} from "./actions/follow-up-actions";
import {
  FIND_CFA_LIST,
  FIND_ORDER_LIST_FOR_PATIENT,
} from "./actions/order-actions";
import { FIND_All_PRODUCT } from "./actions/product-actions";
import {
  INVOICE_LIST,
  INVOICE_LIST_FOR_PATIENT_PROGRAM,
} from "./actions/invoice-actions";

/**
 * Get all programs
 *
 * @param state
 * @param action
 * @return
 */
const programs = (
  state = {
    ...initialRestState,
  },
  action
) => {
  switch (action.type) {
    case FIND_All_PROGRAM:
      return handleRestRequest(state, action);
    default:
      return state;
  }
};

/**
 * Get all products
 *
 * @param state
 * @param action
 * @return
 */
const products = (
  state = {
    ...initialRestState,
  },
  action
) => {
  switch (action.type) {
    case FIND_All_PRODUCT:
      return handleRestRequest(state, action);
    default:
      return state;
  }
};

/**
 * Get all patient programs
 *
 * @param state
 * @param action
 * @return
 */
const patientPrograms = (
  state = {
    ...initialRestState,
  },
  action
) => {
  switch (action.type) {
    case FIND_All_PATIENT_PROGRAM:
      return handleRestRequest(state, action);
    default:
      return state;
  }
};

/**
 * Get patient program
 *
 * @param state
 * @param action
 * @return
 */
const patientProgram = (
  state = {
    ...initialRestState,
  },
  action
) => {
  switch (action.type) {
    case GET_PATIENT_PROGRAM:
      return handleRestRequest(state, action);
    default:
      return state;
  }
};

/**
 * update patient program
 *
 * @param state
 * @param action
 * @return
 */
const updatePatientProgram = (
  state = {
    ...initialRestState,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_PATIENT_PROGRAM:
      return handleRestRequest(state, action);
    default:
      return state;
  }
};

/**
 * Get patient
 *
 * @param state
 * @param action
 * @return
 */
const patient = (
  state = {
    ...initialRestState,
  },
  action
) => {
  switch (action.type) {
    case GET_PATIENT:
      return handleRestRequest(state, action);
    default:
      return state;
  }
};

/**
 * update patient
 *
 * @param state
 * @param action
 * @return
 */
const updatePatient = (
  state = {
    ...initialRestState,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_PATIENT:
      return handleRestRequest(state, action);
    default:
      return state;
  }
};

/**
 * update document
 *
 * @param state
 * @param action
 * @return
 */
const updateDocument = (
  state = {
    ...initialRestState,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_DOCUMENT:
      return handleRestRequest(state, action);
    default:
      return state;
  }
};

/**
 * update patient program document
 *
 * @param state
 * @param action
 * @return
 */
const updatePatientProgramDocument = (
  state = {
    ...initialRestState,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_PATIENT_PROGRAM_DOCUMENT:
      return handleRestRequest(state, action);
    default:
      return state;
  }
};

/**
 * Get all follow ups
 *
 * @param state
 * @param action
 * @return
 */
const followUps = (
  state = {
    ...initialRestState,
  },
  action
) => {
  switch (action.type) {
    case FETCH_FOLLOW_UPS:
      return handleRestRequest(state, action);
    default:
      return state;
  }
};

/**
 * Get all follow ups for patient program
 *
 * @param state
 * @param action
 * @return
 */
const followUpsForPatientProgram = (
  state = {
    ...initialRestState,
  },
  action
) => {
  switch (action.type) {
    case FETCH_FOLLOW_UPS_FOR_PATIENT_PROGRAM:
      return handleRestRequest(state, action);
    default:
      return state;
  }
};

/**
 * Get all follow ups monthly count
 *
 * @param state
 * @param action
 * @return
 */
const followUpsMonthlyCounts = (
  state = {
    ...initialRestState,
  },
  action
) => {
  switch (action.type) {
    case FETCH_FOLLOW_UP_MONTHLY_COUNTS:
      return handleRestRequest(state, action);
    default:
      return state;
  }
};

/**
 * Get all follow ups for month
 *
 * @param state
 * @param action
 * @return
 */
const followUpsForDate = (
  state = {
    ...initialRestState,
  },
  action
) => {
  switch (action.type) {
    case FETCH_FOLLOW_UPS_FOR_DATE:
      return handleRestRequest(state, action);
    default:
      return state;
  }
};

/**
 * Create follow up
 *
 * @param state
 * @param action
 * @return
 */
const createFollowUp = (
  state = {
    ...initialRestState,
  },
  action
) => {
  switch (action.type) {
    case CREATE_FOLLOW_UP:
      return handleRestRequest(state, action);
    default:
      return state;
  }
};

/**
 * Find cfa list
 *
 * @param state
 * @param action
 * @return
 */
const cfaList = (
  state = {
    ...initialRestState,
  },
  action
) => {
  switch (action.type) {
    case FIND_CFA_LIST:
      return handleRestRequest(state, action);
    default:
      return state;
  }
};

/**
 * Fetch patient masters
 *
 * @param state
 * @param action
 * @return
 */
const patientMasters = (
  state = {
    ...initialRestState,
  },
  action
) => {
  switch (action.type) {
    case FETCH_PATIENT_MASTERS:
      return handleRestRequest(state, action);
    default:
      return state;
  }
};

/**
 * Get invoices
 *
 * @param state
 * @param action
 * @return
 */
const invoicesForPatientProgram = (
  state = {
    ...initialRestState,
  },
  action
) => {
  switch (action.type) {
    case INVOICE_LIST_FOR_PATIENT_PROGRAM:
      return handleRestRequest(state, action);
    default:
      return state;
  }
};

/**
 * Get invoices
 *
 * @param state
 * @param action
 * @return
 */
const ordersForPatient = (
  state = {
    ...initialRestState,
  },
  action
) => {
  switch (action.type) {
    case FIND_ORDER_LIST_FOR_PATIENT:
      return handleRestRequest(state, action);
    default:
      return state;
  }
};

/**
 * Get patient program due counts
 *
 * @param state
 * @param action
 * @return
 */
const patientProgramDueCounts = (
  state = {
    ...initialRestState,
  },
  action
) => {
  switch (action.type) {
    case FETCH_PATIENT_PROGRAM_DUE_MONTHLY_COUNT:
      return handleRestRequest(state, action);
    default:
      return state;
  }
};

/**
 * Get all patient programs for month
 *
 * @param state
 * @param action
 * @return
 */
const patientProgramsForDueDate = (
  state = {
    ...initialRestState,
  },
  action
) => {
  switch (action.type) {
    case FETCH_PATIENT_PROGRAM_FOR_DUE_DATE:
      return handleRestRequest(state, action);
    default:
      return state;
  }
};

/**
 * Reducer
 *
 * @type {Reducer<any> | Reducer<any, AnyAction>}
 */
const reducer = combineReducers({
  programs,
  products,
  patientPrograms,
  patientProgram,
  updatePatientProgram,
  patient,
  updatePatient,
  updateDocument,
  updatePatientProgramDocument,
  followUps,
  followUpsForPatientProgram,
  followUpsMonthlyCounts,
  followUpsForDate,
  createFollowUp,
  cfaList,
  patientMasters,
  invoicesForPatientProgram,
  ordersForPatient,
  patientProgramDueCounts,
  patientProgramsForDueDate,
});

export default reducer;
