import React, {Component} from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Typography from "@material-ui/core/es/Typography/Typography";
import Card from "@material-ui/core/es/Card/Card";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import CardActions from "@material-ui/core/es/CardActions/CardActions";
import Button from "@material-ui/core/es/Button/Button";
import * as lodash from "lodash";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import green from "@material-ui/core/es/colors/green";
import {updatePatientProgramStatus} from "../../service/PatientProgramService";
import restCallController from "../../../common/hoc/forms/restCallController";
import PatientProgramStatusUpdateForm from "./forms/PatientProgramStatusUpdateForm";
import {PatientProgramConstant} from "../../PatientProgramConstant";

const styles = theme => ({
  card: {
    borderRadius: 0,
    width: "100%",
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});


/**
 * Update patient program status
 *
 * @param patient
 * @return {*}
 * @constructor
 */
class PatientProgramStatusUpdateFormComponent extends Component {

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);

    let patient_program = {status: '', closer_comments: ''};
    if (props.patient_program) {
      patient_program.status = props.patient_program.status;
      patient_program.closer_comments = props.patient_program.closer_comments;
    }

    this.state = {
      patient_program: patient_program
    };
    this.onModelChange = this.onModelChange.bind(this);
  }

  /**
   * On model field change
   *
   * @param model
   * @return {Function}
   */
  onModelChange = (model) => (field, value) => {
    this.setState({
      ...this.state,
      [model]: {
        ...this.state[model],
        [field]: value
      },
    });
  };

  /**
   * Update document
   */
  update = () => {
    let {handlePromise, patient_program} = this.props;
    handlePromise(updatePatientProgramStatus(patient_program.id, this.state.patient_program));
  };

  /**
   * Render view
   * @return {*}
   */
  render() {
    const {classes, isFetching, handleClose} = this.props;
    const {patient_program} = this.state;
    return (
      <Card className={classes.card}>
        <AppBar position="static" color="default" elevation={0}>
          <Toolbar variant="dense">
            <Typography variant="h6" color="inherit">
              Status update form
            </Typography>
          </Toolbar>
        </AppBar>
        <CardContent>
          <form className={classes.container} noValidate autoComplete="off">
            <PatientProgramStatusUpdateForm model={patient_program}
                                            onModelChange={this.onModelChange('patient_program')}
                                            data={{statuses: PatientProgramConstant.PATIENT_PROGRAM_STATUSES}}/>
          </form>
        </CardContent>
        <CardActions dir="rtl" className={classes.wrapper}>
          <Button variant="contained" color="primary" onClick={this.update} disabled={isFetching} size="medium">
            {isFetching ? "Updating" : "Update"}
          </Button>
          <Button onClick={handleClose} variant="outlined" color="secondary" autoFocus disabled={isFetching}>
            Close
          </Button>
          {isFetching && <CircularProgress size={24} className={classes.buttonProgress}/>}
        </CardActions>
      </Card>
    );
  }

  /**
   * On value change
   *
   * @param name
   */
  onDocumentFieldChange = name => event => {
    let document_types = lodash.clone(this.state.document_types);
    if (name === "document_group") {
      document_types = this.getDocumentTypes(this.state.document_groups, event.target.value);
    }
    this.setState({
      ...this.state,
      document: {
        ...this.state.document,
        [name]: event.target.value
      },
      document_types: document_types
    });
  };
}

PatientProgramStatusUpdateFormComponent.propTypes = {
  patient_program: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  successCallback: PropTypes.func.isRequired,
  failureCallback: PropTypes.func.isRequired
};

export default restCallController(withStyles(styles)(PatientProgramStatusUpdateFormComponent));