import {combineReducers} from 'redux'
import {authData} from "./login";
import {forgotPasswordData} from "./forgot_password";

const authReducer = combineReducers({
  authData,
  forgotPasswordData
});

export default authReducer;
