import {FAILED_REST_REQUEST, NEW_REST_REQUEST, SUCCESS_REST_REQUEST} from "../../../common/actions/actions";

export const FETCH_FOLLOW_UPS = 'FETCH_All_FOLLOW_UPS';
export const CREATE_FOLLOW_UP = 'CREATE_FOLLOW_UP';
export const FETCH_FOLLOW_UP_MONTHLY_COUNTS = 'FETCH_FOLLOW_UP_MONTHLY_COUNTS';
export const FETCH_FOLLOW_UPS_FOR_DATE = 'FETCH_FOLLOW_UPS_FOR_DATE';
export const FETCH_FOLLOW_UPS_FOR_PATIENT_PROGRAM = 'FETCH_FOLLOW_UPS_FOR_PATIENT_PROGRAM';


// request fetch follow ups
export const requestFetchFollowUps = () => ({
  type: FETCH_FOLLOW_UPS,
  status: NEW_REST_REQUEST,
});

// success fetch follow ups
export const successFetchFollowUps = (json) => ({
  type: FETCH_FOLLOW_UPS,
  status: SUCCESS_REST_REQUEST,
  data: json
});

// failed fetch follow ups
export const failedFetchFollowUps = (errors) => ({
  type: FETCH_FOLLOW_UPS,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});

// request fetch follow ups for patient program
export const requestFetchFollowUpsForPatientProgram = () => ({
  type: FETCH_FOLLOW_UPS_FOR_PATIENT_PROGRAM,
  status: NEW_REST_REQUEST,
});

// success fetch follow ups for patient program
export const successFetchFollowUpsForPatientProgram = (json) => ({
  type: FETCH_FOLLOW_UPS_FOR_PATIENT_PROGRAM,
  status: SUCCESS_REST_REQUEST,
  data: json
});

// failed fetch follow ups for patient program
export const failedFetchFollowUpsForPatientProgram = (errors) => ({
  type: FETCH_FOLLOW_UPS_FOR_PATIENT_PROGRAM,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});


// request fetch follow ups monthly count
export const requestFetchFollowUpMonthlyCount = () => ({
  type: FETCH_FOLLOW_UP_MONTHLY_COUNTS,
  status: NEW_REST_REQUEST,
});

// success fetch follow ups monthly count
export const successFetchFollowUpMonthlyCount = (json) => ({
  type: FETCH_FOLLOW_UP_MONTHLY_COUNTS,
  status: SUCCESS_REST_REQUEST,
  data: json
});

// failed fetch follow ups monthly count
export const failedFetchFollowUpMonthlyCount = (errors) => ({
  type: FETCH_FOLLOW_UP_MONTHLY_COUNTS,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});


// request fetch follow ups for month
export const requestFetchFollowUpsForDate = () => ({
  type: FETCH_FOLLOW_UPS_FOR_DATE,
  status: NEW_REST_REQUEST,
});

// success fetch follow ups for month
export const successFetchFollowUpsForDate = (json) => ({
  type: FETCH_FOLLOW_UPS_FOR_DATE,
  status: SUCCESS_REST_REQUEST,
  data: json
});

// failed fetch follow ups for month
export const failedFetchFollowUpsForDate = (errors) => ({
  type: FETCH_FOLLOW_UPS_FOR_DATE,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});

// request create follow up
export const requestCreateFollowUp = () => ({
  type: CREATE_FOLLOW_UP,
  status: NEW_REST_REQUEST,
});

// success create follow up
export const successCreateFollowUp = (json) => ({
  type: CREATE_FOLLOW_UP,
  status: SUCCESS_REST_REQUEST,
  data: json
});

// failed create follow up
export const failedCreateFollowUp = (errors) => ({
  type: CREATE_FOLLOW_UP,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});

