import React from 'react'
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/es/ListItem/ListItem";
import ListItemText from "@material-ui/core/es/ListItemText/ListItemText";
import Typography from "@material-ui/core/es/Typography/Typography";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Icon from "@material-ui/core/es/Icon/Icon";
import ListItemIcon from "@material-ui/core/es/ListItemIcon/ListItemIcon";

const styles = theme => ({
  icon: {
    margin: theme.spacing.unit * 0.5,
  },
  item_icon: {
    marginRight: 0
  }
});

function PatientProgramItem({patient_program, onSelect, classes}) {

  /**
   * Select patient program
   */
  let selectPatientProgram = () => {
    onSelect(patient_program);
  };

  // render
  return (
    <ListItem alignItems="flex-start" onClick={selectPatientProgram} button={true} divider={true}>
      <ListItemIcon className={classes.item_icon}>
        <Icon className={classes.icon} color="primary" fontSize="large">
          account_circle
        </Icon>
      </ListItemIcon>
      <ListItemText
        primary={
          <React.Fragment>
            <Typography variant="body2" gutterBottom>
              {patient_program.program.name} ({patient_program.program.program_type.name})
            </Typography>
          </React.Fragment>
        }
        secondary={
          <React.Fragment>
            <Typography variant="caption">
              {patient_program.created_at}
            </Typography>
          </React.Fragment>
        }
      />
    </ListItem>
  )
}

PatientProgramItem.propTypes = {
  patient_program: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PatientProgramItem);

