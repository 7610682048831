import React from 'react'
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/es/Grid/Grid";
import TextField from "@material-ui/core/es/TextField/TextField";
import formController from "../../../common/hoc/forms/formController";
import withStyles from "@material-ui/core/es/styles/withStyles";
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import InputLabel from "@material-ui/core/es/InputLabel/InputLabel";
import Select from "@material-ui/core/es/Select/Select";
import OutlinedInput from "@material-ui/core/es/OutlinedInput/OutlinedInput";
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";

const ratings = [1, 2, 3, 4, 5];
const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  }
});

class RatingForm extends React.Component {
  render() {
    const {classes, model, onFieldChange} = this.props;
    return (<Grid container spacing={8}>
        <Grid item xs={12} sm={6} md={6}>
          <FormControl variant="outlined" className={classes.formControl} fullWidth={true} margin="dense">
            <InputLabel ref={ref => {
              this.InputLabelRef = ref;
            }} htmlFor="vendor">Rating</InputLabel>
            <Select value={model.rating} onChange={onFieldChange}
                    input={
                      <OutlinedInput labelWidth={0} name="Rating" id="rating"/>
                    }>
              {
                ratings.map((d, i) => {
                  return <MenuItem value={d} key={i}>{d}</MenuItem>
                })
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField id="comments_str"
                     label="Comments"
                     className={classes.textField}
                     value={model.rating_comments}
                     onChange={onFieldChange('rating_comments')}
                     margin="dense" fullWidth={true}
                     variant="outlined"/>
        </Grid>
      </Grid>
    );
  }
}

RatingForm.propTypes = {
  model: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired
};

export default formController(withStyles(styles)(RatingForm));