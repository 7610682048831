import {FAILED_REST_REQUEST, NEW_REST_REQUEST, SUCCESS_REST_REQUEST} from "../../../common/actions/actions";

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const TEMP_TOKEN_VERIFICATION = 'TEMP_TOKEN_VERIFICATION';
export const VERIFICATION_CODE_REQUEST = 'VERIFICATION_CODE_REQUEST';
export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_CHANGE_REQUEST = 'PASSWORD_CHANGE_REQUEST';


// request login
export const requestLogin = (username, password) => ({
  type: LOGIN_REQUEST,
  status: NEW_REST_REQUEST,
  username: username,
  password: password
});

// receive login
export const successLogin = (json) => ({
  type: LOGIN_REQUEST,
  status: SUCCESS_REST_REQUEST,
  user: json.user
});

// failed login request
export const failedLogin = (errors) => ({
  type: LOGIN_REQUEST,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});

// request verify temp token
export const requestVerifyTempToken = () => ({
  type: TEMP_TOKEN_VERIFICATION,
  status: NEW_REST_REQUEST,
});

// receive verify temp token
export const successVerifyTempToken = (json) => ({
  type: TEMP_TOKEN_VERIFICATION,
  status: SUCCESS_REST_REQUEST,
  user: json.user
});

// failed verify temp token
export const failedVerifyTempToken = (errors) => ({
  type: TEMP_TOKEN_VERIFICATION,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});

// request verification code
export const requestVerificationCode = (username) => ({
  type: VERIFICATION_CODE_REQUEST,
  status: NEW_REST_REQUEST,
  username: username
});

// received verification code
export const successVerificationCode = (json) => ({
  type: VERIFICATION_CODE_REQUEST,
  status: SUCCESS_REST_REQUEST,
  message: json.message
});


// failed verification code
export const failedVerificationCode = (errors) => ({
  type: VERIFICATION_CODE_REQUEST,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});

// request password reset
export const requestPasswordReset = (verificationCode, newPassword, confirmNewPassword) => ({
  type: PASSWORD_RESET_REQUEST,
  status: NEW_REST_REQUEST,
  verificationCode: verificationCode,
  newPassword: newPassword,
  confirmNewPassword: confirmNewPassword
});

// failed password reset
export const failedPasswordReset = (errors) => ({
  type: PASSWORD_RESET_REQUEST,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});

// received password reset
export const successPasswordReset = (json) => ({
  type: PASSWORD_RESET_REQUEST,
  status: SUCCESS_REST_REQUEST,
  message: json.message
});

// request password change
export const requestPasswordChange = (newPassword, confirmNewPassword) => ({
  type: PASSWORD_CHANGE_REQUEST,
  status: NEW_REST_REQUEST,
  newPassword: newPassword,
  confirmNewPassword: confirmNewPassword
});

// failed password change
export const failedPasswordChange = (errors) => ({
  type: PASSWORD_CHANGE_REQUEST,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});

// received password change
export const successPasswordChange = (json) => ({
  type: PASSWORD_CHANGE_REQUEST,
  status: SUCCESS_REST_REQUEST,
  message: json.message
});
