import {FAILED_REST_REQUEST, NEW_REST_REQUEST, SUCCESS_REST_REQUEST} from "../../../common/actions/actions";

export const FIND_ORDERS = 'FIND_ORDERS';
export const FIND_CFA_LIST = 'FIND_CFA_LIST';
export const FIND_VENDOR_LIST = 'FIND_VENDOR_LIST';


// request find orders
export const requestFindOrders = () => ({
  type: FIND_ORDERS,
  status: NEW_REST_REQUEST
});

// receive find orders
export const successFindOrders = (json) => ({
  type: FIND_ORDERS,
  status: SUCCESS_REST_REQUEST,
  data: json
});

// failed find orders
export const failedFindOrders = (errors) => ({
  type: FIND_ORDERS,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});

// request cfa list
export const requestFindCfaList = () => ({
  type: FIND_CFA_LIST,
  status: NEW_REST_REQUEST,
});

// success find cfa list
export const successFindCfaList = (json) => ({
  type: FIND_CFA_LIST,
  status: SUCCESS_REST_REQUEST,
  data: json
});

// failed find cfa list
export const failedFindCfaList = (errors) => ({
  type: FIND_CFA_LIST,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});

// request vendor list
export const requestFindVendorList = () => ({
  type: FIND_VENDOR_LIST,
  status: NEW_REST_REQUEST,
});

// success find vendor list
export const successFindVendorList = (json) => ({
  type: FIND_VENDOR_LIST,
  status: SUCCESS_REST_REQUEST,
  data: json
});

// failed find vendor list
export const failedFindVendorList = (errors) => ({
  type: FIND_VENDOR_LIST,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});