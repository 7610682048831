import React, {Component} from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Typography from "@material-ui/core/es/Typography/Typography";
import Card from "@material-ui/core/es/Card/Card";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import CardActions from "@material-ui/core/es/CardActions/CardActions";
import Button from "@material-ui/core/es/Button/Button";
import * as lodash from "lodash";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import green from "@material-ui/core/es/colors/green";
import Icon from "@material-ui/core/es/Icon/Icon";
import {updatePatientProgramDocument} from "../../service/PatientProgramService";
import restCallController from "../../../common/hoc/forms/restCallController";
import PatientProgramDocumentForm from "./forms/PatientProgramDocumentForm";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  icon: {
    margin: 0,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  card: {
    borderRadius: 0,
    width: "100%",
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});


/**
 * Update patient program form
 *
 * @param patient
 * @return {*}
 * @constructor
 */
class PatientProgramDocumentFormComponent extends Component {

  /**
   * Patient form data
   */
  state = {
    document_groups: [],
    document_types: [],
    document: {
      document_type: '', document_group: ''
    }
  };

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);

    // get document types
    let document_groups = props.document_groups;
    let document_types = [];
    let document = {document_group: '', document_type: ''};

    // document from props exists
    if (props.document)
      document = props.document;

    let document_group = document_groups.find(dg => dg.name === document.document_group);
    if (document_group)
      document_types = document_group.documentTypes;

    // set state
    this.state = {
      document_groups: document_groups,
      document_types: document_types,
      document: document
    };

    this.onModelChange = this.onModelChange.bind(this);
  }

  /**
   * On model field change
   *
   * @param model
   * @return {Function}
   */
  onModelChange = (model) => (field, value) => {
    let {document_groups} = this.state;
    let self = this;
    this.setState({
      ...this.state,
      [model]: {
        ...this.state[model],
        [field]: value
      },
    }, function () {
      if (field === 'document_group') {
        let document_types = [];
        let document_group = document_groups.find(dg => dg.name === value);
        if (document_group)
          document_types = document_group.documentTypes;
        self.setState({
          ...self.state,
          document_types: document_types
        });
      }
    });
  };

  /**
   * Update document
   */
  update = () => {
    let {patient_program_id, handlePromise} = this.props;
    let newDocument = this.state.document;
    let self = this;
    handlePromise(updatePatientProgramDocument(patient_program_id, newDocument).then(function (json) {
      self.setState({
        ...self.state,
        document: {document_type: '', document_group: ''}
      });
      return json;
    }));
  };

  /**
   * Render view
   * @return {*}
   */
  render() {
    const {classes, isFetching} = this.props;
    const {document, document_groups, document_types} = this.state;
    return (
      <Card className={classes.card}>
        <AppBar position="static" color="default" elevation={0}>
          <Toolbar variant="dense">
            <Typography variant="h6" color="inherit">
              Document form
            </Typography>
          </Toolbar>
        </AppBar>
        <CardContent>
          <form className={classes.container} noValidate autoComplete="off">
            <PatientProgramDocumentForm model={document}
                                        onModelChange={this.onModelChange('document')}
                                        data={{document_groups: document_groups, document_types: document_types}}/>
          </form>
        </CardContent>
        <CardActions dir="rtl" className={classes.wrapper}>
          <Button variant="contained" color="primary" onClick={this.update} disabled={isFetching} size="medium">
            {isFetching ? "Adding" : "Add"}
            <Icon className={classes.icon} color="inherit" fontSize="small">
              add
            </Icon>
          </Button>
          {isFetching && <CircularProgress size={24} className={classes.buttonProgress}/>}
        </CardActions>
      </Card>
    );
  }

  /**
   * On value change
   *
   * @param name
   */
  onDocumentFieldChange = name => event => {
    let document_types = lodash.clone(this.state.document_types);
    if (name === "document_group") {
      document_types = this.getDocumentTypes(this.state.document_groups, event.target.value);
    }
    this.setState({
      ...this.state,
      document: {
        ...this.state.document,
        [name]: event.target.value
      },
      document_types: document_types
    });
  };
}

PatientProgramDocumentFormComponent.propTypes = {
  patient_program_id: PropTypes.number.isRequired,
  document_groups: PropTypes.array.isRequired,
  document: PropTypes.object,
  classes: PropTypes.object.isRequired,
  successCallback: PropTypes.func.isRequired,
  failureCallback: PropTypes.func.isRequired
};

export default restCallController(withStyles(styles)(PatientProgramDocumentFormComponent));