import React, {Component} from 'react'
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Paper from "@material-ui/core/es/Paper/Paper";
import Table from "@material-ui/core/es/Table/Table";
import TableHead from "@material-ui/core/es/TableHead/TableHead";
import TableCell from "@material-ui/core/es/TableCell/TableCell";
import TableBody from "@material-ui/core/es/TableBody/TableBody";
import TableRow from "@material-ui/core/es/TableRow/TableRow";
import {ReportConstant as ReportConstants} from "../ReportConstant";

const styles = theme => ({
  paper: {
    margin: theme.spacing.unit * 1
  },
  tableCell: {
    padding: "4px"
  },
  cellSmall: {
    width: "100px"
  }
});

class DeferredPatientProgramTableComponent extends Component {
  render() {
    const {patientPrograms, classes} = this.props;
    return (
      <Paper>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classes.tableCell + " " + classes.cellSmall}>Coupon Id</TableCell>
              <TableCell align="center" className={classes.tableCell + " " + classes.cellSmall}>
                Enrolment Date
              </TableCell>
              <TableCell align="center" className={classes.tableCell + " " + classes.cellSmall}>Brand </TableCell>
              <TableCell align="center">Dose</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">QOS</TableCell>
              <TableCell align="center">Reason for deferred</TableCell>
              <TableCell align="center">Documentation pending</TableCell>
              {/*<TableCell align="center">No of calls</TableCell>*/}
              <TableCell align="center">Indication</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {patientPrograms.map((row, key) => (
              <TableRow key={key}>
                <TableCell component="th" scope="row" className={classes.tableCell}>
                  {row.fgo_code}
                </TableCell>
                <TableCell align="right">{ReportConstants.formatDate(row.enrolment_date)}</TableCell>
                <TableCell align="right">{row.brand}</TableCell>
                <TableCell align="right">{row.dosage}</TableCell>
                <TableCell align="right">{row.current_status}</TableCell>
                <TableCell align="right">{row.qos}</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="left">{row.documents_pending}</TableCell>
                {/*<TableCell align="right">{row.no_of_phone_calls}</TableCell>*/}
                <TableCell align="right">{row.treatment}</TableCell>
              </TableRow>
            ))}

            {
              patientPrograms.length === 0 &&
              <TableRow>
                <TableCell component="th" scope="row" colspan={8}>
                  NO RECORDS FOUND
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </Paper>);

  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
DeferredPatientProgramTableComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  patientPrograms: PropTypes.array.isRequired
};
export default withStyles(styles, {withTheme: true})(DeferredPatientProgramTableComponent);