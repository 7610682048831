import {get, postForm, put} from "../../common/services/BaseService";
import {PatientProgramConstant} from "../PatientProgramConstant";
import {
  failedFetchPatientMasters,
  failedPatient,
  requestFetchPatientMasters,
  requestPatient,
  successFetchPatientMasters,
  successPatient
} from "../reducers/actions/patient-actions";


/**
 * Fetch patient master
 *
 * @return {Promise<T> | *}
 */
export const fetchPatientMasters = () => dispatch => {
  dispatch(requestFetchPatientMasters());
  return get(PatientProgramConstant.PATIENT_MASTERS_URL).then(json => {
    dispatch(successFetchPatientMasters(json));
    return json;
  }).catch(error => {
    dispatch(failedFetchPatientMasters(error));
    throw(error);
  });
};

/**
 * Fetch patient programs
 *
 * @returns {function(*): Promise<any>}
 * @param id
 */
export const getPatient = (id) => dispatch => {
  dispatch(requestPatient());
  return get(PatientProgramConstant.PATIENTS_URL + '/' + id).then(json => {
    dispatch(successPatient(json));
    return json;
  }).catch(error => {
    dispatch(failedPatient(error));
    throw(error);
  });
};

/**
 * update patient
 *
 * @param id
 * @param patient
 * @return {Promise<Response>}
 */
export const updatePatient = (id, patient) => {
  return put(`${PatientProgramConstant.PATIENTS_URL}/${id}`, patient).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};


/**
 * Upload patient document
 *
 * @param patient_id
 * @param file
 * @return {Promise<Response>}
 */
export const uploadPatientDocument = (patient_id, file) => {
  return postForm(`${PatientProgramConstant.PATIENTS_URL}/${patient_id}/documents`, file).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};