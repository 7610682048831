import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/es/styles/withStyles";
import green from "@material-ui/core/es/colors/green";
import CardActionArea from "@material-ui/core/es/CardActionArea/CardActionArea";
import CardMedia from "@material-ui/core/es/CardMedia/CardMedia";
import Card from "@material-ui/core/es/Card/Card";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import Typography from "@material-ui/core/es/Typography/Typography";
import upload from "../../../images/upload.png";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import restCallController from "../../../common/hoc/forms/restCallController";
import { uploadPatientDocument } from "../../service/PatientService";
import {
  removePatientProgramDocument,
  updatePatientProgramDocument,
} from "../../service/PatientProgramDocumentService";
import CardActions from "@material-ui/core/es/CardActions/CardActions";
import Button from "@material-ui/core/es/Button/Button";

const styles = (theme) => ({
  wrapper: {
    margin: theme.spacing.unit,
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  media: {
    height: 140,
    backgroundPosition: "center",
    backgroundSize: "auto",
  },
  card: {
    borderRadius: 0,
    margin: "10px",
  },
  input: {
    display: "none",
  },
});

/**
 * Patient follow up form
 *
 * @param patient
 * @return {*}
 * @constructor
 */
class PatientProgramDocumentUpload extends Component {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.remove = this.remove.bind(this);
  }

  /**
   * Upload file
   *
   * @param e
   */
  onChange = (e) => {
    const { patient_id, patient_document, handlePromise } = this.props;

    // get file
    const files = Array.from(e.target.files);

    // prepare form
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append(
      "data",
      new Blob(
        [
          JSON.stringify({
            documentGroup: patient_document.document_group,
            documentType: patient_document.document_type,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );

    // upload file
    uploadPatientDocument(patient_id, formData)
      .then((res) => {
        handlePromise(
          updatePatientProgramDocument(patient_document.id, res.document)
        );
      })
      .catch((errors) => {
        console.log("Errors: " + errors);
      });
  };

  /**
   * Remove patient program document
   */
  remove = () => {
    let { patient_document, handlePromise, enqueueSnackbar } = this.props;
    handlePromise(removePatientProgramDocument(patient_document.id));
  };

  //render content
  render() {
    let { patient_document, classes, isFetching } = this.props;
    return (
      <Card className={classes.card + " " + classes.wrapper}>
        <CardActionArea>
          <input
            accept="application/pdf,image/*"
            className={classes.input}
            id={"contained-button-file" + patient_document.id}
            multiple
            type="file"
            onChange={this.onChange}
          />
          <label htmlFor={"contained-button-file" + patient_document.id}>
            <CardMedia
              className={classes.media}
              image={upload}
              title="Document"
            />
          </label>
        </CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="body2" component="h6">
            {patient_document.document_type
              ? patient_document.document_type
              : "NA"}
          </Typography>
          <Typography component="p" variant="caption">
            {patient_document.document_group
              ? patient_document.document_group
              : "NA"}
          </Typography>
        </CardContent>
        <CardActions className={classes.wrapper}>
          <Button
            size="small"
            color="primary"
            onClick={this.remove}
            disabled={isFetching}
          >
            Remove
          </Button>
          {isFetching && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </CardActions>
      </Card>
    );
  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
PatientProgramDocumentUpload.propTypes = {
  patient_id: PropTypes.string.isRequired,
  patient_document: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  successCallback: PropTypes.func.isRequired,
  failureCallback: PropTypes.func.isRequired,
};

export default restCallController(
  withStyles(styles)(PatientProgramDocumentUpload)
);
