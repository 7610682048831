import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/es/Grid/Grid";
import withStyles from "@material-ui/core/es/styles/withStyles";
import green from "@material-ui/core/es/colors/green";
import NoContent from "../../common/components/common/NoContent";
import DemoRequestDetailsComponent from "../components/DemoRequestDetailsComponent";
import DemoRequestListComponent from "../components/DemoRequestListComponent";

const styles = (theme) => ({
  icon: {
    margin: theme.spacing.unit * 0.5,
  },
  button: {
    borderRadius: 0,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  tabContainer: {
    flexGrow: 1,
  },
  requestDetail: {
    backgroundColor: "#fff",
    overflowY: "scroll",
  },
  order: {
    backgroundColor: "#eee",
  },
  fullHeight: {
    minHeight: "100%",
    backgroundColor: "#fff",
  },
  leftPanel: {
    minHeight: "100%",
    backgroundColor: "#eee",
    zIndex: "1",
  },
});

class DemoRequestPage extends Component {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Prepare view
   *
   * @return {*}
   */
  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        spacing={0}
        className={classes.fullHeight}
        direction={"row-reverse"}
      >
        <Grid
          item
          md={7}
          sm={7}
          className={classes.requestDetail + " " + classes.fullHeight}
        >
          <DemoRequestDetailsComponent />
        </Grid>
        <Grid item md={5} sm={5} className={classes.leftPanel}>
          <DemoRequestListComponent />
        </Grid>
      </Grid>
    );
  }
}

/**
 * Map state to properties
 *
 * @param state
 * @returns {{auth: *}}
 */
const mapStateToProps = (state) => {};
export default connect(mapStateToProps)(withStyles(styles)(DemoRequestPage));
