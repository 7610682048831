import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Grid from "@material-ui/core/es/Grid/Grid";
import OrderCardItem from "./items/OrderCardItem";
import Table from "@material-ui/core/es/Table/Table";
import TableHead from "@material-ui/core/es/TableHead/TableHead";
import TableRow from "@material-ui/core/es/TableRow/TableRow";
import TableCell from "@material-ui/core/es/TableCell/TableCell";
import TableBody from "@material-ui/core/es/TableBody/TableBody";
import CardActionArea from "@material-ui/core/es/CardActionArea/CardActionArea";
import Card from "@material-ui/core/es/Card/Card";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import Typography from "@material-ui/core/es/Typography/Typography";
import Constants from "../../../../Constants";
import restCallController from "../../../../common/hoc/forms/restCallController";
import { sendEmail } from "../../../service/OrderService";
import CardActions from "@material-ui/core/es/CardActions/CardActions";
import Button from "@material-ui/core/es/Button/Button";

const styles = (theme) => ({});

/**
 * Patient documents
 *
 * @param patient
 * @return {*}
 * @constructor
 */
class OrderCardList extends Component {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.renderOrders = this.renderOrders.bind(this);
    this.sendOrderEmail = this.sendOrderEmail.bind(this);
    this.renderOrderItems = this.renderOrderItems.bind(this);
  }

  /**
   * Send email
   */
  sendOrderEmail(id) {
    let { handlePromise } = this.props;
    handlePromise(sendEmail(id));
  }

  render() {
    const { orders, classes } = this.props;
    return this.renderOrders(orders);
  }

  /**
   * Render orders
   *
   * @param orders
   * @return {*}
   */
  renderOrders(orders) {
    const { classes } = this.props;
    return orders.map((order) => (
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="body2" component="p">
            ORDER: {order.id} ({order.order_no}) - {order.user.full_name} (
            {order.user.email})
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Date: {Constants.formatToDisplayDate(order.order_date)}
          </Typography>
          <Typography variant="body2" component="p">
            {this.renderStatus(order)}
          </Typography>
        </CardContent>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell align="right">Product</TableCell>
              <TableCell align="right">Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.renderOrderItems(order)}</TableBody>
        </Table>
        <CardActions>
          <Button
            size="small"
            onClick={() => {
              this.sendOrderEmail(order.id);
            }}
          >
            Resend Email
          </Button>
        </CardActions>
      </Card>
    ));
  }

  /**
   * Render status
   *
   * @param order
   * @return {string}
   */
  renderStatus(order) {
    if (order.status === "delivered")
      return (
        order.status +
        " (" +
        Constants.formatToDisplayDate(order.delivery_date) +
        ")"
      );
    else if (order.status === "dispatch")
      return (
        order.status +
        " (" +
        Constants.formatToDisplayDate(order.dispatch_date) +
        ")"
      );
    return order.status;
  }

  /**
   * Render order items
   *
   * @param order
   * @return {*}
   */
  renderOrderItems(order) {
    return order.items.map((item, key) => (
      <OrderCardItem order={order} orderItem={item} key={key} />
    ));
  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
OrderCardList.propTypes = {
  orders: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default restCallController(
  withStyles(styles, { withTheme: true })(OrderCardList)
);
