import React from "react";
import logo from "../../../logo.svg";
import "./scss/header.css";
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import Typography from "@material-ui/core/es/Typography/Typography";
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import withStyles from "@material-ui/core/es/styles/withStyles";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/es/Button/Button";

const drawerWidth = 240;
const styles = (theme) => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "none",
  },
});

function Header(props) {
  const { classes } = props;

  /**
   * logout
   */
  let logout = () => {
    localStorage.removeItem("auth");
    props.history.push(`/auth`);
  };

  let demoRequest = () => {
    props.history.push(`/`);
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" color="primary">
        <Toolbar>
          {/*<IconButton*/}
          {/*color="inherit"*/}
          {/*aria-label="Open drawer"*/}
          {/*onClick={props.handleDrawerOpen}*/}
          {/*className={classNames(classes.menuButton, {*/}
          {/*[classes.hide]: props.open,*/}
          {/*})}>*/}
          {/*<MenuIcon/>*/}
          {/*</IconButton>*/}
          <IconButton color="inherit" aria-label="Menu">
            <img src={logo} className="App-logo" alt="logo" />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            Oncology App
          </Typography>
          {/* <Button color="inherit" onClick={demoRequest}>Demo Request</Button> */}
          <Button color="inherit" onClick={logout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}

Header.propTypes = {
  handleDrawerOpen: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Header));
