import React from 'react';
import PropTypes from "prop-types";

export default function restCallController(WrappedComponent) {
  return class extends React.Component {

    /**
     * Property types
     *
     * @type {{}}
     */
    PropTypes = {
      successCallback: PropTypes.func.isRequired,
      failedCallback: PropTypes.func.isRequired
    };

    /**
     * Constructor
     *
     * @param props
     */
    constructor(props) {
      super(props);
      this.state = {
        isFetching: false,
        errors: {}
      };
      this.handlePromise = this.handlePromise.bind(this);
    }

    /**
     * Toggle saving
     */
    toggleIsFetching = () => {
      this.setState({
        ...this.state,
        isFetching: !this.state.isFetching
      });
    };

    /**
     * Handle promise
     *
     * @param promise
     */
    handlePromise = (promise) => {
      let {successCallback, failureCallback} = this.props;
      this.toggleIsFetching();
      promise.then(json => {
        this.toggleIsFetching();
        successCallback(json);
      }).catch(errors => {
        this.toggleIsFetching();
        console.log(errors);
        if (errors.errors) {
          this.setState({
            ...this.state,
            errors: errors.errors.errors
          });
          failureCallback(errors);
        }
      });
    };

    /**
     * Render wrapped component
     *
     * @return {*}
     */
    render() {
      return <WrappedComponent {...this.props} isFetching={this.state.isFetching} handlePromise={this.handlePromise}
                               errors={this.state.errors}/>;
    }
  };
}