import React, {Component} from 'react'
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Paper from "@material-ui/core/es/Paper/Paper";
import Table from "@material-ui/core/es/Table/Table";
import TableHead from "@material-ui/core/es/TableHead/TableHead";
import TableCell from "@material-ui/core/es/TableCell/TableCell";
import TableBody from "@material-ui/core/es/TableBody/TableBody";
import TableRow from "@material-ui/core/es/TableRow/TableRow";

const styles = theme => ({
  paper: {
    margin: theme.spacing.unit * 1
  }
});

class DropedPatientProgramTableComponent extends Component {
  render() {
    const {patientPrograms, classes} = this.props;
    return (
      <Paper>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell align="right">Coupon Id</TableCell>
              <TableCell align="right">Enrolment Date</TableCell>
              <TableCell align="right">Dropout Date</TableCell>
              <TableCell align="right">Time on therapy</TableCell>
              <TableCell align="right">Reason for loss</TableCell>
              <TableCell align="right">Brand</TableCell>
              <TableCell align="right">Dosage</TableCell>
              <TableCell align="right">Indication</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {patientPrograms.map(row => (
              <TableRow key={row.month}>
                <TableCell component="th" scope="row">
                  {row.fgo_code}
                </TableCell>
                <TableCell align="right">{row.enrolment_date}</TableCell>
                <TableCell align="right">{row.dropout_date}</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right">{row.reason_for_drop}</TableCell>
                <TableCell align="right">{row.brand}</TableCell>
                <TableCell align="right">{row.dosage}</TableCell>
                <TableCell align="right">{row.treatment}</TableCell>
              </TableRow>
            ))}
            {
              patientPrograms.length === 0 &&
              <TableRow>
                <TableCell component="th" scope="row" colspan={7}>
                  NO RECORDS FOUND
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </Paper>);

  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
DropedPatientProgramTableComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  patientPrograms: PropTypes.array.isRequired
};
export default withStyles(styles, {withTheme: true})(DropedPatientProgramTableComponent);