import {FAILED_REST_REQUEST, NEW_REST_REQUEST, SUCCESS_REST_REQUEST} from "../../../common/actions/actions";

export const GET_PATIENT = 'GET_PATIENT';
export const UPLOAD_PATIENT_DOCUMENT = 'UPLOAD_PATIENT_DOCUMENT';


// request patient
export const requestPatient = () => ({
  type: GET_PATIENT,
  status: NEW_REST_REQUEST,
});

// success patient request
export const successPatient = (json) => ({
  type: GET_PATIENT,
  status: SUCCESS_REST_REQUEST,
  data: json
});

// failed patient request
export const failedPatient = (errors) => ({
  type: GET_PATIENT,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});

// request upload patient document
export const requestUploadPatientDocument = () => ({
  type: UPLOAD_PATIENT_DOCUMENT,
  status: NEW_REST_REQUEST,
});

// upload patient document succeed
export const successUploadPatientDocument = (json) => ({
  type: UPLOAD_PATIENT_DOCUMENT,
  status: SUCCESS_REST_REQUEST,
  data: json
});

// upload patient document failed
export const failedUploadPatientDocument = (errors) => ({
  type: UPLOAD_PATIENT_DOCUMENT,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});