import {FAILED_REST_REQUEST, NEW_REST_REQUEST, SUCCESS_REST_REQUEST} from "../../../common/actions/actions";

export const PATIENT_PROGRAMS = 'PATIENT_PROGRAMS';
export const PATIENT_PROGRAM = 'PATIENT_PROGRAM';
export const PATIENT_PROGRAM_DOCUMENTS_FOR_PATIENT_PROGRAM = 'PATIENT_PROGRAM_DOCUMENTS_FOR_PATIENT_PROGRAM';

// request patient programs
export const requestPatientPrograms = () => ({
  type: PATIENT_PROGRAMS,
  status: NEW_REST_REQUEST,
});

// success patient programs
export const successPatientPrograms = (json) => ({
  type: PATIENT_PROGRAMS,
  status: SUCCESS_REST_REQUEST,
  data: json
});

// failed patient programs
export const failedPatientPrograms = (errors) => ({
  type: PATIENT_PROGRAMS,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});

// request patient program
export const requestPatientProgram = () => ({
  type: PATIENT_PROGRAM,
  status: NEW_REST_REQUEST,
});

// success patient program
export const successPatientProgram = (json) => ({
  type: PATIENT_PROGRAM,
  status: SUCCESS_REST_REQUEST,
  data: json
});

// failed patient program
export const failedPatientProgram = (errors) => ({
  type: PATIENT_PROGRAM,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});

// request patient program documents for patient program
export const requestPatientProgramDocumentsForPatientProgram = () => ({
  type: PATIENT_PROGRAM_DOCUMENTS_FOR_PATIENT_PROGRAM,
  status: NEW_REST_REQUEST,
});

// success patient program documents for patient program
export const successPatientProgramDocumentsForPatientProgram = (json) => ({
  type: PATIENT_PROGRAM_DOCUMENTS_FOR_PATIENT_PROGRAM,
  status: SUCCESS_REST_REQUEST,
  data: json
});

// failed patient program documents for patient program
export const failedPatientProgramDocumentsForPatientProgram = (errors) => ({
  type: PATIENT_PROGRAM_DOCUMENTS_FOR_PATIENT_PROGRAM,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});