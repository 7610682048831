import React, {Component} from 'react';
import {connect} from 'react-redux';
import withStyles from "@material-ui/core/es/styles/withStyles";
import green from "@material-ui/core/es/colors/green";
import PatientProgramDocumentList from "../components/patient_program_document/PatientProgramDocumentList";
import {getPatientProgram} from "../services/PatientProgramService";
import Paper from "@material-ui/core/es/Paper/Paper";
import Typography from "@material-ui/core/es/Typography/Typography";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import Icon from "@material-ui/core/es/Icon/Icon";
import {withRouter} from 'react-router-dom'

const styles = theme => ({
  icon: {
    margin: theme.spacing.unit * 0.5,
  },
  button: {
    borderRadius: 0,
  },
  wrapper: {
    margin: 0,
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  text: {
    paddingTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  infoText: {
    paddingLeft: theme.spacing.unit * 2,
  },
  paper: {
    paddingBottom: 10,
  },
});

class PatientProgramDocumentPage extends Component {

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.refreshComponent = this.refreshComponent.bind(this);
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    this.refreshComponent();
  };

  /**
   * Refresh component
   */
  refreshComponent() {
    const {dispatch, match} = this.props;
    dispatch(getPatientProgram(match.params.patient_program_id));
  }

  /**
   * Go to previous page
   */
  goBack() {
    this.props.history.goBack();
  }

  /**
   * Prepare view
   *
   * @return {*}
   */
  render() {
    let {patient_program, classes, dispatch} = this.props;
    let {isFetching, data, success} = patient_program;
    return (
      <div className={classes.wrapper}>
        <AppBar position="fixed" color="primary">
          <Toolbar>
            <Icon className={classes.icon} color="inherit" fontSize="large" onClick={this.goBack}>
              arrow_back
            </Icon>
            <Typography variant="h6" color="inherit">
              Oncology App
            </Typography>
          </Toolbar>
        </AppBar>
        {
          success &&
          <div>
            <Paper square className={classes.paper}>
              <Typography className={classes.text} variant="h5" gutterBottom>
                {data.patient_program.program.name} ({data.patient_program.product.name})
              </Typography>
              <Typography className={classes.infoText} variant="caption" gutterBottom>
                Please upload specified documents
              </Typography>
            </Paper>
            <PatientProgramDocumentList
              refresh={this.refreshComponent}
              patient_program={data.patient_program}/>
          </div>
        }
        {isFetching && <CircularProgress size={24} className={classes.buttonProgress}/>}
      </div>
    )
  };
}

/**
 * Map state to properties
 *
 * @param state
 * @returns {{auth: *}}
 */
const mapStateToProps = state => {
  const {
    patientProgram
  } = state;

  return {
    patient_program: patientProgram
  };
};
export default connect(mapStateToProps)(withStyles(styles)(withRouter(PatientProgramDocumentPage)));
