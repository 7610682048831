import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  fetchPatientProgramDueMonthlyCounts,
  fetchPatientProgramForDueDate,
  fetchPatientPrograms,
  getPatientProgram
} from "../service/PatientProgramService";
import PatientProgramListComponent from "../components/PatientProgramListComponent";
import '../scss/patient_program.css';
import {fetchPatientMasters, getPatient} from "../service/PatientService";
import Grid from "@material-ui/core/es/Grid/Grid";
import Icon from "@material-ui/core/es/Icon/Icon";
import withStyles from "@material-ui/core/es/styles/withStyles";
import ListItem from "@material-ui/core/es/ListItem/ListItem";
import Avatar from "@material-ui/core/es/Avatar/Avatar";
import ListItemText from "@material-ui/core/es/ListItemText/ListItemText";
import green from "@material-ui/core/es/colors/green";
import {fetchPrograms} from "../service/ProgramService";
import {
  fetchFollowUps,
  fetchFollowUpsForMonth,
  fetchFollowUpsForPatientProgram,
  fetchFollowUpsMonthlyCounts
} from "../service/FollowUpService";
import FollowUpListComponent from "../components/follow_up/FollowUpListComponent";
import Tabs from "@material-ui/core/es/Tabs/Tabs";
import Tab from "@material-ui/core/es/Tab/Tab";
import Paper from "@material-ui/core/es/Paper/Paper";
import FollowUpCalendarComponent from "../components/follow_up/FollowUpCalendarComponent";
import moment from "moment";
import {PatientProgramConstant} from "../PatientProgramConstant";
import NoContent from "../../common/components/common/NoContent";
import Button from "@material-ui/core/es/Button/Button";
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import List from "@material-ui/core/es/List/List";
import {fetchOrdersForPatient, findUsers} from "../service/OrderService";
import ListItemSecondaryAction from "@material-ui/core/es/ListItemSecondaryAction/ListItemSecondaryAction";
import PatientProgramSendForApprovalComponent
  from "../components/patient_program_document/PatientProgramSendForApprovalComponent";
import PatientProgramDetailComponent from "../components/PatientProgramDetailComponent";
import PatientProgramStatusUpdateFormComponent
  from "../components/patient_program/PatientProgramStatusUpdateFormComponent";
import FollowUpFormComponent from "../components/follow_up/FollowUpFormComponent";
import {withSnackbar} from 'notistack';
import {fetchProducts} from "../service/ProductService";
import {fetchInvoicesForPatientProgram} from "../service/InvoiceService";
import CallPatientComponent from "../components/patient_program/CallPatientComponent";
import PatientProgramDueListComponent from "../components/PatientProgramDueListComponent";

const styles = theme => ({
  icon: {
    margin: theme.spacing.unit * 0.5,
  },
  button: {
    borderRadius: 0,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  tabContainer: {
    flexGrow: 1,
  },
  fullHeight: {
    minHeight: "100%",
    backgroundColor: "#fafafa"
  }
});


class MainPage extends Component {

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    let date = moment();
    this.state = {
      value: 0,
      sub_menu_value: 0,
      date: date.date(),
      month: date.month(),
      year: date.year(),
      selected_follow_up: {id: null},
      patient_program: {id: null},
      create_follow_up: false,
      update_patient_program_status: false
    };

    // attach methods
    this.selectPatientProgramCallback = this.selectPatientProgramCallback.bind(this);
    this.refreshPatientProgram = this.refreshPatientProgram.bind(this);
    this.refreshPatient = this.refreshPatient.bind(this);
    this.toggleUpdatePatientProgramStatusFlag = this.toggleUpdatePatientProgramStatusFlag.bind(this);
    this.sendForApprovalSuccessCallback = this.sendForApprovalSuccessCallback.bind(this);
    this.followUpCreatedCallback = this.followUpCreatedCallback.bind(this);
    this.refreshCalendar = this.refreshCalendar.bind(this);
    this.selectFollowUpCalendarMonthCallback = this.selectFollowUpCalendarMonthCallback.bind(this);
    this.selectFollowUpCalendarDateCallback = this.selectFollowUpCalendarDateCallback.bind(this);
    this.patientCallSuccessCallback = this.patientCallSuccessCallback.bind(this);
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    const {dispatch} = this.props;

    // request for all patient programs and programs
    dispatch(fetchPrograms());
    dispatch(fetchProducts());
    dispatch(fetchPatientPrograms('', 1, 250));
    dispatch(fetchFollowUps('', 1, 50));
    dispatch(fetchFollowUpsMonthlyCounts(this.state.month + 1, this.state.year));
    dispatch(fetchPatientProgramDueMonthlyCounts(this.state.month + 1, this.state.year));
    dispatch(fetchFollowUpsForMonth(this.state.date, this.state.month + 1, this.state.year));
  };

  /**
   * Patient program toggle
   */
  toggleUpdatePatientProgramStatusFlag = () => {
    this.setState({...this.state, update_patient_program_status: !this.state.update_patient_program_status});
  };

  /**
   * Refresh patient program
   */
  refreshPatientProgram() {
    const {dispatch} = this.props;
    dispatch(getPatientProgram(this.state.patient_program.id));
    dispatch(fetchFollowUpsForPatientProgram(this.state.patient_program.id));
    dispatch(fetchInvoicesForPatientProgram(this.state.patient_program.id));
    dispatch(fetchOrdersForPatient(this.state.patient_program.id));
  }

  /**
   * Refresh patient details
   */
  refreshPatient() {
    const {dispatch} = this.props;
    const {patient_program} = this.state;
    dispatch(getPatient(patient_program.patient_id));
  }

  /**
   * Fetch patient programs
   *
   * @param q
   * @param page
   */
  refreshPatientPrograms(q, page = 1) {
    const {dispatch} = this.props;
    dispatch(fetchPatientPrograms(q, page, 250));
  }

  /**
   * Select patient program callback
   */
  selectPatientProgramCallback(patient_program) {
    this.setState({
      patient_program: patient_program,
      value: 1
    });
    this.patientProgramChanged(patient_program);
  }

  /**
   * Reload data on patient program changed
   * @param patient_program
   */
  patientProgramChanged(patient_program) {
    const {dispatch} = this.props;
    dispatch(getPatient(patient_program.patient_id));
    dispatch(getPatientProgram(patient_program.id));
    dispatch(fetchFollowUpsForPatientProgram(patient_program.id));
    dispatch(findUsers());
    dispatch(fetchPatientMasters());
    dispatch(fetchInvoicesForPatientProgram(patient_program.id));
    dispatch(fetchOrdersForPatient(patient_program.patient_id));
  }

  /**
   * Select patient program callback
   */
  selectFollowUpCallback(follow_up) {
    this.setState({
      ...this.state,
      selected_follow_up: follow_up,
      patient_program: follow_up.patient_program
    });
    this.patientProgramChanged(follow_up.patient_program);
  }

  /**
   * Select date callback
   * @param date
   */
  selectFollowUpCalendarDateCallback(date) {
    const {dispatch} = this.props;
    this.setState({
      ...this.state,
      date: date
    });
    dispatch(fetchFollowUpsForMonth(date, this.state.month + 1, this.state.year));
    dispatch(fetchPatientProgramForDueDate(date, this.state.month + 1, this.state.year));
  }

  /**
   * Select follow up calendar month callback
   *
   * @param month
   */
  selectFollowUpCalendarMonthCallback(month) {
    const {dispatch} = this.props;
    this.setState({
      ...this.state,
      month: month
    });
    dispatch(fetchFollowUpsForMonth(this.state.date, month, this.state.year));
  }

  /**
   * on tab value change
   *
   * @param event
   * @param value
   */
  handleChange = (event, value) => {
    this.setState({...this.state, value: value});
  };

  /**
   * on tab value change
   *
   * @param event
   * @param value
   */
  handleDueChange = (event, value) => {
    this.setState({...this.state, sub_menu_value: value});
  };

  /**
   * Toggle create follow up
   */
  toggleCreateFollowUp() {
    this.setState({...this.state, create_follow_up: !this.state.create_follow_up})
  }

  /**
   * Follow up created callback
   */
  followUpCreatedCallback() {
    const {dispatch, enqueueSnackbar} = this.props;
    this.setState({...this.state, create_follow_up: !this.state.create_follow_up});
    dispatch(fetchFollowUpsForPatientProgram(this.state.patient_program.id));
    enqueueSnackbar('Follow up created successfully!');
  }

  /**
   * Refresh calendar
   */
  refreshCalendar() {
    const {dispatch} = this.props;
    dispatch(fetchFollowUpsForMonth(this.state.date, this.state.month + 1, this.state.year));
  }

  /**
   * Send for approval success callback
   */
  sendForApprovalSuccessCallback() {
    this.refreshPatientProgram();
  }

  /**
   * Update patient program success callback
   */
  updatePatientProgramSuccessCallback = () => {
    this.toggleUpdatePatientProgramStatusFlag();
    this.refreshPatientProgram();
  };

  /**
   * Patient call back successfully
   */
  patientCallSuccessCallback = () => {
    const {enqueueSnackbar} = this.props;
    enqueueSnackbar('Call set up successfully!');
  };

  /**
   * Prepare view
   *
   * @return {*}
   */
  render() {
    const {value, create_follow_up, update_patient_program_status, sub_menu_value} = this.state;
    let {
      dispatch, classes,
      patient_program: {success: patient_program_success, data: patient_program_data},
      patient_masters: {success: patient_masters_success, data: patient_masters_data}
    } = this.props;
    return (
      <Grid container spacing={0} className={classes.fullHeight}>
        <Grid item md={3} sm={3} className={classes.fullHeight}>
          <PatientProgramListComponent patientPrograms={this.props.patient_programs}
                                       programs={this.props.programs}
                                       patient_program={this.state.patient_program}
                                       selectPatientProgram={this.selectPatientProgramCallback}
                                       onSearch={this.refreshPatientPrograms.bind(this)}/>
        </Grid>
        <Grid item md={6} sm={6} className="program-details">
          {
            !this.state.patient_program.id &&
            <NoContent label="Please select program to view details"/>
          }
          {
            this.props.patient.success &&
            patient_program_success &&
            <List>
              <ListItem>
                <Avatar>
                  <CallPatientComponent patient_program={this.props.patient_program.data.patient_program}
                                        successCallback={this.patientCallSuccessCallback}
                                        failureCallback={() => {

                                        }}/>
                </Avatar>
                <ListItemText primary={
                  `${this.props.patient.data.patient.fullName}
                  (${this.props.patient.data.patient.mobile})`
                } secondary={
                  <React.Fragment>
                    Status: {this.props.patient_program.data.patient_program.status}
                  </React.Fragment>}/>
              </ListItem>
              <ListItemSecondaryAction>
                {
                  !update_patient_program_status &&
                  <Button size="small" color="primary" variant="outlined"
                          onClick={this.toggleUpdatePatientProgramStatusFlag}>
                    Update status
                  </Button>
                }
                <PatientProgramSendForApprovalComponent successCallback={this.sendForApprovalSuccessCallback}
                                                        failureCallback={() => {
                                                        }} patient_program={patient_program_data.patient_program}/>
              </ListItemSecondaryAction>
            </List>
          }

          {
            patient_program_success && patient_masters_success &&
            <div>
              {/* Status update */}
              {
                update_patient_program_status &&
                <PatientProgramStatusUpdateFormComponent
                  patient_program={patient_program_data.patient_program}
                  failureCallback={() => {
                  }}
                  successCallback={this.updatePatientProgramSuccessCallback}
                  handleClose={this.toggleUpdatePatientProgramStatusFlag}/>
              }
              {/* ./Status update */}

              <PatientProgramDetailComponent
                refreshPatientProgram={this.refreshPatientProgram}
                refreshPatient={this.refreshPatient}
                patient_program={this.props.patient_program.data.patient_program}
                products={this.props.products}
                document_groups={patient_masters_data.documentGroups}
                patient={this.props.patient}
                cfa_list={this.props.cfa_list}
                ordersForPatient={this.props.ordersForPatient}
                invoicesForPatientProgram={this.props.invoicesForPatientProgram}/>
            </div>
          }
        </Grid>
        <Grid item md={3} sm={3}>
          <Paper square className={classes.tabContainer} elevation={0}>
            <AppBar position="static" elevation={0}>
              <Tabs value={value} onChange={this.handleChange}>
                <Tab value={1}
                     label={(this.props.patient.success ? PatientProgramConstant.getMobileOrName(this.props.patient.data.patient) + "'s " : "") + "Follow ups"}/>
                <Tab value={0} label="All follow ups"/>
              </Tabs>
            </AppBar>
            {
              value === 0 &&
              <FollowUpCalendarComponent follow_ups={this.props.follow_up_monthly_counts}
                                         duePatientProgramCounts={this.props.patientProgramDueCounts}
                                         date={this.state.date}
                                         month={this.state.month}
                                         year={this.state.year}
                                         selectFollowUpMonth={this.selectFollowUpCalendarMonthCallback}
                                         selectFollowUpDate={this.selectFollowUpCalendarDateCallback}/>
            }
            <AppBar position="static" elevation={0}>
              <Tabs value={sub_menu_value} onChange={this.handleDueChange}>
                <Tab value={0} label="Follow ups"/>
                <Tab value={1} label="Dues"/>
              </Tabs>
            </AppBar>
            {
              value === 0 && sub_menu_value === 0
              &&
              <div>
                <FollowUpListComponent
                  title={PatientProgramConstant.getDate(this.state.date, this.state.month, this.state.year)}
                  follow_ups={this.props.follow_ups_for_date}
                  selected_follow_up={this.state.selected_follow_up}
                  refresh={this.refreshCalendar}
                  selectFollowUp={this.selectFollowUpCallback.bind(this)}/>
              </div>
            }
            {
              value === 1 && sub_menu_value === 0
              &&
              <div>
                {
                  create_follow_up &&
                  <FollowUpFormComponent close={this.toggleCreateFollowUp.bind(this)}
                                         patient_program={this.state.patient_program}
                                         successCallback={this.followUpCreatedCallback}
                                         failureCallback={() => {
                                         }}/>
                }
                {
                  !create_follow_up &&
                  <Button variant="outlined" color="primary" className={classes.button}
                          onClick={this.toggleCreateFollowUp.bind(this)} fullWidth={true}>
                    <Icon color="primary" fontSize="small">
                      add
                    </Icon>Add new follow up
                  </Button>
                }
              </div>
            }
            {
              value === 1 && sub_menu_value === 0
              &&
              <FollowUpListComponent follow_ups={this.props.follow_ups_for_patient_program}
                                     refresh={this.refreshCalendar}
                                     selected_follow_up={this.state.selected_follow_up}
                                     selectFollowUp={this.selectFollowUpCallback.bind(this)}/>
            }
            {
              sub_menu_value === 1
              && this.renderDuePatientPrograms()
            }
          </Paper>
        </Grid>
      </Grid>
    )
  };

  /**
   * Render due patient programs
   */
  renderDuePatientPrograms() {
    const {patient_program} = this.state;
    const {success, data} = this.props.patientProgramsForDueDate;
    if (!success)
      return "";
    return <PatientProgramDueListComponent
      title={PatientProgramConstant.getDate(this.state.date, this.state.month, this.state.year)}
      patientPrograms={data.patient_programs}
      patient_program={patient_program}
      selectPatientProgram={this.selectPatientProgramCallback}/>
  }
}

/**
 * Map state to properties
 *
 * @param state
 * @returns {{auth: *}}
 */
const mapStateToProps = state => {
  const {
    programs,
    products,
    patientPrograms,
    patientProgram,
    patient,
    updatePatient,
    updateDocument,
    updatePatientProgram,
    updatePatientProgramDocument,
    followUps,
    followUpsForPatientProgram,
    followUpsForDate,
    followUpsMonthlyCounts,
    patientMasters,
    cfaList,
    invoicesForPatientProgram,
    ordersForPatient,
    patientProgramDueCounts,
    patientProgramsForDueDate
  } = state;

  return {
    programs: programs,
    products: products,
    patient_programs: patientPrograms,
    patient_program: patientProgram,
    patient: patient,
    updatePatient: updatePatient,
    updateDocument: updateDocument,
    updatePatientProgram: updatePatientProgram,
    updatePatientProgramDocument: updatePatientProgramDocument,
    follow_ups: followUps,
    follow_ups_for_patient_program: followUpsForPatientProgram,
    follow_ups_for_date: followUpsForDate,
    follow_up_monthly_counts: followUpsMonthlyCounts,
    cfa_list: cfaList,
    patient_masters: patientMasters,
    invoicesForPatientProgram: invoicesForPatientProgram,
    ordersForPatient: ordersForPatient,
    patientProgramDueCounts: patientProgramDueCounts,
    patientProgramsForDueDate: patientProgramsForDueDate
  };
};
export default connect(mapStateToProps)(withStyles(styles)(withSnackbar(MainPage)));
