import React, {Component} from 'react'
import PropTypes from "prop-types";
import "../../scss/patient_program.css";
import Grid from "@material-ui/core/es/Grid/Grid";
import PatientProgramDocumentComponent from "./PatientProgramDocumentComponent";
import PatientProgramDocumentFormComponent from "./PatientProgramDocumentFormComponent";
import EmptySpace from "../../../common/components/common/EmptySpace";
import PatientProgramDocumentUpload from "./PatientProgramDocumentUpload";
import withStyles from "@material-ui/core/es/styles/withStyles";
import {withSnackbar} from 'notistack';

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
});

/**
 * Patient documents
 *
 * @param patient
 * @return {*}
 * @constructor
 */
class PatientProgramDocumentsComponent extends Component {

  /**
   * Document delete success callback
   */
  documentDeleteSuccessCallback = () => {
    let {enqueueSnackbar, refreshPatientProgram} = this.props;
    enqueueSnackbar('Document removed successfully!');
    refreshPatientProgram();
  };

  /**
   * Patient program document updated success callback
   */
  patientProgramDocumentAdditionSuccessCallback = () => {
    let {enqueueSnackbar, refreshPatientProgram} = this.props;
    enqueueSnackbar('Document added successfully!');
    refreshPatientProgram();
  };

  /**
   * Image upload success callback
   */
  imageUploadSuccessCallback = () => {
    let {enqueueSnackbar, refreshPatientProgram} = this.props;
    enqueueSnackbar('Image uploaded successfully!');
    refreshPatientProgram();
  };


  //render content
  render() {
    const {
      patient_program, documents, refreshPatientProgram, enqueueSnackbar, document_groups,
      products: {data: {products: productList}}
    } = this.props;
    return <div className="program-documents">
      <Grid container spacing={8}>
        <Grid container item xs={4}>
          <PatientProgramDocumentFormComponent
            document_groups={document_groups}
            patient_program_id={patient_program.id}
            added={refreshPatientProgram}
            successCallback={this.patientProgramDocumentAdditionSuccessCallback}
            failureCallback={() => {
            }}
          />
        </Grid>
        {
          documents &&
          documents.map((d, i) => {
            if (d.document_id) {
              return <Grid item xs={4} key={i}>
                <PatientProgramDocumentComponent document={d}
                                                 productList={productList}
                                                 enqueueSnackbar={enqueueSnackbar}
                                                 refreshPatientProgram={refreshPatientProgram}
                                                 successCallback={this.documentDeleteSuccessCallback}
                                                 failureCallback={() => {
                                                 }}/>
              </Grid>
            }
            return <Grid item xs={4} key={i}>
              <PatientProgramDocumentUpload patient_document={d}
                                            successCallback={this.imageUploadSuccessCallback}
                                            failureCallback={() => {
                                            }}
                                            patient_id={patient_program.patient_id}/>
            </Grid>
          })
        }
      </Grid>
      <EmptySpace/>
    </div>;
  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
PatientProgramDocumentsComponent
  .propTypes = {
  patient_program: PropTypes.object.isRequired,
  products: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  document_groups: PropTypes.array.isRequired,
  documents: PropTypes.array,
  refreshPatientProgram: PropTypes.func.isRequired
};

export default withSnackbar(withStyles(styles)(PatientProgramDocumentsComponent));