import React from 'react';
import './scss/footer.css';
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import withStyles from "@material-ui/core/es/styles/withStyles";
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/es/Typography/Typography";

const styles = theme => ({
  appBar: {
    top: 'auto',
    bottom: 0,
  },
});

function Footer(props) {
  const {classes} = props;
  return (
    <AppBar position="fixed" color="default" className={classes.appBar}>
      <Toolbar>
        <Typography variant="h6" color="inherit">
          GoApptiv pvt. ltd.
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);