import React, {Component} from 'react'
import withStyles from "@material-ui/core/es/styles/withStyles";
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/es/Typography/Typography";
import Grid from "@material-ui/core/es/Grid/Grid";
import Button from "@material-ui/core/es/Button/Button";
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import green from "@material-ui/core/es/colors/green";
import CardActions from "@material-ui/core/es/CardActions/CardActions";
import Card from "@material-ui/core/es/Card/Card";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import TextField from "@material-ui/core/es/TextField/TextField";
import OutlinedInput from "@material-ui/core/es/OutlinedInput/OutlinedInput";
import Select from "@material-ui/core/es/Select/Select";
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import InputLabel from "@material-ui/core/es/InputLabel/InputLabel";
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";
import {withSnackbar} from 'notistack';
import moment from "moment/moment";
import {DatePicker, MuiPickersUtilsProvider} from 'material-ui-pickers';
import DateFnsUtils from "@date-io/date-fns/build/index";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  card: {
    borderRadius: 0
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

/**
 * Mark dispatched form
 *
 * @return {*}
 * @constructor
 */
class MarkDispatchedForm extends Component {

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    let m = moment();
    let date = m.format("YYYY-MM-DD");
    this.state = {
      saving: false,
      order: {
        vendor_id: '',
        vendor_str: '',
        lr_number: '',
        dispatch_date: date,
        picker_date: new Date()
      }
    }
  }

  /**
   * On value change
   *
   * @param name
   */
  onFieldChange = name => event => {
    this.setState({
      ...this.state,
      order: {
        ...this.state.order,
        [name]: event.target.value
      }
    });
  };

  /**
   * Toggle saving
   */
  toggleSaving = () => {
    this.setState({
      ...this.state,
      saving: !this.state.saving
    });
  };

  /**
   * Handle date change
   * @param date
   */
  handleDateChange = date => {
    let m = moment(date);
    let only_date = m.format("YYYY-MM-DD");
    this.setState({
      ...this.state,
      order: {
        ...this.state.order,
        dispatch_date: only_date,
        picker_date: date
      }
    });
  };

  /**
   * Update document
   */
  dispatch = () => {
    this.toggleSaving();
    let {close, done, handlers, order} = this.props;
    handlers.dispatchOrder(order.id, this.state.order).then(json => {
      this.setState({
        ...this.state,
        saving: !this.state.saving,
      });
      this.props.enqueueSnackbar('Order dispatched successfully!');
      done();
      close();
    });
  };

  /**
   * Render html
   * @return {*}
   */
  render() {
    const {classes, close, vendors: {success, data}} = this.props;
    const {saving, order} = this.state;

    return <Card className={classes.card}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Typography variant="h6" color="inherit">
            Dispatch details form
          </Typography>
        </Toolbar>
      </AppBar>
      <CardContent>
        <form noValidate autoComplete="off">
          <Grid container spacing={8}>
            <Grid item xs={12} sm={6} md={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  margin="dense"
                  label="Dispatch date"
                  value={order.picker_date}
                  variant="outlined"
                  fullWidth={true}
                  format={"dd MMM` yyyy"}
                  className={classes.picker}
                  onChange={this.handleDateChange}/>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              {
                success &&
                <FormControl variant="outlined" className={classes.formControl} fullWidth={true} margin="dense">
                  <InputLabel ref={ref => {
                    this.InputLabelRef = ref;
                  }} htmlFor="vendor">Vendor</InputLabel>
                  <Select value={order.vendor_id} onChange={this.onFieldChange("vendor_id")}
                          input={
                            <OutlinedInput labelWidth={0} name="Vendor"
                                           id="vendor"/>
                          }>
                    {
                      data.vendors.map((d, i) => {
                        return <MenuItem value={d.id} key={i}>{d.name}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
              }
            </Grid>
            {
              order.vendor_id === 3 &&
              <Grid item xs={12} sm={6} md={6}>
                <TextField id="vendor_str"
                           label="Vendor name"
                           className={classes.textField}
                           value={order.vendor_str}
                           onChange={this.onFieldChange('vendor_str')}
                           margin="dense" fullWidth={true}
                           variant="outlined"/>
              </Grid>
            }
            <Grid item xs={12} sm={6} md={6}>
              <TextField id="lr_number"
                         label="Lr Number"
                         className={classes.textField}
                         value={order.lr_number}
                         onChange={this.onFieldChange('lr_number')}
                         margin="dense" fullWidth={true}
                         variant="outlined"/>
            </Grid>
          </Grid>
        </form>
      </CardContent>
      <CardActions dir="rtl">
        <Button variant="outlined" color="secondary" onClick={close} disabled={saving}>
          Close
        </Button>
        <div className={classes.wrapper}>
          <Button variant="contained" color="primary" disabled={saving} onClick={this.dispatch}>
            {saving ? "Marking dispatched" : "Mark dispatched"}
          </Button>
          {saving && <CircularProgress size={24} className={classes.buttonProgress}/>}
        </div>
      </CardActions>
    </Card>;
  }
}


/**
 * Properties
 *
 * @type {{fetch: *}}
 */
MarkDispatchedForm.propTypes = {
  order: PropTypes.object.isRequired,
  vendors: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  handlers: PropTypes.object.isRequired,
  done: PropTypes.func.isRequired
};

export default withSnackbar(withStyles(styles)(MarkDispatchedForm));