import React, {Component} from 'react'
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/es/styles/withStyles";
import green from "@material-ui/core/es/colors/green";
import CardActionArea from "@material-ui/core/es/CardActionArea/CardActionArea";
import CardMedia from "@material-ui/core/es/CardMedia/CardMedia";
import Card from "@material-ui/core/es/Card/Card";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import Typography from "@material-ui/core/es/Typography/Typography";
import upload from '../../images/upload.png';
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import {uploadPatientDocument} from "../services/PatientService";
import restCallController from "../../common/hoc/forms/restCallController";
import {updatePatientProgramDocument} from "../services/PatientProgramDocumentService";

const styles = theme => ({
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  media: {
    height: 140,
    backgroundPosition: "center",
    backgroundSize: "auto"
  },
  card: {
    borderRadius: 0,
    margin: "10px"
  },
  input: {
    display: 'none',
  },
});

/**
 * Patient follow up form
 *
 * @param patient
 * @return {*}
 * @constructor
 */
class PatientProgramDocumentUpload extends Component {

  /**
   * Upload file
   *
   * @param e
   */
  onChange = e => {
    const {patient_id, document, handlePromise} = this.props;

    // get file
    const files = Array.from(e.target.files);

    // prepare form
    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('data', new Blob([JSON.stringify({
      "documentGroup": document.document_group,
      "documentType": document.document_type
    })], {
      type: "application/json"
    }));

    // upload file
    uploadPatientDocument(patient_id, formData).then(res => {
      handlePromise(updatePatientProgramDocument(document.id, res.document));
    }).catch(errors => {
      console.log("Errors: " + errors);
    });
  };

  //render content
  render() {
    let {document, classes, isFetching} = this.props;
    return (
      <Card className={classes.card + " " + classes.wrapper}>
        <CardActionArea>
          <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            multiple
            type="file"
            onChange={this.onChange}/>
          <label htmlFor="contained-button-file">
            <CardMedia
              className={classes.media}
              image={upload}
              title="Document"/>
          </label>

          <CardContent>
            <Typography gutterBottom variant="body2" component="h6">
              {document.document_type ? document.document_type : "NA"}
            </Typography>
            <Typography component="p" variant="caption">
              {document.document_group ? document.document_group : "NA"}
            </Typography>
          </CardContent>
        </CardActionArea>
        {isFetching && <CircularProgress size={24} className={classes.buttonProgress}/>}
      </Card>
    );
  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
PatientProgramDocumentUpload.propTypes = {
  patient_id: PropTypes.string.isRequired,
  document: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  successCallback: PropTypes.func.isRequired,
  failureCallback: PropTypes.func.isRequired
};

export default restCallController(withStyles(styles)(PatientProgramDocumentUpload));
