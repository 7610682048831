import React from 'react';
import withStyles from "@material-ui/core/es/styles/withStyles";
import PropTypes from 'prop-types';
import Chip from "@material-ui/core/es/Chip/Chip";
import Avatar from "@material-ui/core/es/Avatar/Avatar";
import red from "@material-ui/core/es/colors/red";

const styles = theme => ({
  root: {
    padding: 10
  },
  chip: {
    margin: 0,
    color: "#fff"
  },
  pending: {
    color: "#f44336",
    backgroundColor: "#ffcdd2"
  },
  delivered: {
    color: "#4caf50",
    backgroundColor: "#c8e6c9"
  },
  dispatch: {
    color: "#03a9f4",
    backgroundColor: "#b3e5fc"
  },
  packing: {
    color: "#ff9800",
    backgroundColor: "#ffe0b2"
  }
});

/**
 * Status to string
 */
function statusToString(status) {
  if (status === 'pending')
    return "Pending";
  if (status === 'accept')
    return "Accepted";
  if (status === 'delivered')
    return "Delivered";
  if (status === 'dispatch')
    return "Dispatched";
  if (status === 'packing')
    return "Packing";
}

function OrderStatus(props) {
  const {classes, status} = props;


  // prepare css for status
  let color;
  if (status === 'pending')
    color = classes.pending;
  if (status === 'accept')
    color = classes.pending;
  if (status === 'delivered')
    color = classes.delivered;
  if (status === 'dispatch')
    color = classes.dispatch;
  if (status === 'packing')
    color = classes.packing;

  // status label
  let label = statusToString(status);
  // render
  return (
    <Chip label={label}
          className={classes.chip + " " + color}
          component="a"
          clickable/>
  );
}

OrderStatus.propTypes = {
  classes: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
};

export default withStyles(styles)(OrderStatus);