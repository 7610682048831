import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/es/Typography/Typography";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Card from "@material-ui/core/es/Card/Card";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import Chip from "@material-ui/core/es/Chip/Chip";
import Constants from "../../../../../Constants";
import TableRow from "@material-ui/core/es/TableRow/TableRow";
import TableCell from "@material-ui/core/es/TableCell/TableCell";

const styles = (theme) => ({
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  chip: {
    margin: theme.spacing.unit,
  },
});

/**
 * Patient personal detail
 *
 * @param patient
 * @return {*}
 * @constructor
 */
function OrderCardItem({ order, orderItem, classes }) {
  // render
  return (
    <TableRow key={orderItem.id}>
      <TableCell align="right">{orderItem.item}</TableCell>
      <TableCell align="right">{orderItem.quantity}</TableCell>
    </TableRow>
  );
}

/**
 * Properties which can be passed to component
 *
 * @type {{}}
 */
OrderCardItem.propTypes = {
  order: PropTypes.object.isRequired,
  orderItem: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OrderCardItem);
