import {get, post, put} from "../../common/services/BaseService";
import {PatientProgramConstant} from "../PatientProgramConstant";
import {
  invoiceListForPatientProgramRefreshFailed,
  invoiceListForPatientProgramRefreshSucceeded,
  runningInvoiceListForPatientProgramRefresh
} from "../reducers/actions/invoice-actions";


/**
 * Create invoice
 *
 * @param invoice
 * @return {Promise<Response>}
 */
export const createInvoice = (invoice) => {
  return post(`${PatientProgramConstant.INVOICES_URL}`, invoice).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};

/**
 * Update invoice
 *
 * @param id
 * @param invoice
 * @return {Promise<Response>}
 */
export const updateInvoice = (id, invoice) => {
  return put(`${PatientProgramConstant.INVOICES_URL}/${id}`, invoice).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};

/**
 * Get invoices for patient program
 *
 * @return {function(*): (Q.Promise<any> | Promise<T> | *)}
 * @param patientProgramId
 */
export const fetchInvoicesForPatientProgram = (patientProgramId) => dispatch => {
  dispatch(runningInvoiceListForPatientProgramRefresh());
  return get(`${PatientProgramConstant.INVOICES_URL}/forPatientProgram/${patientProgramId}`).then(json => {
    dispatch(invoiceListForPatientProgramRefreshSucceeded(json));
    return json;
  }).catch(error => {
    dispatch(invoiceListForPatientProgramRefreshFailed(error));
    throw(error);
  });
};

