import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import theme from "../../app_theme";

const useStyles = makeStyles({
  root: {
    margin: 25,
    border: "1px solid #EBE7E3",
    borderRadius: "4px",
  },
  mainHeading: {
    fontSize: "20px",
    fontWeight: 500,
    fontFamily: "Roboto",
    margin: 25,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Roboto",
  },
  subTitle: {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Roboto",
    color: "#6B6B6B",
  },
  subHeader: {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Roboto",
    color: "#6B6B6B",
  },
  address: {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Roboto",
    marginLeft: 15,
  },
  sideHeading: {
    display: "flex",
    gap: 5,
  },
  sideHeadingText: {
    fontSize: "12px",
    fontWeight: 500,
    fontFamily: "Roboto",
    color: "#49454F",
  },
  footer: {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Roboto",
    marginLeft: 15,
    paddingBlock: 10,
  },
  button: {
    borderRadius: "50px",
    padding: "12px",
    position: "fixed",
    bottom: 20,
    right: 20,
    left: 180,
  },
});

const EnrolledCard = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.root}>
        <CardHeader
          title={
            <div className={classes.header}>
              <Typography className={classes.title}>Rajashree Joshi</Typography>
              <Typography className={classes.subTitle}>#312-413</Typography>
            </div>
          }
          subheader={
            <Typography className={classes.subHeader}>7893399076</Typography>
          }
        />
        <Typography className={classes.address}>
          Mumbai, Maharashtra - 400076
        </Typography>
        <CardContent>
          <div className={classes.sideHeading}>
            <Typography className={classes.subTitle}>Enrolled Date:</Typography>
            <Typography className={classes.sideHeadingText}>
              24th Sept, 2022
            </Typography>
          </div>
          <div className={classes.sideHeading}>
            <Typography className={classes.subTitle}>Product:</Typography>
            <Typography className={classes.sideHeadingText}>
              Becosule
            </Typography>
          </div>
        </CardContent>
        <Divider />

        <Typography className={classes.footer}>
          Referred By Dr. Doctor Name
        </Typography>
      </Card>
    </ThemeProvider>
  );
};

export default EnrolledCard;
