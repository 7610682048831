import React, {Component} from 'react'
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/es/styles/withStyles";
import restCallController from "../../../common/hoc/forms/restCallController";
import {callPatient} from "../../service/PatientProgramService";
import Icon from "@material-ui/core/es/Icon/Icon";


const styles = theme => ({
  icon: {
    margin: theme.spacing.unit * 0.5,
  },
});


/**
 * Update patient program form
 *
 * @param patient
 * @return {*}
 * @constructor
 */
class CallPatientComponent extends Component {


  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
  }

  /**
   * Update patient
   */
  call = () => {
    let {handlePromise, patient_program} = this.props;
    handlePromise(callPatient(patient_program.id));
  };


  /**
   * Render view
   * @return {*}
   */
  render() {
    const {classes} = this.props;
    return (
      <Icon color="primary" fontSize="small" onClick={this.call} className={classes.icon}>
        phone
      </Icon>
    );
  }
}

CallPatientComponent.propTypes = {
  patient_program: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  successCallback: PropTypes.func.isRequired,
  failureCallback: PropTypes.func.isRequired
};

export default restCallController((withStyles(styles)(CallPatientComponent)));
