import React, { Component } from "react";
import withStyles from "@material-ui/core/es/styles/withStyles";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/es/Typography/Typography";
import Button from "@material-ui/core/es/Button/Button";
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import green from "@material-ui/core/es/colors/green";
import CardActions from "@material-ui/core/es/CardActions/CardActions";
import Card from "@material-ui/core/es/Card/Card";
import moment from "moment/moment";
import Table from "@material-ui/core/es/Table/Table";
import TableRow from "@material-ui/core/es/TableRow/TableRow";
import TableCell from "@material-ui/core/es/TableCell/TableCell";
import { OrderConstants } from "../OrderConstants";
import TableBody from "@material-ui/core/es/TableBody/TableBody";
import TableHead from "@material-ui/core/es/TableHead/TableHead";
import ReactToPrint from "react-to-print";
import Constants from "../../Constants";

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 19,
  },
  moreDense: {
    marginTop: 40,
  },
  moreDenseSmall: {
    marginTop: 10,
  },
  menu: {
    width: 200,
  },
  box: {
    backgroundColor: "#fff",
    borderRadius: 0,
    maxHeight: 500,
    overflowY: "scroll",
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: 0,
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  cardBody: {
    backgroundColor: "#fff",
    paddingTop: 30,
    paddingLeft: 50,
    paddingRight: 50,
  },
  address: {
    width: "250px",
  },
  header: {
    textAlign: "center",
  },
});

/**
 * Address label
 *
 * @return {*}
 * @constructor
 */
class DeliveryChallan extends Component {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      delivery_date: "",
      picker_date: new Date(),
    };
  }

  /**
   * Handle date change
   * @param date
   */
  handleDateChange = (date) => {
    let m = moment(date);
    let only_date = m.format("YYYY-MM-DD");
    this.setState({
      ...this.state,
      delivery_date: only_date,
      picker_date: date,
    });
  };

  /**
   * Toggle saving
   */
  toggleSaving = () => {
    this.setState({
      ...this.state,
      saving: !this.state.saving,
    });
  };

  /**
   * Render html
   * @return {*}
   */
  render() {
    const { classes, close, order } = this.props;

    return (
      <div className={classes.box}>
        <Card className={classes.card} ref={(el) => (this.componentRef = el)}>
          <AppBar position="static" color="white">
            <Toolbar variant="dense">
              <img src="/images/letter-head.png" alt="logo" width="100%" />
            </Toolbar>
            {/* <Toolbar variant="dense">
            <Typography variant="h6" color="inherit">
              Delivery Challan
            </Typography>
          </Toolbar> */}
          </AppBar>
          <CardContent className={classes.cardBody}>
            <div className={classes.header}>
              <Typography
                align="center"
                variant="title"
                className={classes.dense}
              >
                Delivery Challan
              </Typography>
            </div>
            <Typography className={classes.dense}>
              Date: {order.order_date}
            </Typography>
            <Typography className={classes.dense}>
              FGO No.: {order.order_no}
            </Typography>
            <Typography
              align="center"
              variant="title"
              className={classes.dense}
            >
              To whomsoever it may concern
            </Typography>
            <Typography align="center">
              This is to certify that the box contains Promotional items and is
              being sent to the following address.
            </Typography>
            <Typography align="left" className={classes.dense}>
              To,
            </Typography>
            <Typography align="left" className={classes.dense}>
              {order.order_for}
            </Typography>
            <Typography align="left" className={classes.address}>
              {order.address && OrderConstants.addressToString(order.address)}
            </Typography>
            <Typography align="left">Phone no.- {order.contact_no}</Typography>
            <Typography align="left" className={classes.dense}>
              {""}
            </Typography>
            <Typography align="left" className={classes.dense}>
              We further declare that this is not for sale and there is no
              commercial value.
            </Typography>
            <Typography align="left" className={classes.dense}>
              {""}
            </Typography>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell component="th">SKU with strength</TableCell>
                  <TableCell component="th">Quantity</TableCell>
                  <TableCell component="th">Batch no</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.items.map((oi) => {
                  return (
                    <TableRow>
                      <TableCell component="th">{oi.item}</TableCell>
                      <TableCell component="th">{oi.quantity}</TableCell>
                      <TableCell>{oi.batch_no}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Typography align="left" className={classes.moreDense}>
              Authorised signatory
            </Typography>
            <Typography align="left" className={classes.moreDense}>
              From:
            </Typography>
            {/* <Typography align="left" className={classes.moreDenseSmall}>
               Biocon Biologics India Ltd
            </Typography> */}
            <Typography align="left">C/o CFA {order.user.full_name}</Typography>
            <Typography align="left" className={classes.address}>
              {order.user.address.address_str}
            </Typography>
            <Typography align="left" className={classes.dense}>
              Phone no. {order.user.mobile}
            </Typography>
          </CardContent>
          <CardActions dir="rtl">
            <Button variant="outlined" color="secondary" onClick={close}>
              Close
            </Button>
            <div className={classes.wrapper}>
              <ReactToPrint
                trigger={() => (
                  <Button variant="contained" color="primary">
                    Print
                  </Button>
                )}
                content={() => this.componentRef}
              />
            </div>
          </CardActions>
        </Card>
      </div>
    );
  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
DeliveryChallan.propTypes = {
  order: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DeliveryChallan);
