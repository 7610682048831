import React, { Component } from "react";
import withStyles from "@material-ui/core/es/styles/withStyles";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/es/Typography/Typography";
import Table from "@material-ui/core/es/Table/Table";
import TableHead from "@material-ui/core/es/TableHead/TableHead";
import TableCell from "@material-ui/core/es/TableCell/TableCell";
import TableBody from "@material-ui/core/es/TableBody/TableBody";
import TableRow from "@material-ui/core/es/TableRow/TableRow";
import Paper from "@material-ui/core/es/Paper/Paper";
import Grid from "@material-ui/core/es/Grid/Grid";
import Button from "@material-ui/core/es/Button/Button";
import { OrderConstants } from "../OrderConstants";
import Modal from "@material-ui/core/es/Modal/Modal";
import { withSnackbar } from "notistack";
import TransferOrderForm from "./TransferOrderForm";
import MarkDispatchedForm from "./MarkDispatchedForm";
import MarkDeliveredForm from "./MarkDeliveredForm";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/es/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import Popper from "@material-ui/core/es/Popper/Popper";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import green from "@material-ui/core/es/colors/green";
import AddressLabel from "./AddressLabel";
import OrderStatus from "./OrderStatus";
import TextField from "@material-ui/core/es/TextField/TextField";
import * as lodash from "lodash";
import DeliveryChallan from "./DeliveryChallan";
import Constants from "../../Constants";

const styles = (theme) => ({
  header: {
    marginTop: 0,
  },
  orderItems: {
    margin: 0,
  },
  actions: {
    padding: "10px 50px",
    background: "#fff",
  },
  address: {},
  addressSection: {
    background: "transparent",
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  modalCss: {
    position: "absolute",
    width: theme.spacing.unit * 60,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: "none",
  },
  deliveryChallanModalCss: {
    position: "absolute",
    top: "0%",
    width: theme.spacing.unit * 100,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: "none",
  },
  textField: {
    margin: 0,
    padding: 5,
    border: "1px solid #eee",
    borderBottom: "1px solid #eee",
  },
  invoiceHeaderDetails: {
    backgroundColor: "#eee",
    padding: 20,
    borderRadius: 0,
  },
  invoiceHeader: {
    padding: 20,
    borderRadius: 0,
  },
  button: {
    margin: theme.spacing.unit,
  },
  popper: {
    zIndex: 1,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.common.white} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.common.white} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.common.white}`,
      },
    },
  },
});

/**
 * Order detail component
 *
 * @return {*}
 * @constructor
 */
class OrderDetailsComponent extends Component {
  /**
   * State of component
   * @type {{value: number}}
   */
  state = {
    arrow: false,
    arrowRef: null,
    saving: false,
    accept_order: false,
    pack_order: false,
    address_label: false,
    delivery_challan: false,
    transfer_order: false,
    mark_dispatched_order: false,
    mark_delivered_order: false,
    select_demo_person: false,
  };

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      order: lodash.cloneDeep(props.order),
    };
    this.popperMarkAcceptedOrder = this.popperMarkAcceptedOrder.bind(this);
    this.modalTransferOrder = this.modalTransferOrder.bind(this);
    this.modalDeliveryChallan = this.modalDeliveryChallan.bind(this);
    this.modalMarkDispatchedOrder = this.modalMarkDispatchedOrder.bind(this);
    this.modalMarkDeliveredOrder = this.modalMarkDeliveredOrder.bind(this);
    this.saveBatchNumber = this.saveBatchNumber.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps &&
      (prevProps.order.id !== this.props.order.id ||
        prevProps.order.status !== this.props.order.status)
    ) {
      this.setState({
        ...this.state,
        order: this.props.order,
      });
    }
  }

  /**
   * On value change
   *
   * @param name
   */
  onFieldChange = (name) => (event) => {
    this.setState({
      ...this.state,
      order: {
        ...this.state.follow_up,
        [name]: event.target.value,
      },
    });
  };

  /**
   * On order item change
   *
   */
  onOrderItemChange = (i) => (event) => {
    let { items } = lodash.cloneDeep(this.state.order);
    items[i][event.target.name] = event.target.value;
    this.setState({
      ...this.state,
      order: {
        ...this.state.order,
        items: items,
      },
    });
  };

  /**
   * transfer order modal
   */
  modalTransferOrder = () => {
    this.setState({
      ...this.state,
      transfer_order: !this.state.transfer_order,
    });
  };

  /**
   * Dispatch order modal
   */
  modalMarkDispatchedOrder = () => {
    this.setState({
      ...this.state,
      mark_dispatched_order: !this.state.mark_dispatched_order,
    });
  };

  /**
   * accept order
   */
  popperMarkAcceptedOrder = () => {
    this.setState({ ...this.state, accept_order: !this.state.accept_order });
  };

  /**
   * accept order
   */
  popperMarkPackingOrder = () => {
    this.setState({ ...this.state, pack_order: !this.state.pack_order });
  };

  /**
   * address label order modal
   */
  modalAddressLabel = () => {
    this.setState({ ...this.state, address_label: !this.state.address_label });
  };

  /**
   * address label order modal
   */
  modalDeliveryChallan = () => {
    this.setState({
      ...this.state,
      delivery_challan: !this.state.delivery_challan,
    });
  };

  /**
   * delivered order modal
   */
  modalMarkDeliveredOrder = () => {
    this.setState({
      ...this.state,
      mark_delivered_order: !this.state.mark_delivered_order,
    });
  };

  /**
   * Toggle saving
   */
  toggleSaving = () => {
    this.setState({
      ...this.state,
      saving: !this.state.saving,
    });
  };

  /**
   * Address to string
   * @return {*}
   */
  addressToString = () => {
    return this.props.order.address.line;
  };

  /**
   * Accept order
   */
  accept = () => {
    this.toggleSaving();
    let { close, handlers, order, done } = this.props;
    handlers.acceptOrder(order.id).then((json) => {
      this.setState({
        ...this.state,
        saving: !this.state.saving,
        accept_order: !this.state.accept_order,
      });
      this.props.enqueueSnackbar("Order accepted successfully!");
      done();
    });
  };

  /**
   * Packing order
   */
  packing = () => {
    this.toggleSaving();
    let { close, handlers, order, done } = this.props;
    handlers.packOrder(order.id).then((json) => {
      this.setState({
        ...this.state,
        saving: !this.state.saving,
        pack_order: !this.state.pack_order,
      });
      this.props.enqueueSnackbar("Address label generated successfully!");
      done();
    });
  };

  // modalSelectDemoPerson = () => {
  //   this.setState({
  //     ...this.state,
  //     select_demo_person: !this.state.select_demo_person,
  //   });
  // };

  handleArrowRef = (node) => {
    this.setState({
      arrowRef: node,
    });
  };

  /**
   * Save batch number
   */
  saveBatchNumber = () => {
    this.toggleSaving();
    let { handlers, enqueueSnackbar, done } = this.props;
    handlers.saveOrder(this.state.order.id, this.state.order).then((json) => {
      this.setState({
        ...this.state,
        saving: !this.state.saving,
      });
      enqueueSnackbar("Order saved successfully!");
      done();
    });
  };

  /**
   * Has batch no
   */
  hasBatchNo = () => {
    let hasBatch = true;
    let { order } = this.props;
    order.items.map((oi) => {
      if (!oi.batch_no || oi.batch_no.length <= 0) hasBatch = false;
      return oi;
    });
    return hasBatch;
  };

  /**
   * Render html
   * @return {*}
   */
  render() {
    const { classes, cfa_list, vendors, handlers } = this.props;
    const { order: order } = this.state;
    const { accept_order, pack_order, arrow, arrowRef, saving } = this.state;
    const id = accept_order ? "scroll-playground" : null;
    const packing_id = pack_order ? "scroll-playground" : null;
    return (
      <div>
        <Paper className={classes.addressSection} elevation={0}>
          <Grid container spacing={8}>
            <Grid item md={7} sm={7}>
              <Paper elevation={0} className={classes.invoiceHeader}>
                <Typography
                  color="primary"
                  align="left"
                  variant="h4"
                  component="h4"
                  className={classes.address}
                >
                  ORDER # {order.order_no}
                </Typography>

                <Typography
                  align="left"
                  variant="caption"
                  component="span"
                  className={classes.address}
                >
                  ORDER FOR:
                </Typography>
                <Typography
                  align="left"
                  variant="subtitle1"
                  component="span"
                  className={classes.address}
                >
                  {order.order_for}
                </Typography>
                <Typography
                  direction="rtl"
                  align="left"
                  className={classes.address}
                >
                  <OrderStatus status={order.status} />
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={5} sm={5}>
              <Paper className={classes.invoiceHeaderDetails} elevation={0}>
                <Typography
                  align="left"
                  variant="subtitle2"
                  className={classes.address}
                >
                  ADDRESS
                </Typography>
                <Typography
                  gutterBottom
                  align="left"
                  variant="caption"
                  className={classes.address}
                >
                  {order.address &&
                    OrderConstants.addressToString(order.address)}
                </Typography>

                <Typography
                  align="left"
                  variant="subtitle2"
                  className={classes.address}
                >
                  ORDER NO.
                </Typography>
                <Typography
                  gutterBottom
                  align="left"
                  variant="caption"
                  className={classes.address}
                >
                  # {order.order_no}
                </Typography>

                <Typography
                  align="left"
                  variant="subtitle2"
                  className={classes.address}
                >
                  ORDER DATE
                </Typography>
                <Typography
                  gutterBottom
                  align="left"
                  variant="caption"
                  className={classes.address}
                  disabled
                >
                  {order.order_date}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.orderItems} elevation={0}>
          <Table className={classes.table + " " + "invoice-table"}>
            <TableHead>
              <TableRow>
                <TableCell align="left">PRODUCT</TableCell>
                <TableCell align="right">QUANTITY</TableCell>
                <TableCell align="right">Batch No.</TableCell>
                <TableCell align="right">UNIT PRICE (IN Rs.)</TableCell>
                <TableCell align="right">AMOUNT (IN Rs.)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order.items &&
                order.items.length > 0 &&
                order.items.map((oi, i) => (
                  <TableRow key={i}>
                    <TableCell align="left">{oi.item}</TableCell>
                    <TableCell align="right">{oi.quantity}</TableCell>
                    {order.status === "packing" && (
                      <TableCell>
                        <TextField
                          label=""
                          name="batch_no"
                          className={classes.textField}
                          value={oi.batch_no ? oi.batch_no : ""}
                          onChange={this.onOrderItemChange(i)}
                          margin="dense"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </TableCell>
                    )}
                    {order.status !== "packing" && (
                      <TableCell align="right">{oi.batch_no}</TableCell>
                    )}
                    <TableCell align="right">{oi.unit_price}</TableCell>
                    <TableCell align="right">{oi.total_price}</TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell colSpan={3} className="empty-cell" />
                <TableCell align="right">Sub Total</TableCell>
                <TableCell align="right">Rs. {order.total_amount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} className="empty-cell" />
                <TableCell align="right">Tax</TableCell>
                <TableCell align="right">0 %</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} className="empty-cell" />
                <TableCell align="right">
                  <Typography
                    color="primary"
                    align="right"
                    variant="subtitle2"
                    className={classes.address}
                  >
                    GRAND TOTAL
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    color="primary"
                    align="right"
                    variant="subtitle2"
                    className={classes.address}
                  >
                    Rs. {order.total_amount}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <Paper elevation={0} className={classes.actions}>
          {order.status === "pending" && (
            <Button
              className={classes.button}
              buttonRef={(node) => {
                this.anchorEl = node;
              }}
              variant="contained"
              color="primary"
              onClick={this.popperMarkAcceptedOrder}
              aria-describedby={id}
              size="small"
            >
              Accept
            </Button>
          )}
          {order.status === "pending" && (
            <Button
              className={classes.button}
              variant="contained"
              size="small"
              color="secondary"
              onClick={this.modalTransferOrder}
            >
              Transfer
            </Button>
          )}
          {order.status === "accept" && (
            <div className={classes.wrapper}>
              <Button
                className={classes.button}
                buttonRef={(node) => {
                  this.anchorEl = node;
                }}
                variant="contained"
                color="primary"
                onClick={this.popperMarkPackingOrder}
                aria-describedby={packing_id}
                size="small"
              >
                Generate Address label
              </Button>
              {/* <Button
                className={classes.button}
                buttonRef={(node) => {
                  this.anchorEl = node;
                }}
                variant="contained"
                color="secondary"
                onClick={() => {}}
                aria-describedby={packing_id}
                size="small"
              >
                Demo Required
              </Button> */}
              {saving && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          )}

          {order.status === "packing" && (
            <div className={classes.wrapper}>
              {this.hasBatchNo() && (
                <div>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={this.modalAddressLabel}
                  >
                    Address label
                  </Button>
                  <Button
                    className={classes.button}
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={this.modalMarkDispatchedOrder}
                  >
                    Generate Delivery Challan
                  </Button>
                </div>
              )}
              <Button
                className={classes.button}
                variant="contained"
                size="small"
                color="primary"
                onClick={this.saveBatchNumber}
                disabled={saving}
              >
                Save batch no
              </Button>
              {saving && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          )}
          {order.status === "dispatch" && (
            <div>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={this.modalDeliveryChallan}
              >
                Delivery challan
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                size="small"
                color="primary"
                onClick={this.modalMarkDeliveredOrder}
              >
                Mark Delivered
              </Button>
              {/* <Button
                className={classes.button}
                variant="contained"
                size="small"
                color="primary"
                onClick={() => {}}
              >
                Demo Required
              </Button> */}
            </div>
          )}
        </Paper>
        {order.status !== "pending" && (
          <Table className={classes.table + " " + "small-table grey-table"}>
            <TableHead>
              <TableRow>
                <TableCell component="th" align="center">
                  Vendor
                </TableCell>
                <TableCell component="th" align="center">
                  LR Number
                </TableCell>
                <TableCell component="th" align="center">
                  Receiver
                </TableCell>
                <TableCell component="th" align="center">
                  Receiver's mobile
                </TableCell>
                <TableCell component="th" align="center">
                  Dispatch Date
                </TableCell>
                <TableCell component="th" align="center">
                  Delivery Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  {order.vendor && order.vendor.name}{" "}
                  {order.vendor_str && `(${order.vendor_str})`}
                </TableCell>
                <TableCell align="center">{order.lr_no}</TableCell>
                <TableCell align="center">{order.received_by}</TableCell>
                <TableCell align="center">{order.received_by_mobile}</TableCell>
                <TableCell align="center">{order.dispatch_date}</TableCell>
                <TableCell align="center">{order.delivery_date}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        <Modal
          aria-labelledby="transfer-order-title"
          aria-describedby="transfer-order-description"
          open={this.state.transfer_order}
          onClose={this.modalTransferOrder}
        >
          <div
            style={OrderConstants.getModalStyle()}
            className={classes.modalCss}
          >
            <TransferOrderForm
              order={order}
              handlers={handlers}
              cfa_list={cfa_list}
              done={this.props.done}
              close={this.modalTransferOrder}
            />
          </div>
        </Modal>

        <Modal
          aria-labelledby="address-label-order-title"
          aria-describedby="address-label-order-description"
          open={this.state.address_label}
          onClose={this.modalAddressLabel}
        >
          <div
            style={OrderConstants.getModalStyle()}
            className={classes.modalCss}
          >
            <AddressLabel order={order} close={this.modalAddressLabel} />
          </div>
        </Modal>

        <Modal
          aria-labelledby="delivery-challan-order-title"
          aria-describedby="delivery-challan-order-description"
          open={this.state.delivery_challan}
          onClose={this.modalDeliveryChallan}
        >
          <div
            style={OrderConstants.getModalStyle()}
            className={classes.deliveryChallanModalCss}
          >
            <DeliveryChallan order={order} close={this.modalDeliveryChallan} />
          </div>
        </Modal>

        <Modal
          aria-labelledby="mark-dispatched-order-title"
          aria-describedby="mark-dispatched-order-description"
          open={this.state.mark_dispatched_order}
          onClose={this.modalMarkDispatchedOrder}
        >
          <div
            style={OrderConstants.getModalStyle()}
            className={classes.modalCss}
          >
            <MarkDispatchedForm
              order={order}
              vendors={vendors}
              done={this.props.done}
              handlers={handlers}
              close={this.modalMarkDispatchedOrder}
            />
          </div>
        </Modal>

        <Modal
          aria-labelledby="transfer-order-title"
          aria-describedby="transfer-order-description"
          open={this.state.mark_delivered_order}
          onClose={this.modalMarkDeliveredOrder}
        >
          <div
            style={OrderConstants.getModalStyle()}
            className={classes.modalCss}
          >
            <MarkDeliveredForm
              order={order}
              done={this.props.done}
              handlers={handlers}
              close={this.modalMarkDeliveredOrder}
            />
          </div>
        </Modal>

        {/* <Modal
          aria-labelledby="address-label-order-title"
          aria-describedby="address-label-order-description"
          open={this.state.accept_request}
          onClose={() => {}}
        >
          <div
            style={{ width: "600px", margin: 200 }}
            className={classes.modalCss}
          >
            <SelectDemoPerson close={this.modalSelectDemoPerson}/>
          </div>
        </Modal> */}

        <Popper
          id={id}
          open={accept_order}
          anchorEl={this.anchorEl}
          placement="top"
          disablePortal={false}
          className={classes.popper}
          modifiers={{
            flip: {
              enabled: true,
            },
            arrow: {
              enabled: arrow,
              element: arrowRef,
            },
          }}
        >
          {arrow ? (
            <span className={classes.arrow} ref={this.handleArrowRef} />
          ) : null}
          <Paper className={classes.paper}>
            <DialogTitle>
              {"Are you sure you want to accept this order?"}
            </DialogTitle>
            <DialogActions>
              <div className={classes.wrapper}>
                <Button
                  onClick={this.popperMarkAcceptedOrder}
                  color="primary"
                  disabled={saving}
                >
                  Disagree
                </Button>
                <Button onClick={this.accept} color="primary" disabled={saving}>
                  Agree
                </Button>
                {saving && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </DialogActions>
          </Paper>
        </Popper>

        <Popper
          id={packing_id}
          open={pack_order}
          anchorEl={this.anchorEl}
          placement="top"
          disablePortal={false}
          className={classes.popper}
          modifiers={{
            flip: {
              enabled: true,
            },
            arrow: {
              enabled: arrow,
              element: arrowRef,
            },
          }}
        >
          {arrow ? (
            <span className={classes.arrow} ref={this.handleArrowRef} />
          ) : null}
          <Paper className={classes.paper}>
            <DialogTitle>
              {"Are you sure you want to Generate address label ?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                You will not be able to revert order after this change.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <div className={classes.wrapper}>
                <Button
                  onClick={this.popperMarkPackingOrder}
                  color="primary"
                  disabled={saving}
                >
                  Disagree
                </Button>
                <Button
                  onClick={this.packing}
                  color="primary"
                  disabled={saving}
                >
                  Agree
                </Button>
                {saving && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </DialogActions>
          </Paper>
        </Popper>
      </div>
    );
  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
OrderDetailsComponent.propTypes = {
  order: PropTypes.object.isRequired,
  cfa_list: PropTypes.object.isRequired,
  vendors: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  handlers: PropTypes.object.isRequired,
  done: PropTypes.func.isRequired,
};

export default withSnackbar(withStyles(styles)(OrderDetailsComponent));
