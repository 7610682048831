import {FAILED_REST_REQUEST, NEW_REST_REQUEST, SUCCESS_REST_REQUEST} from "../../../common/actions/actions";

export const INVOICE_LIST_FOR_PATIENT_PROGRAM = 'INVOICE_LIST_FOR_PATIENT_PROGRAM';

export const runningInvoiceListForPatientProgramRefresh = () => ({
  type: INVOICE_LIST_FOR_PATIENT_PROGRAM,
  status: NEW_REST_REQUEST
});

export const invoiceListForPatientProgramRefreshSucceeded = (json) => ({
  type: INVOICE_LIST_FOR_PATIENT_PROGRAM,
  status: SUCCESS_REST_REQUEST,
  data: json
});

export const invoiceListForPatientProgramRefreshFailed = (errors) => ({
  type: INVOICE_LIST_FOR_PATIENT_PROGRAM,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});
