import {
  FAILED_REST_REQUEST,
  NEW_REST_REQUEST,
  SUCCESS_REST_REQUEST,
} from "../../../common/actions/actions";

export const FIND_CFA_LIST = "FIND_CFA_LIST";
export const FIND_ORDER_LIST_FOR_PATIENT = "FIND_ORDER_LIST_FOR_PATIENT";
export const SEND_ORDER_EMAIL = "SEND_ORDER_EMAIL";

// request cfa list
export const requestFindCfaList = () => ({
  type: FIND_CFA_LIST,
  status: NEW_REST_REQUEST,
});

// success find cfa list
export const successFindCfaList = (json) => ({
  type: FIND_CFA_LIST,
  status: SUCCESS_REST_REQUEST,
  data: json,
});

// failed find cfa list
export const failedFindCfaList = (errors) => ({
  type: FIND_CFA_LIST,
  status: FAILED_REST_REQUEST,
  errors: errors.errors,
});

export const requestFindOrderListForPatient = () => ({
  type: FIND_ORDER_LIST_FOR_PATIENT,
  status: NEW_REST_REQUEST,
});

export const successFindOrderListForPatient = (json) => ({
  type: FIND_ORDER_LIST_FOR_PATIENT,
  status: SUCCESS_REST_REQUEST,
  data: json,
});

export const failedFindOrderListForPatient = (errors) => ({
  type: FIND_ORDER_LIST_FOR_PATIENT,
  status: FAILED_REST_REQUEST,
  errors: errors.errors,
});

export const requestSendEmailForPatient = () => ({
  type: SEND_ORDER_EMAIL,
  status: NEW_REST_REQUEST,
});

export const successSendEmailForPatient = (json) => ({
  type: SEND_ORDER_EMAIL,
  status: SUCCESS_REST_REQUEST,
  data: json,
});

export const failedSendEmailForPatient = (errors) => ({
  type: SEND_ORDER_EMAIL,
  status: FAILED_REST_REQUEST,
  errors: errors.errors,
});
