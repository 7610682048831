import React, {Component} from 'react';
import {Provider} from 'react-redux';
import {applyMiddleware, createStore} from 'redux';
import Header from "../common/components/base/Header";
import Footer from "../common/components/base/Footer";
import reducer from './reducers/reducer'
import {Route, Switch} from "react-router-dom";
import thunk from 'redux-thunk'
import {createLogger} from 'redux-logger'
import {NotificationContainer} from "react-notifications";
import withStyles from "@material-ui/core/es/styles/withStyles";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Navigation from "../common/components/base/Navigation";
import indexRoutes from "./routes/index.js";

const middleware = [thunk];
if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger());
}

/**
 * Patient program app
 *
 * @type {Store<any, Action> & {dispatch: any}}
 */
const store = createStore(
  reducer,
  applyMiddleware(...middleware)
);

const drawerWidth = 240;
const styles = theme => ({
  root: {
    display: 'flex',
    height: "100%",
    backgroundColor: "#fff"
  },
  paper: {
    marginTop: 65
  },
  content: {
    flexGrow: 1,
    padding: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
});

class ReportApp extends Component {

  /**
   * State
   *
   * @type {{}}
   */
  state = {
    open: false,
  };

  /**
   * Open drawer
   */
  handleDrawerOpen = () => {
    this.setState({open: true});
  };

  /**
   * Close drawer
   */
  handleDrawerClose = () => {
    this.setState({open: false});
  };

  render() {
    let {classes, theme} = this.props;

    return (
      <Provider store={store}>
        <div className={classes.root}>
          <NotificationContainer/>
          <Header handleDrawerOpen={this.handleDrawerOpen.bind(this)} open={this.state.open}/>
          <Navigation open={this.state.open} handleDrawerClose={this.handleDrawerClose.bind(this)}/>
          <div className={classNames(classes.content, {
            [classes.contentShift]: this.state.open,
          })}>
            <div className={classes.paper}>
              <Switch>{
                indexRoutes.map((prop, key) => {
                  return <Route path={prop.path} component={prop.component} exact={prop.exact} key={key}/>;
                })
              }
              </Switch>
            </div>
          </div>
          <Footer/>
        </div>
      </Provider>
    );
  }
}

ReportApp.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};
export default withStyles(styles, {withTheme: true})(ReportApp);
