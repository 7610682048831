import React from "react";
import PropTypes from "prop-types";
import modelFormController from "../../../../common/hoc/forms/modelFormController";
import withStyles from "@material-ui/core/es/styles/withStyles";
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import Select from "@material-ui/core/es/Select/Select";
import OutlinedInput from "@material-ui/core/es/OutlinedInput/OutlinedInput";
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";
import TextField from "@material-ui/core/es/TextField/TextField";
import Grid from "@material-ui/core/es/Grid/Grid";

const styles = theme => ({
  tableCell: {
    padding: 0,
    paddingRight: 0
  }
});

class FullfillmentItemForm extends React.Component {

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
  }

  render() {
    const {onItemFieldChange, classes, products, model} = this.props;
    return (
      <Grid container spacing={8}>
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth={true} margin="none">
            <Select value={model.product_id} onChange={onItemFieldChange}
                    input={
                      <OutlinedInput name="product_id" id="product" labelWidth={0}/>
                    }>
              {
                products.map((d, i) => {
                  return <MenuItem value={d.id} key={i}>{d.name}</MenuItem>
                })
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField name="quantity" className={classes.textField}
                     defaultValue={model.quantity} onChange={onItemFieldChange}
                     margin="none" fullWidth={true} variant="outlined"/>
        </Grid>
      </Grid>
    );
  }
}

FullfillmentItemForm.propTypes = {
  products: PropTypes.array.isRequired,
  model: PropTypes.object.isRequired,
  onItemFieldChange: PropTypes.func.isRequired
};

export default modelFormController(withStyles(styles)(FullfillmentItemForm));