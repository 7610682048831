import React from 'react'
import PropTypes from "prop-types";
import Grid from "@material-ui/core/es/Grid/Grid";
import withStyles from "@material-ui/core/es/styles/withStyles";
import TextField from "@material-ui/core/es/TextField/TextField";
import formController from "../../../../common/hoc/forms/formController";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  }
});

class CaretakerForm extends React.Component {
  render() {
    const {classes, onFieldChange, model} = this.props;

    return (<Grid container spacing={8}>
      <Grid item xs={3}>
        <TextField id="caretaker-name" name="fullName" label="Caretaker Name" type="string"
                   defaultValue={model.fullName} onChange={onFieldChange}
                   margin="dense" fullWidth={true} variant="outlined" className={classes.textField}/>
      </Grid>
      <Grid item xs={3}>
        <TextField id="caretaker-mobile" name="mobile" label="Caretaker Mobile" type="number"
                   defaultValue={model.mobile} onChange={onFieldChange}
                   margin="dense" fullWidth={true} variant="outlined" className={classes.textField}/>
      </Grid>
      <Grid item xs={3}>
        <TextField id="caretaker-alt-mobile" name="altMobile" label="Caretaker Alt. Mobile" type="number"
                   defaultValue={model.altMobile} onChange={onFieldChange}
                   margin="dense" fullWidth={true} variant="outlined" className={classes.textField}/>
      </Grid>
    </Grid>);
  }
}

CaretakerForm.propTypes = {
  model: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired
};

export default formController(withStyles(styles)(CaretakerForm));