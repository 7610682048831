import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/es/TextField/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import React from "react";
import Button from "@material-ui/core/Button";
import { Controller, useForm } from "react-hook-form";
import MenuItem from "@material-ui/core/MenuItem";
import { ThemeProvider, makeStyles } from "@material-ui/styles";
import theme from "../../app_theme";
import { useState } from "react";
import {
  getPostalDataBasedOnPincode,
} from "../service/PatientEnrollmentService";
import UploadFileCard from "../components/UploadFileCard";

const useStyles = makeStyles({
  root: {
    display: "grid",
    gap: 25,
    marginInline: "32px",
  },
  demo: {
    display: "flex",
    alignItems: "center",
    border: "1px solid gray",
    padding: "10",
    borderRadius: "3.5px",
  },
  button: {
    marginBottom: "30px",
  },
});

const PatientEnrollmentForm = () => {
  const [pincode, setPincode] = useState("");
  const [stateName, setStateName] = useState("");
  const { handleSubmit, control } = useForm({
    mode: "onChange",
  });
  const classes = useStyles();

  let regexPincode = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;

  const onSubmit = (data) => {
    console.log("pincode", pincode);
    if (regexPincode.test(pincode)) {
      console.log(regexPincode.test(pincode));
      console.log(data, pincode, stateName);
    } else console.log("Pincode invalid");
  };

  const currencies = [
    {
      value: "USD",
      label: "$",
    },
    {
      value: "vaccine",
      label: "€",
    },
    {
      value: "500062",
      label: "฿",
    },
    {
      value: "Telangana",
      label: "¥",
    },
  ];

  const handlePincodeChange = (e) => {
    let value = e.target.value;
    setPincode(value);
    if (regexPincode.test(value)) {
      console.log(value);
      getPostalDataBasedOnPincode(value).then((result) =>
        setStateName(result[0]?.PostOffice[0].State)
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ backgroundColor: "white" }}>
        <div className={classes.root}>
          <div style={{ marginTop: "30px" }}>
            <Typography variant="h6">Patient Enrollment Form</Typography>
          </div>
          <Controller
            name="patientName"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div>
                <Typography color="textSecondary">Patient Name</Typography>
                <TextField
                  value={value}
                  onChange={onChange}
                  placeholder="Enter Patient Name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!error}
                  helperText={error?.message}
                />
              </div>
            )}
            rules={{ required: { value: true, message: "Field Required" } }}
          />

          <Controller
            name="mobileNumber"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div>
                <Typography color="textSecondary">Contact Number</Typography>
                <TextField
                  placeholder="Enter Mobile Number"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                />
              </div>
            )}
            rules={{
              pattern: {
                value: /^[6-9]{1}[0-9]{9}$/,
                message: "Invalid mobile",
              },
              required: { value: true, message: "Field Required" },
            }}
          />
          <Controller
            name="productName"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div>
                <Typography color="textSecondary">Product Name</Typography>
                <TextField
                  defaultValue=""
                  placeholder="Select Product"
                  variant="outlined"
                  fullWidth
                  select
                  size="small"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.value}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            )}
            rules={{ required: { value: true, message: "Field Required" } }}
          />
          <div>
            <Typography color="textSecondary">Pincode</Typography>
            <TextField
              defaultValue=""
              placeholder="Enter Pincode"
              variant="outlined"
              fullWidth
              size="small"
              value={pincode}
              onChange={(e) => handlePincodeChange(e)}
            />
          </div>
          <div>
            <Typography color="textSecondary">State</Typography>
            <TextField
              defaultValue=""
              placeholder="State"
              variant="outlined"
              fullWidth
              value={stateName}
              size="small"
              readOnly
            />
          </div>
          <Controller
            name="demo"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div className={classes.demo}>
                <Checkbox onChange={onChange} value={value} color="primary" />
                <Typography color="textSecondary">Demo Confirmation</Typography>
              </div>
            )}
          />
          <div>
          <UploadFileCard
            title="Upload consent card * "
            id={5}
            onFileSelect={() => {}}
            file={""}
            reset={() => {}}
            isLoading={false}
            error={""}
            helperText={""}
          />
          </div>
          <div className={classes.button}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit(onSubmit)}
            >
              SUBMIT
            </Button>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default PatientEnrollmentForm;
