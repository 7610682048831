import React, { Component } from "react";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Typography from "@material-ui/core/es/Typography/Typography";
import Button from "@material-ui/core/es/Button/Button";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import CardActions from "@material-ui/core/es/CardActions/CardActions";
import Card from "@material-ui/core/es/Card/Card";
import Paper from "@material-ui/core/es/Paper";

const styles = (theme) => ({
  card: {
    borderRadius: 0,
  },
});

/**
 * Demo Person Screen
 *
 * @return {*}
 * @constructor
 */
class DemoPersonScreen extends Component {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Render html
   * @return {*}
   */
  render() {
    const { classes, close } = this.props;

    return (
      <Card className={classes.card}>
        <CardContent>
          <Paper className={classes.invoiceHeaderDetails} elevation={0}>
            <div
              style={{
                display: "grid",
                justifyContent: "center",
                marginBottom: 20,
              }}
            >
              <Typography align="left" variant="subtitle2">
                PATIENT REQUEST DETAILS:
              </Typography>
            </div>

            <Typography align="left" variant="subtitle2">
              NAME
            </Typography>
            <Typography gutterBottom align="left" variant="caption">
              Ramesh
            </Typography>
            <Typography align="left" variant="subtitle2">
              PATIENT ADDRESS
            </Typography>
            <Typography gutterBottom align="left" variant="caption">
              Hanuman Temple, Road Number 1, Banjara Hills, Hyderabad - 500034,
              Telangana, India
            </Typography>

            <Typography align="left" variant="subtitle2">
              REQUEST NO.
            </Typography>
            <Typography gutterBottom align="left" variant="caption">
              # 1234567
            </Typography>

            <Typography align="left" variant="subtitle2">
              REQUEST DATE
            </Typography>
            <Typography gutterBottom align="left" variant="caption" disabled>
              11/10/2022
            </Typography>
          </Paper>
        </CardContent>
        <CardActions dir="rtl">
          <Button variant="contained" color="primary" onClick={() => {}}>
            Accept
          </Button>
          <div style={{ marginRight: 5 }}>
            <Button variant="contained" color="primary" onClick={close}>
              Reject
            </Button>
          </div>
        </CardActions>
      </Card>
    );
  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
// DemoPersonScreen.propTypes = {
//   close: PropTypes.func.isRequired,
//   classes: PropTypes.object.isRequired,
// };

export default withStyles(styles)(DemoPersonScreen);
