import React, {Component} from 'react'
import PropTypes from "prop-types";
import Card from "@material-ui/core/es/Card/Card";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import withStyles from "@material-ui/core/es/styles/withStyles";
import {withSnackbar} from 'notistack';
import Grid from "@material-ui/core/es/Grid/Grid";
import {PatientProgramConstant} from "../../PatientProgramConstant";
import Badge from "@material-ui/core/es/Badge/Badge";
import {DatePicker, MuiPickersUtilsProvider} from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns/build/index";

const styles = theme => ({
  card: {
    borderRadius: 0
  },
  date: {},
  content: {
    padding: 0
  },
  picker: {
    margin: 0
  },
  grid: {
    width: "14%",
    height: 40,
    padding: 5,
    borderRight: "1px solid #eee",
    borderBottom: "1px solid #eee",
    textAlign: "right",
    fontSize: "10px"
  },
  badge: {
    fontSize: 10,
    top: '21px',
    right: "7px",
    // The border color match the background color.
    border: `2px solid ${
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
      }`,
  },
  badgeRed: {
    borderRadius: 0,
    padding: "0",
    fontSize: 10,
    height: "15px",
    minWidth: "15px",
    top: '21px',
    right: "33px",
    // The border color match the background color.
    border: `0px solid ${
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
      }`
  },
  active: {
    backgroundColor: "#eee"
  }
});

/**
 * Patient documents
 *
 * @param patient
 * @return {*}
 * @constructor
 */
class FollowUpCalendarComponent extends Component {

  state = {};

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      selected_month: new Date()
    }
  }

  /**
   * On date click
   *
   * @param date
   * @return {Function}
   */
  onDateClick = date => () => {
    let {selectFollowUpDate} = this.props;
    selectFollowUpDate(date.date);
  };

  /**
   * On treatment start date change
   *
   * @param date
   */
  onMonthChange = (date) => {
    let {selectFollowUpMonth} = this.props;
    this.setState({
      selected_month: date
    });
    selectFollowUpMonth(date.getMonth());
  };

  //render content
  render() {
    const {classes, date} = this.props;
    let {success, data} = this.props.follow_ups;
    let {success: dueSuccess, data: dueData} = this.props.duePatientProgramCounts;
    if (!success || !dueSuccess)
      return "";

    let dates = this.prepareData(data.follow_ups, dueData.counts);
    let self = this;
    return (
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item xs={12}>
              <DatePicker margin="dense" variant="outlined" fullWidth={true}
                          value={this.state.selected_month} onChange={this.onMonthChange}
                          views={["year", "month"]}
                          format={"MMM` yyyy"} className={classes.picker}
                          mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}/>
            </Grid>
          </MuiPickersUtilsProvider>
        </CardContent>
        <Grid container spacing={0} className={classes.date}>
          {
            dates.map(function (d, i) {
              return <Grid item key={i} className={classes.grid + " " + (d.date === date ? classes.active : "")}
                           onClick={self.onDateClick(d)}>
                <Badge badgeContent={d.count} color="primary" classes={{badge: classes.badge}}>
                  {d.date}
                </Badge>
                <Badge badgeContent={d.due_count} color="error" classes={{badge: classes.badgeRed}}>
                  {""}
                </Badge>
              </Grid>
            })
          }
        </Grid>
      </Card>
    );
  }

  /**
   * Prepare data
   *
   * @return {Array}
   */
  prepareData(follow_ups, counts) {
    let dates = PatientProgramConstant.getDates(this.props.month, this.props.year);
    dates.map(function (d) {
      follow_ups.map(function (f) {
        if (f.date === d.date)
          d.count = f.count;
      });
      counts.map(c => {
        if (c.date === d.date)
          d.due_count = c.count;
      })
    });
    return dates;
  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
FollowUpCalendarComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  date: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
  follow_ups: PropTypes.object.isRequired,
  duePatientProgramCounts: PropTypes.object.isRequired,
  selectFollowUpDate: PropTypes.func.isRequired,
  selectFollowUpMonth: PropTypes.func.isRequired
};

export default withSnackbar(withStyles(styles, {withTheme: true})(FollowUpCalendarComponent));
