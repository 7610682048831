import {handleRestRequest, initialRestState} from "../../common/actions/actions";
import {combineReducers} from 'redux'
import {
  REPORT_CALLS,
  REPORT_COUNTS,
  REPORT_DEFERRED_PATIENT_PROGRAMS,
  REPORT_DROPPED_PATIENT_PROGRAMS,
  REPORT_MONTHLY_COUNTS,
  REPORT_ORDERS,
  REPORT_PATIENT_PROGRAMS
} from "./actions/report-actions";

/**
 * Get report counts
 *
 * @param state
 * @param action
 * @return
 */
const reportCounts = (state = {
  ...initialRestState
}, action) => {
  switch (action.type) {
    case REPORT_COUNTS:
      return handleRestRequest(state, action);
    default:
      return state
  }
};

/**
 * Get report monthly counts
 *
 * @param state
 * @param action
 * @return
 */
const reportMonthlyCounts = (state = {
  ...initialRestState
}, action) => {
  switch (action.type) {
    case REPORT_MONTHLY_COUNTS:
      return handleRestRequest(state, action);
    default:
      return state
  }
};

/**
 * Get report for deferred patient programs
 *
 * @param state
 * @param action
 * @return
 */
const reportDeferredPatientPrograms = (state = {
  ...initialRestState
}, action) => {
  switch (action.type) {
    case REPORT_DEFERRED_PATIENT_PROGRAMS:
      return handleRestRequest(state, action);
    default:
      return state
  }
};

/**
 * Get report for dropped patient programs
 *
 * @param state
 * @param action
 * @return
 */
const reportDroppedPatientPrograms = (state = {
  ...initialRestState
}, action) => {
  switch (action.type) {
    case REPORT_DROPPED_PATIENT_PROGRAMS:
      return handleRestRequest(state, action);
    default:
      return state
  }
};

/**
 * Get report for orders
 *
 * @param state
 * @param action
 * @return
 */
const reportReportOrders = (state = {
  ...initialRestState
}, action) => {
  switch (action.type) {
    case REPORT_ORDERS:
      return handleRestRequest(state, action);
    default:
      return state
  }
};

/**
 * Get report for patient programs
 *
 * @param state
 * @param action
 * @return
 */
const reportReportPatientPrograms = (state = {
  ...initialRestState
}, action) => {
  switch (action.type) {
    case REPORT_PATIENT_PROGRAMS:
      return handleRestRequest(state, action);
    default:
      return state
  }
};

/**
 * Get report for calls
 *
 * @param state
 * @param action
 * @return
 */
const reportCalls = (state = {
  ...initialRestState
}, action) => {
  switch (action.type) {
    case REPORT_CALLS:
      return handleRestRequest(state, action);
    default:
      return state
  }
};

/**
 * Reducer
 *
 * @type {{}}
 */
const reducer = combineReducers({
  reportCounts,
  reportMonthlyCounts,
  reportDeferredPatientPrograms,
  reportDroppedPatientPrograms,
  reportReportOrders,
  reportReportPatientPrograms,
  reportCalls
});

export default reducer;
