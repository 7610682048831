import React, {Component} from 'react'
import PropTypes from "prop-types";
import green from "@material-ui/core/es/colors/green";
import withStyles from "@material-ui/core/es/styles/withStyles";
import restCallController from "../../../common/hoc/forms/restCallController";
import AlertBox from "../../../common/components/common/AlertBox";
import {generateToken} from "../../service/AuthService";

const styles = theme => ({
  card: {
    borderRadius: 0
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
});

/**
 * Add Patient program form
 *
 * @param patient
 * @return {*}
 * @constructor
 */
class SendLinkToPatientComponent extends Component {

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.sendLink = this.sendLink.bind(this);
  }

  /**
   * Update program
   */
  sendLink = () => {
    let {handlePromise, data} = this.props;
    handlePromise(generateToken({mobile: data.patient_program.mobile, userId: data.patient.authId}));
  };

  /**
   * Render view
   * @return {*}
   */
  render() {
    let {close, isFetching, data, open} = this.props;
    return (
      <AlertBox open={open} handleClose={close} handleSuccess={this.sendLink}
                title="A message will be sent to patient with link to upload document"
                isFetching={isFetching}/>
    );
  }
}

SendLinkToPatientComponent.propTypes = {
  data: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  successCallback: PropTypes.func.isRequired,
  failedCallback: PropTypes.func.isRequired
};

export default restCallController(withStyles(styles)(SendLinkToPatientComponent));