import {FAILED_REST_REQUEST, NEW_REST_REQUEST, SUCCESS_REST_REQUEST} from "../../../common/actions/actions";

export const FIND_All_PROGRAM = 'FIND_All_PROGRAM';


// request programs
export const requestPrograms = () => ({
  type: FIND_All_PROGRAM,
  status: NEW_REST_REQUEST,
});

// success programs request
export const successPrograms = (json) => ({
  type: FIND_All_PROGRAM,
  status: SUCCESS_REST_REQUEST,
  data: json
});

// failed programs request
export const failedPrograms = (errors) => ({
  type: FIND_All_PROGRAM,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});