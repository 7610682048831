import Constants from "../Constants";
import moment from "moment";

export class PatientProgramConstant {
  // patient program rest url
  static PROGRAMS_URL = Constants.PATIENT_PROGRAM_API_ENDPOINT + "programs";
  static PRODUCTS_URL = Constants.PATIENT_PROGRAM_API_ENDPOINT + "products";
  static FOLLOW_UPS_URL = Constants.PATIENT_PROGRAM_API_ENDPOINT + "follow_ups";
  static PATIENT_PROGRAMS_URL =
    Constants.PATIENT_PROGRAM_API_ENDPOINT + "patient_programs";
  static PATIENT_PROGRAM_DOCUMENTS_URL =
    Constants.PATIENT_PROGRAM_API_ENDPOINT + "documents";
  static PATIENTS_URL = Constants.PATIENT_SERVICE_API_ENDPOINT + "patients";
  static PATIENT_MASTERS_URL =
    Constants.PATIENT_SERVICE_API_ENDPOINT + "masters";
  static ORDERS_URL = Constants.ORDER_SERVICE_API_ENDPOINT + "orders";
  static SEND_ORDER_EMAIL_URL =
    Constants.ORDER_SERVICE_API_ENDPOINT + "emails/sendForOrder";
  static INVOICES_URL = Constants.PATIENT_PROGRAM_API_ENDPOINT + "invoices";
  static TEMP_TOKEN_URL = Constants.AUTH_API_ENDPOINT + "auth";
  static ORDER_USERS_URL = Constants.ORDER_SERVICE_API_ENDPOINT + "users";
  static REPORT_URL = Constants.PATIENT_PROGRAM_API_ENDPOINT + "reports";
  static DOCUMENTS_URL = Constants.PATIENT_SERVICE_API_ENDPOINT + "documents";

  // treatment cycle
  static TREATMENT_CYCLES = [
    "daily",
    "weekly",
    "3 weekly",
    "fortnightly",
    "monthly",
  ];

  // patient program statuses
  static PATIENT_PROGRAM_STATUSES = [
    "New Registration",
    "Processing documents",
    "Pending proof of supply",
    "Waiting for approval",
    "Sent to CFA",
    "Dispatched",
    "Delivered",
    "Patient Deferred",
    "Discontinued treatment",
    "Rejected",
    "Patient Expired",
    "Successfully Closed",
  ];

  // follow up statuses
  static FOLLOW_UP_STATUSES = ["pending", "completed", "cancelled"];

  // patient program can approve statuses
  static PATIENT_PROGRAM_CAN_APPROVE_STATUSES = [
    "New Registration",
    "Processing documents",
    "Pending proof of supply",
  ];

  /**
   * Get dates for calendar
   *
   * @param month
   * @param year
   * @return {Array}
   */
  static getDates(month, year) {
    let date = moment().date(1).month(month).year(year).startOf("week");
    let dates = [];
    for (
      let d = date;
      dates.length < 7 || dates.length % 7 === 0 || date.month() === month;
      date.add(1, "day")
    ) {
      if (month === date.month())
        dates.push({ date: date.date(), count: 0, due_count: 0 });
      else dates.push({ count: 0, date: "", due_count: 0 });
    }

    return dates;
  }

  /**
   * Prepare date
   *
   * @param date
   * @param month
   * @param year
   * @return {string}
   */
  static getDate(date, month, year) {
    return moment().date(date).month(month).year(year).format("DD-MM-YYYY");
  }

  /**
   * Get mobile and name
   *
   * @param patient
   * @return {string}
   */
  static getMobileOrName(patient) {
    if (patient.fullName) return patient.fullName;
    return patient.mobile;
  }

  /**
   * Convert address to string
   */
  static addressToString = (address) => {
    return (
      (address.line ? address.line + ", " : "") +
      (address.landmark ? address.landmark + ", " : "") +
      (address.city ? address.city + ", " : "") +
      (address.district ? address.district + ", " : "") +
      (address.state ? address.state + ", " : "") +
      (address.country ? address.country : "") +
      (address.pincode ? address.pincode : "")
    );
  };
}
