import React, {Component} from 'react'
import PropTypes from "prop-types";
import Card from "@material-ui/core/es/Card/Card";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import green from "@material-ui/core/es/colors/green";
import withStyles from "@material-ui/core/es/styles/withStyles";
import * as lodash from "lodash";
import CardActions from "@material-ui/core/es/CardActions/CardActions";
import Button from "@material-ui/core/es/Button/Button";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import Constants from "../../../Constants";
import PatientProgramForm from "./forms/PatientProgramForm";
import restCallController from "../../../common/hoc/forms/restCallController";
import {updatePatientProgram} from "../../service/PatientProgramService";
import moment from "moment";

const styles = theme => ({
  card: {
    borderRadius: 0
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
});

/**
 * Add Patient program form
 *
 * @param patient
 * @return {*}
 * @constructor
 */
class PatientProgramFormComponent extends Component {

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);

    // set patient program
    let patient_program = {};
    if (props.patient_program) {
      patient_program = props.patient_program;
    }

    // set prescription date
    if (!patient_program.prescription_date)
      patient_program.prescription_date_picker = Constants.getTodayDate();
    else
      patient_program.prescription_date_picker = moment(patient_program.prescription_date).toDate();

    // set treatment start date
    if (!patient_program.treatment_start_date)
      patient_program.treatment_start_date_picker = Constants.getTodayDate();
    else
      patient_program.treatment_start_date_picker = moment(patient_program.treatment_start_date).toDate();

    // set next free cycle date
    if (!patient_program.next_free_cycle_date)
      patient_program.next_free_cycle_date_picker = Constants.getTodayDate();
    else
      patient_program.next_free_cycle_date_picker = moment(patient_program.next_free_cycle_date).toDate();

    if (patient_program.last_infusion_date)
      patient_program.last_infusion_date = moment(patient_program.last_infusion_date).toDate();

    if (patient_program.next_infusion_date)
      patient_program.next_infusion_date = moment(patient_program.next_infusion_date).toDate();

    // prepare patient program
    this.state = {
      patient_program: patient_program
    };

    this.onModelChange = this.onModelChange.bind(this);
  }

  /**
   * On model field change
   *
   * @param model
   * @return {Function}
   */
  onModelChange = (model) => (field, value) => {
    this.setState({
      ...this.state,
      [model]: {
        ...this.state[model],
        [field]: value
      },
    });
  };

  /**
   * Update program
   */
  update = () => {
    let {handlePromise} = this.props;
    let patient_program = lodash.clone(this.state.patient_program);

    // format dates
    if (patient_program.prescription_date_picker)
      patient_program['prescription_date'] = Constants.formatToServerDate(patient_program.prescription_date_picker);
    if (patient_program.treatment_start_date_picker)
      patient_program['treatment_start_date'] = Constants.formatToServerDate(patient_program.treatment_start_date_picker);
    if (patient_program.next_free_cycle_date_picker)
      patient_program['next_free_cycle_date'] = Constants.formatToServerDate(patient_program.next_free_cycle_date_picker);
    if (patient_program.last_infusion_date)
      patient_program['last_infusion_date'] = Constants.formatToServerDate(patient_program.last_infusion_date);
    if (patient_program.next_infusion_date)
      patient_program['next_infusion_date'] = Constants.formatToServerDate(patient_program.next_infusion_date);

    // save patient program
    patient_program = lodash(patient_program).omitBy(function (field) {
      return (!field || ((typeof field === typeof '') && field.length === 0));
    }).value();
    handlePromise(updatePatientProgram(this.props.patient_program.id, patient_program));
  };

  /**
   * Render view
   * @return {*}
   */
  render() {
    let {classes, close, isFetching, data, errors} = this.props;
    let {patient_program} = this.state;
    return (
      <Card className={classes.card} elevation={0}>
        <CardContent>
          <form className={classes.container} noValidate autoComplete="off">
            <PatientProgramForm model={patient_program} onModelChange={this.onModelChange("patient_program")}
                                data={data} errors={errors}/>
          </form>
        </CardContent>
        <CardActions dir="rtl">
          <Button variant="outlined" color="secondary" onClick={close} disabled={isFetching}>
            Close
          </Button>
          <div className={classes.wrapper}>
            <Button variant="contained" color="primary" onClick={this.update} disabled={isFetching}>
              {isFetching ? "Updating" : "Update"}
            </Button>
            {isFetching && <CircularProgress size={24} className={classes.buttonProgress}/>}
          </div>
        </CardActions>
      </Card>
    );
  }
}

PatientProgramFormComponent.propTypes = {
  data: PropTypes.object.isRequired,
  patient_program: PropTypes.object.isRequired,
  close: PropTypes.func,
  classes: PropTypes.object.isRequired,
  successCallback: PropTypes.func.isRequired,
  failureCallback: PropTypes.func.isRequired
};

export default restCallController(withStyles(styles)(PatientProgramFormComponent));
