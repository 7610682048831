import {LOGIN_REQUEST} from './actions/login'
import {handleRestRequest, initialRestState} from "../../common/actions/actions";

/**
 * Prepare auth data
 *
 * @param state
 * @param action
 * @return
 */
export const authData = (state = {
  ...initialRestState,
  user: {},
}, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return handleRestRequest(state, action);
    default:
      return state
  }
};
