import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Typography from "@material-ui/core/es/Typography/Typography";
import Card from "@material-ui/core/es/Card/Card";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import CardActions from "@material-ui/core/es/CardActions/CardActions";
import Button from "@material-ui/core/es/Button/Button";
import * as lodash from "lodash";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import green from "@material-ui/core/es/colors/green";
import Icon from "@material-ui/core/es/Icon/Icon";
import CaretakerForm from "./patient/caretaker/CaretakerForm";
import AddressForm from "./patient/address/AddressForm";
import PatientForm from "./patient/PatientForm";
import { updatePatient } from "../service/PatientService";
import restCallController from "../../common/hoc/forms/restCallController";
import Address from "../models/Address";

const styles = (theme) => ({
  card: {
    borderRadius: 0,
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: "relative",
  },
  subHeading: {
    padding: 10,
  },
  button: {
    marginLeft: 10,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  addMore: {
    marginLeft: 10,
  },
  cardContent: {
    maxHeight: 400,
    overflowY: "scroll",
  },
});

/**
 * Update patient program form
 *
 * @param patient
 * @return {*}
 * @constructor
 */
class PatientFormComponent extends Component {
  /**
   * Patient form data
   */
  state = {
    documentGroups: [],
    patient: {},
    caretakers: [{}],
    primaryAddress: Address.get(),
    secondaryAddress: Address.get(),
  };

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);

    // update patient
    if (props.patient) this.preparePatientData(props.patient);

    // function linking
    this.onModelArrayChange = this.onModelArrayChange.bind(this);
    this.onModelChange = this.onModelChange.bind(this);
    this.addMoreCaretaker = this.addMoreCaretaker.bind(this);
    this.copyPrimaryAddressToSecondaryAddress =
      this.copyPrimaryAddressToSecondaryAddress.bind(this);
  }

  /**
   * Prepare patient
   */
  preparePatientData = (patient) => {
    let data = {
      patient: patient,
    };

    // set primary address
    if (patient.primaryAddress)
      data["primaryAddress"] = Address.format(patient.primaryAddress);

    // set secondary address
    if (patient.secondaryAddress)
      data["secondaryAddress"] = Address.format(patient.secondaryAddress);

    // set caretakers
    if (patient.caretakers != null && patient.caretakers.length > 0)
      data["caretakers"] = patient.caretakers;

    // set state
    this.state = {
      ...this.state,
      ...data,
    };
  };

  /**
   * On Bulk model change
   *
   * @param model
   * @return {Function}
   */
  onBulkModelChange = (model) => (data) => {
    this.setState({
      ...this.state,
      [model]: {
        ...this.state[model],
        ...data,
      },
    });
  };

  /**
   * On model field change
   *
   * @param model
   * @return {Function}
   */
  onModelChange = (model) => (field, value) => {
    this.setState({
      ...this.state,
      [model]: {
        ...this.state[model],
        [field]: value,
      },
    });
  };

  /**
   * On model field change
   *
   * @param model
   * @param index
   * @return {Function}
   */
  onModelArrayChange = (model, index) => (field, value) => {
    const values = [...this.state[model]];
    values[index][field] = value;
    this.setState({
      ...this.state,
      [model]: values,
    });
  };

  /**
   * add more caretaker
   */
  addMoreCaretaker = () => {
    const caretakers = [...this.state.caretakers, {}];
    this.setState({
      ...this.state,
      caretakers: caretakers,
    });
  };

  /**
   * Copy primary address to secondary address
   */
  copyPrimaryAddressToSecondaryAddress() {
    this.setState({
      ...this.state,
      secondaryAddress: { ...this.state.primaryAddress },
    });
  }

  /**
   * Update patient
   */
  update = () => {
    let { patient, handlePromise } = this.props;
    let patient_to_save = lodash.clone(this.state.patient);
    patient_to_save["primaryAddress"] = lodash.clone(this.state.primaryAddress);
    patient_to_save["secondaryAddress"] = lodash.clone(
      this.state.secondaryAddress
    );
    patient_to_save["caretakers"] = lodash.clone(this.state.caretakers);
    handlePromise(updatePatient(patient.id, patient_to_save));
  };

  /**
   * Render view
   * @return {*}
   */
  render() {
    const { classes, isFetching, errors } = this.props;
    const { patient, primaryAddress, secondaryAddress, caretakers } =
      this.state;
    let self = this;
    return (
      <Card className={classes.card}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" color="inherit">
              Patient form
            </Typography>
          </Toolbar>
        </AppBar>
        <CardContent className={classes.cardContent}>
          <form className={classes.container} noValidate autoComplete="off">
            {/* Patient form */}
            <Typography
              component="h6"
              variant="subtitle1"
              color="textSecondary"
              className={classes.subHeading}
            >
              Personal details
            </Typography>
            <PatientForm
              model={patient}
              onModelChange={self.onModelChange("patient")}
              errors={errors}
            />
            {/* ./Patient form */}

            {/* Caretakers */}
            <Typography
              component="h6"
              variant="subtitle1"
              color="textSecondary"
              className={classes.subHeading}
            >
              Caretakers
              <Button
                variant="outlined"
                onClick={self.addMoreCaretaker}
                size="small"
                className={classes.addMore}
              >
                <Icon color="primary" fontSize="small">
                  add
                </Icon>{" "}
                ADD MORE
              </Button>
            </Typography>
            {caretakers.map(function (c, i) {
              return (
                <CaretakerForm
                  model={c}
                  onModelChange={self.onModelArrayChange("caretakers", i)}
                  key={i}
                />
              );
            })}
            {/* ./Caretakers */}

            {/* Primary address */}
            <Typography
              component="h6"
              variant="subtitle1"
              color="textSecondary"
              className={classes.subHeading}
            >
              Permanent Address
            </Typography>
            <AddressForm
              model={primaryAddress}
              onModelChange={self.onModelChange("primaryAddress")}
              onBulkModelChange={this.onBulkModelChange("primaryAddress")}
            />
            {/* ./Primary address */}

            {/* Secondary address */}
            <Typography
              component="h6"
              variant="subtitle1"
              color="textSecondary"
              className={classes.subHeading}
            >
              Communication Address
              <Button
                color="primary"
                className={classes.button}
                variant="outlined"
                size="small"
                onClick={this.copyPrimaryAddressToSecondaryAddress}
              >
                <Icon color="primary" fontSize="small">
                  add
                </Icon>{" "}
                Same as primary
              </Button>
            </Typography>
            <AddressForm
              model={secondaryAddress}
              onModelChange={self.onModelChange("secondaryAddress")}
              onBulkModelChange={this.onBulkModelChange("secondaryAddress")}
            />
            {/* ./Secondary address */}
          </form>
        </CardContent>
        <CardActions dir="rtl">
          <Button
            variant="outlined"
            color="secondary"
            onClick={this.props.close}
            disabled={isFetching}
          >
            Close
          </Button>
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.update}
              disabled={isFetching}
            >
              {isFetching ? "Updating" : "Update"}
            </Button>
            {isFetching && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </CardActions>
      </Card>
    );
  }
}

PatientFormComponent.propTypes = {
  patient: PropTypes.object,
  handlePromise: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  successCallback: PropTypes.func.isRequired,
  failureCallback: PropTypes.func.isRequired,
};

export default restCallController(withStyles(styles)(PatientFormComponent));
