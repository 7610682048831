import moment from "moment/moment";
import Constants from "../../Constants";

export default class Order {
  contact_no;
  order_no;
  patient_id;
  order_for;
  order_for_id;
  order_from;
  user_id;
  items = [{product_id: "", quantity: 0}];
  address = {};
  caretaker_alt;
  caretaker;
  address_str = "";
  expected_delivery_date_picker;
  patient_cycle_date_picker;

  constructor(info) {
    this.contact_no = info.contact_no;
    this.patient_id = info.patient_id;
    this.order_no = info.order_no;
    this.order_for = info.order_for;
    this.order_for_id = info.order_for_id;
    this.order_from = info.order_from;
    this.user_id = info.user_id;
    this.address_str = info.address_str;
    this.caretaker = info.caretaker;
    this.caretaker_alt = info.caretaker_alt;

    if (info.items)
      this.items = info.items;
    if (info.address)
      this.address = info.address;

    if (!info.expected_delivery_date)
      this.expected_delivery_date_picker = Constants.getTodayDate();
    else
      this.expected_delivery_date_picker = moment(info.expected_delivery_date).toDate();

    if (!info.patient_cycle_date)
      this.patient_cycle_date_picker = Constants.getTodayDate();
    else
      this.patient_cycle_date_picker = moment(info.patient_cycle_date).toDate();
  }

  addNewItem() {
    this.items.push({product_id: "", quantity: 0})
  }
}