import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "formik";
import { fetchLogin } from "../../service/LoginService";
import { Redirect } from "react-router-dom";
import TextField from "@material-ui/core/es/TextField/TextField";
import Grid from "@material-ui/core/es/Grid/Grid";
import Card from "@material-ui/core/es/Card/Card";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import Typography from "@material-ui/core/es/Typography/Typography";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import Button from "@material-ui/core/es/Button/Button";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Lock from "@material-ui/icons/Lock";
import LockOpen from "@material-ui/icons/LockOpen";
import InputAdornment from "@material-ui/core/es/InputAdornment/InputAdornment";
import withStyles from "@material-ui/core/es/styles/withStyles";
import CardActions from "@material-ui/core/es/CardActions/CardActions";

const styles = (theme) => ({
  card: {
    margin: 10,
  },
  cardHeader: {
    textAlign: "center",
  },
  cardContent: {
    margin: "10px 30px",
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: "relative",
  },
  textField: {
    fontSize: 12,
    fontWeight: 400,
    marginRight: 20,
  },
  InputProps: {
    position: "static",
  },
  cardActions: {
    textAlign: "right",
  },
});

class LoginPage extends Component {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      errors: {},
      role: "",
      loading: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * On value change
   *
   * @param name
   */
  onFieldChange = (name) => (event) => {
    this.setState({
      ...this.state,
      [name]: event.target.value,
    });
  };

  /**
   * On submit of login form
   */
  onSubmit() {
    let { dispatch } = this.props;
    let self = this;
    dispatch(fetchLogin(this.state.username, this.state.password))
      .then(function (json) {
        localStorage.setItem("auth", JSON.stringify(json.auth));
        self.setState({ ...self.state, role: json.auth.role.name });
      })
      .catch((error) => {
        if (error.errors && error.errors.errors)
          this.setState({
            ...this.state,
            errors: error.errors.errors,
          });
      });
  }

  // view
  render() {
    const { isFetching, dispatch, success, classes } = this.props;
    const url = this.props.match.url;
    return (
      <Grid container spacing={24}>
        <Grid item md={4} sm={3} xs={12}></Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Form>
            <Card className={classes.card}>
              <CardContent>
                <Typography
                  variant="h6"
                  color="inherit"
                  className={classes.cardHeader}
                >
                  <LockOpen />
                </Typography>
                <Typography
                  variant="h6"
                  color="inherit"
                  className={classes.cardHeader}
                >
                  LOGIN FORM
                </Typography>
              </CardContent>
              <CardContent className={classes.cardContent}>
                <Grid container spacing={24}>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ color: "red" }}
                    key={1}
                  >
                    {this.state.errors.password}
                  </Grid>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ color: "red" }}
                    key={1}
                  >
                    {this.state.errors.message}
                  </Grid>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ color: "red" }}
                    key={2}
                  >
                    {this.state.errors.username}
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <TextField
                      id="username"
                      label="Username"
                      className={classes.textField}
                      onChange={this.onFieldChange("username")}
                      margin="normal"
                      fullWidth={true}
                      inputProps={{ position: "static" }}
                      InputProps={{
                        position: "static",
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <TextField
                      id="password"
                      label="Password"
                      type="password"
                      className={classes.textField}
                      onChange={this.onFieldChange("password")}
                      margin="normal"
                      fullWidth={true}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <CardActions className={classes.cardActions}>
                  <Button
                    className={classes.wrapper}
                    variant="contained"
                    color="primary"
                    onClick={this.onSubmit}
                    disabled={isFetching}
                    fullWidth={true}
                  >
                    {isFetching && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                    {isFetching ? "Logging in" : "Login"}
                  </Button>
                </CardActions>
                {/*<CardActions className={classes.cardActions} dir="rtl">*/}
                {/*<Button component="a" size="small" color="primary" href={'/auth/forgot_password'}>*/}
                {/*?Forget Password*/}
                {/*</Button>*/}
                {/*</CardActions>*/}
              </CardContent>
            </Card>
          </Form>
        </Grid>
        {success && this.state.role === "ADMIN" && (
          <Redirect to="/patient_programs" />
        )}
        {this.state.role === "COMPANY_ADMIN" && (
          <Redirect to="/reports/dashboard" />
        )}
        {success && this.state.role === "CFA" && <Redirect to="/orders" />}
        {success && this.state.role === "PATIENT" && (
          <Redirect to="/patients/patient_programs" />
        )}
      </Grid>
    );
  }
}

/**
 * Map state to properties
 *
 * @param state
 * @returns {{auth: *}}
 */
const mapStateToProps = (state) => {
  const { authData } = state;

  return { ...authData };
};

export default connect(mapStateToProps)(withStyles(styles)(LoginPage));
