import React from 'react';
import PropTypes from "prop-types";


export default function modelFormController(WrappedComponent) {
  return class extends React.Component {

    /**
     * Property types
     *
     * @type {{}}
     */
    PropTypes = {
      model: PropTypes.object.isRequired
    };

    /**
     * Constructor
     *
     * @param props
     */
    constructor(props) {
      super(props);
      this.onFieldChange = this.onFieldChange.bind(this);
    }

    /**
     * On field change
     *
     * @param event
     */
    onFieldChange = (event) => {
      this.props.model[event.target.name] = event.target.value;
    };

    /**
     * Render wrapped component
     *
     * @return {*}
     */
    render() {
      return <WrappedComponent {...this.props} onModelChange={this.onFieldChange}/>;
    }
  };
}