import React, {Component} from 'react';
import {Provider} from 'react-redux';
import {applyMiddleware, createStore} from 'redux';
import Footer from "../common/components/base/Footer";
import reducer from './reducers/reducer'
import {Route, Switch} from "react-router-dom";
import thunk from 'redux-thunk'
import {createLogger} from 'redux-logger'
import {NotificationContainer} from "react-notifications";
import withStyles from "@material-ui/core/es/styles/withStyles";
import PropTypes from 'prop-types';
import PatientHeader from "./components/base/PatientHeader";
import indexRoutes from "./routes/index.js";

const middleware = [thunk];
if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger());
}

/**
 * Patient program app
 *
 * @type {Store<any, Action> & {dispatch: any}}
 */
const store = createStore(
  reducer,
  applyMiddleware(...middleware)
);

const drawerWidth = 240;
const styles = theme => ({
  root: {
    display: 'flex',
    maxHeight: "100%"
  },
  paper: {
    marginTop: 57,
    width: "100%",
    maxHeight: "100%"
  }
});

/**
 * Auth App
 */
class PatientApp extends Component {

  /**
   * State
   *
   * @type {{}}
   */
  state = {
    open: false,
  };

  render() {
    let {classes} = this.props;

    return (
      <Provider store={store}>
        <div className={classes.root}>
          <NotificationContainer/>
          <div className={classes.paper}>
            <Switch>{
                indexRoutes.map((prop,key)=>{
                    return <Route path={prop.path} component={prop.component} exact={prop.exact} key={key}/>;
                })
            }
            </Switch>
          </div>
          <Footer/>
        </div>
      </Provider>
    );
  }
}

PatientApp.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};
export default withStyles(styles, {withTheme: true})(PatientApp);
