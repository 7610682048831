import React from "react";
import PropTypes from "prop-types";
import Pagination from "./models/Pagination";


class PaginationComponent extends React.Component {

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      pagination: new Pagination(props.pagesPerSection, props.page, props.totalPages)
    };
    this.goToPreviousSection = this.goToPreviousSection.bind(this);
    this.goToNextSection = this.goToNextSection.bind(this);
    this.goToPreviousPage = this.goToPreviousPage.bind(this);
    this.goToNextPage = this.goToNextPage.bind(this);
  }

  /**
   * On page click
   *
   * @param page
   * @return {Function}
   */
  onPageClick = (page) => () => {
    const {pagination} = this.state;
    pagination.setPage(page);
    this.goToPage(page);
  };

  /**
   * Go to page number
   *
   * @param page
   */
  goToPage = (page) => {
    let {onPageChange} = this.props;
    onPageChange(page);
  };

  /**
   * Go to previous section
   */
  goToPreviousSection = () => {
    const {pagination} = this.state;
    pagination.goToPreviousSection();
    this.goToPage(pagination.page);
  };

  /**
   * Go to previous section
   */
  goToNextSection = () => {
    const {pagination} = this.state;
    pagination.goToNextSection();
    this.goToPage(pagination.page);
  };

  /**
   * Go to next page
   */
  goToPreviousPage = () => {
    const {pagination} = this.state;
    pagination.goToPreviousPage();
    this.goToPage(pagination.page);
  };

  /**
   * Go to next page
   */
  goToNextPage = () => {
    const {pagination} = this.state;
    pagination.goToNextPage();
    this.goToPage(pagination.page);
  };

  render() {
    let {pagination} = this.state;
    let {page} = this.props;
    let pages = pagination.sectionPages;

    return (<nav aria-label="Page navigation example">
      <ul className="pagination m-0">
        {
          !pagination.isFirstSection() &&
          <li className="page-item">
            <a className="page-link" onClick={this.goToPreviousSection}>
              <i className="fa fa-chevron-circle-left"></i>
            </a>
          </li>
        }
        {
          !pagination.isFirstPage() &&
          <li className="page-item">
            <a className="page-link" onClick={this.goToPreviousPage}>
              <i className="fa fa-chevron-left"></i>
            </a>
          </li>
        }
        {
          pages.map((p, key) => {
            return <li className={"page-item " + (p === page ? "active" : "")} key={key}>
              <a className="page-link" onClick={this.onPageClick(p)}>{p}</a>
            </li>
          })
        }
        {
          !pagination.isLastPage() &&
          <li className="page-item">
            <a className="page-link" onClick={this.goToNextPage}>
              <i className="fa fa-chevron-right"></i>
            </a>
          </li>
        }
        {
          !pagination.isLastSection() &&
          <li className="page-item">
            <a className="page-link" onClick={this.goToNextSection}>
              <i className="fa fa-chevron-circle-right"></i>
            </a>
          </li>
        }
      </ul>
    </nav>);
  }
}

PaginationComponent.propTypes = {
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};

export default PaginationComponent;