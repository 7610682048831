import Constants from "../../../../Constants";

export default class Pagination {
  pagesInSingleSection = 5;
  page = 1;
  totalPages = 10;
  sectionPages = [];

  /**
   * Constructor
   *
   * @param pagesInSingleSection
   * @param page
   * @param totalPages
   */
  constructor(pagesInSingleSection, page, totalPages) {
    this.pagesInSingleSection = pagesInSingleSection;
    this.totalPages = totalPages;
    this.setPage(page);
  }

  /**
   * Set page
   *
   * @param page
   */
  setPage(page) {
    this.page = page;
    this.setPages();
  }

  /**
   * Set section pages
   */
  setPages() {
    this.sectionPages = Constants.getRange(this.getStartPage(), this.getEndPage());

    // remove pages bigger than total pages
    this.sectionPages = this.sectionPages.filter(p => p <= this.totalPages);
  }

  /**
   * Get current section start page
   *
   * @return {number}
   */
  getStartPage() {
    return ((Math.ceil(this.page / this.pagesInSingleSection) - 1) * (this.pagesInSingleSection)) + 1;
  }

  /**
   * Get current section end page
   *
   * @return {number}
   */
  getEndPage() {
    return (Math.ceil(this.page / this.pagesInSingleSection) * this.pagesInSingleSection);
  }

  /**
   * Go to end page of previous section
   *
   * @return {number}
   */
  goToPreviousSection() {
    this.setPage(this.getEndPage() - this.pagesInSingleSection);
  }

  /**
   * Get start page of next section
   *
   * @return {number}
   */
  goToNextSection() {
    this.setPage(this.getStartPage() + this.pagesInSingleSection);
  }

  /**
   * Go to next page
   */
  goToPreviousPage() {
    this.setPage(Math.max(1, this.page - 1));
  }

  /**
   * Go to next page
   */
  goToNextPage() {
    this.setPage(Math.min(this.page + 1, this.totalPages));
  }

  /**
   * Is the section last section
   */
  isLastSection() {
    return this.getEndPage() >= this.totalPages;
  }

  /**
   * is First section
   * @return {boolean}
   */
  isFirstSection() {
    return (this.page - this.pagesInSingleSection) <= 0
  }

  /**
   * Is last page
   *
   * @return {boolean}
   */
  isFirstPage() {
    return this.page === 1;
  }

  /**
   * Is last page
   *
   * @return {boolean}
   */
  isLastPage() {
    return this.page === this.totalPages;
  }
}