import {
  acceptOrder,
  deliveredOrder,
  dispatchOrder,
  packOrder,
  saveOrder,
  transferOrder
} from "../../services/OrderService";

let orderHandler = (dispatch) => {
  return {
    transferOrder: (id, order) => {
      return dispatch(transferOrder(id, order));
    },
    acceptOrder: (id) => {
      return dispatch(acceptOrder(id));
    },
    packOrder: (id) => {
      return dispatch(packOrder(id));
    },
    dispatchOrder: (id, order) => {
      return dispatch(dispatchOrder(id, order));
    },
    deliveredOrder: (id, order) => {
      return dispatch(deliveredOrder(id, order));
    },
    saveOrder: (id, order) => {
      return dispatch(saveOrder(id, order));
    }
  }
};

export default orderHandler;
