import {
  PASSWORD_RESET_REQUEST,
  VERIFICATION_CODE_REQUEST
} from './actions/login'
import {handleRestRequest, SUCCESS_REST_REQUEST} from "../../common/actions/actions";

const forgotPassword = (state, action) => {
  state = handleRestRequest(state, action);
  switch (action.type) {
    case VERIFICATION_CODE_REQUEST:
      switch (action.type) {
        case SUCCESS_REST_REQUEST:
          return {
            ...state,
            verificationCodeSent: true
          };
        default:
          return state;
      }
    default:
      return state;
  }
};

/**
 * Prepare auth data
 *
 * @param state
 * @param action
 * @return
 */
export const forgotPasswordData = (state = {
  isFetching: false,
  failed: false,
  verificationCodeSent: false,
  errors: {},
}, action) => {
  switch (action.type) {
    case VERIFICATION_CODE_REQUEST:
    case PASSWORD_RESET_REQUEST:
      return forgotPassword(state, action);
    default:
      return state
  }
};