import {handleRestRequest, initialRestState} from "../../common/actions/actions";
import {combineReducers} from 'redux'
import {GET_PATIENT, UPLOAD_PATIENT_DOCUMENT} from "./actions/patient-actions";
import {
  PATIENT_PROGRAM,
  PATIENT_PROGRAM_DOCUMENTS_FOR_PATIENT_PROGRAM,
  PATIENT_PROGRAMS
} from "./actions/patient-program-actions";

/**
 * Get patient
 *
 * @param state
 * @param action
 * @return
 */
const patient = (state = {
  ...initialRestState
}, action) => {
  switch (action.type) {
    case GET_PATIENT:
      return handleRestRequest(state, action);
    default:
      return state
  }
};

/**
 * Get all patient programs for logged in user
 *
 * @param state
 * @param action
 * @return
 */
const patientPrograms = (state = {
  ...initialRestState
}, action) => {
  switch (action.type) {
    case PATIENT_PROGRAMS:
      return handleRestRequest(state, action);
    default:
      return state
  }
};

/**
 * Get patient program for id
 *
 * @param state
 * @param action
 * @return
 */
const patientProgram = (state = {
  ...initialRestState
}, action) => {
  switch (action.type) {
    case PATIENT_PROGRAM:
      return handleRestRequest(state, action);
    default:
      return state
  }
};

/**
 * Get all patient program document for patient program
 *
 * @param state
 * @param action
 * @return
 */
const patientProgramDocumentsForPatientProgram = (state = {
  ...initialRestState
}, action) => {
  switch (action.type) {
    case PATIENT_PROGRAM_DOCUMENTS_FOR_PATIENT_PROGRAM:
      return handleRestRequest(state, action);
    default:
      return state
  }
};

/**
 * Patient document upload
 *
 * @param state
 * @param action
 * @return {*}
 */
const uploadPatientDocument = (state = {
  ...initialRestState
}, action) => {
  switch (action.type) {
    case UPLOAD_PATIENT_DOCUMENT:
      return handleRestRequest(state, action);
    default:
      return state
  }
};

/**
 * Reducer
 *
 * @type {Reducer<any> | Reducer<any, AnyAction>}
 */
const reducer = combineReducers({
  patient,
  patientPrograms,
  patientProgram,
  patientProgramDocumentsForPatientProgram,
  uploadPatientDocument
});

export default reducer;
