import React, {Component} from 'react';
import '../../scss/login.css'

class LoginSuccessPage extends Component {

  render() {
    return (
      <div className="row">
        <div className="col-md-4 offset-md-4 login-form">
          <h3>Successfully Logged in</h3>
        </div>
      </div>
    )
  };
}

export default LoginSuccessPage
