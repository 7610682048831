import React, {Component} from 'react'
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Paper from "@material-ui/core/es/Paper/Paper";
import Table from "@material-ui/core/es/Table/Table";
import TableHead from "@material-ui/core/es/TableHead/TableHead";
import TableCell from "@material-ui/core/es/TableCell/TableCell";
import TableBody from "@material-ui/core/es/TableBody/TableBody";
import TableRow from "@material-ui/core/es/TableRow/TableRow";
import Grid from "@material-ui/core/es/Grid/Grid";
import {ReportConstant} from "../ReportConstant";

const styles = theme => ({
  paper: {
    overflowY: "scroll",
    overflowX: "scroll",
    maxHeight: "55vh",
    maxWidth: "99vw"
  },
  tableCell: {
    padding: "4px"
  },
  cellSmall: {
    width: "100px"
  }
});

class DocumentStatusReportTableComponent extends Component {
  render() {
    const {patientPrograms, classes} = this.props;
    return (
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>Coupon ID</TableCell>
                  <TableCell align="center" className={classes.tableCell}>Enrolment Date</TableCell>
                  <TableCell align="center" className={classes.tableCell}>FGO number</TableCell>
                  <TableCell align="center" className={classes.tableCell}>FGO raised date</TableCell>
                  <TableCell align="center" className={classes.tableCell}>SKU</TableCell>
                  <TableCell align="center" className={classes.tableCell}>DOSE</TableCell>
                  <TableCell align="center" className={classes.tableCell}>Quantity Sent</TableCell>
                  <TableCell align="center" className={classes.tableCell}>QOS </TableCell>
                  <TableCell align="center" className={classes.tableCell}>Indication</TableCell>
                  <TableCell align="center" className={classes.tableCell}>CFA</TableCell>
                  <TableCell align="center" className={classes.tableCell}>Status</TableCell>
                  <TableCell align="center" className={classes.tableCell}>Dispatch date</TableCell>
                  <TableCell align="center" className={classes.tableCell}>Delivered date</TableCell>
                  <TableCell align="center" className={classes.tableCell}>Doctor</TableCell>
                  <TableCell align="center" className={classes.tableCell}>Document – Coupon Verified?</TableCell>
                  <TableCell align="center" className={classes.tableCell}>Document Consent Verified?</TableCell>
                  <TableCell align="center" className={classes.tableCell}>Rx Verified?</TableCell>
                  <TableCell align="center" className={classes.tableCell}>Patient ID Verified?</TableCell>
                  <TableCell align="center" className={classes.tableCell}>Address proof Verified?</TableCell>
                  <TableCell align="center" className={classes.tableCell}>Exceptional Request Verified?</TableCell>
                  <TableCell align="center" className={classes.tableCell}>Valid Invoices Verified?</TableCell>
                  {/*<TableCell align="center" className={classes.tableCell}>GST Number Vitrified?</TableCell>*/}
                </TableRow>
              </TableHead>
              <TableBody>
                {patientPrograms.map((row, key) => {
                  if (row.orders.length > 0)
                    return row.orders.map(o => {
                      return <TableRow key={o.id + "-" + row.id}>
                        <TableCell align="left" className={classes.tableCell}>
                          {row.coupon_code}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {ReportConstant.formatDate(row.enrolment_date)}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {o.fgo_code}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {ReportConstant.formatDate(o.order_date)}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {row.brand}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {row.dosage + row.uom}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {o.quantity}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {row.qos}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {row.treatment}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {o.cfa}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {o.status}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {ReportConstant.formatDate(o.dispatched_date)}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {ReportConstant.formatDate(o.delivered_date)}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {row.doctor_full_name}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {row.coupon_verified}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {row.consent_verified}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {row.rx_verified}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {row.patient_id_verified}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {row.patient_address_verified}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {row.exceptional_request_date}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {row.bill_verified}
                        </TableCell>
                        {/*<TableCell align="center" className={classes.tableCell}>*/}
                        {/*{row.bill_verified}*/}
                        {/*</TableCell>*/}
                      </TableRow>;
                    });
                  else
                    return <TableRow key={row.id}>
                      <TableCell align="left" className={classes.tableCell}>
                        {row.coupon_code}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        {ReportConstant.formatDate(row.enrolment_date)}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>

                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>

                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        {row.brand}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        {row.dosage + row.uom}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>

                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        {row.QOS}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        {row.treatment}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        {row.doctor_full_name}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>

                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>

                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>

                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>

                      </TableCell>
                      {/*<TableCell align="center" className={classes.tableCell}>*/}
                      {/*{row.bill_verified}*/}
                      {/*</TableCell>*/}
                    </TableRow>;
                })}
                {
                  patientPrograms.length === 0 &&
                  <TableRow>
                    <TableCell component="th" scope="row" colspan={10}>
                      NO RECORDS FOUND
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    );
  }

  /**
   * Render patient program status
   *
   * @param patientProgram
   * @return {*}
   */
  renderPatientProgramStatus(patientProgram) {
    if (patientProgram.current_status === 'active')
      return patientProgram.total_orders + " Cycles";
    return patientProgram.current_status;
  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
DocumentStatusReportTableComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  patientPrograms: PropTypes.array.isRequired
};
export default withStyles(styles, {withTheme: true})(DocumentStatusReportTableComponent);