import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/es/Grid/Grid";
import TextField from "@material-ui/core/es/TextField/TextField";
import Radio from "@material-ui/core/es/Radio/Radio";
import formController from "../../../common/hoc/forms/formController";
import withStyles from "@material-ui/core/es/styles/withStyles";

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
});

class PatientForm extends React.Component {
  render() {
    const { classes, model, onFieldChange, errors } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="full-name"
            label="Full name"
            name={"fullName"}
            className={classes.textField}
            defaultValue={model.fullName}
            onChange={onFieldChange}
            margin="dense"
            fullWidth={true}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="age"
            label="Age"
            name={"age"}
            className={classes.textField}
            defaultValue={model.age}
            onChange={onFieldChange}
            margin="dense"
            fullWidth={true}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3}>
          <Radio
            checked={model.gender === "male"}
            onChange={onFieldChange}
            value="male"
            name="gender"
            aria-label="A"
          />{" "}
          Male
          <Radio
            checked={model.gender === "female"}
            onChange={onFieldChange}
            value="female"
            name="gender"
            aria-label="B"
          />{" "}
          Female
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="email"
            label="Email"
            name="email"
            className={classes.textField}
            defaultValue={model.email}
            onChange={onFieldChange}
            margin="dense"
            error={!!errors.email}
            helperText={errors.email}
            fullWidth={true}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="mobile"
            label="Mobile"
            name="mobile"
            className={classes.textField}
            defaultValue={model.mobile}
            onChange={onFieldChange}
            type="number"
            error={!!errors.mobile}
            helperText={errors.mobile}
            margin="dense"
            fullWidth={true}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="alt-mobile"
            label="Alternate Mobile"
            name="altMobile"
            className={classes.textField}
            defaultValue={model.altMobile}
            onChange={onFieldChange}
            margin="dense"
            error={!!errors.altMobile}
            helperText={errors.altMobile}
            fullWidth={true}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="no-of-family-members"
            label="No. of family members"
            name="noOfFamilyMembers"
            className={classes.textField}
            defaultValue={model.noOfFamilyMembers}
            onChange={onFieldChange}
            error={!!errors.noOfFamilyMembers}
            helperText={errors.noOfFamilyMembers}
            type="number"
            margin="dense"
            fullWidth={true}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="no-of-earning-family-members"
            label="No. of earning family members"
            name="noOfEarningFamilyMembers"
            className={classes.textField}
            defaultValue={model.noOfEarningFamilyMembers}
            onChange={onFieldChange}
            type="number"
            error={!!errors.noOfEarningFamilyMembers}
            helperText={errors.noOfEarningFamilyMembers}
            margin="dense"
            fullWidth={true}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="annual-income-of-family"
            label="Annual income of family"
            name="annualIncomeOfFamily"
            className={classes.textField}
            defaultValue={model.annualIncomeOfFamily}
            onChange={onFieldChange}
            error={!!errors.annualIncomeOfFamily}
            helperText={errors.annualIncomeOfFamily}
            type="number"
            margin="dense"
            fullWidth={true}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="comments"
            label="Comments"
            name="comments"
            className={classes.textField}
            defaultValue={model.comments}
            onChange={onFieldChange}
            multiline={true}
            rows={2}
            margin="dense"
            fullWidth={true}
            variant="outlined"
          />
        </Grid>
      </Grid>
    );
  }
}

PatientForm.propTypes = {
  model: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default formController(withStyles(styles)(PatientForm));
