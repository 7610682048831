import React from 'react'
import PropTypes from 'prop-types'
import {requestVerificationCode} from "../../reducers/actions/login";
import {ErrorMessage, Field, Form, Formik} from 'formik';

/**
 * Send verification code form
 *
 * @param isFetching
 * @return {*}
 * @constructor
 */
const VerificationCodeForm = ({isFetching, dispatch}) => (
  <Formik initialValues={{username: ''}}
          onSubmit={(values) => {
            dispatch(requestVerificationCode(values.username));
          }}>
    <Form>
      <div className="form-group">
        <Field className="form-control" placeholder="Enter Username" type="text" name="username"/>
        <ErrorMessage name="username" component="div"/>
      </div>
      <div className="form-group text-center">
        <button className="btnSubmit" type="login" disabled={isFetching}>
          {isFetching && "Submitting..."} Submit
        </button>
      </div>
    </Form>
  </Formik>
);

VerificationCodeForm.propTypes = {
  isFetching: PropTypes.bool.isRequired,
};

export default VerificationCodeForm
