import React from 'react';
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import green from "@material-ui/core/es/colors/green";
import withStyles from "@material-ui/core/es/styles/withStyles";
import restCallController from "../../../common/hoc/forms/restCallController";
import Grid from "@material-ui/core/es/Grid/Grid";
import InvoiceFormComponent from "../invoice/InvoiceFormComponent";

const styles = theme => ({
  media: {
    height: 140,
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialogContent: {
    minWidth: 400
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  image: {
    width: "100%",
  }
});

class DocumentInvoiceComponent extends React.Component {

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
  }

  // render view
  render() {
    const {classes, open, handleClose, document, data} = this.props;
    return (
      <Dialog open={open} onClose={handleClose} maxWidth="lg"
              aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Document Invoice details</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={0}>

            {/* Document image */}
            <Grid item md={6} sm={6}>
              <div className={classes.root}>
                {
                  document.document_id &&
                  (document.file_type === 'image/png' || document.file_type === 'image/jpeg') &&
                  <img src={document.document_path} className={classes.image}/>
                }
                {
                  document.document_id && document.file_type === 'application/pdf' &&
                  <img className={classes.image}
                       src="https://banner2.kisspng.com/20180426/zkw/kisspng-computer-icons-pdf-5ae16a1a6187a1.6588193515247222023995.jpg"/>
                }
              </div>
            </Grid>
            {/* Document image */}

            {/* Patient program document verification form */}
            <Grid item md={6} sm={6}>
              <InvoiceFormComponent patient_program_document={document}
                                    data={data}
                                    close={this.props.handleClose}
                                    successCallback={this.props.successCallback}
                                    failureCallback={this.props.failureCallback}/>
            </Grid>
            {/* ./Patient program document verification form */}

          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

DocumentInvoiceComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  document: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  successCallback: PropTypes.func.isRequired,
  failureCallback: PropTypes.func.isRequired
};

export default restCallController(withStyles(styles)(DocumentInvoiceComponent));