import {handleRestRequest, initialRestState} from "../../common/actions/actions";
import {combineReducers} from 'redux'
import {FIND_CFA_LIST, FIND_ORDERS, FIND_VENDOR_LIST} from "./actions/order-actions";

/**
 * Get all orders
 *
 * @param state
 * @param action
 * @return
 */
const orders = (state = {
  ...initialRestState
}, action) => {
  switch (action.type) {
    case FIND_ORDERS:
      return handleRestRequest(state, action);
    default:
      return state
  }
};

/**
 * Find cfa list
 *
 * @param state
 * @param action
 * @return
 */
const cfaList = (state = {
  ...initialRestState
}, action) => {
  switch (action.type) {
    case FIND_CFA_LIST:
      return handleRestRequest(state, action);
    default:
      return state
  }
};

/**
 * Find vendor list
 *
 * @param state
 * @param action
 * @return
 */
const vendors = (state = {
  ...initialRestState
}, action) => {
  switch (action.type) {
    case FIND_VENDOR_LIST:
      return handleRestRequest(state, action);
    default:
      return state
  }
};

/**
 * Reducer
 *
 * @type {Reducer<any> | Reducer<any, AnyAction>}
 */
const reducer = combineReducers({
  orders,
  cfaList,
  vendors
});

export default reducer;
