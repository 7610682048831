import {FAILED_REST_REQUEST, NEW_REST_REQUEST, SUCCESS_REST_REQUEST} from "../../../common/actions/actions";

export const GET_PATIENT = 'GET_PATIENT';
export const FETCH_PATIENT_MASTERS = 'FETCH_PATIENT_MASTERS';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';


// request patient
export const requestPatient = () => ({
  type: GET_PATIENT,
  status: NEW_REST_REQUEST,
});

// success patient request
export const successPatient = (json) => ({
  type: GET_PATIENT,
  status: SUCCESS_REST_REQUEST,
  data: json
});

// failed patient request
export const failedPatient = (errors) => ({
  type: GET_PATIENT,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});

// request update patient
export const requestUpdatePatient = () => ({
  type: UPDATE_PATIENT,
  status: NEW_REST_REQUEST
});

// receive update patient
export const successUpdatePatient = (json) => ({
  type: UPDATE_PATIENT,
  status: SUCCESS_REST_REQUEST,
  data: json
});

// failed update patient request
export const failedUpdatePatient = (errors) => ({
  type: UPDATE_PATIENT,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});


// request patient masters
export const requestFetchPatientMasters = () => ({
  type: FETCH_PATIENT_MASTERS,
  status: NEW_REST_REQUEST
});

// receive patient masters
export const successFetchPatientMasters = (json) => ({
  type: FETCH_PATIENT_MASTERS,
  status: SUCCESS_REST_REQUEST,
  data: json
});

// failed patient masters
export const failedFetchPatientMasters = (errors) => ({
  type: FETCH_PATIENT_MASTERS,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});
