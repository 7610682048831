import React from 'react';
import logo from '../../../logo.svg';
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import Typography from "@material-ui/core/es/Typography/Typography";
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import withStyles from "@material-ui/core/es/styles/withStyles";
import PropTypes from 'prop-types';
import Button from "@material-ui/core/es/Button/Button";

const styles = theme => ({
  hide: {
    display: 'none',
  },
  grow: {
    flexGrow: 1,
  }
});

function PatientHeader(props) {
  const {classes} = props;

  /**
   * logout
   */
  let logout = () => {
    props.history.push(`/auth`)
  };

  return (
    <AppBar position="fixed" color="primary">
      <Toolbar disableGutters={!props.open}>
        <IconButton color="inherit" aria-label="Menu">
          <img src={logo} className="App-logo" alt="logo"/>
        </IconButton>
        <Typography variant="h6" color="inherit" className={classes.grow}>
          Oncology App
        </Typography>
        <Button color="inherit" onClick={logout}>Logout</Button>
      </Toolbar>
    </AppBar>
  );
}

PatientHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PatientHeader);
