import React, {Component} from 'react'
import PropTypes from "prop-types";
import '../scss/patient_program_list.css';
import PatientProgramItem from "./PatientProgramListItemComponent";
import Card from "@material-ui/core/es/Card/Card";
import List from "@material-ui/core/es/List/List";
import withStyles from "@material-ui/core/es/styles/withStyles";
import {withSnackbar} from 'notistack';
import NoContent from "../../common/components/common/NoContent";
import EmptySpace from "../../common/components/common/EmptySpace";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import Typography from "@material-ui/core/es/Typography/Typography";

const styles = theme => ({
  card: {
    borderRadius: 0,
    backgroundColor: "#eee"
  },
  list: {
    maxHeight: "45vh",
    paddingTop: 0,
    paddingBottom: 0,
    overflowY: "scroll",
    backgroundColor: "#eee"
  },
  card_header: {
    backgroundColor: "#eee",
    marginBottom: 0,
    padding: 0,
    fontSize: 14,
    textAlign: "center"
  },
  content: {
    padding: 0
  },
  margin: {
    margin: 4
  }
});

/**
 * Patient documents
 *
 * @param patient
 * @return {*}
 * @constructor
 */
class PatientProgramDueListComponent extends Component {

  state = {};

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
  }

  render() {
    const {classes, patientPrograms, patient_program, title} = this.props;
    let patient_programs = this.props.patientPrograms.map((p, key) => {
      return <PatientProgramItem patientProgram={p} key={key}
                                 selectedPatientProgramId={patient_program.id}
                                 onSelect={this.props.selectPatientProgram}/>;
    });

    return (
      <Card className={classes.card} elevation={0}>
        <CardContent className={classes.content}>
          {
            title &&
            <Typography gutterBottom variant="body2" component="h6" align="center" className={classes.header}>
              {title}
            </Typography>
          }
        </CardContent>
        {
          this.props.patientPrograms.length > 0 &&
          <List className={classes.list} dense={true}>
            {patient_programs}
            <EmptySpace/>
          </List>
        }
        {
          this.props.patientPrograms.length === 0 &&
          <NoContent label="No enrolments"/>
        }
      </Card>
    );
  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
PatientProgramDueListComponent.propTypes = {
  patientPrograms: PropTypes.object.isRequired,
  patient_program: PropTypes.object.isRequired,
  selectPatientProgram: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles, {withTheme: true})(PatientProgramDueListComponent));
