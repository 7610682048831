import React, {Component} from 'react'
import PropTypes from "prop-types";
import '../scss/patient_program_list.css';
import PatientProgramItem from "./PatientProgramListItemComponent";
import Card from "@material-ui/core/es/Card/Card";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import List from "@material-ui/core/es/List/List";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Button from "@material-ui/core/es/Button/Button";
import Modal from "@material-ui/core/es/Modal/Modal";
import {withSnackbar} from 'notistack';
import PatientProgramAppConstants from "../PatientProgramAppConstants";
import SearchBox from "../../common/components/forms/SearchBox";
import Grid from "@material-ui/core/es/Grid/Grid";
import Icon from "@material-ui/core/es/Icon/Icon";
import NoContent from "../../common/components/common/NoContent";
import PatientEnrolComponent from "./PatientEnrolComponent";

const styles = theme => ({
  card: {
    borderRadius: 0,
    backgroundColor: "#eee"
  },
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 70,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: 'none',
  },
  list: {
    maxHeight: "78vh",
    paddingTop: 0,
    paddingBottom: 0,
    overflowY: "scroll",
    backgroundColor: "#eee"
  },
  card_header: {
    backgroundColor: "#eee",
    marginBottom: 0,
    padding: 0,
    fontSize: 14,
    textAlign: "center"
  },
  content: {
    padding: 0
  },
  margin: {
    margin: 4
  }
});

/**
 * Patient documents
 *
 * @param patient
 * @return {*}
 * @constructor
 */
class PatientProgramListComponent extends Component {

  state = {
    enrol_patient: false
  };

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.toggleEnrolPatientFlag = this.toggleEnrolPatientFlag.bind(this);
  }

  /**
   * Patient toggle
   */
  toggleEnrolPatientFlag = () => {
    this.setState({...this.state, enrol_patient: !this.state.enrol_patient});
  };

  /**
   * Patient enrolled
   */
  patientEnrolSuccessCallback = () => {
    let {enqueueSnackbar, onSearch} = this.props;
    enqueueSnackbar('Patient enrolled successfully!');
    this.toggleEnrolPatientFlag();
    onSearch("");
  };

  //render content
  render() {
    const {enrol_patient} = this.state;
    const {classes, onSearch, patientPrograms: {success, data}, patient_program} = this.props;
    const {programs: {success: programsSuccess, data: programsData}} = this.props;

    // prepare patent programs
    let patientPrograms = success ? data.patient_programs.map((p, key) => {
      return <PatientProgramItem patientProgram={p} key={key}
                                 selectedPatientProgramId={patient_program.id}
                                 onSelect={this.props.selectPatientProgram}/>;
    }) : '';

    return (
      <Card className={classes.card} elevation={0}>
        <CardContent className={classes.content}>
          <Grid container spacing={0}>
            <Grid item md={8} sm={8}>
              <SearchBox placeholder={"Search enrolments"} onChange={onSearch}/>
            </Grid>
            <Grid item md={4} sm={4}>
              <Button variant="outlined" size="small" color="primary" className={classes.margin}
                      onClick={this.toggleEnrolPatientFlag}>
                <Icon color="primary" fontSize="small">
                  add
                </Icon>Add new
              </Button>
            </Grid>
          </Grid>
        </CardContent>
        {
          success &&
          <List className={classes.list} dense={true}>
            {patientPrograms}
          </List>
        }
        {
          success && data.patient_programs.length === 0 &&
          <NoContent label="No enrolments"/>
        }

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={enrol_patient}
          onClose={this.toggleEnrolPatientFlag}>
          <div style={PatientProgramAppConstants.getModalStyle()} className={classes.paper}>
            {
              programsSuccess &&
              <PatientEnrolComponent close={this.toggleEnrolPatientFlag}
                                     programs={programsData.programs}
                                     successCallback={this.patientEnrolSuccessCallback}
                                     failureCallback={() => {
                                     }}/>
            }
          </div>
        </Modal>
      </Card>
    );
  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
PatientProgramListComponent.propTypes = {
  patientPrograms: PropTypes.object.isRequired,
  patient_program: PropTypes.object.isRequired,
  selectPatientProgram: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  programs: PropTypes.object.isRequired,
  onSearch: PropTypes.func.isRequired
};

export default withSnackbar(withStyles(styles, {withTheme: true})(PatientProgramListComponent));
