import Constants from "../Constants";
import moment from "moment";

export class ReportConstant {
  static REPORT_URL = Constants.PATIENT_PROGRAM_API_ENDPOINT + 'reports';

  /**
   * Convert to month and year string
   * @param month
   * @param year
   * @return {string}
   */
  static convertToMonthYearString(month, year) {
    let date = moment();
    date.month(month - 1).year(year);
    return date.format("MMM 'YY");
  }

  /**
   * return difference in dates
   * @param startDate
   * @param endDate
   */
  static differenceInDates(startDate, endDate) {
    if (!startDate || !endDate)
      return "NA";
    return moment(startDate).diff(endDate, 'days')
  }


  /**
   * return difference in dates
   * @param startDate
   * @param endDate
   */
  static differenceFromCurrentMonth(startDate) {
    if (!startDate)
      return "0";
    return moment().diff(startDate, 'months')
  }

  /**
   * Format String date
   *
   * @param date
   * @return {string}
   */
  static formatDate(date) {
    if (!date)
      return "";
    return moment(date).format("DD-MMM-YYYY");
  }

  /**
   * Get month details
   *
   * @param date
   * @return {string}
   */
  static getMonth(date) {
    if (!date)
      return "";
    return moment(date).format("MMM")
  }

  static maskNumber(data) {
    return 'XXXXXXXX' + data.substring(data.length - 2);
  }

  /**
   * Get month year
   *
   * @param date
   * @return {string}
   */
  static getMonthYear(date) {
    if (!date)
      return "";
    return moment(date).format("MMM, YY")
  }

  /**
   * Round digits to single decimal
   *
   * @param digits
   * @return {*}
   */
  static roundToOneDecimal(digits) {
    if (!digits)
      return 0.0;
    return parseFloat(digits).toFixed(1)
  }
}