import {put} from "../../common/services/BaseService";
import {PatientConstants} from "../PatientConstants";


/**
 * Update patient program document
 *
 * @param document_id
 * @param document
 * @return {Promise<Response>}
 */
export const updatePatientProgramDocument = (document_id, document) => {
  return put(`${PatientConstants.PATIENT_PROGRAM_DOCUMENTS_URL}/${document_id}`, document).then(json => {
    return json;
  }).catch(error => {
    throw(error);
  });
};