import {FAILED_REST_REQUEST, NEW_REST_REQUEST, SUCCESS_REST_REQUEST} from "../../../common/actions/actions";

export const FIND_All_PRODUCT = 'FIND_All_PRODUCT';

// request products
export const requestProducts = () => ({
  type: FIND_All_PRODUCT,
  status: NEW_REST_REQUEST,
});

// success products request
export const successProducts = (json) => ({
  type: FIND_All_PRODUCT,
  status: SUCCESS_REST_REQUEST,
  data: json
});

// failed products request
export const failedProducts = (errors) => ({
  type: FIND_All_PRODUCT,
  status: FAILED_REST_REQUEST,
  errors: errors.errors
});