import React from 'react'
import PropTypes from "prop-types";
import Grid from "@material-ui/core/es/Grid/Grid";
import withStyles from "@material-ui/core/es/styles/withStyles";
import formController from "../../../../common/hoc/forms/formController";
import OutlinedInput from "@material-ui/core/es/OutlinedInput/OutlinedInput";
import Select from "@material-ui/core/es/Select/Select";
import InputLabel from "@material-ui/core/es/InputLabel/InputLabel";
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  picker: {
    marginLeft: 8,
    marginRight: 8
  }
});

class PatientProgramDocumentForm extends React.Component {
  render() {
    const {classes, onFieldChange, model, data: {document_groups, document_types}} = this.props;
    return (<Grid container spacing={8}>
      <Grid item xs={12}>
        <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
          <InputLabel ref={ref => {
            this.InputLabelRef = ref;
          }} htmlFor="document-group">Document Group</InputLabel>
          <Select value={model.document_group} onChange={onFieldChange}
                  input={
                    <OutlinedInput labelWidth={125} name="document_group" id="document-group"/>
                  }>
            {
              document_groups.map((d, i) => {
                return <MenuItem value={d.name} key={i}>{d.name}</MenuItem>
              })
            }
          </Select>
        </FormControl>
      </Grid>
      {
        model.document_group &&
        <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
            <InputLabel ref={ref => {
              this.InputLabelRef = ref;
            }} htmlFor="document-type">Document Type</InputLabel>
            <Select value={model.document_type} onChange={onFieldChange}
                    input={
                      <OutlinedInput labelWidth={125} name="document_type" id="document-type"/>
                    }>
              {
                document_types.map((d, i) => {
                  return <MenuItem value={d.name} key={i}>{d.name}</MenuItem>
                })
              }
            </Select>
          </FormControl>
        </Grid>
      }
    </Grid>);
  }
}

PatientProgramDocumentForm.propTypes = {
  model: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired
};

export default formController(withStyles(styles)(PatientProgramDocumentForm));