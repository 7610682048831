import React from 'react'
import PropTypes from "prop-types";
import Grid from "@material-ui/core/es/Grid/Grid";
import withStyles from "@material-ui/core/es/styles/withStyles";
import formController from "../../../../common/hoc/forms/formController";
import DateFnsUtils from "@date-io/date-fns/build/index";
import TextField from "@material-ui/core/es/TextField/TextField";
import {DatePicker, MuiPickersUtilsProvider, TimePicker} from "material-ui-pickers";
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";
import OutlinedInput from "@material-ui/core/es/OutlinedInput/OutlinedInput";
import FormHelperText from "@material-ui/core/es/FormHelperText/FormHelperText";
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import InputLabel from "@material-ui/core/es/InputLabel/InputLabel";
import Select from "@material-ui/core/es/Select/Select";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  picker: {
    marginLeft: 8,
    marginRight: 8
  }
});

class FollowUpForm extends React.Component {

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.onFollowUpDateChange = this.onFollowUpDateChange.bind(this);
  }

  /**
   * On treatment start date change
   *
   * @param date
   */
  onFollowUpDateChange = (date) => {
    let {onFieldChange} = this.props;
    onFieldChange({target: {name: 'selected_date', value: date}});
  };

  /**
   * Render view
   */
  render() {
    const {classes, onFieldChange, model, data: {statuses}, errors} = this.props;
    return (<Grid container spacing={8}>
      {
        !model.id &&
        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item xs={12}>
              <DatePicker margin="dense" variant="outlined" fullWidth={true} label="Follow up Date"
                          value={model.selected_date} onChange={this.onFollowUpDateChange}
                          format={"dd MMM` yyyy"} className={classes.picker}
                          mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}/>
            </Grid>
            <Grid item xs={12}>
              <TimePicker margin="dense" variant="outlined" fullWidth={true} label="Follow up Time"
                          value={model.selected_date} onChange={this.onFollowUpDateChange}
                          className={classes.picker}
                          mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}/>
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
      }
      <Grid item xs={12}>
        <TextField id="comment" label="Comments" className={classes.textField} name="comment"
                   value={model.comment} onChange={onFieldChange}
                   margin="normal" fullWidth={true} variant="outlined"
                   error={!!errors.comment} helperText={errors.comment}/>
      </Grid>
      {
        model.id &&
        <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.picker} fullWidth={true} margin="dense">
            <InputLabel ref={ref => {
              this.InputLabelRef = ref;
            }} htmlFor="status">Status</InputLabel>
            <Select value={model.status} onChange={onFieldChange}
                    input={
                      <OutlinedInput labelWidth={100} name="status" id="status"/>
                    }>
              {
                statuses.map((d, i) => {
                  return <MenuItem value={d} key={i}>{d}</MenuItem>
                })
              }
            </Select>
            {
              errors.status &&
              <FormHelperText>{errors.status}</FormHelperText>
            }
          </FormControl>
        </Grid>
      }
    </Grid>);
  }
}

FollowUpForm.propTypes = {
  data: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired
};

export default formController(withStyles(styles)(FollowUpForm));