import React from 'react'
import PropTypes from "prop-types";
import Card from "@material-ui/core/es/Card/Card";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import List from "@material-ui/core/es/List/List";
import withStyles from "@material-ui/core/es/styles/withStyles";
import {withSnackbar} from 'notistack';
import FollowUpListItemComponent from "./FollowUpListItemComponent";
import Typography from "@material-ui/core/es/Typography/Typography";
import NoContent from "../../../common/components/common/NoContent";

const styles = theme => ({
  card: {
    borderRadius: 0
  },
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 70,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: 'none',
  },
  list: {
    maxHeight: "65vh",
    paddingTop: 0,
    paddingBottom: 0,
    overflowY: "scroll"
  },
  content: {
    padding: 0
  },
  header: {
    backgroundColor: "#eee",
    margin: 0
  }
});

/**
 * Patient documents
 *
 * @param patient
 * @return {*}
 * @constructor
 */
class FollowUpListComponent extends React.Component {

  state = {};

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
  }

  //render content
  render() {
    const {success, data} = this.props.follow_ups;
    const {classes, selectFollowUp, selected_follow_up, title, refresh} = this.props;

    // prepare patent programs
    let follow_ups = success ? data.follow_ups.map((p, key) => {
      return <FollowUpListItemComponent follow_up={p} key={key}
                                        refresh={refresh}
                                        selected_follow_up_id={selected_follow_up.id}
                                        onSelect={selectFollowUp}/>;
    }) : '';

    return (
      <Card className={classes.card} elevation={0}>
        <CardContent className={classes.content}>
          {
            title &&
            <Typography gutterBottom variant="body2" component="h6" align="center" className={classes.header}>
              {title}
            </Typography>
          }
        </CardContent>
        {
          success && follow_ups.length > 0 &&
          <List className={classes.list}>
            {follow_ups}
          </List>
        }
        {
          success && follow_ups.length === 0 &&
          <NoContent label="No follow ups"/>
        }
      </Card>
    );
  }
}

/**
 * Properties
 *
 * @type {{fetch: *}}
 */
FollowUpListComponent.propTypes = {
  title: PropTypes.string,
  follow_ups: PropTypes.object.isRequired,
  selected_follow_up: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  selectFollowUp: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired
};

export default withSnackbar(withStyles(styles, {withTheme: true})(FollowUpListComponent));
